import getClassName from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { DatasetMetadata, ErrorPage } from "#components/index.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import useHideFooter from "../../helpers/hooks/useHideFooter.ts";
import { IComponentProps } from "../index.ts";
import Class from "./Class.tsx";
import SubclassTree from "./SubclassTree.tsx";
import useCurrentClass from "./useCurrentClass.ts";
import styles from "./styles/index.scss";

const Ontology: React.FC<IComponentProps> = () => {
  useHideFooter();
  const dispatch = useDispatch();
  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();
  const currentClass = useCurrentClass();

  React.useEffect(() => {
    if (currentDs?.id) {
      dispatch({
        type: "triply/data/SET_LAST_ONTOLOGY_RESOURCE",
        datasetId: currentDs.id,
        resource: currentClass,
      });
    }
  }, [currentClass, currentDs?.id, dispatch]);

  if (!currentAccount || !currentDs) return <ErrorPage statusCode={404} />;

  return (
    <div className={getClassName(styles.container)}>
      <DatasetMetadata currentAccount={currentAccount} currentDs={currentDs} title="Ontology" />
      <div className={getClassName(styles.content, "flex g-5")}>
        <SubclassTree />
        <div className="grow">
          <Class />
        </div>
      </div>
    </div>
  );
};

export default Ontology;
