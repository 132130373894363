import { MenuItem } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Alert, Avatar, Button, Dialog, FontAwesomeIcon } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import { transferQuery } from "#reducers/queries.ts";
import styles from "./style.scss";

interface Props {}

const TransferQuery: React.FC<Props> = ({}) => {
  const authenticatedUser = useAuthenticatedUser();
  const [toAccount, setToAccount] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const query = useSelector((state: GlobalState) => state.queries.current);

  const dispatch = useDispatch();
  const history = useHistory<{ transferQueryDialogOpen?: boolean }>();
  const location = useLocation<{ transferQueryDialogOpen?: boolean }>();
  if (!authenticatedUser || !query) return null;
  const close = () => {
    setToAccount(undefined);
    history.goBack();
  };
  const accounts = [authenticatedUser, ...(authenticatedUser.orgs || [])].filter(
    (account) => account.accountName !== query.owner.accountName
  );

  return (
    <>
      <MenuItem
        disabled={accounts.length === 0}
        title={accounts.length === 0 ? "No account available to transfer to" : "Transfer a query to another user"}
        onClick={() => {
          history.push({ state: { transferQueryDialogOpen: true } });
        }}
      >
        Transfer
      </MenuItem>
      <Dialog open={!!location.state?.transferQueryDialogOpen} onClose={close} title="Transfer query">
        <div className="px-5 pb-5">
          {!toAccount && (
            <>
              <h5 className="mt-0 mb-3">Select the account to transfer the query to</h5>
              <div className={getClassName(styles.moveAccounts, "py-3")}>
                {accounts.map((account) => {
                  const accountName = account.accountName;
                  return (
                    <button
                      key={account.accountName}
                      className={getClassName(styles.moveAccount, "p-2")}
                      onClick={() => {
                        setToAccount(account.name ?? accountName);
                        dispatch<typeof transferQuery>(transferQuery(query.link, accountName))
                          .then(({ body: newQuery }) => {
                            history.replace(`/${newQuery.owner.accountName}/-/queries/${newQuery.name}`);
                          })
                          .catch((e) => setError(e.message));
                      }}
                      type="button"
                    >
                      <Avatar
                        size="lg"
                        avatarName={accountName}
                        avatarUrl={account.avatarUrl}
                        title={account.accountName}
                        alt=""
                      />
                      <span className={getClassName(styles.moveAccountName, "mt-2")}>
                        {account.name || account.accountName}
                      </span>
                    </button>
                  );
                })}
              </div>
            </>
          )}
          {!error && toAccount && (
            <div>
              <FontAwesomeIcon icon={["fas", "cog"]} spin /> {`Transferring query to ${toAccount}`}
            </div>
          )}
          {!!error && <Alert transparent message={error} className="mt-3 mb-5" />}
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default TransferQuery;
