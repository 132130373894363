import { Alert, Autocomplete, Skeleton, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import { cloneDeep, merge } from "lodash-es";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { useApplyPrefixes, useRemovePrefixes } from "../../../components/Prefixed";
import useCurrentClass from "../useCurrentClass";
import useSparql from "../useSparql";
import { validateIri } from "./helpers";
import localTheme from "./Theme";
import { ObjectPropertyData } from "./Types";

const PropertyForm: React.FC<{ onSubmit: (values: ObjectPropertyData) => Promise<void> }> = ({ onSubmit }) => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    setError,
    watch,
  } = useForm<ObjectPropertyData>({
    defaultValues: async () => ({ label: "", id: applyPrefixes(`${datasetUrl}/def/${Date.now()}`), range: "" }),
    mode: "onChange",
  });

  const currentDs = useCurrentDataset()!;
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });
  const currentClass = useCurrentClass();

  const applyPrefixes = useApplyPrefixes();
  const removePrefixes = useRemovePrefixes();

  const currentPropertyValue = removePrefixes(watch("id"));

  const { data: existingProperties } = useSparql<
    {
      id: string;
      label: string;
      description?: string;
      rangeOptions: { id: string; label?: string; description?: string }[];
    }[]
  >(
    `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>
    prefix owl: <http://www.w3.org/2002/07/owl#>

    select
      ?id
      ?rangeOptions_id
      (sample(?label_t) as ?label)
      (sample(?description_t) as ?description)
      (sample(?rangeOptions_label_t) as ?rangeOptions_label)
      (sample(?rangeOptions_description_t) as ?rangeOptions_description)
    where {
      bind(<${currentClass}> as ?class)
      # ?id rdfs:subPropertyOf*/rdfs:domain/^rdfs:subClassOf* ?class.
      ?id rdfs:domain/^rdfs:subClassOf* ?class.
      # filter not exists {
      #   ?id rdfs:subPropertyOf*/rdfs:range/rdfs:subClassOf* rdfs:Literal.
      # }
      ?id a owl:ObjectProperty.
      ?id rdfs:label|skos:prefLabel ?label_t
      optional {
        ?id rdfs:comment|skos:definition ?description_t
      }
      # ?id rdfs:subPropertyOf*/rdfs:range/^rdfs:subClassOf* ?rangeOptions_id.
      ?id rdfs:range/^rdfs:subClassOf* ?rangeOptions_id.
      filter (!regex(str(?rangeOptions_id), "\.well-known/genid"))
      optional {
        ?rangeOptions_id rdfs:label|skos:prefLabel ?rangeOptions_label_t
      }
      optional {
        ?rangeOptions_id rdfs:comment|skos:definition ?rangeOptions_description_t
      }

    }
    group by ?id ?rangeOptions_id
    order by ?label
    `,
    { singularizeVariables: {} }
  );

  const { data: allRangeOptions } = useSparql<{ id: string; label?: string; description?: string }[]>(`
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix owl: <http://www.w3.org/2002/07/owl#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>

    select
      ?id
      (sample(?l) as ?label)
      (sample(?d) as ?description)
    where {
      {
        ?id a rdfs:Class
      } union {
        ?id a owl:Class
      } union {
        ?id rdfs:subClassOf|^rdfs:subClassOf []
      }
      filter(?id != rdfs:Literal)
      filter not exists {
        ?id rdfs:subClassOf+ rdfs:Literal
      }
      filter not exists {
        ?id a rdfs:Datatype
      }
      filter (!regex(str(?id), "\.well-known/genid"))

      optional {
        ?id rdfs:label|skos:prefLabel ?l
      }
      optional {
        ?id rdfs:comment|skos:definition ?d
      }
    }
    group by ?id
    order by ?label
    `);

  const currentProperty = currentPropertyValue
    ? existingProperties?.find((p) => p.id === currentPropertyValue)
    : undefined;
  const rangeOptions = currentProperty?.rangeOptions || allRangeOptions || [];

  const submit = async (values: ObjectPropertyData) => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setError("root.serverError", {
        type: "500",
      });
    }
  };

  if (!existingProperties || !allRangeOptions) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }

  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
        <FormField label="Label" required>
          <Controller
            name={"label"}
            control={control}
            rules={{
              validate: (value) => {
                if (!value.trim()) return "A label is required.";
              },
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                required
                autoFocus
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Property IRI" required>
          <Controller
            name={"id"}
            control={control}
            rules={{
              validate: validateIri,
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              const currentProperty = existingProperties.find((p) => value === p.id);
              return (
                <Autocomplete
                  fullWidth
                  options={existingProperties}
                  {...rest}
                  onChange={(_e, value) => {
                    if (typeof value === "string") {
                      onChange(removePrefixes(value.trim()));
                    } else if (typeof value === "object" && value && "id" in value) {
                      onChange(value.id);
                    } else {
                      onChange(null);
                    }
                  }}
                  freeSolo
                  value={value || ""}
                  renderInput={(params) => (
                    <TextField
                      {...(params as any)}
                      fullWidth
                      required
                      error={!!error}
                      helperText={
                        error?.message ||
                        (currentProperty && (currentProperty.description || currentProperty.label)) ||
                        (value ? "New property" : "")
                      }
                      onBlur={(e) => {
                        const currentProperty = existingProperties.find(
                          (p) => removePrefixes(e.target.value.trim()) === p.id
                        );
                        if (currentProperty) {
                          onChange(currentProperty.id);
                        } else {
                          onChange(removePrefixes(e.target.value.trim()));
                        }
                      }}
                    />
                  )}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") return applyPrefixes(option);
                    return applyPrefixes(option.id);
                  }}
                  renderOption={(props, option) => {
                    if (typeof option === "string") return <li>{option}</li>;
                    return (
                      <li {...props}>
                        <div>
                          <span>{option.label}</span>
                          <div>
                            <Typography variant="caption">{applyPrefixes(option.id)}</Typography>
                          </div>
                          {/*<div>
                            <Typography variant="body2">{option.description}</Typography>
                          </div>*/}
                        </div>
                      </li>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (!option) return false;
                    if (typeof value === "string") {
                      return value === option.id;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                />
              );
            }}
          />
        </FormField>

        <FormField label="Description">
          <Controller
            name={"description"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                minRows={3}
                multiline
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Range" required>
          <Controller
            name={`range`}
            control={control}
            rules={{
              validate: validateIri,
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              return (
                <Autocomplete
                  {...rest}
                  fullWidth
                  options={rangeOptions}
                  getOptionLabel={(option) => option.label || applyPrefixes(option.id)}
                  onChange={(_e, value) => {
                    onChange(value ? value.id : undefined);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <div>
                          <span>{option.label}</span>
                          <div>
                            <Typography variant="caption">{applyPrefixes(option.id)}</Typography>
                          </div>
                        </div>
                      </li>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option && option.id === value.id;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...(params as any)}
                      required
                      error={!!error}
                      helperText={error?.message || rangeOptions?.find((r) => r.id === value)?.description}
                      fullWidth
                    />
                  )}
                />
              );
            }}
          />
        </FormField>

        <FormField label="Min count">
          <Controller
            name={"minCount"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type="number"
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Max count">
          <Controller
            name={"maxCount"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type="number"
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <LoadingButton color="secondary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </LoadingButton>

        {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
      </form>
    </ThemeProvider>
  );
};

export default PropertyForm;
