import getClassName from "classnames";
import { push } from "connected-react-router";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { asyncConnect } from "redux-connect";
import { mergePrefixArray } from "@triply/utils/prefixUtils.js";
import { Alert, Button, DatasetMetadata, ErrorPage, FlexContainer, SinkList } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import { getCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import {
  classFrequencyIsLoadedFor,
  classFrequencyIsOutdatedFor,
  getClassFrequency,
  getClassFrequencyFor,
} from "#reducers/insights.ts";
import { showNotification } from "#reducers/notifications.ts";
import BarChart from "./barChart.tsx";
import GraphSelector from "./GraphSelector.tsx";
import styles from "./style.scss";

const ClassFrequency: React.FC<IComponentProps> = (props) => {
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();
  const query = parseSearchString(props.location.search);
  const selectedGraph = typeof query.graph === "string" ? query.graph : undefined;

  const {
    classFrequency,
    currentAccount,
    currentDs,
    largestGraph,
    localPrefixes,
    globalPrefixes,
    classFrequencyIsOutdated,
  } = useSelector((state: GlobalState) => {
    const currentDs = getCurrentDataset(state);

    return {
      classFrequency:
        (currentDs && selectedGraph && getClassFrequencyFor(state, currentDs, selectedGraph)) || undefined,
      classFrequencyIsOutdated:
        !!currentDs && !!selectedGraph && classFrequencyIsOutdatedFor(state, currentDs, selectedGraph),
      currentAccount: getCurrentAccount(state),
      currentDs: currentDs,
      largestGraph: currentDs && state.graphs[currentDs.id].largestList[0]?.graphName,
      localPrefixes: currentDs?.prefixes,
      globalPrefixes: state.config.clientConfig?.prefixes,
    };
  });

  React.useEffect(() => {
    if (classFrequencyIsOutdated && currentDs && currentAccount && selectedGraph) {
      dispatch<typeof getClassFrequency>(getClassFrequency(currentAccount, currentDs, selectedGraph))
        .then(() => dispatch(showNotification("The data was reloaded after a change in the dataset.", "info")))
        .catch(() => {});
    }
    // Fetch class frequency again when it is outdated (when a job finishes).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classFrequencyIsOutdated]);

  const loadNextPage = React.useCallback(() => {
    if (currentAccount && currentDs && selectedGraph) {
      dispatch(getClassFrequency(currentAccount, currentDs, selectedGraph, classFrequency?.nextPage));
    }
  }, [classFrequency, currentAccount, currentDs, dispatch, selectedGraph]);

  if (!currentAccount || !currentDs) {
    return <ErrorPage statusCode={404} />;
  }

  if (!selectedGraph)
    return (
      <Redirect
        to={{
          pathname: `/${currentAccount.accountName}/${currentDs.name}/insights/classFrequency`,
          search: stringifyQuery({ graph: largestGraph }),
        }}
      />
    );

  if (!classFrequency?.data) {
    return <ErrorPage statusCode={404} message={`No data found for graph '${query.graph}'.`} />;
  }

  const prefixes = mergePrefixArray(localPrefixes || [], globalPrefixes || []);

  return (
    <FlexContainer>
      <DatasetMetadata
        currentPath={props.location.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title="Class frequency"
      />
      <Alert
        className="my-5 shadow"
        info
        message={
          <>
            The class frequency diagram shows how often classes and properties appear in a graph. Check{" "}
            <a
              className={styles.whiteLink}
              href="https://triply.cc/docs/triply-db-getting-started#class-frequency"
              target="_blank"
            >
              our documentation
            </a>{" "}
            to learn more.
          </>
        }
      />
      <SinkList>
        <div className={getClassName(styles.item, styles.barChartItem)}>
          <GraphSelector
            termsUrl={constructUrlToApi({
              pathname: `/datasets/${currentAccount.accountName}/${currentDs.name}/terms`,
            })}
            selectGraph={(graph) => {
              dispatch(push({ pathname: props.location.pathname, search: stringifyQuery({ graph: graph }) }));
            }}
            prefixes={prefixes}
            selectedGraph={selectedGraph}
          />
          {classFrequency.data.length === 0 ? (
            <Alert warning message={"The class frequency cannot be visualized because it is too large."} />
          ) : (
            <>
              <BarChart key={selectedGraph} data={classFrequency.data} prefixes={prefixes} />
              <>
                {classFrequency.nextPage && (
                  <div className={styles.showMore}>
                    <Button variant="text" size="small" onClick={loadNextPage}>
                      Show more...
                    </Button>
                  </div>
                )}
              </>
            </>
          )}
        </div>
      </SinkList>
    </FlexContainer>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState }, location }) => {
      const query = parseSearchString(location.search);
      const state = getState();
      const currentDs = getCurrentDataset(state);
      const currentAccount = getCurrentAccount(state);
      if (
        currentDs &&
        currentAccount &&
        typeof query.graph === "string" &&
        currentDs.graphCount > 0 &&
        !classFrequencyIsLoadedFor(state, currentDs, query.graph)
      ) {
        return dispatch<any>(getClassFrequency(currentAccount, currentDs, query.graph));
      }
    },
  },
])(ClassFrequency) as typeof ClassFrequency;
