import * as connectedReactRouter from "connected-react-router";
import * as ReduxForm from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { createQuery, formDataToUpdateObj } from "#reducers/queries.ts";
import useDispatch from "./useDispatch.ts";

export function useCreateQuery(accountName: string | undefined, replaceCurrentPage: boolean) {
  const dispatch = useDispatch();
  return (values: Forms.QueryMeta.FormData) => {
    if (accountName) {
      return dispatch<typeof createQuery>(createQuery(accountName, formDataToUpdateObj(values)))
        .catch((e) => {
          if (e?.status === 409) {
            throw new ReduxForm.SubmissionError({ name: "URL is already in use." });
          }
          throw new ReduxForm.SubmissionError({ _error: e.message });
        })
        .then((response) => {
          dispatch(
            connectedReactRouter[replaceCurrentPage ? "replace" : "push"](
              `/${accountName}/-/queries/${response.body.name}`
            )
          );
        });
    }
  };
}
