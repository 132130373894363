import { Box, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { stratify } from "d3";
import { flatMap, fromPairs } from "lodash-es";
import { Tree, TreeMultipleSelectionKeys, TreeNodeTemplateOptions } from "primereact/tree";
import { TreeNode } from "primereact/treenode";
import * as React from "react";
import { useHistory } from "react-router";
import { CachePolicies } from "use-http";
import { getPrefixed } from "@triply/utils/prefixUtils.js";
import useAcl from "#helpers/hooks/useAcl.ts";
import { FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import { useDatasetPrefixes } from "../../helpers/hooks/useDatasetPrefixes";
import useFetch from "../../helpers/hooks/useFetch";
import { stringifyQuery } from "../../helpers/utils";
import { useCurrentAccount } from "../../reducers/app";
import { Prefixes, useCurrentDataset } from "../../reducers/datasetManagement";
import AddClass from "./AddClass";
import useCurrentClass from "./useCurrentClass";
import prime from "./styles/prime.scss";

interface Item {
  id: string;
  iri: string;
  parent: string;
  label?: string;
}

const getKey = (item: Item) => (item.parent ? `${item.parent}>${item.id}` : item.id);

const getHierarchy = (data: Item[], currentClass: string, prefixes: Prefixes) => {
  const treeData = flatMap(data, (item) => {
    const parents = data.filter((p) => p.id === item.parent);
    if (parents.length === 0)
      return [
        {
          id: `tree:root>${item.id}`,
          parent: "tree:root",
          label: item.label,
          iri: item.id,
        },
      ];
    return parents.map((parent) => ({
      id: getKey(item),
      parent: getKey(parent),
      label: item.label,
      iri: item.id,
    }));
  });

  treeData.push({ id: "tree:root", iri: "tree:root" } as any);

  let hierarchy;

  try {
    hierarchy =
      treeData.length > 0
        ? stratify<Item & { key?: string }>()
            .id((item) => item.id)
            .parentId((item) => item.parent)(treeData as any)
        : undefined;
  } catch (e) {
    console.error(e);
  }

  const selectedKeys: TreeMultipleSelectionKeys = {};

  hierarchy?.eachBefore((node) => {
    (node as any).label = node.data.label || getPrefixed(node.data.iri, prefixes) || node.data.iri;
    (node as any).key = node.data.key = node.data.id;

    if (node.data.iri === currentClass) selectedKeys[node.data.id] = true;
  });

  hierarchy?.sort((a: any, b: any) => {
    if ((a.data.label && b.data.label) || (!a.data.label && !b.data.label)) {
      return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    } else if (a.data.label) {
      return -1;
    } else {
      return 1;
    }
  });

  return { hierarchy, selectedKeys };
};

const SubclassTree: React.FC<{}> = ({}) => {
  const history = useHistory();
  const currentClass = useCurrentClass();
  const prefixes = useDatasetPrefixes();

  const url = useConstructUrlToApi()({ pathname: "/internal/sparql", fromBrowser: true });
  const { post, data, error } = useFetch<Item[]>(url, {
    cachePolicy: CachePolicies.NO_CACHE,
    credentials: "same-origin",
  });

  const currentAccountName = useCurrentAccount()!.accountName;
  const currentDatasetName = useCurrentDataset()!.name;
  const currentDatasetUpdateTime = useCurrentDataset()!.lastGraphsUpdateTime;

  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const mayManageOntology = acl.check({
    action: "manageOntology",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  React.useEffect(() => {
    post({
      account: currentAccountName,
      dataset: currentDatasetName,
      queryString: `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix owl: <http://www.w3.org/2002/07/owl#>
select ?id ?parent (sample(?l) as ?label) where {
  {
    {
      select distinct ?class where {
        {
          ?class a rdfs:Class
        } union {
          ?class a owl:Class
        } union {
          ?class rdfs:subClassOf|^rdfs:subClassOf []
        }
        filter (!regex(str(?class), "\.well-known/genid"))
      }
    }
    optional {
      ?class rdfs:subClassOf ?superclass
    }
    optional {
      ?class rdfs:label|skos:prefLabel ?l
    }
    bind(coalesce(?superclass, <tree:root>) as ?parent)
    bind(?class as ?id)
  }
}
group by ?id ?parent
      `,
    }).catch(() => {});
  }, [post, currentAccountName, currentDatasetName, currentDatasetUpdateTime]);

  const [expandedKeys, setExpandedKeys] = React.useState<{ [key: string]: boolean }>({
    "tree:root>http://www.w3.org/2000/01/rdf-schema#Resource": true,
  });

  React.useEffect(() => {
    if (currentClass && data) {
      const { hierarchy } = getHierarchy(data, currentClass, prefixes);
      const toExpand = fromPairs(
        hierarchy
          ?.find((node) => node.data.iri === currentClass)
          ?.ancestors()
          ?.slice(1)
          .map((node) => [node.data.id, true]) || []
      );
      setExpandedKeys((expandedKeys) => {
        return {
          ...expandedKeys,
          ...toExpand,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClass, data]);

  const filterInputRef = React.useRef<HTMLInputElement>(null);

  if (!data || error) return null;

  const { hierarchy, selectedKeys } = getHierarchy(data, currentClass, prefixes);

  return (
    <Paper className="p-5">
      <Box sx={{ width: 400, height: "100%" }}>
        {hierarchy && (
          <div className={prime.prime}>
            <Tree
              value={hierarchy.children || []}
              expandedKeys={expandedKeys}
              onToggle={(e) => setExpandedKeys(e.value)}
              dragdropScope=""
              filter
              filterMode="lenient"
              filterPlaceholder="Filter"
              filterTemplate={({ filterInputChange, filterOptions }: any) => {
                return (
                  <div className="mb-5 flex center g-5">
                    <TextField
                      placeholder="Filter"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" className="m-3">
                            {filterInputRef.current?.value ? (
                              <IconButton
                                onClick={() => {
                                  filterOptions.reset();
                                  if (filterInputRef.current) {
                                    filterInputRef.current.value = "";
                                    filterInputRef.current.focus();
                                  }
                                }}
                                sx={{ marginRight: "-10px" }}
                              >
                                <FontAwesomeIcon icon="times" size="xs" fixedWidth />
                              </IconButton>
                            ) : (
                              <FontAwesomeIcon icon="search" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onChange={filterInputChange}
                      inputRef={filterInputRef}
                    />
                    {mayManageOntology && <AddClass />}
                  </div>
                );
              }}
              nodeTemplate={nodeTemplate}
              selectionMode="multiple"
              onSelect={({ node }) => {
                history.push({ search: stringifyQuery({ class: node.data.iri }) });
              }}
              selectionKeys={selectedKeys}
            />
          </div>
        )}
      </Box>
    </Paper>
  );
};

const nodeTemplate = (node: TreeNode, options: TreeNodeTemplateOptions) => {
  return <div className={options.className}>{node.label}</div>;
};

export default SubclassTree;
