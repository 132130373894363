import { Badge, IconButton } from "@mui/material";
import getClassName from "classnames";
import { Node as DagreNode } from "dagre";
import { Location } from "history";
import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "#components/index.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { NodeData } from "./useData.ts";
import { escapeId, getLocalName } from "./utils.ts";
import styles from "./style.scss";

const Node: React.FC<{ data: NodeData; layoutData?: DagreNode; unrestricted: boolean; location: Location }> = ({
  data,
  layoutData,
  unrestricted,
  location,
}) => {
  const history = useHistory();
  const query = parseSearchString(location.search);
  const classFilter = query.f ? (query.f as string).split(",") : [];
  const inFilter = classFilter.includes(data.id);

  return (
    <g
      style={{
        transform: layoutData
          ? // Rounding prevents some slight shifting of nodes in Firefox
            `translate(${Math.round(layoutData.x - layoutData.width / 2)}px,${Math.round(
              layoutData.y - layoutData.height / 2
            )}px)`
          : "",
      }}
      className={getClassName(styles.node, {
        [styles.owl]: data.owl,
        [styles.shacl]: data.shacl,
        [styles.blur]: !!data.blur,
      })}
    >
      <foreignObject
        width={(!unrestricted && layoutData?.width) || 1000}
        height={(!unrestricted && layoutData?.height) || "100%"}
      >
        <div
          id={`node-${escapeId(data.id)}`}
          className={styles.nodeDiv}
          onClick={(e) => {
            e.stopPropagation();
            history.push({ hash: `#${encodeURIComponent(data.id)}`, search: location.search });
          }}
          role="button"
          tabIndex={0}
          style={{ borderColor: data.color }}
        >
          <div className={styles.title}>
            <div className={styles.block} style={{ backgroundColor: data.color }}></div>
            <h5 title={data.id}>
              {data.prefix ? (
                <>
                  <span style={{ color: data.color }}>{data.prefix + ":"}</span>
                  {data.label}
                </>
              ) : (
                data.label
              )}
            </h5>
            <IconButton
              title={inFilter ? "Remove from class filter" : "Add to class filter"}
              disabled={!!data.blur}
              onClick={(e) => {
                e.stopPropagation();
                const newFilter = inFilter ? classFilter.filter((c) => c !== data.id) : [...classFilter, data.id];
                history.replace({
                  search: stringifyQuery({
                    ...query,
                    f: newFilter.join(","),
                  }),
                });
              }}
            >
              <Badge
                badgeContent={<div style={{ fontSize: "150%" }}>{inFilter ? "-" : "+"}</div>}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="circular"
              >
                <FontAwesomeIcon icon="filter" fixedWidth />
              </Badge>
            </IconButton>
          </div>
          {data.datatypeProperties && data.datatypeProperties.length > 0 && (
            <div className={styles.datatypeProperties}>
              {data.datatypeProperties.map((p) => {
                return (
                  <div
                    key={p.datatype + p.path}
                    className={getClassName(styles.datatypeProperty, { [styles.shacl]: p.shacl, [styles.owl]: p.owl })}
                  >
                    <div className="ellipsis">{getLocalName(p.path)}</div>
                    <em className="ellipsis">{p.datatype}</em>
                    <div style={{ fontSize: "small" }}>{p.cardinality}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </foreignObject>
    </g>
  );
};
export default Node;
