import getClassName from "classnames";
import * as React from "react";
import { RedBoxError } from "redbox-react";
// @ts-ignore
import redboxStyles from "redbox-react/lib/style.js";
import { FlexContainer, Meta, StaticRouterContext, Svg } from "#components/index.ts";
import { ensureTrailingDot } from "#helpers/utils.ts";
import icon from "./sad.svg";
import styles from "./style.scss";

namespace ErrorPage {
  export interface Props {
    message?: string;
    title?: string;
    style?: React.CSSProperties;
    className?: string;
    statusCode?: number;
    error?: Error;
    currentPath?: string;
  }
}

const ErrorPage: React.FC<ErrorPage.Props> = ({ error, statusCode, message, title, style, className, currentPath }) => {
  // if (!statusCode) statusCode = 500;
  switch (statusCode) {
    case 401:
      if (!title) title = "Not authorized";
      if (!message) message = "You are not authorized to view this page";
      break;
    case 404:
      if (!title) title = "Not found";
      if (!message) message = "The page you're looking for does not exist";
      break;
    default:
      if (!title) title = "Error";
      if (!message) message = "Something went wrong...";
  }
  if (error && __DEVELOPMENT__) {
    /**
     * By default redbox mounts to the body. Have to do some styling tricks to render it as a child
     */

    return (
      <div>
        <Meta title={error.message} currentPath={currentPath} />
        <RedBoxError
          error={error}
          style={{
            ...redboxStyles.redbox,
            height: "100%",
            overflow: "auto",
            // border: "3px solid red",
            position: "static",
          }}
        />
      </div>
    );
  } else {
    return (
      <FlexContainer style={style} className={getClassName(styles.container, className)}>
        <Meta title={title} currentPath={currentPath} />
        <StaticRouterContext statusCode={statusCode || 500}>
          <div className={styles.iconRow}>
            <div className="m-6" style={{ flexGrow: 8 }}>
              <h2>Oops!</h2>
              <p>{ensureTrailingDot(message)}</p>
            </div>
            <div className="m-6" style={{ flexGrow: 4 }}>
              <Svg src={icon} className={styles.icon} alt="" />
            </div>
          </div>
        </StaticRouterContext>
      </FlexContainer>
    );
  }
};

export default ErrorPage;
