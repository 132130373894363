import { Autocomplete, Box, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { debounce } from "@mui/material/utils";
import { stratify } from "d3";
import { escapeRegExp, flatMap, fromPairs, uniqBy } from "lodash-es";
import { Tree, TreeMultipleSelectionKeys, TreeNodeTemplateOptions } from "primereact/tree";
import { TreeNode } from "primereact/treenode";
import * as React from "react";
import { useHistory } from "react-router";
import { CachePolicies } from "use-http";
import { getPrefixed } from "@triply/utils/prefixUtils.js";
import useHideFooter from "#helpers/hooks/useHideFooter.ts";
import { FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import { useDatasetPrefixes } from "../../helpers/hooks/useDatasetPrefixes";
import useFetch from "../../helpers/hooks/useFetch";
import { stringifyQuery } from "../../helpers/utils";
import { useCurrentAccount } from "../../reducers/app";
import { Prefixes, useCurrentDataset } from "../../reducers/datasetManagement";
import useCurrentClass from "./useCurrentResource";
import prime from "../Ontology/styles/prime.scss";

interface Item {
  id: string;
  iri: string;
  parent: string;
  label?: string;
}

const getTreeItems: (allItems: Item[], item: Item, parentKey: string) => Item[] = (allItems, item, parentKey) => {
  const key = parentKey + item.id;
  const subItems = allItems.filter((s) => s.parent === item.id);
  return [
    {
      id: key,
      iri: item.id,
      parent: parentKey,
      label: item.label,
    },
    ...flatMap(subItems, (subItem) => {
      return getTreeItems(allItems, subItem, key);
    }),
  ];
};

const getHierarchy = (data: Item[], currentClass: string, prefixes: Prefixes) => {
  const treeData = getTreeItems(
    data,
    {
      id: "tree:root",
      iri: "",
      parent: "",
    },
    ""
  );

  let hierarchy;

  try {
    hierarchy =
      treeData.length > 0
        ? stratify<Item & { key?: string }>()
            .id((item) => item.id)
            .parentId((item) => item.parent)(treeData as any)
        : undefined;
  } catch (e) {
    console.error(e);
  }

  const selectedKeys: TreeMultipleSelectionKeys = {};

  hierarchy?.eachBefore((node) => {
    (node as any).label = node.data.label || getPrefixed(node.data.iri, prefixes) || node.data.iri;
    (node as any).key = node.data.key = node.data.id;

    if (node.data.iri === currentClass) selectedKeys[node.data.id] = true;
  });

  hierarchy?.sort((a: any, b: any) => {
    if ((a.data.label && b.data.label) || (!a.data.label && !b.data.label)) {
      return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    } else if (a.data.label) {
      return -1;
    } else {
      return 1;
    }
  });

  return { hierarchy, selectedKeys };
};

const SubclassTree: React.FC<{}> = ({}) => {
  const history = useHistory();
  const currentClass = useCurrentClass();
  const prefixes = useDatasetPrefixes();
  useHideFooter();

  const conceptLimit = 1000;

  const url = useConstructUrlToApi()({ pathname: "/internal/sparql", fromBrowser: true });
  const { post, data, error } = useFetch<Item[]>(url, {
    cachePolicy: CachePolicies.NO_CACHE,
    credentials: "same-origin",
  });

  const currentAccountName = useCurrentAccount()!.accountName;
  const currentDatasetName = useCurrentDataset()!.name;
  const currentDatasetUpdateTime = useCurrentDataset()!.lastGraphsUpdateTime;

  React.useEffect(() => {
    post({
      account: currentAccountName,
      dataset: currentDatasetName,
      queryString: `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
prefix owl: <http://www.w3.org/2002/07/owl#>
select ?id ?parent (sample(?l) as ?label) where {
  {
    {
      select distinct ?class where {
        {
          ?class a/rdfs:subClassOf* skos:Concept
        }  union {
          ?class skos:broader|^skos:broader|skos:narrower|^skos:narrower|skos:broadMatch|^skos:broadMatch|skos:narrowMatch|^skos:narrowMatch []
        }
        filter (!regex(str(?class), "\.well-known/genid"))
      }
      limit ${conceptLimit}
    }
    optional {
      ?class skos:broader|^skos:narrower|skos:broadMatch|^skos:narrowMatch ?superclass
    }
    optional {
      ?class rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?l
    }
    bind(coalesce(?superclass, <tree:root>) as ?parent)
    bind(?class as ?id)
  }
}
group by ?id ?parent
      `,
    }).catch(() => {});
  }, [post, currentAccountName, currentDatasetName, currentDatasetUpdateTime]);

  const [expandedKeys, setExpandedKeys] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    if (currentClass && data) {
      const { hierarchy } = getHierarchy(data, currentClass, prefixes);
      const toExpand = fromPairs(
        hierarchy
          ?.find((node) => node.data.iri === currentClass)
          ?.ancestors()
          ?.slice(1)
          .map((node) => [node.data.id, true]) || []
      );
      setExpandedKeys((expandedKeys) => {
        return {
          ...expandedKeys,
          ...toExpand,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClass, data]);

  const filterInputRef = React.useRef<HTMLInputElement>(null);

  if (!data || error) return null;

  const { hierarchy, selectedKeys } = getHierarchy(data, currentClass, prefixes);

  const needAlternativeSearch = data.length >= conceptLimit;
  // if (hierarchy?.height === 0) return null;

  return (
    <Paper className="p-3 m-5" sx={{ maxHeight: "calc(100vh - 90px)" }}>
      <Box sx={{ width: 300, height: "calc(100% + 10px)" }}>
        <div className={prime.prime}>
          <Tree
            value={hierarchy?.children || []}
            expandedKeys={expandedKeys}
            onToggle={(e) => setExpandedKeys(e.value)}
            dragdropScope=""
            emptyMessage=" "
            filter
            filterMode="lenient"
            filterPlaceholder="Filter"
            filterTemplate={({ filterInputChange, filterOptions }: any) => {
              if (needAlternativeSearch) return <SearchField />;
              return (
                <div className="mb-5 flex center g-5">
                  <TextField
                    placeholder="Filter"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="m-3">
                          {filterInputRef.current?.value ? (
                            <IconButton
                              onClick={() => {
                                filterOptions.reset();
                                if (filterInputRef.current) {
                                  filterInputRef.current.value = "";
                                  filterInputRef.current.focus();
                                }
                              }}
                              sx={{ marginRight: "-10px" }}
                            >
                              <FontAwesomeIcon icon="times" size="xs" fixedWidth />
                            </IconButton>
                          ) : (
                            <FontAwesomeIcon icon="search" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={filterInputChange}
                    inputRef={filterInputRef}
                  />
                </div>
              );
            }}
            nodeTemplate={nodeTemplate}
            selectionMode="multiple"
            onSelect={({ node }) => {
              history.push({ search: stringifyQuery({ resource: node.data.iri }) });
            }}
            selectionKeys={selectedKeys}
          />
        </div>
      </Box>
    </Paper>
  );
};

const nodeTemplate = (node: TreeNode, options: TreeNodeTemplateOptions) => {
  return <div className={options.className}>{node.label}</div>;
};

interface Concept {
  id: string;
  label: string;
}
const SearchField: React.FC<{}> = () => {
  const [value, setValue] = React.useState<Concept | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly Concept[]>([]);

  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/internal/sparql`,
    fromBrowser: true,
  });

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: "application/json" },
        body: new URLSearchParams({
          account: currentDs.owner.accountName,
          dataset: currentDs.name,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sparqlUrl, currentDs.owner.accountName, currentDs.name]
  );

  const debouncedQuery = React.useMemo(
    () =>
      debounce(
        (
          { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
          callback: (results?: readonly Concept[]) => void
        ) => {
          searchTerm = escapeRegExp(searchTerm.replace(/"/g, "")).replace(/\\/g, "\\\\");
          sparql(
            `
            prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
            prefix skos: <http://www.w3.org/2004/02/skos/core#>
            prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
            prefix owl: <http://www.w3.org/2002/07/owl#>

            select ?id ?label where {
              ?id a/rdfs:subClassOf* skos:Concept .

              filter (!regex(str(?id), ".well-known/genid"))

              ?id rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?label .

              filter (regex(?label, "${searchTerm}", "i"))

            }
            order by
              desc(regex(?label, "^${searchTerm}", "i"))
              asc(strlen(?label))
            limit 20
            `,
            abortSignal
          )
            .then((results) => {
              callback(results);
            })
            .catch(() => {});
        },
        400
      ),
    [sparql]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    debouncedQuery({ searchTerm: inputValue, abortSignal: abortController.signal }, (results?: readonly Concept[]) => {
      if (active) {
        let newOptions: readonly Concept[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
  }, [value, inputValue, debouncedQuery]);

  const history = useHistory();

  return (
    <div className="mb-5 flex center g-5">
      <Autocomplete
        getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        fullWidth
        noOptionsText="No results"
        onChange={(_event: any, newValue: Concept | null, reason) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (reason === "selectOption") {
            history.push({
              search: stringifyQuery({ resource: newValue?.id }),
            });
          }
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params: any) => {
          return <TextField variant="outlined" placeholder="Search" {...params} sx={{ height: "40px" }} />;
        }}
      />
    </div>
  );
};

export default SubclassTree;
