import { Container, Paper } from "@mui/material";
import * as React from "react";
import ClassInfo from "./ClassInfo";
import Description from "./Description";
import Instances from "./Instances";
import Iri from "./Iri";
import Label from "./Label";
import Links from "./Links";
import useCurrentClass from "./useCurrentClass";

const Class: React.FC<{}> = ({}) => {
  const currentClass = useCurrentClass();

  return (
    <Paper className="p-5" sx={{ height: "100%" }}>
      {!!currentClass && (
        <Container className="flex column g-5" sx={{ height: "100%", overflow: "auto" }}>
          <div>
            <Label />
            <Iri />
          </div>
          <Description />
          <ClassInfo />
          <Links />
          <Instances />
        </Container>
      )}
    </Paper>
  );
};

export default Class;
