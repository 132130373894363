export const classesQuery = `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>

select
  ?id
  (sample(?label_t) as ?label)
  (sample(?description_t) as ?description)
  ?properties_propertyShape
  ?properties_id
  (sample(?properties_label_t) as ?properties_label)
  (sample(?properties_description_t) as ?properties_description)
  ?properties_datatype
  ?properties_range
  (sample(?properties_minCount_t) as ?properties_minCount)
  (sample(?properties_maxCount_t) as ?properties_maxCount)
  # ?properties_values_id
  # (sample(?properties_values_label_t) as ?properties_values_label)
  # (sample(?properties_values_description_t) as ?properties_values_description)
{

  ?nodeShape sh:targetClass ?class .
  ?id rdfs:subClassOf* ?class .

  # ?id rdfs:subClassOf*/^sh:targetClass ?nodeShape .
  # ?id ^sh:targetClass ?nodeShape .

  optional {
    ?id rdfs:label|skos:prefLabel ?label_t .
  }
  optional {
    ?id rdfs:comment|skos:definition ?description_t
  }
  optional {
    ?nodeShape sh:property ?properties_propertyShape .
    ?properties_propertyShape sh:path ?properties_id .
    optional {
      ?properties_id rdfs:label|skos:prefLabel ?properties_p_label_t
    }
    optional {
      ?properties_propertyShape sh:name ?properties_sh_label_t
    }
    bind(coalesce(?properties_sh_label_t, ?properties_p_label_t) as ?properties_label_t)
    optional {
      ?properties_id rdfs:comment|skos:definition ?properties_p_description_t
    }
    optional {
      ?properties_propertyShape sh:description ?properties_sh_description_t
    }
    bind(coalesce(?properties_sh_description_t, ?properties_p_description_t) as ?properties_description_t)
    optional {
      ?properties_propertyShape sh:datatype ?properties_datatype
    }

    optional {
      ?properties_propertyShape sh:minCount ?properties_minCount_t
    }
    optional {
      ?properties_propertyShape sh:maxCount ?properties_maxCount_t
    }

    optional {
      ?properties_propertyShape sh:class ?properties_range .
      # optional {
      #   ?properties_values_id a/rdfs:subClassOf* ?properties_range .
      #   optional {
      #     ?properties_values_id rdfs:label|skos:prefLabel ?properties_values_label_t
      #   }
      #   optional {
      #     ?properties_values_id rdfs:comment|skos:definition ?properties_values_description_t
      #   }
      # }
    }
  }
}
group by
?id
?properties_propertyShape
?properties_id
?properties_datatype
?properties_range
# ?properties_values_id
`;

export const valuesQuery = (resource: string) => `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix p: <https://triplydb.com/academy/pokemon/id/pokemon/>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>

select
  # (sample(?label_t) as ?label)
  ?type_id
  (sample(?type_label_t) as ?type_label)
  (sample(?type_description_t) as ?type_description)
  ?properties_value_id
  (sample(?properties_value_label_t) as ?properties_value_label)
  (sample(?properties_value_description_t) as ?properties_value_description)
  ?properties_property_id
  (sample(?properties_property_label_t) as ?properties_property_label)
  (sample(?properties_property_description_t) as ?properties_property_description)
  ?properties_property_propertyShape
  ?properties_property_datatype
  ?properties_property_range
  (sample(?properties_property_minCount_t) as ?properties_property_minCount)
  (sample(?properties_property_maxCount_t) as ?properties_property_maxCount)
  # ?properties_property_values_id
  # (sample(?properties_property_values_label_t) as ?properties_property_values_label)
  # (sample(?properties_property_values_description_t) as ?properties_property_values_description)
{
  <${resource}> a ?type_id.

  # optional {
  #   <${resource}> rdfs:label|skos:prefLabel ?label_t .
  # }

  ?type_nodeShape a sh:NodeShape ;
                  sh:targetClass/^rdfs:subClassOf* ?type_id .

  optional {
    ?type_id rdfs:label|skos:prefLabel ?type_label_t .
  }

  optional {
    ?type_id rdfs:comment|skos:definition ?type_description_t
  }

  optional {
    ?type_nodeShape sh:property ?properties_property_propertyShape .
    ?properties_property_propertyShape sh:path ?properties_property_id .
    <${resource}> ?properties_property_id ?properties_value_id .

    optional {
      ?properties_value_id rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?properties_value_label_t
    }

    optional {
      ?properties_value_id rdfs:comment|skos:definition ?properties_value_description_t
    }

    optional {
      ?properties_property_id rdfs:label|skos:prefLabel ?properties_property_p_label_t
    }
    optional {
      ?properties_property_propertyShape sh:name ?properties_property_sh_label_t
    }
    bind(coalesce(?properties_property_sh_label_t, ?properties_property_p_label_t) as ?properties_property_label_t)

    optional {
      ?properties_property_id rdfs:comment|skos:definition ?properties_property_p_description_t
    }
    optional {
      ?properties_property_propertyShape sh:description ?properties_property_sh_description_t
    }
    bind(coalesce(?properties_property_sh_description_t, ?properties_property_p_description_t) as ?properties_property_description_t)

    optional {
      ?properties_property_propertyShape sh:datatype ?properties_property_datatype
    }

    optional {
      ?properties_property_propertyShape sh:minCount ?properties_property_minCount_t
    }
    optional {
      ?properties_property_propertyShape sh:maxCount ?properties_property_maxCount_t
    }

    optional {
      ?properties_property_propertyShape sh:class ?properties_property_range.
      # optional {
      #   ?properties_property_values_id a/rdfs:subClassOf* ?properties_property_range .

      #   optional{
      #     ?properties_property_values_id rdfs:label|skos:prefLabel ?properties_property_values_label_t
      #   }

      #   optional {
      #     ?properties_property_values_id rdfs:comment|skos:definition ?properties_property_values_description_t
      #   }
      # }
    }
  }
}
group by
  ?type_id
  ?properties_value_id
  ?properties_property_id
  ?properties_property_propertyShape
  ?properties_property_datatype
  ?properties_property_range
  # ?properties_property_values_id

`;
