import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { stringifyQuery } from "#helpers/utils.ts";
import styles from "./style.scss";

const ForgotPasswordLink: React.FC<{
  className?: string;
  email?: string;
}> = ({ className, email }) => {
  return (
    <Link
      className={getClassName(styles.resendPassword, className)}
      to={{ pathname: "/auth/resendPassword", search: stringifyQuery(email ? { email: email } : {}) }}
    >
      Forgot password?
    </Link>
  );
};

export default ForgotPasswordLink;
