import { push } from "connected-react-router";
import { pickBy } from "lodash-es";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { asyncConnect } from "redux-connect";
import { Prefixes } from "@triply/utils/Models.js";
import { mergePrefixArray } from "@triply/utils/prefixUtils.js";
import { Alert, DatasetMetadata, ErrorPage, FlexContainer } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { Account } from "#reducers/accountCollection.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import { createDatasetPrefix, Dataset, getCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { showNotification } from "#reducers/notifications.ts";
import {
  getNextTriplesPageFor,
  getTriples,
  getTriplesFor,
  Triples,
  triplesAreLoadedFor,
  triplesAreOutdatedFor,
} from "#reducers/triples.ts";
import TriplesTable from "./table.tsx";
import styles from "./style.scss";

export interface Query {
  subject?: string;
  predicate?: string;
  object?: string;
  graph?: string;
}

export namespace Table {
  export interface OwnProps extends IComponentProps {}
  export interface PropsFromState {
    currentDs?: Dataset;
    currentAccount?: Account;
    fetchingTriples: boolean;
    triples?: Triples;
    nextTriplePage?: string;
    triplesAreOutdated: boolean;
    currentPath: string;
    localPrefixes?: Prefixes;
    globalPrefixes?: Prefixes;
  }
  export type Props = OwnProps & PropsFromState;
}

const Table: React.FC<Table.Props> = ({
  currentAccount,
  currentDs,
  currentPath,
  fetchingTriples,
  globalPrefixes,
  localPrefixes,
  location,
  nextTriplePage,
  triplesAreOutdated,
  triples,
}) => {
  const acl = useAcl();
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();

  React.useEffect(() => {
    if (triplesAreOutdated && currentDs) {
      dispatch<typeof getTriples>(getTriples(currentDs, parseSearchString(location.search)))
        .then(() => dispatch(showNotification("The data was reloaded after a change in the dataset.", "info")))
        .catch(() => {});
    }
    // Fetch triples again when they are outdated (when a job finishes).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triplesAreOutdated]);

  if (!currentAccount || !currentDs || !triples) {
    return <ErrorPage statusCode={404} />;
  }
  const metadata = (
    <DatasetMetadata
      currentPath={location.pathname}
      currentAccount={currentAccount}
      currentDs={currentDs}
      title="Table"
    />
  );

  if (
    !currentDs.graphCount &&
    acl.check({
      action: "importDataToDataset",
      context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
    }).granted
  ) {
    return (
      <FlexContainer>
        {metadata}
        <div className={styles.noDataMsg}>
          <Link to={`/${currentAccount.accountName}/${currentDs.name}/graphs`} className="noLinkDecoration">
            <Alert className="shadow" key="alert" message="This dataset is empty, start by importing data" info />
          </Link>
        </div>
      </FlexContainer>
    );
  }

  return (
    <>
      {metadata}
      <TriplesTable
        filter={(query: Query) => {
          const stringifiedQuery = stringifyQuery(pickBy(query, (x) => !!x));
          if ((location && location.search) !== "?" + stringifiedQuery) {
            dispatch(push({ pathname: currentPath, search: stringifiedQuery }));
          }
        }}
        query={parseSearchString(location.search)}
        fetchingTriples={fetchingTriples}
        createPrefix={
          acl.check({
            action: "editDatasetMetadata",
            context: {
              roleInOwnerAccount: acl.getRoleInAccount(currentAccount),
              accessLevel: currentDs.accessLevel,
              newAccessLevel: undefined,
            },
          }).granted
            ? (prefix) => dispatch(createDatasetPrefix(prefix, currentAccount, currentDs))
            : undefined
        }
        triples={triples}
        goToNextPage={
          (nextTriplePage &&
            currentDs &&
            (() =>
              dispatch<typeof getTriples>(
                getTriples(currentDs, parseSearchString(location.search), nextTriplePage)
              ).catch(() => {}))) ||
          undefined
        }
        prefixes={mergePrefixArray(localPrefixes || [], globalPrefixes || [])}
        termPath={constructUrlToApi({
          pathname: `/datasets/${currentAccount.accountName}/${currentDs.name}/terms`,
        })}
        pathname={location.pathname}
      />
    </>
  );
};

export default connect<Table.PropsFromState, {}, Table.OwnProps, GlobalState>(
  (state, props) => {
    const currentDs = getCurrentDataset(state);
    const dataState = currentDs ? state.datasets[currentDs.id] : undefined;
    const query = parseSearchString(props.location.search);
    return {
      currentDs: currentDs,
      currentAccount: getCurrentAccount(state),
      fetchingTriples: !!dataState && dataState.fetchingTriples,
      triples: currentDs && getTriplesFor(state, currentDs, query),
      nextTriplePage: currentDs && getNextTriplesPageFor(state, currentDs, query),
      triplesAreOutdated: !!currentDs && triplesAreOutdatedFor(state, currentDs, query),
      currentPath: props.location.pathname,
      localPrefixes: currentDs?.prefixes,
      globalPrefixes: state.config.clientConfig?.prefixes,
    };
  },
  //dispatch
  {}
)(
  asyncConnect<GlobalState>([
    {
      promise: ({ location, store: { dispatch, getState } }) => {
        var state: GlobalState = getState();
        const currentDs = getCurrentDataset(state);
        const query = parseSearchString(location.search);
        if (currentDs && !triplesAreLoadedFor(state, currentDs, query)) {
          return dispatch<any>(getTriples(currentDs, query));
        }
      },
    },
  ])(Table) as typeof Table
);
