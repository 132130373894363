import { Tooltip } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import getClassName from "classnames";
import * as React from "react";
import { LiteralUtils } from "@triply/utils";
import { getPrefixInfoFromIri, PrefixInfo } from "@triply/utils/prefixUtils.js";
import { formatNumber, formatNumericTerm } from "@triply/utils-private/formatting.js";
import { FontAwesomeIcon } from "#components/index.ts";
import { Binding, ConstructResult } from "#components/Sparql/SparqlUtils.ts";
import { SparqlVisualizationContext } from "../../SparqlVisualizationContext.tsx";
import GeometryRenderer from "./GeometryTerm.tsx";
import { CellContent } from "./index.tsx";
import IriTerm from "./IriTerm.tsx";
import styles from "./styles.scss";

const CellRenderer: React.FC<CellContext<ConstructResult | Binding, CellContent>> = ({ cell }) => {
  const term = cell.getValue();
  const { prefixes } = React.useContext(SparqlVisualizationContext);
  if (!term) return null;
  if (term.type === "uri") {
    return <IriTerm term={term} />;
  }
  if (term.type === "bnode") {
    return (
      <Tooltip
        placement="bottom-start"
        title={
          <div className="my-2">
            <span>Blank node</span>
          </div>
        }
        componentsProps={{
          tooltip: {
            className: getClassName(styles.prefixTooltip, "shadow"),
          },
        }}
      >
        <div className={styles.cellContent}>
          <span>{term.value}</span>
        </div>
      </Tooltip>
    );
  }
  if (term.type === "literal" || term.type === "typed-literal") {
    let datatype: string | undefined;
    let prefixInfoDatatype: PrefixInfo | undefined;
    let language: string | undefined;
    if ("datatype" in term && term.datatype !== undefined) {
      datatype = term.datatype;
      prefixInfoDatatype = getPrefixInfoFromIri(term.datatype, prefixes);
    }
    if ("xml:lang" in term) {
      language = term["xml:lang"];
    }
    return (
      <Tooltip
        placement="bottom-start"
        title={
          (datatype || language) && (
            <dl>
              {datatype && (
                <>
                  <dt>Datatype</dt>
                  <dd>
                    {prefixInfoDatatype?.prefixLabel !== undefined && <span>{prefixInfoDatatype.prefixLabel}:</span>}
                    <a href={datatype}>
                      {prefixInfoDatatype?.prefixLabel !== undefined ? prefixInfoDatatype.localName : datatype}
                    </a>
                  </dd>
                </>
              )}
              {language && (
                <>
                  <dt>Language</dt>
                  <dd>{language}</dd>
                </>
              )}
            </dl>
          )
        }
        componentsProps={{
          tooltip: {
            className: getClassName(styles.prefixTooltip, "shadow"),
          },
        }}
      >
        <div className={styles.cellContent}>
          <LiteralRenderer value={term.value} datatype={datatype} language={language} />
        </div>
      </Tooltip>
    );
  }

  return <span>{term.value}</span>;
};
const LiteralRenderer: React.FC<{ value: string; datatype: string | undefined; language: string | undefined }> = ({
  value,
  language,
  datatype,
}) => {
  if (datatype) {
    if (LiteralUtils.isBooleanDatatype(datatype)) {
      if (value === "true") {
        return (
          <span aria-label={value}>
            <FontAwesomeIcon icon="check" />
          </span>
        );
      } else if (value === "false") {
        return (
          <span aria-label={value}>
            <FontAwesomeIcon icon="xmark" />
          </span>
        );
      }
    }
    if (LiteralUtils.isNumericDatatype(datatype) && !Number.isNaN(+value)) {
      return <span>{formatNumericTerm(value, datatype)}</span>;
    }
    if (LiteralUtils.isWktDatatype(datatype)) {
      return <GeometryRenderer wkt={value} />;
    }
    if (LiteralUtils.isUriDatatype(datatype)) {
      return (
        <a href={value} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      );
    }
  }
  if (language) {
    return <span lang={language}>{value}</span>;
  }
  return <span>{value}</span>;
};

export default React.memo(CellRenderer);
