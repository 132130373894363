import { Alert, FormControlLabel, Radio, RadioGroup, Skeleton } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { Models } from "@triply/utils";
import ColorPicker from "#components/ColorPicker/index.tsx";
import { BannerUpload, IconUpload, LogoLgUpload, LogoUpload } from "#components/Forms/index.ts";
import { Button, Label, Svg } from "#components/index.ts";
import SquareLogo from "#components/SquareLogo/index.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import {
  deleteBanner,
  updateClientConfig,
  uploadBanner,
  uploadLandscapeLogo,
  uploadSquareLogo,
} from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";
import styles from "./style.scss";

interface Props {}

const DEFAULT_LOGO_BG_COLOR_HEX = "#ffffff";
const DEFAULT_LOGO_BG_SHAPE = "circle";

const SiteLogos: React.FC<Props> = ({}) => {
  const [removingBanner, setRemovingBanner] = React.useState(false);

  const logo = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logo);
  const logoLg = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoLg);
  const banner = useSelector((state: GlobalState) => state.config.clientConfig?.branding.banner);
  const logoBgTypeConfig = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgType);
  const logoBgColorConfig = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgColor);

  const [logoBgType, setLogoBgType] = React.useState<Models.LogoBackgroundType>(
    logoBgTypeConfig || DEFAULT_LOGO_BG_SHAPE
  );
  const [logoBgColor, setLogoBgColor] = React.useState<string>(logoBgColorConfig || DEFAULT_LOGO_BG_COLOR_HEX);

  const dispatch = useDispatch();
  const handleLogoUpload = (values: IconUpload.FormData) => {
    return dispatch<typeof uploadSquareLogo>(uploadSquareLogo(values.avatarFile)).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      }
    );
  };
  const handleLogoLgUpload = (values: IconUpload.FormData) => {
    return dispatch<typeof uploadLandscapeLogo>(uploadLandscapeLogo(values.avatarFile)).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      }
    );
  };
  const handleBannerUpload = (values: IconUpload.FormData) => {
    return dispatch<typeof uploadBanner>(uploadBanner(values.avatarFile, "/imgs/logos/banner.webp")).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      }
    );
  };
  const handleBannerDelete = () => {
    setRemovingBanner(true);
    return dispatch<typeof deleteBanner>(deleteBanner()).then(
      () => {
        setRemovingBanner(false);
      },
      () => {
        setRemovingBanner(false);
      }
    );
  };

  const applyLogoBgCustomization = () => {
    return dispatch<typeof updateClientConfig>(
      updateClientConfig({
        branding: {
          logoBgColor,
          logoBgType,
        },
      })
    );
  };

  const resetLogoBgCustomization = () => {
    setLogoBgColor(logoBgColorConfig || DEFAULT_LOGO_BG_COLOR_HEX);
    setLogoBgType(logoBgTypeConfig || DEFAULT_LOGO_BG_SHAPE);
  };

  const useDefaults = () => {
    setLogoBgColor(DEFAULT_LOGO_BG_COLOR_HEX);
    setLogoBgType(DEFAULT_LOGO_BG_SHAPE);
  };

  if (!logo || !logoLg) {
    return <Skeleton />;
  }

  return (
    <div className="whiteSink">
      <h3>Site logos</h3>
      <Alert severity="warning">Only SVG files are supported</Alert>
      <h4>Square Logo</h4>
      <div className={styles.logoSquare}>
        <LogoUpload
          submitText="Upload square logo"
          onSubmit={handleLogoUpload}
          className={styles.uploadForm}
          accept=".svg"
        >
          <SquareLogo logo={logo} logoBgColor={logoBgColor} logoBgType={logoBgType} heightWidthPx={70} showBorderLine />
        </LogoUpload>

        <div className={styles.logoSquareCustomization}>
          <div>
            <div className={styles.logoSquareBgShape}>
              <span className={styles.label}>Background shape</span>
              <RadioGroup
                name="logoBgTypeRadioGroup"
                value={logoBgType}
                onChange={(event) => {
                  setLogoBgType(event.target.value as Models.LogoBackgroundType);
                }}
              >
                <FormControlLabel value="circle" control={<Radio />} label="Circle" />
                <FormControlLabel value="square" control={<Radio />} label="Square" />
                <FormControlLabel value="none" control={<Radio />} label="None" />
              </RadioGroup>
            </div>
            <div>
              <span className={styles.label}>Background shape color</span>
              <ColorPicker
                color={logoBgColor}
                onChange={(color) => {
                  setLogoBgColor(color);
                }}
                disabled={logoBgType === "none"}
              />
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              color="error"
              variant="outlined"
              onClick={resetLogoBgCustomization}
              disabled={logoBgColor === logoBgColorConfig && logoBgType === logoBgTypeConfig}
            >
              Reset
            </Button>
            <Button color="info" variant="outlined" onClick={useDefaults}>
              Use Defaults
            </Button>
            <Button color="info" variant="outlined" onClick={applyLogoBgCustomization}>
              Apply
            </Button>
          </div>
        </div>
      </div>

      <h4>Landscape Logo</h4>
      <LogoLgUpload
        submitText="Upload landscape logo"
        onSubmit={handleLogoLgUpload}
        className={styles.uploadForm}
        accept=".svg"
      >
        <Svg className={styles.logo} src={logoLg} alt="" />
      </LogoLgUpload>
      <h3>Site banner</h3>
      <div>
        <BannerUpload submitText="Upload banner logo" onSubmit={handleBannerUpload}>
          <>
            <picture className={styles.bannerImage}>
              {banner && (
                <>
                  <source srcSet={`${banner}&w=720&h=500`} type="image/webp" />
                  <source srcSet={`${banner.replace("webp", "png")}&w=720&h=500`} type="image/png" />
                </>
              )}
              <img width="720px" className={getClassName({ [styles.customBanner]: !!banner })} alt="" />
              <div className={getClassName(styles.exampleText)} aria-hidden="true">
                <p>██████████</p>
                <p>
                  █████ █████ ██ ████████
                  <br />
                  ███ ██████ ███
                </p>
              </div>
            </picture>
            {banner && (
              <Button
                color="error"
                variant="outlined"
                className="mx-3"
                onClick={handleBannerDelete}
                disabled={removingBanner}
              >
                Remove banner
              </Button>
            )}
          </>
        </BannerUpload>
        <Label>To represent the welcome text on any screen correctly the image is darkened</Label>
      </div>
    </div>
  );
};
export default SiteLogos;
