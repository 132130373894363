import { Fade, Tooltip } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { Link, LinkProps, useHistory } from "react-router-dom";
import { formatNumber } from "@triply/utils-private/formatting.js";
import { FontAwesomeIcon } from "#components/index.ts";
import styles from "./style.scss";

type Props = IconProps | ComponentProps;
interface IconProps extends BaseProps {
  icon?: FontAwesomeIcon.Props["icon"];
}

interface ComponentProps extends BaseProps {
  iconComponent?: JSX.Element;
}
interface BaseProps {
  name: string;
  beta?: true;
  className?: string;
  active?: boolean;
  panelCollapsed: boolean;
  to: LinkProps["to"];
  number?: number;
  loading?: boolean;
  current?: boolean;
  replace: boolean;
  tabIndex?: number;
  warning?: boolean;
}

function formatNumberLarge(n: number) {
  return formatNumber(n);
}
function formatNumberSmall(n: number) {
  if (n > 99) return "99+";
  return n;
}

const PanelItem: React.FC<Props> = (props) => {
  const { name, className, active, to, number, loading, panelCollapsed, replace, tabIndex, beta, warning } = props;
  const history = useHistory();

  function renderImgComponent() {
    if ("iconComponent" in props) return props.iconComponent;
    if ("icon" in props && props.icon)
      return (
        <FontAwesomeIcon
          icon={props.icon}
          size="lg"
          fixedWidth
          className={getClassName(styles.icon, {
            [styles.loading]: loading,
            ["fa-flip-vertical"]: props.icon === "paperclip",
          })}
        />
      );

    return <div className="ml-6"></div>;
  }

  return (
    <Tooltip
      title={panelCollapsed ? name : ""}
      placement="right"
      enterDelay={0}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
    >
      <Link
        replace={replace}
        to={to}
        className={getClassName(
          styles.item,
          { [styles.active]: active, [styles.collapsed]: panelCollapsed },
          className
        )}
        onClick={(e) => {
          if (active) {
            e.preventDefault();
            if (replace) {
              history.goBack();
            }
          }
        }}
        tabIndex={tabIndex}
      >
        {renderImgComponent()}

        <div className={styles.nameContainer}>
          <span className={styles.name}>
            {name}
            {beta && <sup className={styles.beta}>BETA</sup>}
          </span>
          {warning && <FontAwesomeIcon className={styles.warning} icon={["fas", "exclamation-triangle"]} />}
        </div>
        <div className={styles.loadingContainer}>
          {loading && (
            <div className={styles.loadingIcon}>
              <div className={styles.delay1} />
              <div className={styles.delay2} />
              <div />
            </div>
          )}
        </div>

        {number !== undefined && number > -1 && <div className={styles.numberLarge}>{formatNumberLarge(number)}</div>}
        {number !== undefined && number > -1 && (
          <div className={getClassName(styles.numberSmall, "ml-1")}>{formatNumberSmall(number)}</div>
        )}
      </Link>
    </Tooltip>
  );
};
export default PanelItem;
