import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router";
import { DatasetMetadata, ErrorPage, FlexContainer } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { getServiceByName } from "#reducers/services.ts";
import Query from "./Query.tsx";
import Text from "./Text.tsx";
import styles from "./style.scss";

type Tab = "text" | "query";

const Elasticsearch: React.FC<IComponentProps> = () => {
  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{ serviceName: string }>();
  const { tab } = useParams<{ tab: Tab }>();
  const currentService = useSelector(
    (state: GlobalState) => currentDs && getServiceByName(state, currentDs, match.params.serviceName)
  );
  const lastSearchTerm = useSelector((state: GlobalState) => {
    return (currentDs && state.datasets[currentDs.id]?.lastSearchQuery) || undefined;
  });

  if (!currentAccount || !currentDs || !currentService) return <ErrorPage statusCode={404} />;

  const currentTab = tab;

  return (
    <>
      <DatasetMetadata
        currentPath={location.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title={"Elasticsearch - " + currentService.name}
      />
      <TabContext value={currentTab}>
        <div className="white">
          <FlexContainer style={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                aria-label=""
                onChange={(_event, tab: Tab) => {
                  if (tab === currentTab) return;
                  history.push({
                    pathname: `/${currentDs.owner.accountName}/${currentDs.name}/elasticsearch/${match.params.serviceName}/${tab}`,
                    search: (tab === "text" && lastSearchTerm && stringifyQuery({ q: lastSearchTerm })) || undefined,
                  });
                }}
                className={styles.tabs}
              >
                <Tab label="Text" value="text" className={styles.tab} />
                <Tab label="Query" value="query" className={styles.tab} hidden={false} />
              </TabList>
            </Box>
          </FlexContainer>
        </div>

        {currentTab === "text" && <Text />}
        {currentTab === "query" && <Query />}
      </TabContext>
    </>
  );
};
export default Elasticsearch;
