import React from "react";
import { useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import { Link } from "react-router-dom";
import { Alert, FlexContainer } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import { getCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import styles from "./style.scss";

const Insights: React.FC<IComponentProps> = ({ route }) => {
  const acl = useAcl();
  const { currentAccount, currentDs } = useSelector((state: GlobalState) => {
    return {
      currentAccount: getCurrentAccount(state),
      currentDs: getCurrentDataset(state),
    };
  });

  if (currentDs && currentDs.graphCount === 0) {
    if (
      currentAccount &&
      acl.check({
        action: "importDataToDataset",
        context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
      }).granted
    ) {
      return (
        <FlexContainer className={styles.noContentMsg}>
          <Link to={`/${currentAccount.accountName}/${currentDs.name}/graphs`} className="noLinkDecoration">
            <Alert className="shadow" key="alert" message="This dataset is empty, start by importing data" info />
          </Link>
        </FlexContainer>
      );
    } else {
      return <div className={styles.noContentMsg}>This dataset is empty.</div>;
    }
  }

  return renderRoutes(route?.routes);
};

export default Insights;
