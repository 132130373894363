import * as React from "react";
import { useSelector } from "react-redux";
import { Button } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { editTopics } from "#reducers/admin.ts";
import { GlobalState } from "#reducers/index.ts";

interface Props {}

const Topics: React.FC<Props> = ({}) => {
  const topics = useSelector((state: GlobalState) => state.topics.list);
  const [topicJson, setTopicJson] = React.useState(topics ? JSON.stringify(topics, null, 2) : DEFAULT_TOPICS);
  const [confirm, setConfirmed] = React.useState(false);
  const dispatch = useDispatch();

  const editTopic = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch<typeof editTopics>(editTopics(JSON.parse(topicJson)))
      .then((res) => console.info("Topics", res.body))
      .catch(() => {});
  };

  return (
    <form className="whiteSink" onSubmit={editTopic}>
      <h3>Topics</h3>
      <div>
        <textarea
          value={topicJson}
          onChange={(e) => setTopicJson(e.target.value)}
          style={{ width: "calc(100% - 4rem)" }}
          rows={10}
        />
      </div>
      <div>
        <input
          checked={confirm}
          onChange={(e) => {
            setConfirmed(e.target.checked);
          }}
          type="checkbox"
        />{" "}
        I am sure
      </div>
      <Button color="secondary" type="submit" disabled={!confirm}>
        Save
      </Button>
    </form>
  );
};
export default Topics;

const DEFAULT_TOPICS = JSON.stringify(
  [
    { iri: "https://triply.cc/topic/art", label: "Art", description: "Art" },
    {
      iri: "https://triply.cc/topic/architecture",
      label: "Architecture",
      description: "Architecture",
      parent: "https://triply.cc/topic/art",
    },
    {
      iri: "https://triply.cc/topic/Test1",
      label: "Test1",
      description: "This is the first test topic",
    },
    {
      iri: "https://triply.cc/topic/Test2",
      label: "Test2",
      description: "This is the second test topic, this is a sub topic of Test1",
      parent: "https://triply.cc/topic/Test1",
    },
    {
      iri: "https://triply.cc/topic/poetry",
      label: "Poetry",
      description: "Poetry",
      parent: "https://triply.cc/topic/art",
    },
    { iri: "https://triply.cc/topic/egovernment", label: "eGov", description: "eGovernment" },
    { iri: "https://triply.cc/topic/geography", label: "Geo", description: "Geography" },
    { iri: "https://triply.cc/topic/history", label: "History", description: "History" },
    { iri: "https://triply.cc/topic/semantics", label: "Semantics", description: "Semantics" },
    {
      iri: "https://triply.cc/topic/sameas",
      label: "SameAs",
      description: "SameAs",
      parent: "https://triply.cc/topic/semantics",
    },
    {
      iri: "https://triply.cc/topic/cultural-heritage",
      label: "Cultural Heritage",
      description: "Cultural Heritage",
    },
    { iri: "https://triply.cc/topic/persons", label: "Persons", description: "Persons" },
    { iri: "https://triply.cc/topic/libraries", label: "Libraries", description: "Libraries" },
    { iri: "https://triply.cc/topic/film", label: "Film", description: "Film" },
    { iri: "https://triply.cc/topic/music", label: "Music", description: "Music" },
    { iri: "https://triply.cc/topic/language", label: "Language", description: "Language" },
  ],
  null,
  2
);
