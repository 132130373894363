import { InputAdornment } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { validation } from "@triply/utils-private";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Alert, FontAwesomeIcon, FormField, MuiTextFieldRedux } from "#components/index.ts";
import styles from "./style.scss";

const emailValidator = validation.toStringValidator(validation.emailValidations);

namespace RequiredDetailsForm {
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    askEmail: boolean;
  }
  export interface FormData {
    email: string;
  }
}

const RequiredDetailsForm = ReduxForm.reduxForm<RequiredDetailsForm.FormData, RequiredDetailsForm.Props>({
  form: "addRequiredInfo",
  // fields: ['accountName'],
  validate: memoizee(
    (formData: RequiredDetailsForm.FormData, props: RequiredDetailsForm.Props) => {
      return {
        email: props.askEmail ? emailValidator(formData.email) : undefined,
      };
    },
    { max: 10 }
  ),
})(
  class RequiredDetailsForm extends React.PureComponent<
    RequiredDetailsForm.Props & Partial<ReduxForm.InjectedFormProps<FormData>>,
    any
  > {
    render() {
      const { handleSubmit, error, submitting, askEmail } = this.props;
      return (
        <form onSubmit={handleSubmit} className={styles.form}>
          {askEmail && (
            <FormField label="Email address">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                name="email"
                props={{
                  type: "email",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon="envelope" />
                      </InputAdornment>
                    ),
                  },
                }}
                component={MuiTextFieldRedux}
              />
            </FormField>
          )}
          <Alert transparent message={error} />
          <div className="form-group mt-5">
            <LoadingButton type="submit" color="secondary" onClick={handleSubmit} loading={submitting}>
              Send my details
            </LoadingButton>
          </div>
        </form>
      );
    }
  } as any
);

export default RequiredDetailsForm;
