import { MenuItem } from "@mui/material";
import { stringify } from "qs";
import * as React from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import { substringMatch } from "#components/Highlight/index.tsx";
import {
  Alert,
  Avatar,
  Button,
  FontAwesomeButton,
  FontAwesomeIcon,
  FontAwesomeRoundIcon,
  Highlight,
  LoadingButton,
  StoryBadge,
} from "#components/index.ts";
import { _MuiAutosuggest, default as MuiAutosuggest } from "#components/MuiAutosuggest/AutoSuggest.tsx";
import { getQueryIconForType } from "#helpers/FaIcons.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useMemoizedFetch from "#helpers/hooks/useMemoizedFetch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import styles from "./style.scss";

type PinnedItem = PinnedDataset | PinnedStory | PinnedQuery;
interface PinnedDataset {
  type: "Dataset";
  item: Pick<Models.SomeDataset, "id" | "name" | "displayName" | "avatarUrl">;
}
interface PinnedStory {
  type: "Story";
  item: Pick<Models.SomeStory, "id" | "name" | "displayName" | "bannerUrl">;
}
interface PinnedQuery {
  type: "Query";
  item: Pick<Models.SomeQuery, "id" | "name" | "displayName" | "resultType">;
}

export interface FormData {
  items: PinnedItem[];
}

interface Props {
  onCancel: React.EventHandler<React.MouseEvent<any>>;
}

const _OverviewForm: React.FC<Props & ReduxForm.InjectedFormProps<FormData, Props>> = (props) => {
  const PinnedItemField = ({ input }: ReduxForm.WrappedFieldProps) => {
    return <AutoSuggestMultiSortable {...input} />;
  };

  const { handleSubmit, submitting, error, pristine, onCancel } = props;
  return (
    <div>
      <form method="POST" onSubmit={handleSubmit}>
        <div style={{ width: "100%", maxWidth: 600 }}>
          <ReduxForm.Field name="items" component={PinnedItemField} />
        </div>
        <Alert transparent message={error} />
        <div className="form-group mt-5">
          <LoadingButton
            type="submit"
            color="secondary"
            disabled={pristine}
            onClick={handleSubmit}
            loading={submitting}
          >
            Save overview
          </LoadingButton>
          <Button onClick={onCancel} className="ml-3" variant="text">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

const OverviewForm = ReduxForm.reduxForm<FormData, Props>({
  form: "accountOverview",
})(_OverviewForm);

export default OverviewForm;

const DragHandle = SortableHandle(() => <FontAwesomeIcon icon="bars" className={styles.handle} />);

const getIcon = (pinnedItem: PinnedItem) => {
  if (!pinnedItem) return <FontAwesomeIcon icon="search" className={styles.formItemIcon} />;
  const pinnedItemLabel = pinnedItem.item.displayName || pinnedItem.item.name;
  switch (pinnedItem.type) {
    case "Dataset":
      return <Avatar avatarName={pinnedItemLabel} avatarUrl={pinnedItem.item.avatarUrl} size="sm" alt={"Account"} />;
    case "Query":
      return (
        <FontAwesomeRoundIcon
          icon={getQueryIconForType(pinnedItem.item.resultType)}
          size="sm"
          aria-label={`Query result type: ${pinnedItem.item.resultType}`}
        />
      );
    case "Story":
      return <StoryBadge bannerUrl={pinnedItem.item.bannerUrl} />;
    default:
      return <FontAwesomeIcon icon="search" className={styles.formItemIcon} aria-label={"Search result"} />;
  }
};

interface SortableListItemProps {
  pinnedItem: PinnedItem;
  onRemove: (record: PinnedItem) => void;
}

const SortableListItem = SortableElement<SortableListItemProps>(({ pinnedItem, onRemove }: SortableListItemProps) => {
  return (
    <div className={styles.sortableElement}>
      <DragHandle />
      <div className={styles.formItemAvatar}>{getIcon(pinnedItem)}</div>
      <div className={styles.itemLabel}>
        {pinnedItem.item.displayName || pinnedItem.item.name}
        {!!pinnedItem.item.displayName && pinnedItem.item.displayName !== pinnedItem.item.name && (
          <span className={styles.secondaryLabel}>&nbsp; — &nbsp;{pinnedItem.item.name}</span>
        )}
      </div>
      <FontAwesomeButton icon="times" onClick={() => onRemove(pinnedItem)} title="Remove item" />
    </div>
  );
});

interface SortableListProps {
  pinnedItems: PinnedItem[];
  onRemove: (item: PinnedItem) => void;
}
const SortableList = SortableContainer<SortableListProps>((props: SortableListProps) => {
  return (
    <div className={styles.sortableList}>
      {props.pinnedItems.map((pinnedItem, index) => (
        <SortableListItem
          key={`item-${getId(pinnedItem)}`}
          index={index}
          pinnedItem={pinnedItem}
          onRemove={props.onRemove}
        />
      ))}
    </div>
  );
});

const getId = (p: PinnedItem) => p.item.id;

interface AutoSuggestMultiSortableProps {
  value: PinnedItem[];
  onChange: (values: PinnedItem[]) => void;
}

const AutoSuggestMultiSortable: React.FC<AutoSuggestMultiSortableProps> = ({ value, onChange }) => {
  const autosuggestRef = React.useRef<_MuiAutosuggest>();
  const constructUrlToApi = useConstructUrlToApi();
  const currentAccount = useCurrentAccount();
  const fetch = useMemoizedFetch<{
    datasets: Models.SomeDataset[];
    stories: Models.SomeStory[];
    queries: Models.SomeQuery[];
  }>(30000);

  const search = async (q: string): Promise<PinnedItem[]> => {
    const url = `${constructUrlToApi({ pathname: "/_some" })}?${stringify(
      {
        q: q,
        o: currentAccount?.accountName,
        e: value.map(getId),
      },
      { arrayFormat: "repeat" }
    )}`;
    const results = await fetch(url);

    if (results) {
      return [
        ...results.datasets.map((d) => ({ type: "Dataset", item: d } as PinnedItem)),
        ...results.stories.map((s) => ({ type: "Story", item: s } as PinnedItem)),
        ...results.queries.map((q) => ({ type: "Query", item: q } as PinnedItem)),
      ];
    }
    return [];
  };

  const handleRemove = (pinnedItem: PinnedItem) => {
    onChange(value.filter((r) => getId(r) !== getId(pinnedItem)));
  };

  const addPinnedItem = (newPinnedItem: PinnedItem) => {
    const values = value || [];
    onChange([...values, newPinnedItem]);
  };

  const handleSortEnd = ({ oldIndex, newIndex }: any) => {
    const newArray = value.slice();
    newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);
    onChange(newArray);
  };

  const autosuggestProps: MuiAutosuggest.Props<PinnedItem> = {
    placeholder: "Type to search",
    label: "Add a dataset, story or query",
    loadSuggestions: search,
    renderSuggestion: (pinnedItem, { query, isHighlighted }) => {
      return (
        <MenuItem selected={isHighlighted} component="div">
          <div className={styles.formItemAvatar}>{getIcon(pinnedItem)}</div>
          <div className="flex wrap">
            <Highlight
              fullText={pinnedItem.item.displayName || pinnedItem.item.name}
              highlightedText={query}
              matcher={substringMatch}
            />
            {!!pinnedItem.item.displayName && (
              <span className={styles.secondaryLabel}>
                &nbsp; — &nbsp;
                <Highlight fullText={pinnedItem.item.name} highlightedText={query} matcher={substringMatch} />
              </span>
            )}
          </div>
        </MenuItem>
      );
    },
    getSuggestionSearchText: (p) => p.item.name,
    clearOnSelect: true,
    TextFieldProps: { fullWidth: true },
    allowNewValues: false,
    onSuggestionSelected: (_e, suggestion) => addPinnedItem(suggestion.suggestion),
  };

  return (
    <>
      <SortableList
        helperClass={styles.draggingElement}
        pinnedItems={value}
        onSortEnd={handleSortEnd}
        useDragHandle
        onRemove={handleRemove}
      />
      {value.length < 10 && (
        <div className={styles.inputField}>
          <MuiAutosuggest innerRef={autosuggestRef} {...autosuggestProps} />
        </div>
      )}
    </>
  );
};
