import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import styles from "./style.scss";

const LoadingPage = React.memo<{ className?: string }>(({ className }) => {
  return (
    <div className={getClassName(styles.loadingWrapper, className)}>
      <FontAwesomeIcon icon="spinner" size="2x" pulse />
    </div>
  );
});

export default LoadingPage;
