import getClassName from "classnames";
import * as React from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { matchRoutes, renderRoutes } from "react-router-config";
import { ErrorPage, Footer, Notifications } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { GlobalState } from "#reducers/index.ts";
import NavBar from "./NavBar.tsx";
import styles from "./style.scss";

export const SIDE_PANEL_COLLAPSED_COOKIE = "lgC";

interface OwnProps extends IComponentProps {
  cookies?: any;
}
interface PropsFromState {
  sidePanelCollapsed: boolean;
}
type Props = OwnProps & PropsFromState;

interface State {
  error?: Error;
  navBarClassName: string;
  hideFooter: boolean;
}

export const FooterContext = React.createContext({
  hideFooter: (hide: boolean) => {},
});

class NavConsole extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      navBarClassName: "",
      hideFooter: false,
    };
  }

  setNavBarClass = (navBarClass: string) => {
    this.setState({ navBarClassName: navBarClass });
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.state.error && this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ error: undefined });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      __CLIENT__ &&
      prevProps.location !== this.props.location &&
      this.props.history.action !== "POP" &&
      !this.props.location.state?.preserveScrollPosition
    ) {
      window.scrollTo(0, 0);
    }
  }

  componentDidCatch(error: Error) {
    this.setState({ error: error });
  }

  hideFooter = (hide: boolean) => {
    this.setState({ hideFooter: hide });
  };

  render() {
    const { sidePanelCollapsed, location, route } = this.props;
    const { navBarClassName } = this.state;

    const embedded = parseSearchString(location.search).embed !== undefined;
    const match = matchRoutes(route!.routes!, this.props.location.pathname)[0].match;
    const onFrontPage = match.path === "/";
    const onDatasetPage = match.path === "/:account/:dataset";
    const hasSidePanel = onDatasetPage;

    return (
      <div className={styles.app}>
        {!embedded && (
          <NavBar onFrontPage={onFrontPage} onDatasetPage={onDatasetPage} match={match} className={navBarClassName} />
        )}
        <main
          className={getClassName({
            [styles.bottomMargin]: !embedded && !this.state.hideFooter,
            [styles.withToolbar]: !onFrontPage && !embedded,
            [styles.withSidePanel]: hasSidePanel,
            [styles.sidePanelCollapsed]: hasSidePanel && sidePanelCollapsed,
          })}
        >
          {this.state.error ? (
            <ErrorPage error={this.state.error} />
          ) : (
            <FooterContext.Provider value={{ hideFooter: this.hideFooter }}>
              {renderRoutes(this.props.route?.routes, {
                setNavBarClass: this.setNavBarClass,
              })}
            </FooterContext.Provider>
          )}
        </main>
        {!embedded && !this.state.hideFooter && (
          <Footer
            className={getClassName({
              [styles.withSidePanel]: hasSidePanel,
              [styles.sidePanelCollapsed]: hasSidePanel && sidePanelCollapsed,
            })}
          />
        )}
        <Notifications />
        <div className={styles.draggingFileOverlay} />
      </div>
    );
  }
}

export default withCookies(
  connect<PropsFromState, {}, OwnProps, GlobalState>((state, ownProps) => {
    return {
      sidePanelCollapsed: state.app.sidePanelCollapsed ?? ownProps.cookies.get(SIDE_PANEL_COLLAPSED_COOKIE) === "1",
    };
  })(NavConsole) as unknown as typeof NavConsole
);
