import getClassName from "classnames";
import * as React from "react";
import styles from "./style.scss";

interface Props {
  className?: string;
  label: string | undefined;
  helperText?: string;
  labelWidth?: number;
  breakPoint?: number;
  inputId?: string;
  children: React.ReactNode;
  required?: boolean;
}
const FormField: React.FC<Props> = ({
  className,
  label,
  helperText,
  labelWidth,
  breakPoint,
  inputId,
  children,
  required,
}) => {
  return (
    <div className={getClassName(className, styles.container)}>
      <div className={styles.labelContainer} style={labelWidth !== undefined ? { width: labelWidth } : undefined}>
        <label className={getClassName(styles.label, { [styles.required]: required })} htmlFor={inputId}>
          {label}
        </label>
        {helperText && <div className={styles.helperText}>{helperText}</div>}
      </div>
      <div
        className={styles.field}
        style={breakPoint !== undefined ? { minWidth: `min(100%, ${breakPoint}px)` } : undefined}
      >
        {children}
      </div>
    </div>
  );
};

export default FormField;
