import { Backdrop, Button, Container, Divider, Paper } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import styles from "./largeResultWidget.scss";

interface Props {
  onContinue: () => void;
  exceededSize: "resultSize" | "amountOfResults";
  extraAction?: React.ReactNode;
}

const LargeResultWidget: React.FC<Props> = ({ exceededSize, onContinue, extraAction }) => {
  return (
    <Backdrop className={getClassName("flex", styles.wrapper)} open>
      <Container maxWidth="xs" className={styles.widgetContainer}>
        <Paper elevation={4} className="py-1">
          <Container>
            <p className="py-3 my-0">
              This query returned a {exceededSize === "resultSize" ? "large result" : "lot of results"}, rendering it is
              costly and could make the browser unresponsive.
            </p>
          </Container>
          <Divider />
          <Container>
            <Button color="info" variant="text" onClick={onContinue} className="mr-2">
              Continue anyway
            </Button>
            {extraAction || null}
          </Container>
        </Paper>
      </Container>
    </Backdrop>
  );
};

export default LargeResultWidget;
