import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { ApplicationMetadata } from "#components/Forms/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateClientConfig } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";

interface Props {}

const SiteMetadata: React.FC<Props> = ({}) => {
  const name = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const tagline = useSelector((state: GlobalState) => state.config.clientConfig?.branding.tagline);
  const description = useSelector((state: GlobalState) => state.config.clientConfig?.branding.description);
  const welcomeMessage = useSelector((state: GlobalState) => state.config.clientConfig?.branding.welcomeMessage);

  const dispatch = useDispatch();

  const handleMetadataSubmit = (values: ApplicationMetadata.FormData) => {
    return dispatch<typeof updateClientConfig>(
      updateClientConfig({
        branding: {
          name: values.name,
          tagline: values.tagline,
          description: values.description,
          welcomeMessage: values.welcomeText,
        },
      })
    ).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      }
    );
  };

  return (
    <div className="whiteSink">
      <h3>Site metadata</h3>
      <ApplicationMetadata
        initialValues={{
          name: name,
          tagline: tagline,
          description: description,
          welcomeText: welcomeMessage,
        }}
        onSubmit={handleMetadataSubmit}
        enableReinitialize
      />
    </div>
  );
};
export default SiteMetadata;
