import { syntaxTree } from "@codemirror/language";
import { ChangeSpec, SelectionRange, StateCommand } from "@codemirror/state";
import { SyntaxNode, Tree } from "@lezer/common";
import {
  ANON,
  BlankNodePropertyListPath,
  ConstructQuery,
  ConstructTemplate,
  ConstructTriples,
  GraphGraphPattern,
  GroupGraphPattern,
  InlineDataFull,
  InlineDataOneVar,
  IRIREF,
  NIL,
  ObjectList,
  ObjectListPath,
  PathElt,
  PathSequence,
  PNAME_NS,
  PrefixDecl,
  PropertyListNotEmpty,
  PropertyListPathNotEmpty,
  ServiceGraphPattern,
  TriplesBlock,
  TriplesSameSubject,
  TriplesSameSubjectPath,
  TriplesTemplate,
  VarOrIri,
} from "../grammar/sparqlParser.terms.ts";

const VALID_POSITIONS = [
  ANON,
  NIL,
  BlankNodePropertyListPath,
  PropertyListNotEmpty,
  PropertyListPathNotEmpty,
  TriplesSameSubject,
  TriplesSameSubjectPath,
  ConstructQuery,
  ConstructTemplate,
  ConstructTriples,
  PathElt,
  TriplesBlock,
  GroupGraphPattern,
  PathSequence,
  ObjectListPath,
  ObjectList,
  PrefixDecl,
  TriplesTemplate,
  InlineDataOneVar,
  InlineDataFull,
  ServiceGraphPattern,
  GraphGraphPattern,
];

function shouldSkip(tree: Tree, from: number, termToCheck: number, allowedTokens: string[]) {
  const node = tree.cursorAt(from, -1);
  if (termToCheck === node.type.id) {
    let at = from;
    let node = tree.cursorAt(at, -1);
    while (termToCheck === node.type.id) {
      node = tree.cursorAt(at, -1);
      at--;
    }
    return !allowedTokens.includes(node.name);
  }
  return false;
}

/**
 * Auto-closes '<' in IRI completion position
 */
export const autoCloseIri: StateCommand = function ({ state, dispatch }) {
  const tree = syntaxTree(state);
  // Update the state so that the syntax for the Prefix namespace is correct

  const changes: ChangeSpec[] = [];
  for (const range of state.selection?.ranges || []) {
    if (range.from !== range.to) {
      // For now, let's not do anything with double ranges, let's just add '<'
    } else {
      const cursor = tree.cursorAt(range.from, -1);
      const node = cursor.node;
      if (VALID_POSITIONS.includes(node.type.id)) {
        // These node types are on a very high level, instead we track back until we find a token that we expect to be there
        if (shouldSkip(tree, range.from, GroupGraphPattern, ["{", ".", ";"])) continue;
        if (shouldSkip(tree, range.from, ConstructTemplate, ["{", ".", ";"])) continue;
        if (shouldSkip(tree, range.from, ConstructQuery, ["{", ".", ";"])) continue;
        if (shouldSkip(tree, range.from, ObjectListPath, [","])) continue;
        if (shouldSkip(tree, range.from, ObjectList, [","])) continue;
        if (shouldSkip(tree, range.from, BlankNodePropertyListPath, ["[", ";"])) continue;
        // Prefix declaration
        if (node.type.id === PrefixDecl) {
          if (!node.getChild(PNAME_NS) || node.getChild(IRIREF)) continue;
        }
        // This is an "high level" type as well, however, we should make sure that we are not outside of a data-row declaration
        if (node.type.id === InlineDataFull) {
          let at = range.from;
          let node = tree.cursorAt(range.from, -1);
          while (node.type.id === InlineDataFull) {
            node = tree.cursorAt(at, -1);
            at--;
          }
          if (node.name === "{" || node.name === ")") continue;
        }
        if (node.type.id === ServiceGraphPattern || node.type.id === GraphGraphPattern) {
          if (node.getChild(VarOrIri)) continue;
        }

        changes.push({
          from: range.from,
          to: range.from,
          insert: ">",
        });
      }
    }
  }
  if (changes.length > 0) {
    dispatch(
      state.update({
        userEvent: "Close IRI",
        changes: changes,
      })
    );
  }
  return false;
};
