import { Alert, FormControlLabel, InputAdornment, Radio } from "@mui/material";
import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import { useSelector } from "react-redux";
import * as ReduxForm from "redux-form";
import { FormSection } from "redux-form";
import { Constants, Models } from "@triply/utils";
import { ServiceType } from "@triply/utils/Models.js";
import { validation } from "@triply/utils-private";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import {
  Button,
  FontAwesomeIcon,
  FormField,
  MaterialRadioButtonGroup,
  MuiTextFieldRedux,
  ServiceTypeBadge,
} from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { isServiceNameAvailable } from "#reducers/services.ts";
import { SERVICE_DESCRIPTIONS } from "../../../containers/Services/ServiceListItem";
import styles from "./style.scss";

const serviceNameValidator = validation.toStringValidator(validation.serviceNameValidations);

namespace ServiceAddForm {
  export type FormData = {
    type: Models.ServiceType;
    name: string;
    memoryLimit?: number;
    config?: { reasonerType: "None" | "RDFS" | "OWL" };
  };

  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    className?: string;
    forDataset: Dataset;
    cancelFunction: () => void;
  }
}

const ServiceAdd: React.FC<
  ServiceAddForm.Props & ReduxForm.InjectedFormProps<ServiceAddForm.FormData, ServiceAddForm.Props>
> = ({ handleSubmit, className, pristine, asyncValidating, form, error, cancelFunction, submitting, invalid }) => {
  const user = useAuthenticatedUser();
  const isLightUser = user?.role === "light";
  const triplyDbDemoLink = useSelector((state: GlobalState) => state.config.clientConfig?.triplydb?.triplydbDemoLink);
  const type: Models.ServiceType = useSelector((state: GlobalState) =>
    ReduxForm.formValueSelector(form)(state, "type")
  );
  const acl = useAcl();

  return (
    <form onSubmit={handleSubmit} className={getClassName(className)}>
      {isLightUser && (
        <Alert
          className="mt-0 mb-7 "
          severity="warning"
          action={
            triplyDbDemoLink && (
              <a href={triplyDbDemoLink}>
                <Button variant="text">Book a demo</Button>
              </a>
            )
          }
        >
          Only{" "}
          <a href={Constants.SUBSCRIPTIONS_PAGE} target="_blank">
            enterprise users
          </a>{" "}
          may create a service.
          <br />
          For more information about this feature check{" "}
          <a
            href={"https://docs.triply.cc/triply-db-getting-started/publishing-data/#starting-services"}
            target="_blank"
          >
            our documentation
          </a>{" "}
          or book a demo!
        </Alert>
      )}
      <p>
        The service will load the current state of the dataset.
        <br />
        Changes made to the dataset will <b>not</b> be reflected in the running service.
      </p>
      <FormField label="Name" className="mt-7 mb-6" inputId={`${form}-name`}>
        <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
          name="name"
          props={{
            autoFocus: true,
            formIsPristine: pristine,
            fullWidth: true,
            InputProps: {
              id: `${form}-name`,
              endAdornment: asyncValidating && (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon="cog" spin />
                </InputAdornment>
              ),
            },
          }}
          component={MuiTextFieldRedux}
        />
      </FormField>

      <FormField label="Service type" className="mb-6" inputId={`${form}-ServiceType`}>
        <ReduxForm.Field<ReduxForm.BaseFieldProps<ServiceTypeProps>>
          name="type"
          component={ServiceType}
          props={{ id: `${form}-ServiceType` }}
        />
      </FormField>

      {type === "jena" && (
        <FormField label="Reasoner" className="mb-6" inputId={`${form}-Jena-Reasoner`}>
          <FormSection name="config">
            <MaterialRadioButtonGroup.Field
              name={"reasonerType"}
              component={MaterialRadioButtonGroup}
              id={`${form}-Jena-Reasoner`}
            >
              <FormControlLabel value="None" control={<Radio color="primary" />} label="None" />
              <FormControlLabel value="RDFS" control={<Radio color="primary" />} label="RDFS" />
              <FormControlLabel value="OWL" control={<Radio color="primary" />} label="OWL" />
            </MaterialRadioButtonGroup.Field>
          </FormSection>
        </FormField>
      )}

      {acl.check({ action: "setServiceMemoryLimit" }).granted && (
        <FormField
          label="Memory limit"
          helperText="In bytes or gb/mb notation. Leave blank to set automatically."
          className="mb-6"
          inputId={`${form}-MemoryLimit`}
        >
          <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
            name="memoryLimit"
            props={{
              fullWidth: true,
              InputProps: { id: `${form}-MemoryLimit` },
            }}
            component={MuiTextFieldRedux}
          />
        </FormField>
      )}

      {error && (
        <Alert severity="error" role="alert">
          {error}
        </Alert>
      )}

      <div className={getClassName(styles.btns, "form-group mt-7")}>
        <LoadingButton
          type="submit"
          color="secondary"
          disabled={invalid || isLightUser}
          onClick={handleSubmit}
          loading={submitting}
        >
          Create service
        </LoadingButton>
        {cancelFunction && !submitting && (
          <Button variant="text" onClick={cancelFunction}>
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

const FORM_NAME = "serviceCreate";

const ServiceAddForm = ReduxForm.reduxForm<ServiceAddForm.FormData, ServiceAddForm.Props>({
  form: FORM_NAME,
  validate: memoizee(
    (formData: ServiceAddForm.FormData) => {
      return {
        name: serviceNameValidator(formData.name),

        memoryLimit: (function (memLimit) {
          let problem = validation.memoryLimit(memLimit ? memLimit.toString() : undefined);
          if (problem) return problem.message;
        })(formData.memoryLimit),
      };
    },
    { max: 10 }
  ),
  asyncValidate: async function asyncValidate(values, dispatch, props) {
    return dispatch(isServiceNameAvailable("name", props.forDataset, values.name));
  },
  asyncBlurFields: ["name"],
})(ServiceAdd);

export default ServiceAddForm;

/**
Using a regular button instead of radio button (don't want the round thingy) <-- The round thingy is everywhere now mwahahahaa
**/

interface ServiceTypeProps {
  id: string;
}

const ServiceType: React.FC<ServiceTypeProps & ReduxForm.WrappedFieldProps> = ({ input, id }) => {
  const enabledServices = useSelector((state: GlobalState) => state.config.clientConfig?.enabledServices);
  const updateHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      input.onChange(e.currentTarget.value);
    },
    [input]
  );
  const keyHandler = React.useCallback((e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "ArrowRight" || e.key === "ArrowDown" || (e.key === "Tab" && !e.shiftKey)) {
      // Select next on tab, arrow-right or arrow-down
      if (e.currentTarget.nextElementSibling) {
        (e.currentTarget.nextElementSibling as HTMLButtonElement).focus();
        if (e.key === "Tab") e.preventDefault();
        return true;
        // Unless we're at the last element, then we select the first element (unless tab is pressed then it should do the default behavior)
      } else if (e.key !== "Tab" && e.currentTarget.parentElement?.firstChild) {
        (e.currentTarget.parentElement.firstChild as HTMLButtonElement).focus();
        return true;
      }
    } else if (e.key === "ArrowLeft" || e.key === "ArrowUp" || (e.key === "Tab" && e.shiftKey)) {
      // Select previous on shift+tab, arrow-left or arrow-up
      if (e.currentTarget.previousElementSibling) {
        (e.currentTarget.previousElementSibling as HTMLButtonElement).focus();
        if (e.key === "Tab") e.preventDefault();
        return true;
        // Unless we're at the first element, then we select the last element (unless tab is pressed then it should do the default behavior)
      } else if (e.key !== "Tab" && e.currentTarget.parentElement?.lastElementChild) {
        (e.currentTarget.parentElement.lastElementChild as HTMLButtonElement).focus();
        return true;
      }
    }
    return false;
  }, []);

  if (enabledServices === undefined || enabledServices.length === 0) {
    return <Alert severity="error">No services are enabled</Alert>;
  }
  const value: Models.ServiceType = input.value || enabledServices[0];
  return (
    <>
      <div role="radiogroup" aria-orientation="horizontal" className={styles.serviceTypes} id={id}>
        {enabledServices.map((serviceType) => {
          const active = value === serviceType;
          return (
            <button
              key={serviceType}
              aria-describedby={
                active && SERVICE_DESCRIPTIONS[value] ? `${FORM_NAME}-serviceTypeDescription-${serviceType}` : undefined
              }
              tabIndex={active ? 0 : -1} // According to mdn, the default element you should tab into is the selected element (or the first if none is set). Other tab events we handle with the tab keydown events
              role="radio"
              aria-checked={active}
              type="button"
              value={serviceType}
              className={getClassName(styles.serviceType, active ? styles.active : undefined)}
              onClick={updateHandler}
              onKeyDown={keyHandler}
            >
              <ServiceTypeBadge
                showLabel
                type={serviceType}
                className={getClassName({ [styles.inactiveAvatar]: !active })}
              />
            </button>
          );
        })}
      </div>
      {SERVICE_DESCRIPTIONS[value] && (
        <Alert severity="info" className="mt-3" id={`${FORM_NAME}-serviceTypeDescription-${value}`}>
          {SERVICE_DESCRIPTIONS[value]}
        </Alert>
      )}
    </>
  );
};
