import * as React from "react";
import * as ReduxForm from "redux-form";
import styles from "./style.scss";

namespace SimpleDateField {
  export interface Props {
    startDate?: string; // Format YYYY-MM-DD
    endDate?: string; // Format YYYY-MM-DD
  }
}

const SimpleDateField: React.FC<SimpleDateField.Props & ReduxForm.WrappedFieldProps> & {
  Field: new () => React.Component<SimpleDateField.Props & ReduxForm.BaseFieldProps>;
} = ({ startDate, endDate, input }) => {
  if (startDate && endDate) {
    if (Date.parse(startDate) > Date.parse(endDate)) {
      console.warn("Startdate is further in the future then the end-date");
    }
  }
  return (
    <div className={styles.textField}>
      <input
        type={"date"}
        min={startDate} // React will filter falsy values
        max={endDate}
        {...input}
      />
    </div>
  );
};

SimpleDateField.Field = ReduxForm.Field as any;
export default SimpleDateField;
