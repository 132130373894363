import * as React from "react";
import { Html } from "#components/index.ts";
import styles from "./style.scss";

const ELLIPSIS_SIDE = 30;
const ELLIPSIS_TRIGGER_SIZE = 3 * ELLIPSIS_SIDE;

export interface Props {
  value: string;
  renderAsHTML?: boolean;
  title?: string;
  language?: string;
}

const Ellipsis: React.FC<Props> = (props) => {
  const [expanded, expand] = React.useState(false);
  const value = props.value;
  if (expanded || value.length < ELLIPSIS_TRIGGER_SIZE) {
    if (props.renderAsHTML) {
      return (
        <div title={props.title || ""}>
          <Html>{value}</Html>
        </div>
      );
    }
    return (
      <span lang={props.language ? props.language : ""} title={props.title || ""}>
        {value}
      </span>
    );
  }
  return (
    <span lang={props.language ? props.language : ""} title={props.title || ""}>
      {value.substring(0, ELLIPSIS_SIDE)}{" "}
      <span
        onClick={() => expand(true)}
        className={styles.dots}
        title={value}
        role="button"
        tabIndex={0}
        aria-label="Show full text"
      >
        ......
      </span>{" "}
      {value.substring(value.length - ELLIPSIS_SIDE)}
    </span>
  );
};

export default Ellipsis;
