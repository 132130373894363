import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { validation } from "@triply/utils-private";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Button, FontAwesomeIcon, MuiTextFieldRedux } from "#components/index.ts";

const urlValidator = validation.toStringValidator([validation.nonBlacklistedHttpUrl, validation.URL]);

namespace AddDownloadUrlForm {
  export interface FormData {
    url: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    className?: string;
  }
}

const AddDownloadUrlForm = ReduxForm.reduxForm<AddDownloadUrlForm.FormData, AddDownloadUrlForm.Props>({
  form: "addDownloadUrl",
  validate: memoizee((formData: AddDownloadUrlForm.FormData) => {
    return {
      url: urlValidator(formData.url),
    };
  }),
})(
  class AddDownloadUrlForm extends React.PureComponent<
    AddDownloadUrlForm.Props & Partial<ReduxForm.InjectedFormProps<FormData>>
  > {
    render() {
      const { submitting, handleSubmit, className, invalid, pristine } = this.props;
      return (
        <div className={className}>
          <form method="POST" noValidate className="flex" onSubmit={handleSubmit}>
            <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
              name="url"
              props={{
                fullWidth: true,
                type: "url",
                placeholder: "Add data from a URL",
                variant: "outlined",
                InputProps: {
                  endAdornment: (
                    <LoadingButton
                      type="submit"
                      color="secondary"
                      disabled={submitting || invalid || pristine}
                      onClick={handleSubmit}
                      loading={submitting}
                    >
                      ↵
                    </LoadingButton>
                  ),
                },
              }}
              component={MuiTextFieldRedux}
            />
          </form>
        </div>
      );
    }
  } as any
);
export default AddDownloadUrlForm;
