import { GoogleDataTableCell, GoogleDataTableColumnType, GoogleDataTableRow, GoogleViz } from "react-google-charts";
import { getPrefixed, Prefix, PrefixesArray } from "@triply/utils/prefixUtils.js";
import { Binding, Term } from "#components/Sparql/SparqlUtils.ts";

// Comment from YASGUI implementation
// Google Chart formats numbers by default, and adds thousand separators. We don't want this when displaying years. Refer https://issues.triply.cc/issues/4625
const DISABLE_NUMBER_FORMATTING = "0";

const DATATYPE_DATE_GROUPING = [
  "http://www.w3.org/2001/XMLSchema#gYearMonth",
  "http://www.w3.org/2001/XMLSchema#gYear",
  "http://www.w3.org/2001/XMLSchema#gMonthDay",
  "http://www.w3.org/2001/XMLSchema#gDay",
  "http://www.w3.org/2001/XMLSchema#gMonth",
];

const DATATYPE_NUMBER_DATE_GROUPING = [
  "http://www.w3.org/2001/XMLSchema#float",
  "http://www.w3.org/2001/XMLSchema#decimal",
  "http://www.w3.org/2001/XMLSchema#int",
  "http://www.w3.org/2001/XMLSchema#integer",
  "http://www.w3.org/2001/XMLSchema#long",
  ...DATATYPE_DATE_GROUPING,
];

const DATATYPE_NUMBER_DATE_FULL_GROUPING = [
  "http://www.w3.org/2001/XMLSchema#double",
  ...DATATYPE_NUMBER_DATE_GROUPING,
];

const DATATYPE_TIME_GROUPING = ["http://www.w3.org/2001/XMLSchema#dateTime", "http://www.w3.org/2001/XMLSchema#time"];

function getGoogleFormatForBinding(binding: Term | undefined): string | undefined {
  if (binding !== undefined && "datatype" in binding && DATATYPE_DATE_GROUPING.includes(binding.datatype)) {
    return DISABLE_NUMBER_FORMATTING;
  }
  return undefined;
}

function getGoogleTypeForBinding(binding: Term | undefined): GoogleDataTableColumnType | undefined {
  if (binding === undefined) return undefined;
  if (binding.type !== undefined && "datatype" in binding) {
    if (DATATYPE_NUMBER_DATE_FULL_GROUPING.includes(binding.datatype)) return "number";
    if (binding.datatype === "http://www.w3.org/2001/XMLSchema#date") return "date";
    if (binding.datatype === "http://www.w3.org/2001/XMLSchema#dateTime") return "datetime";
    if (binding.datatype === "http://www.w3.org/2001/XMLSchema#time") return "timeofday";
  }
  return "string";
}

function getGoogleTypeForBindings(
  bindings: Binding[],
  header: string
): {
  googleType: GoogleDataTableColumnType;
  pattern: string | undefined;
} {
  let googleType: GoogleDataTableColumnType | undefined;
  let pattern: string | undefined;
  for (const binding of bindings) {
    if (!googleType) {
      googleType = getGoogleTypeForBinding(binding[header]);
      pattern = getGoogleFormatForBinding(binding[header]);
    } else {
      const newType = getGoogleTypeForBinding(binding[header]);
      // Can't have two different types, return the default (string)
      if (newType && newType !== googleType) {
        return { googleType: "string", pattern: undefined };
      }
    }
  }
  // If no type available default to string and no pattern
  return { googleType: googleType || "string", pattern: pattern || undefined };
}

function castGoogleType(binding: Term | undefined, prefixes: PrefixesArray, googleType: string): GoogleDataTableCell {
  if (binding === undefined) return {};
  if (googleType !== "string" && binding !== undefined && "datatype" in binding) {
    if (DATATYPE_NUMBER_DATE_GROUPING.includes(binding.datatype)) return Number(binding.value);
    if (binding.datatype === "http://www.w3.org/2001/XMLSchema#double") return Number(parseFloat(binding.value));
    if (binding.datatype === "http://www.w3.org/2001/XMLSchema#date") {
      //Comments from YASGUI Implementation
      //The date function does not parse -any- date (including most xsd dates!)
      //datetime and time seem to be fine though.
      //so, first try our custom parser. if that does not work, try the regular date parser anyway
      //There are no PROPER xml schema to js date parsers
      //A few libraries exist: moment, jsdate, Xdate, but none of them parse valid xml schema dates (e.g. 1999-11-05+02:00).
      //There are other hacky solutions (regular expressions based on trial/error) such as http://stackoverflow.com/questions/2731579/convert-an-xml-schema-date-string-to-a-javascript-date
      const date = new Date(binding.value.replace(/(\d)([\+-]\d{2}:\d{2})/, "$1Z$2"));
      if (date && !isNaN(date.getDate())) return date;
    }
    if (DATATYPE_TIME_GROUPING.includes(binding.datatype)) return new Date(binding.value);
    return binding.value;
  } else {
    if (binding.type === "uri") {
      return getPrefixed(binding.value, prefixes) || binding.value;
    } else {
      return binding.value;
    }
  }
}

export function parseGoogleData(
  google: GoogleViz,
  data: Binding[],
  variables: string[],
  prefixes: Prefix[]
): string | undefined {
  const dataTable = new google.visualization.DataTable({});
  if (!data) return undefined;
  for (const header of variables) {
    const res = getGoogleTypeForBindings(data, header);
    dataTable.addColumn({ type: res.googleType, label: header, pattern: res.pattern });
  }
  for (const result of data) {
    const row: GoogleDataTableRow = variables.map((variable, columnId) =>
      castGoogleType(result[variable], prefixes, dataTable.getColumnType(columnId))
    );
    dataTable.addRow(row);
  }
  // Apply a generic number-formatter for date-Like
  const disabledNumberFormatter = new google.visualization.NumberFormat({
    pattern: DISABLE_NUMBER_FORMATTING,
  });
  for (let currentIndex = 0; currentIndex < dataTable.getNumberOfColumns(); currentIndex++) {
    if (dataTable.getColumnPattern(currentIndex) === DISABLE_NUMBER_FORMATTING) {
      disabledNumberFormatter.format(dataTable, currentIndex);
    }
  }
  return dataTable.toJSON();
}
