import { Switch } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { FeatureToggle } from "@triply/utils/Models.js";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateFeatureToggle } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";
import styles from "./style.scss";

interface Props {}

const FeatureToggles: React.FC<Props> = ({}) => {
  const featureToggles = useSelector((state: GlobalState) => state.config.clientConfig?.featureToggles);
  const [ongoingFeatureToggleSwitching, setOngoingFeatureToggleSwitch] = React.useState<{
    [feature in FeatureToggle]?: boolean;
  }>({});

  const dispatch = useDispatch();
  return (
    <div className="whiteSink">
      <h3>Feature Toggles</h3>
      <div>
        {featureToggles &&
          Object.entries(featureToggles).map(([key, object]) => {
            return (
              <div key={key} className={styles.featureToggle}>
                <b className={styles.featureToggleLabel}>{key}</b>
                <p className={styles.featureToggleDescription}>{object.label}</p>
                <Switch
                  color="primary"
                  checked={object.value}
                  onChange={(_event, checked) => {
                    setOngoingFeatureToggleSwitch({ ...ongoingFeatureToggleSwitching, [key]: true });
                    dispatch<typeof updateFeatureToggle>(updateFeatureToggle(key as FeatureToggle, checked))
                      .catch(() => {})
                      .finally(() => {
                        setOngoingFeatureToggleSwitch({ ...ongoingFeatureToggleSwitching, [key]: false });
                      });
                  }}
                  disabled={ongoingFeatureToggleSwitching[key as FeatureToggle]}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default FeatureToggles;
