import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { MatchedRoute } from "react-router-config";
import { FontAwesomeButton, FontAwesomeIcon, NavIcon, UserWidget } from "#components/index.ts";
import SiteSearch from "#components/SiteSearch/index.tsx";
import { undoImpersonate, useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import useDispatch from "../../helpers/hooks/useDispatch.ts";
import BreadCrumbs from "./BreadCrumbs.tsx";
import LoginButtons from "./LoginButtons.tsx";
import styles from "./style.scss";

interface Props {
  onFrontPage: boolean;
  onDatasetPage: boolean;
  className?: string;
  match: MatchedRoute<{
    account?: string;
    dataset?: string;
    query?: string;
    story?: string;
  }>["match"];
}

const NavBar: React.FC<Props> = ({ className, onFrontPage, onDatasetPage, match }) => {
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation<{ dsPanelOpen: boolean } | undefined>();
  const history = useHistory();
  const dispatch = useDispatch();
  const appConfig = useSelector((state: GlobalState) => state.config.clientConfig);
  return (
    // The mui-fixed class makes sure this element gets some padding when the scrollbar is removed because of a MUI popover opening..
    <nav
      className={getClassName(className, styles.navbar, { [styles.frontPage]: onFrontPage }, "mui-fixed")}
      aria-label="Main"
    >
      <div className={getClassName(styles.navBarLeft)}>
        {appConfig && (
          <NavIcon
            className={getClassName({ ["hidden-xs"]: onDatasetPage })}
            svg={appConfig.branding.logo}
            to="/"
            title={`Home | ${appConfig.branding.name}`}
          />
        )}
        {
          //only show hamburger icon when we're at the ds level (as the panel shows ds-related actions)
          onDatasetPage && (
            <FontAwesomeButton
              title="open/close menu"
              icon="bars"
              size="lg"
              onClick={() => {
                if (location.state?.dsPanelOpen) {
                  history.goBack();
                } else {
                  history.push({
                    search: location.search,
                    hash: location.hash,
                    state: { dsPanelOpen: true, preserveScrollPosition: true },
                  });
                }
              }}
              className={getClassName(styles.hamburgerBtn, "ml-2", "mr-4")}
              disableHoverStyling
              aria-pressed={!!location.state?.dsPanelOpen}
            />
          )
        }
        <BreadCrumbs match={match} />
      </div>
      <div className={getClassName(styles.navBarRight)}>
        <SiteSearch className={styles.quickNavSearch} mayShrink />
        {authenticatedUser?.role && (
          <UserWidget
            pathname={location.pathname}
            accountName={authenticatedUser.accountName}
            avatarUrl={authenticatedUser.avatarUrl}
            consentExpiring={authenticatedUser.legalConsent === "expiring"}
            siteAdmin={authenticatedUser.role === "siteAdmin" || authenticatedUser.role === "superAdmin"}
            adminStatus={appConfig?.limitStatus}
          />
        )}
        {authenticatedUser?.impersonated && (
          <button
            className={getClassName(styles.impersonateBtn, "resetButton")}
            title="Stop impersonation"
            onClick={() => dispatch<typeof undoImpersonate>(undoImpersonate()).catch(() => {})}
            aria-label="Stop impersonation"
          >
            <FontAwesomeIcon icon={["fas", "user-secret"]} />
          </button>
        )}
        {!authenticatedUser && (
          <LoginButtons enabledOauth={!!appConfig?.enabledOauth?.length} passwordSignup={!!appConfig?.passwordSignup} />
        )}
      </div>
    </nav>
  );
};

export default NavBar;
