import { Alert, IconButton, Tooltip, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DATATYPE_DEFINITIONS } from "@triply/utils/Constants.js";
import { formatNumber } from "@triply/utils-private/formatting.js";
import { Button, Dialog, FontAwesomeIcon, Prefixed } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";
import { showNotification } from "#reducers/notifications.ts";
import { parseSearchString, stringifyQuery } from "../../helpers/utils";
import { useCurrentAccount } from "../../reducers/app";
import { Graphs } from "../../reducers/graphs";
import styles from "./style.scss";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  () => ({
    "&:before": {
      display: "none",
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<FontAwesomeIcon icon="chevron-down" />} {...props} />
))(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    marginRight: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  border: 0,
}));

export const QualityReportButton: React.FC<{ graphName: string }> = ({ graphName }) => {
  const history = useHistory();

  return (
    <IconButton
      size="small"
      onClick={() => {
        history.push(
          { search: stringifyQuery({ report: graphName }) },
          { preserveScrollPosition: true, canUseGoBack: true }
        );
      }}
      title="Open data quality report"
      color="warning"
    >
      <FontAwesomeIcon icon={["fas", "file-exclamation"]} fixedWidth />
    </IconButton>
  );
};

const QualityReport: React.FC<{ graphs: Graphs; ldTablePage: string }> = ({ graphs, ldTablePage }) => {
  const history = useHistory();
  const location = useLocation<{ preserveScrollPosition?: true; canUseGoBack?: true } | undefined>();
  const query = parseSearchString(location.search);
  const graphWithDataQuality = !!query.report && graphs.find((g) => g.graphName === query.report);
  const report = !!graphWithDataQuality && graphWithDataQuality.qualityReport;
  const graphId = !!graphWithDataQuality && graphWithDataQuality.id;
  const expanded = query.expanded;
  const prefixes = useDatasetPrefixes();
  const currentDs = useCurrentDataset();
  const currentAccount = useCurrentAccount();
  const importUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs?.owner.accountName}/${currentDs?.name}/graphs/${graphId}/importQualityReport`,
  });
  const dispatch = useDispatch();

  if (!report) return null;

  const graphName = query.report as string;

  function dialogOnClose() {
    if (location.state?.canUseGoBack) {
      history.goBack();
    } else {
      history.push({ search: "" });
    }
  }

  return (
    <Dialog open={!!report} maxWidth="xl" classes={{ paper: "p-5" }} onClose={dialogOnClose} closeButton>
      <h3>
        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} fixedWidth /> Data Quality Report
      </h3>
      <Typography variant="caption" className="py-3">
        {graphName}
      </Typography>
      <h4>
        {report.numberOfDistinctErrors
          ? `This graph contains ${formatNumber(report.numberOfDistinctErrors)} data quality ${
              report.numberOfDistinctErrors > 1 ? "issues" : "issue"
            } that occur ${report.numberOfErrors} ${report.numberOfErrors > 1 ? "times" : "time"} in total`
          : `This graph contains ${report.numberOfErrors} ${report.numberOfErrors > 1 ? "issues" : "issue"}`}
      </h4>
      <div className="flex column gy-3">
        {report.datatypeErrors && (
          <Accordion
            expanded={expanded === "datatype"}
            onChange={(_, isExpanded) => {
              history.replace(
                {
                  search: stringifyQuery({ report: graphName, expanded: isExpanded ? "datatype" : undefined }),
                },
                { preserveScrollPosition: true, canUseGoBack: location.state?.canUseGoBack }
              );
            }}
          >
            <AccordionSummary id="datatype-header" aria-controls="datatype-content">
              <div>
                <h5 className={styles.typeOfError}>
                  Invalid datatype literals ({formatNumber(report.numberOfDatatypeErrors || 0)})
                </h5>
                <div>The value of these literals could not be parsed according to their datatype.</div>
                <div>
                  The datatype of these literals has been replaced with{" "}
                  <code>
                    <Prefixed>http://www.w3.org/2001/XMLSchema#string</Prefixed>
                  </code>
                  .
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="pl-3">
                {Object.values(report.datatypeErrors).map((errorsForDatatype) => {
                  const datatypeInfo = DATATYPE_DEFINITIONS[errorsForDatatype.datatype];
                  return (
                    <div key={errorsForDatatype.datatype}>
                      <div className="flex row">
                        <h6 className="mt-4">
                          <Prefixed>{errorsForDatatype.datatype}</Prefixed>
                          {` (${formatNumber(errorsForDatatype.count)})`}
                        </h6>
                        {datatypeInfo && (
                          <Tooltip
                            title={
                              <>
                                {datatypeInfo.description} Examples:{" "}
                                {datatypeInfo.examples
                                  .map<React.ReactNode>((example) => (
                                    <span key={example.value}>
                                      <code>{example.value}</code>
                                      {example.textual && ` (${example.textual})`}
                                    </span>
                                  ))
                                  .reduce<React.ReactNode[]>(
                                    (prev, curr) => (prev.length !== 0 ? [...prev, ", ", curr] : [curr]),
                                    []
                                  )}
                              </>
                            }
                            placement="right"
                          >
                            <IconButton size="small" className="px-2 mt-3">
                              <FontAwesomeIcon icon={"info-circle"} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                      <ol>
                        {errorsForDatatype.examples.map((example, index) => {
                          return (
                            <li key={index}>
                              <Tooltip title={example.message} placement="right">
                                <span>
                                  {example.value === "" ? (
                                    <em>(empty value)</em>
                                  ) : (
                                    <code className="nowrap">{example.value}</code>
                                  )}
                                  <IconButton
                                    title="Show example in table"
                                    size="small"
                                    component={Link}
                                    to={{
                                      pathname: ldTablePage,
                                      search: stringifyQuery({
                                        ...example.context,
                                        graph: graphName,
                                      }),
                                    }}
                                    className="ml-2"
                                  >
                                    <FontAwesomeIcon size="xs" icon="table-cells" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </li>
                          );
                        })}
                        {errorsForDatatype.count > errorsForDatatype.examples.length && (
                          <Typography variant="caption">{`...and ${formatNumber(
                            errorsForDatatype.count - errorsForDatatype.examples.length
                          )} more`}</Typography>
                        )}
                      </ol>
                    </div>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {report.languageTagErrors && (
          <Accordion
            expanded={expanded === "languageTag"}
            onChange={(_, isExpanded) => {
              history.replace(
                {
                  search: stringifyQuery({ report: graphName, expanded: isExpanded ? "languageTag" : undefined }),
                },
                { preserveScrollPosition: true, canUseGoBack: location.state?.canUseGoBack }
              );
            }}
          >
            <AccordionSummary id="language-tag-header" aria-controls="language-tag-content">
              <div>
                <h5 className={styles.typeOfError}>
                  Invalid language tags ({formatNumber(report.numberOfLanguageTagErrors || 0)})
                </h5>
                <div>
                  The invalid language tags have been removed, and the datatype of the literals has been replaced with{" "}
                  <code>
                    <Prefixed>http://www.w3.org/2001/XMLSchema#string</Prefixed>
                  </code>
                  .
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="pl-3">
                <ol>
                  {report.languageTagErrors.examples.map((example, index) => {
                    return (
                      <li key={index}>
                        <Tooltip title={example.message} placement="right">
                          <span>
                            {example.value === "" ? (
                              <em>(empty value)</em>
                            ) : (
                              <code className="nowrap">{example.value}</code>
                            )}
                            <IconButton
                              title="Show example in table"
                              size="small"
                              component={Link}
                              to={{
                                pathname: ldTablePage,
                                search: stringifyQuery({
                                  ...example.context,
                                  graph: graphName,
                                }),
                              }}
                              className="ml-2"
                            >
                              <FontAwesomeIcon size="xs" icon="table-cells" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </li>
                    );
                  })}
                  {report.languageTagErrors.count > report.languageTagErrors.examples.length && (
                    <Typography variant="caption">{`...and ${formatNumber(
                      report.languageTagErrors.count - report.languageTagErrors.examples.length
                    )} more`}</Typography>
                  )}
                </ol>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {report.iriErrors && (
          <Accordion
            expanded={expanded === "iri"}
            onChange={(_, isExpanded) => {
              history.replace(
                {
                  search: stringifyQuery({ report: graphName, expanded: isExpanded ? "iri" : undefined }),
                },
                { preserveScrollPosition: true, canUseGoBack: location.state?.canUseGoBack }
              );
            }}
          >
            <AccordionSummary id="iri-header" aria-controls="iri-content">
              <div>
                <h5 className={styles.typeOfError}>Invalid IRIs ({formatNumber(report.numberOfInvalidIris || 0)})</h5>
                <div>The invalid IRIs have been replaced.</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="pl-3">
                <ol>
                  {report.iriErrors.examples.map((example, index) => {
                    return (
                      <li key={index}>
                        <Tooltip title={example.message} placement="right">
                          <span>
                            {example.value === "" ? (
                              <em>(empty value)</em>
                            ) : (
                              <code className="nowrap">{example.value}</code>
                            )}
                            <IconButton
                              title="Show example in table"
                              size="small"
                              component={Link}
                              to={{
                                pathname: ldTablePage,
                                search: stringifyQuery({
                                  ...example.context,
                                  graph: graphName,
                                }),
                              }}
                              className="ml-2"
                            >
                              <FontAwesomeIcon size="xs" icon="table-cells" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </li>
                    );
                  })}
                  {report.iriErrors.count > report.iriErrors.examples.length && (
                    <Typography variant="caption">{`...and ${formatNumber(
                      report.iriErrors.count - report.iriErrors.examples.length
                    )} more`}</Typography>
                  )}
                </ol>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        <Alert
          severity="info"
          action={
            <Button
              endIcon={<FontAwesomeIcon icon={["fas", "file-import"]} />}
              onClick={async () => {
                const response = await fetch(importUrl, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ graphName: `${graphName}:DataQualityReport` }),
                });

                if (response && response.status === 201) {
                  if (currentDs && currentAccount) {
                    dispatch<typeof getGraphs>(
                      getGraphs({
                        accountName: currentAccount.accountName,
                        datasetName: currentDs.name,
                        datasetId: currentDs.id,
                      })
                    ).catch(() => {});
                  }

                  dialogOnClose();
                } else {
                  dispatch(showNotification("The data quality report failed to import", "error"));
                }
              }}
            >
              Import
            </Button>
          }
        >
          You can import all data quality issues as a graph into your dataset.
        </Alert>
      </div>
    </Dialog>
  );
};

export default QualityReport;
