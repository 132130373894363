import { Alert, DialogContent, LinearProgress, Typography } from "@mui/material";
import { sortBy } from "lodash-es";
import * as React from "react";
import { Prompt } from "react-router";
import { SocketEvent } from "@triply/utils/SocketEvents.js";
import { Button, Dialog } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useWebSocket from "#helpers/hooks/useWebSocket.ts";
import {
  deleteServiceFromAdminList,
  issueServiceCommand,
  recreateServiceFromAdminList,
  Service,
} from "#reducers/services.ts";

interface Props {
  open: boolean;
  onClose: () => void;
  services: Service[];
}

type ComponentState = "Verifying" | "Recreate" | "Done";

const BulkRecreateDialog: React.FC<Props> = ({ open, onClose: _onClose, services }) => {
  const [servicesToUpdate, setServicesToUpdate] = React.useState<Service[]>([]);
  const { subscribe, unsubscribe } = useWebSocket();
  const interrupted = React.useRef(false);
  const [restartingServices, setRestartingServices] = React.useState<ComponentState>("Verifying");
  const [progress, setProgress] = React.useState(0);
  const [errors, setErrors] = React.useState<string[]>([]);

  const dispatch = useDispatch();

  // Reset component when dialog is opened
  React.useEffect(() => {
    if (open === true) {
      setProgress(0);
      setRestartingServices("Verifying");
      setErrors([]);
      interrupted.current = false;
    }
  }, [open]);
  // Check if a service can be recreated
  React.useEffect(() => {
    const tempServicesToUpdate: Service[] = [];
    for (const service of services) {
      if (service.dataset?.owner !== undefined) {
        tempServicesToUpdate.push(service);
      } else {
        setErrors((errors) => [...errors, `Couldn't recreate service ${service.name} due to missing information`]);
      }
    }
    setServicesToUpdate(sortBy(tempServicesToUpdate, (s) => s.queriedAt || new Date(0).toISOString()).reverse());
  }, [services]);
  const onClose = () => {
    if (restartingServices === "Recreate") {
      interrupted.current = true;
      setRestartingServices("Done");
    }
    _onClose();
  };
  const updateServices = async () => {
    if (!open) return;
    setRestartingServices("Recreate");
    for (const service of servicesToUpdate) {
      if (!open || interrupted.current) return;
      if (service.dataset?.owner === undefined) {
        setErrors((errors) => [...errors, `Couldn't recreate service ${service.name} due to missing information`]);
        continue;
      }
      try {
        await dispatch<typeof deleteServiceFromAdminList>(deleteServiceFromAdminList(service));
      } catch (e: any) {
        if (!interrupted.current) {
          setErrors((errors) => [
            ...errors,
            `Failed removing ${service.dataset?.owner.accountName}/${service.dataset?.name}/${service.name} : ${e.message}`,
          ]);
        }
        continue;
      }
      let recreateSuccess: (value?: void) => void;
      let recreateFail: (e: unknown) => void;
      const servicePromise = new Promise<void>((resolve, reject) => {
        recreateSuccess = resolve;
        recreateFail = reject;
      });
      const handler = (event: SocketEvent) => {
        if (event.eventType !== "serviceMetadataUpdate") return;
        if (event.status === "error") {
          recreateFail(new Error("Failed updating service"));
        } else if (event.status === "running" && !interrupted.current) {
          setProgress((progress) => progress + 1);
          recreateSuccess();
        }
      };
      try {
        const newServiceId = await dispatch<typeof recreateServiceFromAdminList>(
          recreateServiceFromAdminList(service)
        ).then((action) => action.body.id);
        subscribe(`/datasets/${service.dataset?.id}`, `/services/${newServiceId}/metadata`, handler);
        // We want redux to subscribe to update the table
        subscribe(`/datasets/${service.dataset?.id}`, `/services/${newServiceId}/metadata`);
        await servicePromise;
        // Should stop the service again on success
        if (service.status === "stopped" || service.status === "stopping") {
          try {
            await dispatch<typeof issueServiceCommand>(
              issueServiceCommand(
                service.dataset.owner,
                service.dataset,
                { ...service, id: newServiceId }, // Use the new service ID
                service.autoResume ? "stopWithAutoresume" : "stop"
              )
            );
          } catch (e: any) {
            setErrors((errors) => [
              ...errors,
              `Failed stopping ${service.dataset?.owner.accountName}/${service.dataset?.name}/${service.name} : ${e.message}`,
            ]);
          }
        }
        // No reason to listen anymore
        unsubscribe(`/datasets/${service.dataset?.id}`, `/services/${newServiceId}/metadata`);
        unsubscribe(`/datasets/${service.dataset?.id}`, `/services/${newServiceId}/metadata`, handler);
      } catch (e: any) {
        setErrors((errors) => [
          ...errors,
          `Failed recreating ${service.dataset?.owner.accountName}/${service.dataset?.name}/${service.name} : ${e.message}`,
        ]);
        continue;
      }
    }
    setRestartingServices("Done");
  };
  return (
    <Dialog open={open} onClose={onClose} title="Recreate services">
      <Prompt
        when={restartingServices === "Recreate"}
        message={() => {
          // Don't prompt when we're navigating to the confirmation dialog
          return "Moving away will interrupt the current process. Are you sure?";
        }}
      />

      <DialogContent>
        {restartingServices === "Verifying" && (
          <>
            {services.length !== servicesToUpdate.length && (
              <Alert severity="warning">Some selected services cannot be recreated</Alert>
            )}
            <Typography>The following services will be recreated</Typography>
            <ul>
              {servicesToUpdate.map((service) => (
                <li key={service.id}>
                  {service.dataset?.owner.accountName}/{service.dataset?.name}/{service.name}
                </li>
              ))}
            </ul>
          </>
        )}
        {restartingServices === "Recreate" && (
          <>
            <Typography>Recreating services, closing this window will interrupt the process</Typography>
            <LinearProgress variant="determinate" value={(progress / servicesToUpdate.length) * 100} />
          </>
        )}
        {restartingServices === "Done" && <>Successfully recreated {progress} services</>}
        {errors.map((error, idx) => {
          return (
            <Alert className="my-2" key={`service-update-error-${idx}`} severity="error">
              {error}
            </Alert>
          );
        })}
      </DialogContent>
      <div className="m-5">
        {restartingServices === "Verifying" && (
          <Button color="warning" onClick={updateServices} className="mr-4" disabled={servicesToUpdate.length === 0}>
            Recreate ({servicesToUpdate.length}) services
          </Button>
        )}
        <Button variant="text" onClick={onClose}>
          {restartingServices === "Done" ? "Close" : "Cancel"}
        </Button>
      </div>
    </Dialog>
  );
};

export default BulkRecreateDialog;
