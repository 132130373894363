import dedent from "dedent";
import * as React from "react";
import { factories, getWriter, parse, Store } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useEditModeStatementsLimit from "#helpers/hooks/useEditModeStatementsLimit.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { Button, Dialog, FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import Resource from "./Forms/Resource";
import { ResourceData } from "./Forms/Types";
import useRemovePrefixes from "./useRemovePrefixes";

const factory = factories.compliant;

const EditResource: React.FC<{ resource: string }> = ({ resource }) => {
  const [open, setOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const editModeStatementsLimit = useEditModeStatementsLimit();
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const authorAcc = useAuthenticatedUser();
  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();

  return (
    <>
      <Button
        color="primary"
        elevation
        onClick={() => setOpen(true)}
        title="Edit instance"
        startIcon={<FontAwesomeIcon icon="pencil" />}
        size="small"
      >
        Edit
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth title="Edit instance" closeButton>
        <div className="px-5 pb-5">
          <Resource
            editingResource={resource}
            onSubmit={async (values: ResourceData) => {
              const properties = values.properties.map((p) => {
                let value = `(<${p.property!.id}> `;
                if (p.value?.datatype) {
                  value += `"${p.value.id.trim()}"`;
                  p.value.datatype === "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString"
                    ? (value += "@nl")
                    : (value += `^^<${p.value.datatype}>`);
                } else {
                  value += `<${removePrefixes(p.value?.id.trim() || "")}>`;
                }
                return value + ")";
              });

              const query = dedent`
                prefix skos: <http://www.w3.org/2004/02/skos/core#>
                prefix vs: <http://www.w3.org/2003/06/sw-vocab-status/ns#>
                prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
                prefix dct: <http://purl.org/dc/terms/>
                prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
                delete where {
                  <${resource}> ?p ?o
                };
                insert {
                  ?id ?predicate ?object ;
                      dct:modified ?creationDate.
                } where {
                  bind(<${resource}> as ?id)
                  bind(now() as ?creationDate)
                  values (?predicate ?object) {
                    (rdf:type <${values.type!.id}>)
                    (vs:term_status "in bewerking"@nl)
                    (rdfs:isDefinedBy <${factory.namedNode(authorUrl).id}>)
                    ${properties.join("\n\t\t")}
                  }
                }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name })
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                })
              );

              setOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditResource;
