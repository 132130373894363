import { IconButton } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import React from "react";
import * as ReduxForm from "redux-form";
import { RenameGraph as RenameGraphForm } from "#components/Forms/index.ts";
import { Dialog, FontAwesomeIcon } from "#components/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { Graph, removeGraph, renameGraph } from "#reducers/graphs.ts";
import { QualityReportButton } from "./QualityReport";
import styles from "./style.scss";

const Actions: React.FC<CellContext<Graph, any>> = ({ row: { original: graph } }) => {
  const confirm = useConfirmation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const currentAccount = useCurrentAccount()!;
  const currentDs = useCurrentDataset()!;
  const acl = useAcl();
  const userRole = acl.getRoleInAccount(currentAccount);
  const mayManageGraphs =
    acl.check({
      action: "manageGraphs",
      context: { roleInOwnerAccount: userRole },
    }).granted &&
    userRole !== "editor" &&
    userRole !== "reviewer";

  return (
    <div key={graph.graphName} className={styles.actions}>
      {graph.qualityReport && mayManageGraphs && <QualityReportButton graphName={graph.graphName} />}
      <IconButton
        size="small"
        component="a"
        href={`/${currentDs.owner.accountName}/${currentDs.name}/download.trig.gz?graph=${encodeURIComponent(
          graph.graphName
        )}`}
        target="_blank"
        download
        title="Export graph"
      >
        <FontAwesomeIcon icon="arrow-to-bottom" fixedWidth />
      </IconButton>
      {mayManageGraphs && (
        <>
          <IconButton
            size="small"
            onClick={() => {
              setOpen(true);
            }}
            title="Edit graph name"
          >
            <FontAwesomeIcon icon="pencil" fixedWidth />
          </IconButton>
          <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            classes={{ paper: "p-5" }}
            onClose={() => {
              setOpen(false);
            }}
          >
            <RenameGraphForm
              initialValues={{ graphName: graph.graphName }}
              form={graph.id + "_RenameGraphForm"}
              handleCancel={() => {
                setOpen(false);
              }}
              onSubmit={(values: RenameGraphForm.FormData) => {
                return dispatch<typeof renameGraph>(
                  renameGraph(currentAccount, currentDs, graph.id, values.graphName)
                ).then(
                  () => {
                    setOpen(false);
                  },
                  (e: any) => {
                    throw new ReduxForm.SubmissionError({ _error: e.message });
                  }
                );
              }}
            />
          </Dialog>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              confirm({
                description: `Are you sure you want to delete graph '${graph.graphName}'?`,
                title: "Delete graph?",
                actionLabel: "Delete",
                onConfirm: () => {
                  dispatch<typeof removeGraph>(removeGraph(currentAccount, currentDs, graph)).catch(() => {});
                },
              });
            }}
            title="Delete graph"
          >
            <FontAwesomeIcon icon="times" fixedWidth />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default Actions;
