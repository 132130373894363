import { Alert, Autocomplete, Skeleton, TextField, ThemeProvider, useTheme } from "@mui/material";
import { cloneDeep, fill, flatMap, merge } from "lodash-es";
import * as React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Button, FontAwesomeButton, FontAwesomeIcon, FormField } from "#components/index.ts";
import { validateIri } from "#containers/Ontology/Forms/helpers.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import useApplyPrefixes from "../useApplyPrefixes";
import useRemovePrefixes from "../useRemovePrefixes";
import IriField from "./IriField";
import LiteralField from "./LiteralField";
import localTheme from "./Theme";
import { ResourceData } from "./Types";
import useClasses from "./useClasses";
import useFetchInitialValues from "./useFetchInitialValues";

const ResourceForm: React.FC<{ onSubmit: (values: ResourceData) => Promise<void>; editingResource?: string }> = ({
  onSubmit,
  editingResource,
}) => {
  const theme = useTheme();
  const fetchInitialValues = useFetchInitialValues();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors, submitCount },
    getValues,
    watch,
    setError,
    setValue,
    trigger,
  } = useForm<ResourceData>({
    defaultValues: (editingResource && (() => fetchInitialValues(editingResource))) || undefined,
  });

  const { fields, remove, append } = useFieldArray({
    name: "properties",
    control,
    rules: {
      validate: (value) => {
        for (const property of properties || []) {
          const count = value.filter((p) => p.property?.propertyShape === property.propertyShape).length;
          const minCount = Number(property.minCount || 0);
          const maxCount = Number(property.maxCount || Number.MAX_SAFE_INTEGER);
          if (minCount === 1 && count === 0) {
            return `Property '${property.label}' is required.`;
          }
          if (count < minCount) {
            return `Property '${property.label}' is required ${minCount} times.`;
          }
          if (maxCount === 1 && count > maxCount) {
            return `Property '${property.label}' is only allowed once.`;
          }
          if (count > maxCount) {
            return `Property '${property.label}' is only allowed ${maxCount} times.`;
          }
        }
      },
    },
  });

  const type = watch("type.id");
  watch("properties");

  const classes = useClasses();
  const properties = classes?.find((c) => c.id === type)?.properties || [];

  const submit = async (values: ResourceData) => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setError("root.serverError", {
        type: "500",
      });
    }
  };

  const applyPrefixes = useApplyPrefixes();
  const currentDs = useCurrentDataset()!;
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });
  const removePrefixes = useRemovePrefixes();

  if (!classes) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }
  if (classes.length === 0) {
    return (
      <Alert severity="info">
        No SHACL shapes were found {editingResource ? "for this resource" : "in this dataset"}
      </Alert>
    );
  }

  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
        <FormField label="Type" required>
          <Controller
            name="type"
            control={control}
            rules={{ required: "A type is required." }}
            defaultValue={null}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <Autocomplete
                options={classes}
                onChange={(_e, data: any) => {
                  setValue(
                    "properties",
                    flatMap(data?.properties || [], (p: any) => {
                      const minCount = Number(p.minCount || 0);
                      return fill(Array(minCount), { property: p, value: null });
                    })
                  );
                  return onChange(data);
                }}
                renderInput={(params) => (
                  <TextField
                    {...(params as any)}
                    required
                    error={!!error}
                    helperText={error?.message || rest.value?.description}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                getOptionLabel={(option) => {
                  return option.label || applyPrefixes(option.id);
                }}
                {...rest}
              />
            )}
          />
        </FormField>

        {!editingResource && (
          <FormField label="Instance IRI" required>
            <Controller
              name="iri"
              control={control}
              rules={{
                validate: (value) => validateIri(removePrefixes(value?.trim())),
              }}
              defaultValue={applyPrefixes(`${datasetUrl}/id/${Date.now()}`)}
              render={({ field, fieldState }) => (
                <TextField {...field} error={!!fieldState.error} required helperText={fieldState.error?.message} />
              )}
            />
          </FormField>
        )}

        {properties.length > 0 && (
          <FormField label="Properties">
            <div className="flex column g-5">
              {fields.map((field, index) => {
                return (
                  <div key={field.id} className="flex wrap g-5">
                    <Controller
                      name={`properties.${index}.property`}
                      control={control}
                      rules={{ required: "A property is required." }}
                      defaultValue={null}
                      render={({ field: { onChange, ...rest }, fieldState: { error } }) => {
                        return (
                          <Autocomplete
                            options={properties}
                            onChange={(_e, data) => {
                              setValue(`properties.${index}.value`, null);
                              if (submitCount > 0) trigger(`properties.${index}.value`).catch(console.error);
                              onChange(data);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...(params as any)}
                                error={!!error}
                                helperText={error?.message || rest.value?.description}
                              />
                            )}
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id;
                            }}
                            getOptionLabel={(option) => {
                              return (
                                (option.label || applyPrefixes(option.id)) +
                                (Number(option.minCount || 0) > 0 ? "*" : "")
                              );
                            }}
                            {...rest}
                          />
                        );
                      }}
                    />
                    {getValues(`properties.${index}.property`)?.datatype ? (
                      <LiteralField
                        name={`properties.${index}.value`}
                        control={control}
                        datatype={getValues(`properties.${index}.property`)!.datatype!}
                        required={Number(getValues(`properties.${index}.property`)?.minCount || 0) > 0}
                      />
                    ) : (
                      <IriField
                        name={`properties.${index}.value`}
                        control={control}
                        range={getValues(`properties.${index}.property`)?.range}
                        required={Number(getValues(`properties.${index}.property`)?.minCount || 0) > 0}
                      />
                    )}
                    <FontAwesomeButton
                      color="error"
                      icon="trash"
                      onClick={() => remove(index)}
                      title="Remove property"
                    />
                  </div>
                );
              })}
              <Button
                onClick={() => append({ property: properties[0], value: null })}
                startIcon={<FontAwesomeIcon icon="plus" />}
                title="Add new property"
              />

              {errors.properties?.root?.message && <Alert severity="error">{errors.properties?.root.message}</Alert>}
            </div>
          </FormField>
        )}

        <LoadingButton color="secondary" type="submit" disabled={!isValid && 1 != 1} loading={isSubmitting}>
          Save
        </LoadingButton>

        {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
      </form>
    </ThemeProvider>
  );
};

export default ResourceForm;
