import { ensureSyntaxTree, syntaxTree } from "@codemirror/language";
import { StateCommand } from "@codemirror/state";
import { isEmpty } from "lodash-es";
import { Prefix } from "@triply/utils";
import { OutsidePrefixes } from "../facets/outsidePrefixes.ts";
import { definedPrefixesField, getPrologueNodeFromGlobalTree } from "../fields/definedPrefixes.ts";
import { BaseDecl, PNAME_LN, PNAME_NS, PrefixDecl, Prologue } from "../grammar/sparqlParser.terms.ts";

/**
 * Gets prefix inject command. Should be bound to ':'
 */
export const injectPrefix: StateCommand = function ({ state, dispatch }) {
  // Update the state so that the syntax for the Prefix namespace is correct
  const injectTransaction = state.update(state.replaceSelection(":"));
  state = injectTransaction.state;

  const tree = ensureSyntaxTree(state, state.doc.length) || syntaxTree(state);
  const definedPrefixes = state.field(definedPrefixesField).map((prefix) => prefix.prefixLabel);
  const prefixesToAdd: { [name: string]: string } = {};

  // We know that the Prologue only exists on this position
  const prologue = getPrologueNodeFromGlobalTree(tree);
  let firstErrorStart: number | undefined;
  // Let's see which prefixes are already defined by going through the prologue
  const prologueCursor = prologue?.toTree().cursor();
  do {
    // If we find a prefix declaration
    if (prologueCursor?.type.id === PrefixDecl) {
      // See if any children have an error
      if (firstErrorStart === undefined && prologueCursor.node.getChildren(0)[0]) {
        // Trees reset index
        firstErrorStart = prologueCursor.from + (prologue?.from || 0);
      }
      // We've processed the declaration, skip to the end of the node
      prologueCursor.lastChild();
    } else if (prologueCursor?.type.id === BaseDecl) {
      // We've processed the declaration, skip to the end of the node
      prologueCursor.lastChild();
      // We start at the prologue node
    } else if (prologueCursor?.type.id === Prologue) {
      continue;
    } else if (firstErrorStart === undefined) {
      // See if we find a node we don't expect
      firstErrorStart = (prologueCursor?.from || 0) + (prologue?.from || 0);
    }
  } while (prologueCursor?.next());

  // Go through the selections
  for (const range of injectTransaction.selection?.ranges || []) {
    const nameNode = tree.cursorAt(range.from, -1).node;
    // Check if the completed node is a namespace and we're not in a Prefix declaration or in an invalid position
    // Since empty prefix-names are valid IRIs we can make sure we only inject at syntactically valid positions of the cursor
    // Note: We will get injections inside of "incomplete/invalid" positions as we match on a Token
    if (
      (nameNode.type.id === PNAME_NS && !nameNode.parent?.type.isError && nameNode.parent?.type.id !== PrefixDecl) ||
      nameNode.type.id === PNAME_LN
    ) {
      // First check if it has an error
      // Grab the prefix label
      const prefixLabel = state.sliceDoc(nameNode.from, range.from - 1);
      // Check if the prefix is already defined/is going to be added
      if (definedPrefixes.includes(prefixLabel)) continue;
      if (prefixesToAdd[prefixLabel]) continue;
      // Check if we know about this prefix
      for (const prefix of state.facet(OutsidePrefixes) || []) {
        if (prefix.prefixLabel === prefixLabel) {
          prefixesToAdd[prefixLabel] = prefix.iri;
          break;
        }
      }
    }
  }
  // We're not adding prefixes. So we don't commit any changes and let codemirror do its thing
  if (isEmpty(prefixesToAdd)) return false;
  // We want to inject at the most "valid" place
  const injectAt = firstErrorStart ?? prologue?.to ?? 0;
  // Dispatch adding the prefix(es)
  dispatch(
    injectTransaction.startState.update({
      userEvent: "Inject prefix",
      changes: {
        from: injectAt, // Make sure we append the prefix at the end of the Prologue
        insert:
          (prologue?.from === injectAt ? "" : "\n") +
          Object.entries(prefixesToAdd)
            .map(([prefixLabel, iri]) => `prefix ${prefixLabel}: <${iri}>`)
            .join("\n") +
          (prologue?.from === injectAt || state.doc.lineAt(injectAt).to !== injectAt ? "\n" : ""),
      },
    })
  );
  // No need to inject the prefixes ourselves, so we want to let Codemirror do its default behavior now
  return false;
};
