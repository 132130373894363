import { Menu, MenuItem } from "@mui/material";
import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import { range } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { GlobalState } from "#reducers/index.ts";
import styles from "./style.scss";

interface Props {
  isDraft: boolean;
  mayEdit: boolean;
  reset: () => void;
}

const getPath = (accountName: string | undefined, queryName: string, postPath?: string | number) =>
  "/" + [accountName || "", "-", "queries", queryName, postPath || ""].join("/");
const VersionSelector = React.memo<Props>(({ isDraft, reset, mayEdit }) => {
  const dispatch = useDispatch();
  const query = useSelector((state: GlobalState) => state.queries.current);
  const { search } = useLocation();
  const [versionsButtonReference, setVersionsButtonReference] = React.useState<HTMLAnchorElement>();
  const hideVersionsMenu = () => setVersionsButtonReference(undefined);
  if (!query) return <></>;
  const selectVersion = (selectedVersion: number) => {
    hideVersionsMenu();
    const accountName = query.owner.accountName;
    const queryName = query.name;
    if (!accountName || !queryName) return;
    if (selectedVersion === query.version) {
      reset();
    } else {
      dispatch(
        connectedReactRouter.push({ pathname: getPath(accountName, queryName, selectedVersion), search: search })
      );
    }
  };
  const hasVersions = !query.numberOfVersions;
  return (
    <>
      <a
        className={getClassName(styles.versionBtn, "mr-2", {
          [styles.empty]: !query.numberOfVersions,
          [styles.draft]: isDraft,
          [styles.readView]: !mayEdit,
        })}
        href={(hasVersions && undefined) || ""}
        onClick={(event) => {
          event.preventDefault();
          if (query.numberOfVersions) {
            //dont want to show menu when there is only a draft version
            setVersionsButtonReference(event.currentTarget);
          }
        }}
      >
        Version:{" "}
        <span className={styles.versionLabel}> {isDraft ? (mayEdit ? "Draft" : "Modified") : query.version}</span>
        {!hasVersions && <FontAwesomeIcon icon={["fas", versionsButtonReference ? "caret-down" : "caret-right"]} />}
      </a>
      {versionsButtonReference && (
        <Menu
          anchorEl={versionsButtonReference}
          /**
           * Tweaking the menu a bit, so the dropdown is drawn _under_ the button
           */
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={styles.versionMenu}
          open={Boolean(versionsButtonReference)}
          onClose={hideVersionsMenu}
        >
          {range(query.numberOfVersions || 0).map((i) => {
            const versionNumber = i + 1;
            return (
              <MenuItem
                key={versionNumber}
                disabled={!isDraft && versionNumber === query.version}
                onClick={() => selectVersion(versionNumber)}
              >
                Version {versionNumber}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
});

export default VersionSelector;
