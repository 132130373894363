import getClassName from "classnames";
import React from "react";
import { Avatar, FontAwesomeIcon } from "#components/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import styles from "./style.scss";

interface Props {
  bannerUrl?: string;
  className?: string;
  size?: "sm" | "md";
}

const StoryBadge: React.FC<Props> = ({ bannerUrl, className, size }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const imageSizeLimit = size === "md" ? 180 : 100;
  return bannerUrl ? (
    <Avatar
      size={size || "sm"}
      square
      avatarUrl={constructUrlToApi({
        fullUrl: bannerUrl,
        query: { h: imageSizeLimit, w: imageSizeLimit } as any,
        fromBrowser: true,
      })}
      avatarName=""
      className={className}
      alt="Story"
    />
  ) : (
    <div className={getClassName("center flex", styles.storyBadge, { [styles.medium]: size === "md" }, className)}>
      <FontAwesomeIcon icon="book-open" aria-label="Story" />
    </div>
  );
};

export default StoryBadge;
