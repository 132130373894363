import { Alert, Container, Divider, IconButton, Skeleton, Stack, Step, StepLabel, Stepper } from "@mui/material";
import getClassName from "classnames";
import { useSnackbar } from "notistack";
import * as React from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AuthenticatorCode, Button, FontAwesomeIcon, LinkButton, Prompt } from "#components/index.ts";
import { TimezoneContext } from "#context.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useCopyToClipboard from "#helpers/hooks/useCopyToClipboard.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { setAuthenticatorEnabled, setupAuthenticator, verifyAuthenticator } from "#reducers/accounts.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";
import styles from "./style.scss";

interface RecoveryCodes {
  codes: string[];
  generatedAt: string;
}

const steps = ["Authenticator setup", "Recovery codes"];

const Step1: React.FC<{
  setRecoveryCodes: (recoveryCodes: RecoveryCodes) => void;
  next: () => void;
  cancelable: boolean;
}> = ({ setRecoveryCodes, next, cancelable }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentAccount = useCurrentAccount();
  const [authUri, setAuthUri] = React.useState("");
  const authSecret = authUri !== "" && new URL(authUri).searchParams.get("secret");
  const [incorrectCode, setIncorrectCode] = React.useState(false);
  const [showManualInstructions, setShowManualInstructions] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [token, setToken] = React.useState("");
  const { ref: copyFrameRef, copyToClipboard } = useCopyToClipboard();

  React.useEffect(() => {
    if (!currentAccount) return;
    if (authUri === "" && !processing && !error) {
      setProcessing(true);
      dispatch<typeof setupAuthenticator>(setupAuthenticator(currentAccount.accountName, currentAccount.uid))
        .then((res) => setAuthUri(res.body.uri))
        .catch((e) => {
          setError(e.message);
        })
        .finally(() => setProcessing(false));
    }
  }, [currentAccount, authUri, processing, dispatch, error]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!currentAccount) return;
        setProcessing(true);
        dispatch<typeof verifyAuthenticator>(
          verifyAuthenticator(currentAccount.accountName, currentAccount.uid, token?.replace(/\D/g, ""))
        )
          .then((result) => {
            setRecoveryCodes(result.body);
            next();
          })
          .catch((e) => {
            if (e.status === 401 && e.code === 98) {
              setIncorrectCode(true);
            } else {
              setError("Error verifying authenticator.");
              console.error(e);
            }
          })
          .finally(() => setProcessing(false));
      }}
    >
      <Stack ref={copyFrameRef} direction="column" alignItems="center" justifyContent="center" spacing={5}>
        <div className={styles.center}>
          To configure two-factor authentication you need an app like{" "}
          <a href="https://support.google.com/accounts/answer/1066447" rel="noopener noreferrer" target="_blank">
            Google Authenticator
          </a>{" "}
          or{" "}
          <a
            href="https://www.microsoft.com/security/mobile-authenticator-app"
            rel="noopener noreferrer"
            target="_blank"
          >
            Microsoft Authenticator
          </a>{" "}
          on your mobile device.
        </div>
        <div className={styles.center}>
          {authUri ? (
            <a href={authUri} target="_blank">
              <QRCode value={authUri} />
            </a>
          ) : (
            <div className="flex horizontalCenter">
              <Skeleton variant="rectangular" width={256} height={256} />
            </div>
          )}
          <div className="mt-5">Scan the QR code with your authenticator app.</div>
          {!showManualInstructions && (
            <div className="mt-2">
              <LinkButton className={styles.italic} onClickOrEnter={() => setShowManualInstructions(true)}>
                I can't scan the code
              </LinkButton>
            </div>
          )}
          {showManualInstructions && (
            <Alert severity="info" className="mt-3">
              If you can't scan the QR code, you can configure your authenticator app by entering the key below. When
              asked for the type of key, select time-based/TOTP.
              {authSecret ? (
                <div className="mt-2">
                  <code className={styles.breakable}>{authSecret}</code>
                  <IconButton onClick={() => copyToClipboard(authSecret)} title="Copy the authenticator key">
                    <FontAwesomeIcon icon="copy" size="sm" />
                  </IconButton>
                </div>
              ) : (
                <Skeleton variant="text" width="100%" />
              )}
            </Alert>
          )}
        </div>
        <div className={styles.center}>
          Enter the 6-digit code from the app.
          <AuthenticatorCode
            value={token}
            onChange={(value: string) => {
              setToken(value);
              setIncorrectCode(false);
            }}
            shouldAutoFocus
            hasErrored={!!incorrectCode}
            containerStyle="mt-3"
          />
          {incorrectCode && (
            <div className={getClassName(styles.otpErrorText, "mt-3")}>
              The code that you entered is incorrect. Please try again.
            </div>
          )}
        </div>
      </Stack>
      <Divider className="mt-6 mb-3" />
      <Stack direction="row" spacing={1} className="mt-3">
        {cancelable && (
          <Button onClick={() => history.goBack()} variant="text">
            Cancel
          </Button>
        )}
        <Button type="submit" color="primary" variant="contained" disabled={token.length !== 6 || incorrectCode}>
          Next
        </Button>
      </Stack>
    </form>
  );
};

const Step2: React.FC<{ recoveryCodes: RecoveryCodes; cancelable: boolean }> = ({ recoveryCodes, cancelable }) => {
  const dispatch = useDispatch();
  const constructConsoleUrl = useConstructConsoleUrl();
  const currentAccount = useCurrentAccount();
  const brandingName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name || "");
  const timezone = React.useContext(TimezoneContext);
  const locale = useSelector((state: GlobalState) => state.app.locale ?? "en-us");
  const { ref: copyFrameRef, copyToClipboard } = useCopyToClipboard();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  if (!currentAccount) return null;

  return (
    <div ref={copyFrameRef}>
      <div>When you lose access to your authentication device, you can log in with one of the codes below.</div>
      <Alert severity="warning" className="my-5">
        Store your recovery codes. <strong>These recovery codes are only shown once!</strong>
      </Alert>
      <Container maxWidth="xs" className={getClassName(styles.recoveryCodes, "my-5")}>
        {recoveryCodes.codes.map((token, _index) => (
          <span key={token}>{token}</span>
        ))}
      </Container>
      <Stack direction="row" spacing={1} className="my-5" justifyContent="center">
        <Button
          variant="outlined"
          endIcon={<FontAwesomeIcon icon="download" />}
          href={URL.createObjectURL(
            createRecoveryFile({
              recoveryCodes,
              instanceName: constructConsoleUrl(),
              accountName: currentAccount.accountName,
              timezone,
              locale,
            })
          )}
          LinkComponent={React.forwardRef(({ children, ...props }, ref) => (
            <a
              ref={ref}
              {...props}
              download={`recovery-codes-${brandingName.replace(/ /g, "_")}-${currentAccount.accountName}.txt`}
            >
              {children}
            </a>
          ))}
        >
          Download
        </Button>
        <Button
          variant="outlined"
          endIcon={<FontAwesomeIcon icon="copy" />}
          onClick={() => copyToClipboard(recoveryCodes.codes.join(" "))}
        >
          Copy
        </Button>
      </Stack>
      <Divider className="mt-6 mb-3" />
      <Stack direction="row" spacing={1}>
        {cancelable && (
          <Button onClick={() => history.goBack()} variant="text">
            Cancel
          </Button>
        )}
        <Button
          color="primary"
          onClick={() => {
            dispatch<typeof setAuthenticatorEnabled>(
              setAuthenticatorEnabled(currentAccount.accountName, currentAccount.uid, true)
            )
              .then(() => {
                cancelable && history.goBack();
                enqueueSnackbar("Two-factor authentication configured successfully.", { variant: "success" });
              })
              .catch(() => {
                // possibly do in UX ticket
              });
          }}
        >
          Finish
        </Button>
      </Stack>
    </div>
  );
};

const TfaSetup: React.FC<{ cancelable?: boolean }> = ({ cancelable = true }) => {
  const [currentStep, setStep] = React.useState(0);
  const [recoveryCodes, setRecoveryCodes] = React.useState<RecoveryCodes>({
    codes: [],
    generatedAt: "",
  });
  return (
    <>
      <Prompt message="The two-factor authentication configuration was not finished. Are you sure you want to abort the configuration?" />
      <Container className="px-7 py-5">
        <Stepper activeStep={currentStep} className="mt-5" alternativeLabel>
          {steps.map((stepLabel, index) => {
            return (
              <Step key={index}>
                <StepLabel>{stepLabel}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Divider className="mt-5 mb-6" />
        {currentStep === 0 && (
          <Step1 next={() => setStep(1)} setRecoveryCodes={setRecoveryCodes} cancelable={cancelable} />
        )}
        {currentStep === 1 && <Step2 recoveryCodes={recoveryCodes} cancelable={cancelable} />}
      </Container>
    </>
  );
};

export default TfaSetup;

function createRecoveryFile(opts: {
  instanceName: string;
  accountName: string;
  recoveryCodes: RecoveryCodes;
  timezone: string;
  locale: string;
}) {
  const generatedAt = new Date(opts.recoveryCodes.generatedAt).toLocaleString(opts.locale, {
    timeZone: opts.timezone,
  });
  let fileContent = `Recovery codes for ${opts.instanceName} account ${opts.accountName}, which were generated on ${generatedAt}\n`;
  opts.recoveryCodes.codes.forEach((code) => {
    fileContent += `${code}\n`;
  });
  return new Blob([fileContent], { type: "text/plain" });
}
