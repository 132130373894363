import bytes from "bytes";
import * as React from "react";
import { useHistory } from "react-router";
import { SubmissionError } from "redux-form";
import { AVATAR_SIZE_LIMIT } from "@triply/utils/Constants.js";
import * as Forms from "#components/Forms/index.ts";
import { Avatar } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { accountHasOngoingUploads } from "#helpers/tusUploadManagement.ts";
import { updateProfile, uploadAvatar } from "#reducers/accounts.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { trimUntouched } from "./utils.ts";
import styles from "./style.scss";

const ProfileSettings: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentAccount = useCurrentAccount();

  if (!currentAccount) {
    return null;
  }

  const handleAvatarUpload = (values: Forms.IconUpload.FormData) => {
    const avatarLimitBytes = bytes(AVATAR_SIZE_LIMIT);
    if (values.avatarFile.size > avatarLimitBytes) {
      throw new SubmissionError({
        _error: `File too large. Maximum file size is ${bytes.format(avatarLimitBytes, { decimalPlaces: 0 })}.`,
      });
    }
    return dispatch<typeof uploadAvatar>(uploadAvatar(currentAccount, values.avatarFile)).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      }
    );
  };

  return (
    <div className="whiteSink">
      <h3>Profile settings</h3>
      <div>
        <div className={styles.flex}>
          <div style={{ flexGrow: 5 }}>
            {currentAccount.type === "user" && (
              <Forms.UserProfile
                //Need to consider moving from 1 accountsettings page to the settings of a different user
                //For this to work, we need to use a scoped form value so redux-form stores it in a different branch of the tree
                //we also need to specify the key property, so react re-renders this component when the account name changes
                key={currentAccount.accountName + "_AccountProfileForm"}
                form={currentAccount.accountName + "_AccountProfileForm"}
                initialValues={{
                  name: currentAccount.name,
                  email: currentAccount.email,
                  description: currentAccount.description,
                }}
                onSubmit={(values: Forms.UserProfile.FormData) => {
                  return dispatch<typeof updateProfile>(
                    updateProfile(
                      currentAccount,
                      trimUntouched(
                        {
                          name: values.name,
                          email: values.email,
                          description: values.description,
                        },
                        currentAccount
                      )
                    )
                  ).then(
                    () => {},
                    (e: any) => {
                      throw new SubmissionError({ _error: e.message });
                    }
                  );
                }}
                passwordAuthMethod={currentAccount.authMethod === "password"}
              />
            )}
            {currentAccount.type === "org" && (
              <Forms.OrgProfile
                //Need to consider moving from 1 accountsettings page to the settings of a different user
                //For this to work, we need to use a scoped form value so redux-form stores it in a different branch of the tree
                //we also need to specify the key property, so react re-renders this component when the account name changes
                key={currentAccount.accountName + "_AccountProfileForm"}
                form={currentAccount.accountName + "_AccountProfileForm"}
                initialValues={{
                  name: currentAccount.name || currentAccount.accountName,
                  accountName: currentAccount.accountName,
                  email: currentAccount.email,
                  description: currentAccount.description,
                }}
                onSubmit={(values: Forms.OrgProfile.FormData) => {
                  const nameChange = values.accountName !== currentAccount.accountName;
                  if (nameChange && accountHasOngoingUploads(currentAccount.uid)) {
                    throw new SubmissionError({ _error: "Changing the URL is not possible while uploading" });
                  }
                  return dispatch<typeof updateProfile>(
                    updateProfile(
                      currentAccount,
                      trimUntouched(
                        {
                          accountName: values.accountName,
                          name: values.name,
                          email: values.email,
                          description: values.description,
                        },
                        currentAccount
                      )
                    )
                  ).then(
                    () => {
                      if (nameChange) history.replace("/" + values.accountName);
                    },
                    (e: any) => {
                      if (e?.status === 409) {
                        throw new SubmissionError({ accountName: "URL is already in use." });
                      }
                      throw new SubmissionError({ _error: e.message });
                    }
                  );
                }}
              />
            )}
          </div>
          <div className="py-5 px-2">
            <Forms.AvatarUpload onSubmit={handleAvatarUpload} accept="image/*">
              {!!currentAccount.avatarUrl && !!currentAccount.accountName ? (
                <Avatar size="lg" avatarUrl={currentAccount.avatarUrl} avatarName={currentAccount.accountName} alt="" />
              ) : undefined}
            </Forms.AvatarUpload>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
