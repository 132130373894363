import { produce } from "immer";
import { Models, Routes } from "@triply/utils";
import { SocketEpic } from "#helpers/hooks/useWebSocketContext.ts";
import { Account } from "#reducers/accountCollection.ts";
import { Dataset, getCurrentDataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";

export const LocalActions = {
  GET_SERVICE_INFO: "triply/services/GET_SERVICE_INFO",
  GET_SERVICE_INFO_SUCCESS: "triply/services/GET_SERVICE_INFO_SUCCESS",
  GET_SERVICE_INFO_FAIL: "triply/services/GET_SERVICE_INFO_FAIL",
  CREATE_SERVICE: "triply/services/CREATE_SERVICE",
  CREATE_SERVICE_SUCCESS: "triply/services/CREATE_SERVICE_SUCCESS",
  CREATE_SERVICE_FAIL: "triply/services/CREATE_SERVICE_FAIL",
  DELETE_SERVICE: "triply/services/DELETE_SERVICE",
  DELETE_SERVICE_SUCCESS: "triply/services/DELETE_SERVICE_SUCCESS",
  DELETE_SERVICE_FAIL: "triply/services/DELETE_SERVICE_FAIL",
  DELETE_SERVICE_FROM_ADMIN_LIST: "triply/services/DELETE_SERVICE_FROM_ADMIN_LIST",
  DELETE_SERVICE_FROM_ADMIN_LIST_SUCCESS: "triply/services/DELETE_SERVICE_FROM_ADMIN_LIST_SUCCESS",
  DELETE_SERVICE_FROM_ADMIN_LIST_FAIL: "triply/services/DELETE_SERVICE_FROM_ADMIN_LIST_FAIL",
  RECREATE_SERVICE_FROM_ADMIN_LIST: "triply/services/CREATE_SERVICE_FROM_ADMIN_LIST",
  RECREATE_SERVICE_FROM_ADMIN_LIST_SUCCESS: "triply/services/CREATE_SERVICE_FROM_ADMIN_LIST_SUCCESS",
  RECREATE_SERVICE_FROM_ADMIN_LIST_FAIL: "triply/services/CREATE_SERVICE_FROM_ADMIN_LIST_FAIL",
  ISSUE_SERVICE_COMMAND: "triply/services/ISSUE_SERVICE_COMMAND",
  ISSUE_SERVICE_COMMAND_SUCCESS: "triply/services/ISSUE_SERVICE_COMMAND_SUCCESS",
  ISSUE_SERVICE_COMMAND_FAIL: "triply/services/ISSUE_SERVICE_COMMAND_FAIL",
  CHECK_SERVICE_AVAILABILITY: "triply/services/CHECK_SERVICE_AVAILABILITY",
  CHECK_SERVICE_AVAILABILITY_SUCCESS: "triply/services/CHECK_SERVICE_AVAILABILITY_SUCCESS",
  CHECK_SERVICE_AVAILABILITY_FAIL: "triply/services/CHECK_SERVICE_AVAILABILITY_FAIL",
  GET_SERVICE_LIST_AS_ADMIN: "triply/services/GET_SERVICE_LIST_AS_ADMIN",
  GET_SERVICE_LIST_AS_ADMIN_SUCCESS: "triply/services/GET_SERVICE_LIST_AS_ADMIN_SUCCESS",
  GET_SERVICE_LIST_AS_ADMIN_FAIL: "triply/services/GET_SERVICE_LIST_AS_ADMIN_FAIL",
  UPDATE_SERVICE: "triply/services/UPDATE_SERVICE",
  UPDATE_SERVICE_SUCCESS: "triply/services/UPDATE_SERVICE_SUCCESS",
  UPDATE_SERVICE_FAIL: "triply/services/UPDATE_SERVICE_FAIL",
} as const;

type GET_SERVICE_INFO = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_SERVICE_INFO,
      typeof LocalActions.GET_SERVICE_INFO_SUCCESS,
      typeof LocalActions.GET_SERVICE_INFO_FAIL
    ];
    datasetId: string;
  },
  Routes.datasets._account._dataset.services._serviceName.Get
>;

type ISSUE_SERVICE_COMMAND = GlobalAction<
  {
    types: [
      typeof LocalActions.ISSUE_SERVICE_COMMAND,
      typeof LocalActions.ISSUE_SERVICE_COMMAND_SUCCESS,
      typeof LocalActions.ISSUE_SERVICE_COMMAND_FAIL
    ];
    datasetId: string;
    service: Service;
    command: keyof Models.ServiceActions;
  },
  Routes.datasets._account._dataset.services._serviceName.Post
>;
type DELETE_SERVICE = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_SERVICE,
      typeof LocalActions.DELETE_SERVICE_SUCCESS,
      typeof LocalActions.DELETE_SERVICE_FAIL
    ];
    datasetId: string;
    service: Service;
    accountUid: string;
    datasetName: string;
  },
  Routes.datasets._account._dataset.services._serviceName.Delete
>;

type CHECK_SERVICE_AVAILABILITY = GlobalAction<
  {
    types: [
      typeof LocalActions.CHECK_SERVICE_AVAILABILITY,
      typeof LocalActions.CHECK_SERVICE_AVAILABILITY_SUCCESS,
      typeof LocalActions.CHECK_SERVICE_AVAILABILITY_FAIL
    ];
  },
  Routes.datasets._account._dataset.services._serviceName.Get
>;

type CREATE_SERVICE = GlobalAction<
  {
    types: [
      typeof LocalActions.CREATE_SERVICE,
      typeof LocalActions.CREATE_SERVICE_SUCCESS,
      typeof LocalActions.CREATE_SERVICE_FAIL
    ];
    datasetId: string;
    accountUid: string;
    datasetName: string;
  },
  Routes.datasets._account._dataset.services.Post
>;

type UPDATE_SERVICE = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_SERVICE,
      typeof LocalActions.UPDATE_SERVICE_SUCCESS,
      typeof LocalActions.UPDATE_SERVICE_FAIL
    ];
    datasetId: string;
  },
  Routes.datasets._account._dataset.services._serviceName.Patch
>;

type GET_SERVICE_LIST_AS_ADMIN = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_SERVICE_LIST_AS_ADMIN,
      typeof LocalActions.GET_SERVICE_LIST_AS_ADMIN_SUCCESS,
      typeof LocalActions.GET_SERVICE_LIST_AS_ADMIN_FAIL
    ];
  },
  Routes.admin.services.Get
>;

type DELETE_SERVICE_FROM_ADMIN_LIST = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_SERVICE_FROM_ADMIN_LIST,
      typeof LocalActions.DELETE_SERVICE_FROM_ADMIN_LIST_SUCCESS,
      typeof LocalActions.DELETE_SERVICE_FROM_ADMIN_LIST_FAIL
    ];
    service: Service;
  },
  Routes.admin.services._serviceId.Delete
>;
type RECREATE_SERVICE_FROM_ADMIN_LIST = GlobalAction<
  {
    types: [
      typeof LocalActions.RECREATE_SERVICE_FROM_ADMIN_LIST,
      typeof LocalActions.RECREATE_SERVICE_FROM_ADMIN_LIST_SUCCESS,
      typeof LocalActions.RECREATE_SERVICE_FROM_ADMIN_LIST_FAIL
    ];
    service: Service;
  },
  Routes.datasets._account._dataset.services.Post
>;

export type LocalAction =
  | GET_SERVICE_INFO
  | DELETE_SERVICE
  | ISSUE_SERVICE_COMMAND
  | CHECK_SERVICE_AVAILABILITY
  | CREATE_SERVICE
  | GET_SERVICE_LIST_AS_ADMIN
  | DELETE_SERVICE_FROM_ADMIN_LIST
  | UPDATE_SERVICE
  | RECREATE_SERVICE_FROM_ADMIN_LIST;

export type Service = Models.ServiceMetadata;
export type Services = Service[];

export interface State {
  [datasetId: string]: Services;
}

export const GLOBAL_ID = "__global";

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.ISSUE_SERVICE_COMMAND:
      if (action.command !== "sync") return;
      if (draftState[action.datasetId]) {
        const syncServiceIndex = draftState[action.datasetId].findIndex((service) => service.id === action.service.id);
        if (syncServiceIndex >= 0) {
          draftState[action.datasetId][syncServiceIndex].status = "updating";
        }
      }
      if (draftState[GLOBAL_ID]) {
        const syncServiceIndex = draftState[GLOBAL_ID].findIndex((service) => service.id === action.service.id);
        if (syncServiceIndex >= 0) {
          draftState[GLOBAL_ID][syncServiceIndex].status = "updating";
        }
      }
      return;
    case Actions.ISSUE_SERVICE_COMMAND_SUCCESS:
      // Prevent Update service message flickering after clicking it, see #6486
      if (action.command === "sync") return;
      if (draftState[action.datasetId]) {
        const syncedServiceIndex = draftState[action.datasetId].findIndex((service) => service.id === action.result.id);
        if (syncedServiceIndex >= 0) {
          draftState[action.datasetId][syncedServiceIndex] = { ...action.result };
        }
      }
      const indexInGlobalState = draftState[GLOBAL_ID]?.findIndex((service) => service.id === action.result.id);
      if (indexInGlobalState >= 0) {
        draftState[GLOBAL_ID][indexInGlobalState] = { ...draftState[GLOBAL_ID][indexInGlobalState], ...action.result };
      }
    // Fallthrough is on purpose
    case Actions.GET_SERVICE_INFO_SUCCESS:
      if (
        "command" in action &&
        action.command !== "start" &&
        action.command !== "stop" &&
        action.command !== "stopWithAutoresume"
      )
        return;
      if (draftState[action.datasetId]) {
        const receivedServiceIndex = draftState[action.datasetId].findIndex(
          (service) => service.id === action.result.id
        );
        if (receivedServiceIndex >= 0) {
          draftState[action.datasetId][receivedServiceIndex] = action.result;
        } else {
          draftState[action.datasetId].push(action.result);
        }
      }
      return;
    case Actions.ISSUE_SERVICE_COMMAND_FAIL:
      if (action.command !== "sync") return;
      if (draftState[action.datasetId]) {
        const syncedServiceIndex = draftState[action.datasetId].findIndex(
          (service) => service.id === action.service.id
        );
        if (syncedServiceIndex >= 0) {
          draftState[action.datasetId][syncedServiceIndex] = { ...action.service };
        }
      }
      return;
    case Actions.UPDATE_DATASET_SUCCESS:
      if (action.renaming) {
        delete draftState[action.result.id];
      }
      return;

    case Actions.GET_CURRENT_DATASET_SUCCESS:
      const datasetServices = action.result.services.filter((s) => "capabilities" in s) as Models.ServiceMetadata[];
      draftState[action.result.id] = [...datasetServices];
      return;

    case Actions.GET_SERVICE_LIST_AS_ADMIN_SUCCESS:
      const adminServices = action.result.filter((s) => "capabilities" in s) as Models.ServiceMetadata[];
      draftState[GLOBAL_ID] = adminServices;
      return;

    case Actions.CREATE_SERVICE_SUCCESS:
      draftState[action.datasetId].push(action.result);
      return;
    case Actions.RECREATE_SERVICE_FROM_ADMIN_LIST_SUCCESS:
      // The create call only sends back basic service information, lets re-use the old ds/user
      draftState[GLOBAL_ID].push({ ...action.result, dataset: action.service.dataset });
      // If the dataset is loaded, add the new service to that list as well
      if (action.service.dataset?.id && draftState[action.service.dataset.id]) {
        draftState[action.service.dataset.id].push({
          ...action.result,
          dataset: action.service.dataset,
        });
      }
      return;

    case Actions.UPDATE_SERVICE_SUCCESS:
      const updatedServiceIndex = draftState[action.datasetId].findIndex((service) => service.id === action.result.id);
      let serviceToUpdate = draftState[action.datasetId][updatedServiceIndex];
      draftState[action.datasetId][updatedServiceIndex] = {
        ...serviceToUpdate,
        ...action.result,
      };
      return;

    case Actions.SOCKET_EVENT.serviceMetadataUpdate:
      const dsId = action.data.datasetId;
      if (draftState[dsId]) {
        const updatedServiceIndex = draftState[dsId].findIndex((service) => service.id === action.data.serviceId);
        if (updatedServiceIndex >= 0) {
          // Don't update the state if the service has an error. This can happen when an /error event is emitted
          // before the /info event, e.g. for gateway timeout errors from the service.
          if (draftState[dsId][updatedServiceIndex].status === "error") return;
          // When we change from "updating" to "running", the final graph update will trigger a refresh of the service
          // metadata. This needs to happen at the same time, else we get the out-of-sync popup momentarily.
          if (draftState[dsId][updatedServiceIndex].status === "updating" && action.data.status === "running") return;
          // We also trigger a refresh of the metadata on error
          if (action.data.status === "error") return;

          if (action.data.status !== undefined) draftState[dsId][updatedServiceIndex].status = action.data.status;
          if (action.data.numberOfGraphs !== undefined)
            draftState[dsId][updatedServiceIndex].numberOfGraphs = action.data.numberOfGraphs;
          if (action.data.numberOfLoadedGraphs !== undefined)
            draftState[dsId][updatedServiceIndex].numberOfLoadedGraphs = action.data.numberOfLoadedGraphs;
          if (action.data.numberOfGraphErrors !== undefined)
            draftState[dsId][updatedServiceIndex].numberOfGraphErrors = action.data.numberOfGraphErrors;
          if (action.data.numberOfLoadedStatements !== undefined)
            draftState[dsId][updatedServiceIndex].numberOfLoadedStatements = action.data.numberOfLoadedStatements;
        }
      }
      const globalServiceIndex = draftState[GLOBAL_ID]?.findIndex((service) => service.id === action.data.serviceId);
      if (globalServiceIndex >= 0) {
        if (action.data.status !== undefined) draftState[GLOBAL_ID][globalServiceIndex].status = action.data.status;
        if (action.data.numberOfGraphs !== undefined)
          draftState[GLOBAL_ID][globalServiceIndex].numberOfGraphs = action.data.numberOfGraphs;
        if (action.data.numberOfLoadedGraphs !== undefined)
          draftState[GLOBAL_ID][globalServiceIndex].numberOfLoadedGraphs = action.data.numberOfLoadedGraphs;
        if (action.data.numberOfGraphErrors !== undefined)
          draftState[GLOBAL_ID][globalServiceIndex].numberOfGraphErrors = action.data.numberOfGraphErrors;
        if (action.data.numberOfLoadedStatements !== undefined)
          draftState[GLOBAL_ID][globalServiceIndex].numberOfLoadedStatements = action.data.numberOfLoadedStatements;
      }
      return;

    case Actions.DELETE_SERVICE_SUCCESS:
      draftState[action.datasetId] = draftState[action.datasetId].filter((service) => service.id !== action.service.id);
      return;

    case Actions.DELETE_SERVICE_FROM_ADMIN_LIST_SUCCESS:
      if (action.service.dataset && draftState[action.service.dataset.id]) {
        //remove from posssible redux list for this dataset
        draftState[action.service.dataset.id] = draftState[action.service.dataset.id].filter(
          (service) => service.id !== action.service.id
        );
      }

      //remove from admin service list as well
      draftState[GLOBAL_ID] = draftState[GLOBAL_ID].filter((service) => service.id !== action.service.id);
      return;
  }
}, <State>{});

export function needToFetchAdminList(_state: GlobalState) {
  // return !state.services.has(GLOBAL_ID);
  return true;
}

export function getListAsAdmin(): BeforeDispatch<GET_SERVICE_LIST_AS_ADMIN> {
  return {
    types: [
      Actions.GET_SERVICE_LIST_AS_ADMIN,
      Actions.GET_SERVICE_LIST_AS_ADMIN_SUCCESS,
      Actions.GET_SERVICE_LIST_AS_ADMIN_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/admin/services",
        method: "get",
      }),
  };
}
export function shouldShowServiceAsRunning(service: Service) {
  return service.status === "running" || (service.status === "stopped" && service.autoResume);
}
export function getRunningServices(services: Services, capability: Models.Capability) {
  if (!services) return [];
  return services.filter(
    (service: Service) =>
      shouldShowServiceAsRunning(service) && service.capabilities.includes(capability) && service.endpoint
  );
}

export function getServiceByName(state: GlobalState, forDataset: Dataset, serviceName: string) {
  if (!forDataset) return undefined;
  if (!state.services[forDataset.id]) return undefined;
  return state.services[forDataset.id].find((c) => c.name == serviceName);
}

export function needToFetchServiceInfo(state: GlobalState, serviceName: string, forDataset?: Dataset) {
  if (!forDataset) forDataset = getCurrentDataset(state);
  if (!forDataset) return false;
  if (forDataset.serviceCount === 0) return false;
  if (!state.services[forDataset.id]) return true;
  return !state.services[forDataset.id].find((service) => {
    return service.name === serviceName;
  });
}

export function deleteServiceFromAdminList(service: Service): BeforeDispatch<DELETE_SERVICE_FROM_ADMIN_LIST> {
  return {
    types: [
      Actions.DELETE_SERVICE_FROM_ADMIN_LIST,
      Actions.DELETE_SERVICE_FROM_ADMIN_LIST_SUCCESS,
      Actions.DELETE_SERVICE_FROM_ADMIN_LIST_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/admin/services/" + service.id,
        method: "delete",
      }),
    service: service,
  };
}

export function getServiceInfo(
  accountName: string,
  datasetName: string,
  datasetId: string,
  serviceName: string
): BeforeDispatch<GET_SERVICE_INFO> {
  return {
    types: [Actions.GET_SERVICE_INFO, Actions.GET_SERVICE_INFO_SUCCESS, Actions.GET_SERVICE_INFO_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + accountName + "/" + datasetName + "/services/" + serviceName,
        method: "get",
      }),
    datasetId,
  };
}

export function issueServiceCommand(
  forAccount: Account,
  forDataset: Dataset,
  service: Service,
  cmd: keyof Models.ServiceActions
): BeforeDispatch<ISSUE_SERVICE_COMMAND> {
  return {
    types: [Actions.ISSUE_SERVICE_COMMAND, Actions.ISSUE_SERVICE_COMMAND_SUCCESS, Actions.ISSUE_SERVICE_COMMAND_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + forAccount.accountName + "/" + forDataset.name + "/services/" + service.name,
        method: "post",
        body: {
          [cmd]: true,
        },
      }),
    datasetId: forDataset.id,
    service: service,
    command: cmd,
  };
}
export function deleteService(
  forAccount: Account,
  forDataset: Dataset,
  service: Service
): BeforeDispatch<DELETE_SERVICE> {
  return {
    types: [Actions.DELETE_SERVICE, Actions.DELETE_SERVICE_SUCCESS, Actions.DELETE_SERVICE_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + forAccount.accountName + "/" + forDataset.name + "/services/" + service.name,
        method: "delete",
      }),
    accountUid: forAccount.uid,
    datasetId: forDataset.id,
    datasetName: forDataset.name,
    service: service,
  };
}

export function isServiceNameAvailable(
  formItemName: string,
  forDataset: Dataset,
  serviceName: string
): BeforeDispatch<CHECK_SERVICE_AVAILABILITY> {
  return {
    types: [
      Actions.CHECK_SERVICE_AVAILABILITY,
      Actions.CHECK_SERVICE_AVAILABILITY_SUCCESS,
      Actions.CHECK_SERVICE_AVAILABILITY_FAIL,
    ],
    promise: (client) =>
      client
        .req({
          // path: '/datasets/' + ownerName + '/' + datasetName,
          pathname: "/datasets/" + forDataset.owner.accountName + "/" + forDataset.name + "/services/" + serviceName,
          method: "get",
          statusCodeMap: { 404: 202 },
        })
        .then((d) => {
          if (d.body.name)
            throw {
              [formItemName]: "A service named " + d.body.name + " already exists",
            };
          return d;
        }),
  };
}

export function createService(
  forAccount: Account,
  forDataset: Dataset,
  body: Models.CreateService
): BeforeDispatch<CREATE_SERVICE> {
  return {
    types: [Actions.CREATE_SERVICE, Actions.CREATE_SERVICE_SUCCESS, Actions.CREATE_SERVICE_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + forAccount.accountName + "/" + forDataset.name + "/services",
        method: "post",
        body: body,
      }),
    datasetId: forDataset.id,
    datasetName: forDataset.name,
    accountUid: forAccount.uid,
  };
}
export function recreateServiceFromAdminList(service: Service): BeforeDispatch<RECREATE_SERVICE_FROM_ADMIN_LIST> {
  return {
    types: [
      Actions.RECREATE_SERVICE_FROM_ADMIN_LIST,
      Actions.RECREATE_SERVICE_FROM_ADMIN_LIST_SUCCESS,
      Actions.RECREATE_SERVICE_FROM_ADMIN_LIST_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + service.dataset!.owner.accountName + "/" + service.dataset!.name + "/services",
        method: "post",
        body: {
          name: service.name,
          type: service.type,
          config: service.config as any,
        },
      }),
    service: service,
  };
}

export function updateService(
  forAccount: Account,
  forDataset: Dataset,
  forService: Service,
  serviceInfo: Models.UpdateService
): BeforeDispatch<UPDATE_SERVICE> {
  return {
    types: [Actions.UPDATE_SERVICE, Actions.UPDATE_SERVICE_SUCCESS, Actions.UPDATE_SERVICE_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + forAccount.accountName + "/" + forDataset.name + "/services/" + forService.name,
        method: "patch",
        body: serviceInfo,
      }),
    datasetId: forDataset.id,
  };
}

export const serviceSocketEpics: SocketEpic[] = [
  // We refresh the service redux state once the graph updates are finished, or if an error is raised during startup.
  (event, getState, dispatch) => {
    if (event.eventType === "serviceMetadataUpdate" && (event.status === "running" || event.status === "error")) {
      const state = getState();
      const service = state.services[event.datasetId]?.find((s) => s.id === event.serviceId);
      // Don't dispatch if the service has an error. This can happen when an /error event is emitted before the
      // final /graphUpdate event, e.g. for gateway timeout errors from the service.
      if (service?.status === "error") return;
      const serviceName = service?.name;
      if (!serviceName) return;
      const dataset = state.datasetCollection[event.datasetId];
      if (!dataset) return;
      const ownerName = state.accountCollection[dataset.owner]?.accountName;
      if (!ownerName) return;

      dispatch<typeof getServiceInfo>(getServiceInfo(ownerName, dataset.name, event.datasetId, serviceName)).catch(
        () => {}
      );
    }
  },
];
