import { produce } from "immer";
import { isEmpty } from "lodash-es";
import * as React from "react";
import { Constants, Models } from "@triply/utils";
import {
  cancelTusUpload,
  getEnqueuedUploadSize,
  getOngoingUploadSize,
  registerTusUploads,
  state as tusState,
  TusUploadConfig,
} from "#helpers/tusUploadManagement.ts";
import { Job, startJob, updateJobProgress } from "#reducers/datasets.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState, Thunk } from "#reducers/index.ts";
import { getDataset } from "./datasetCollection.ts";

const SUPPORTED_EXTENSIONS = Constants.SUPPORTED_EXTENSIONS.map((ext: string) => `.${ext}`);

export const LocalActions = {
  UPLOAD_PROGRESS: "triply/uploading/UPLOAD_PROGRESS",
  UPLOAD_FILE_SUCCESS: "triply/uploading/UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_ERROR: "triply/uploading/UPLOAD_FILE_ERROR",
  CANCEL_UPLOAD: "triply/uploading/CANCEL_UPLOAD",
  START_UPLOAD: "triply/uploading/START_UPLOAD",
  SKIP_FILES: "triple/uploading/SKIP_FILES",
} as const;

type UPLOAD_PROGRESS = GlobalAction<{
  type: typeof LocalActions.UPLOAD_PROGRESS;
  datasetId: string;
  file: File;
  uploadId: string;
  uploadProgress: number;
}>;

type UPLOAD_FILE_SUCCESS = GlobalAction<{
  type: typeof LocalActions.UPLOAD_FILE_SUCCESS;
  datasetId: string;
  uploadId: string;
  result: Job;
}>;

type UPLOAD_FILE_ERROR = GlobalAction<{
  type: typeof LocalActions.UPLOAD_FILE_ERROR;
  datasetId: string;
  file: File;
  uploadId: string;
  message: string;
}>;

type CANCEL_UPLOAD = GlobalAction<{
  type: typeof LocalActions.CANCEL_UPLOAD;
  datasetId: string;
  uploadId: string;
}>;

type START_UPLOAD = GlobalAction<{
  type: typeof LocalActions.START_UPLOAD;
  datasetId: string;
  file: File;
  uploadId: string;
}>;

type SKIP_FILES = GlobalAction<{
  type: typeof LocalActions.SKIP_FILES;
  skippedFileNames: string[];
}>;

export type LocalAction =
  | UPLOAD_PROGRESS
  | UPLOAD_FILE_SUCCESS
  | UPLOAD_FILE_ERROR
  | CANCEL_UPLOAD
  | START_UPLOAD
  | SKIP_FILES;

export interface JobUpload {
  uploadId: string;
  file: File;
  progress: number;
  cancelled: boolean; //using a cancelled flag, because we cannot simply remove the record on cancel. A possibly late
  //progress event would simply add it again
  error?: string;
}
export interface State {
  [datasetId: string]: {
    [uploadId: string]: JobUpload;
  };
}

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.UPLOAD_FILE_ERROR:
      if (!draftState[action.datasetId]) draftState[action.datasetId] = {};

      draftState[action.datasetId][action.uploadId] = {
        ...draftState[action.datasetId][action.uploadId],
        uploadId: action.uploadId,
        error: action.message,
      };
      if (action.file) {
        draftState[action.datasetId][action.uploadId].file = action.file;
      }
      return;

    case Actions.UPLOAD_PROGRESS:
      if (!draftState[action.datasetId]) draftState[action.datasetId] = {};

      if (draftState[action.datasetId][action.uploadId] && !draftState[action.datasetId][action.uploadId].cancelled) {
        draftState[action.datasetId][action.uploadId].progress = action.uploadProgress;
      }
      return;

    case Actions.START_UPLOAD:
      if (!draftState[action.datasetId]) draftState[action.datasetId] = {};

      draftState[action.datasetId][action.uploadId] = {
        uploadId: action.uploadId,
        file: action.file,
        cancelled: false,
        progress: 0,
        error: undefined,
      };
      return;

    case Actions.UPLOAD_FILE_SUCCESS:
      if (!draftState[action.datasetId]) draftState[action.datasetId] = {};

      delete draftState[action.datasetId][action.uploadId];
      return;

    case Actions.CANCEL_UPLOAD:
      if (!draftState[action.datasetId]) draftState[action.datasetId] = {};

      if (draftState[action.datasetId][action.uploadId]) {
        draftState[action.datasetId][action.uploadId].cancelled = true;
      }
      return;

    case Actions.DELETE_JOB_SUCCESS:
    case Actions.START_JOB_SUCCESS:
      delete draftState[action.datasetId];
      return;
  }
}, <State>{});

function handleTusError(
  dispatch: (action: any) => any,
  datasetId: string,
  requestId: string,
  file: File,
  message: string
) {
  dispatch(addFileError(datasetId, requestId, file, message));
}
export function getSupportedExtensions(mayImportHdt?: boolean) {
  const supportedExtensions = SUPPORTED_EXTENSIONS;
  if (mayImportHdt) {
    // Not including `.hdt.index.v1-1` extension here. React-dropzone considers this a 'bad' extension
    return [...supportedExtensions, ".hdt"];
  }
  return supportedExtensions;
}
export function sendIgnoredUploadNotification(files: File[]): BeforeDispatch<SKIP_FILES> {
  return {
    type: Actions.SKIP_FILES,
    skippedFileNames: files.map((f: any) => {
      // whether f.path is available depends on the browser.
      return f.path ? f.path : f.name;
    }),
  };
}

export const computePartialProgress = (uploadProps: JobUpload[]) => {
  const nums = uploadProps.map((f) =>
    tusState.ongoingUploads.findIndex((u) => u.uploadId === f.uploadId) > -1 ? f.file.size * (f.progress / 100) : 0
  );
  return nums.reduce((a, b) => a + b, 0);
};

export function uploadFile(
  job: Models.Job,
  droppedFiles?: File[],
  changeEvent?: React.ChangeEvent<HTMLInputElement>
): Thunk<any> {
  return (dispatch: (action: any) => any, getState: () => GlobalState) => {
    if (!job) {
      if (__DEVELOPMENT__) console.warn("no job yet. cannot upload");
      return;
    }
    // get the list of input files
    let files: File[] = [];
    if (droppedFiles) {
      files = droppedFiles;
    } else if (changeEvent?.target.files) {
      files = Array.from(changeEvent.target.files);
    }

    const dataset = getDataset(getState(), job.datasetId);
    if (!dataset) {
      throw new Error("Cannot upload file as dataset is not known");
    }
    const endpoint = `/_api/datasets/${dataset.owner.accountName}/${dataset.name}/jobs/${job.jobId}/add`;

    const dispatchJobProgress = () => {
      const uploads = getState().uploading[job.datasetId];
      const partial = computePartialProgress(Object.keys(uploads).map((uploadId) => uploads[uploadId]));
      dispatch(
        updateJobProgress(
          job.datasetId,
          partial,
          undefined,
          getEnqueuedUploadSize("jobUpload", job.datasetId),
          getOngoingUploadSize("jobUpload", job.datasetId)
        )
      );
    };
    const onSuccess = (body: Models.Job, uploadId: string): void => {
      dispatch(markUploaded(job.datasetId, uploadId, body));
      dispatchJobProgress();

      const state = getState();
      const datasetState = state.datasets[job.datasetId];
      if (datasetState && datasetState.startingJobWhenUploaded && isEmpty(state.uploading[job.datasetId])) {
        dispatch(startJob(job));
      }
    };

    const onError = (uploadId: string, message: string, file: File) => {
      handleTusError(dispatch, job.datasetId, uploadId, file, message);
    };

    const onProgress = (percentage: number, uploadId: string, file: File) => {
      dispatch(updateFileProgress(job.datasetId, uploadId, file, percentage));
      dispatchJobProgress();
    };

    const onUploadObjectCreated = (uploadId: string, file: File) => {
      dispatch(startUpload(job.datasetId, uploadId, file));
    };

    registerTusUploads(
      files.map(
        (file) =>
          ({
            file: file,
            type: "jobUpload",
            accountId: dataset.owner.uid,
            datasetId: job.datasetId,
            endpoint: endpoint,
            onSuccess: onSuccess,
            onProgress: onProgress,
            onError: onError,
            onUploadObjectCreated: onUploadObjectCreated,
          } as TusUploadConfig<Models.Job | Models.Asset>)
      )
    );
  };
}

export function cancelUpload(uploadInfo: JobUpload, job: Job): Thunk<CANCEL_UPLOAD> {
  cancelTusUpload(uploadInfo.uploadId);
  return (dispatch) => {
    dispatch({
      type: Actions.CANCEL_UPLOAD,
      datasetId: job.datasetId,
      uploadId: uploadInfo.uploadId,
    });
  };
}

export function updateFileProgress(
  datasetId: string,
  uploadId: string,
  file: File,
  progress: number
): BeforeDispatch<UPLOAD_PROGRESS> {
  return {
    type: Actions.UPLOAD_PROGRESS,
    datasetId: datasetId,
    file,
    uploadId,
    uploadProgress: progress,
  };
}
export function addFileError(
  datasetId: string,
  uploadId: string,
  file: File,
  message: string
): BeforeDispatch<UPLOAD_FILE_ERROR> {
  return {
    type: Actions.UPLOAD_FILE_ERROR,
    datasetId: datasetId,
    file,
    uploadId,
    message,
  };
}
export function startUpload(datasetId: string, uploadId: string, file: File): BeforeDispatch<START_UPLOAD> {
  return {
    type: Actions.START_UPLOAD,
    datasetId: datasetId,
    file,
    uploadId,
  };
}
export function markUploaded(datasetId: string, uploadId: string, job: Job): BeforeDispatch<UPLOAD_FILE_SUCCESS> {
  return {
    type: Actions.UPLOAD_FILE_SUCCESS,
    datasetId,
    uploadId,
    result: job,
  };
}
export function getUploadingFiles(state: GlobalState, forDataset: string): JobUpload[] {
  const uploading = state.uploading[forDataset];
  if (!uploading) return [];
  return Object.keys(uploading)
    .map((uploadId) => uploading[uploadId])
    .filter((upload) => !upload.cancelled);
}
