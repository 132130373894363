import { persistState } from "@redux-devtools/core";
import { LOCATION_CHANGE, routerMiddleware } from "connected-react-router";
import {
  applyMiddleware,
  compose,
  createStore as reduxCreateStore,
  Middleware,
  Store,
  StoreEnhancerStoreCreator,
} from "redux";
import thunk from "redux-thunk";
import ApiClient from "#helpers/ApiClient.ts";
import { updateWithRequestInfo } from "#reducers/app.ts";
import { default as getReducer, GlobalState } from "#reducers/index.ts";
import createPromiseMw from "./promiseMw.ts";

declare var window: __App.ReactWindow;

export interface SsrRequestInfo {
  requestLanguage?: string;
  userAgent?: string;
}
export default function createStore(
  history: any,
  apiClient: ApiClient,
  config: {
    state?: GlobalState;
    requestInfo?: SsrRequestInfo;
  }
) {
  // Sync dispatched route actions to the history
  const middleware: Middleware[] = [createPromiseMw(apiClient), <Middleware>routerMiddleware(history), thunk];

  let finalCreateStore: StoreEnhancerStoreCreator<GlobalState>;
  if (__DEVELOPMENT__ && __CLIENT__) {
    //only include lib here, when we're not running prod mode

    const storeMw = [applyMiddleware(...middleware)];

    if (window.__REDUX_DEVTOOLS_EXTENSION__) storeMw.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    storeMw.push(persistState(<any>window.location.href.match(/[?&]debug_session=([^&]+)\b/)) as any);
    finalCreateStore = (<any>compose)(...storeMw)(reduxCreateStore);
  } else {
    finalCreateStore = <any>applyMiddleware(...middleware)(reduxCreateStore);
  }
  const reducer = getReducer(history);
  //casting state to any here, as redux uses a `DeepPartial` on the global JS state
  //We're running into the same-old 'deep partial' issues here, where the non-partial
  //object becomes unassignable to the DeepPartial object
  const store: Store<GlobalState> = finalCreateStore(reducer, config.state as any);

  //server does not use the react-router-redux ConnectedRouter, so set initial location in redux state
  if (__SERVER__) store.dispatch({ type: LOCATION_CHANGE, payload: history.location });

  if (config.requestInfo) {
    store.dispatch(updateWithRequestInfo(config.requestInfo));
  }
  return store;
}
