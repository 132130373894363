import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { TfaSetup } from "#components/Forms/index.ts";
import { Button, Dialog } from "#components/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { setAuthenticatorEnabled } from "#reducers/accounts.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { shouldEnforceTfaForUser } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";

const MfaDialog: React.FC<{}> = () => {
  const history = useHistory();
  return (
    <Dialog open onClose={() => history.goBack()} maxWidth="sm">
      <TfaSetup />
    </Dialog>
  );
};

const ConfigureMfa: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const acl = useAcl();
  const currentAccount = useCurrentAccount();
  const confirm = useConfirmation();
  const history = useHistory();
  const location = useLocation<{ configureMfa: boolean } | undefined>();
  const is2FaEnforcedForUser = useSelector(
    (state: GlobalState) =>
      currentAccount?.type === "user" && shouldEnforceTfaForUser(currentAccount, state.config.clientConfig?.enforceTfa)
  );

  if (!currentAccount) {
    throw new Error("Expected account to be present");
  }

  if (currentAccount.mfaEnabled) {
    return (
      <Button
        color="warning"
        onClick={() =>
          confirm({
            onConfirm: () =>
              dispatch<typeof setAuthenticatorEnabled>(
                setAuthenticatorEnabled(currentAccount.accountName, currentAccount.uid, false)
              ),
            title:
              is2FaEnforcedForUser && acl.isAuthenticatedUser(currentAccount)
                ? "Reset two-factor authentication"
                : "Disable two-factor authentication?",
            description:
              is2FaEnforcedForUser && acl.isAuthenticatedUser(currentAccount)
                ? "After clicking confirm, you will be forced to re-configure two-factor authentication"
                : "We strongly encourage using two-factor authentication to protect your account.",
          })
        }
        disabled={
          !acl.check({ action: "disableMfa", context: { isOwnAccount: acl.isAuthenticatedUser(currentAccount) } })
            .granted
        }
      >
        {is2FaEnforcedForUser ? "Reset two-factor authentication" : "Disable two-factor authentication"}
      </Button>
    );
  }

  return (
    <>
      <Button
        color="success"
        onClick={() => {
          history.push({ state: { configureMfa: true, preserveScrollPosition: true } });
        }}
        disabled={
          !acl.check({ action: "manageMfa", context: { isOwnAccount: acl.isAuthenticatedUser(currentAccount) } })
            .granted
        }
      >
        Configure two-factor authentication
      </Button>

      {!!location.state?.configureMfa && <MfaDialog />}
    </>
  );
};

export default ConfigureMfa;
