import { produce } from "immer";
import { isEmpty, pick } from "lodash-es";
import { Models, Routes } from "@triply/utils";
import { FeatureToggle } from "@triply/utils/Models.js";
import { default as EnforceTfaForm } from "#components/Forms/EnforceTFA/index.tsx";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { Config, getConfig } from "#staticConfig.ts";
import { PrefixUpdate } from "./datasetManagement.ts";

export const LocalActions = {
  DELETE_BANNER: "triply/app/DELETE_BANNER",
  DELETE_BANNER_SUCCESS: "triply/app/DELETE_BANNER_SUCCESS",
  DELETE_BANNER_FAIL: "triply/app/DELETE_BANNER_FAIL",
  GET_CONFIG: "triply/config/GET_CONFIG",
  GET_CONFIG_SUCCESS: "triply/config/GET_CONFIG_SUCCESS",
  GET_CONFIG_FAIL: "triply/config/GET_CONFIG_FAIL",
  GET_FRONT_PAGE: "triply/config/GET_FRONT_PAGE",
  GET_FRONT_PAGE_SUCCESS: "triply/config/GET_FRONT_PAGE_SUCCESS",
  GET_FRONT_PAGE_FAIL: "triply/config/GET_FRONT_PAGE_FAIL",
  UPDATE_FRONT_PAGE: "triply/config/UPDATE_FRONT_PAGE",
  UPDATE_FRONT_PAGE_SUCCESS: "triply/config/UPDATE_FRONT_PAGE_SUCCESS",
  UPDATE_FRONT_PAGE_FAIL: "triply/config/UPDATE_FRONT_PAGE_FAIL",
  UPLOAD_LOGO: "triply/app/UPLOAD_LOGO",
  UPLOAD_LOGO_SUCCESS: "triply/app/UPLOAD_LOGO_SUCCESS",
  UPLOAD_LOGO_FAIL: "triply/app/UPLOAD_LOGO_FAIL",
  UPDATE_APPLICATION_CONFIG: "triply/app/UPDATE_APPLICATION_CONFIG",
  UPDATE_APPLICATION_CONFIG_SUCCESS: "triply/app/UPDATE_APPLICATION_CONFIG_SUCCESS",
  UPDATE_APPLICATION_CONFIG_FAIL: "triply/app/UPDATE_APPLICATION_CONFIG_FAIL",
  UPDATE_AUTH_SETTINGS: "triply/app/UPDATE_AUTH_SETTINGS",
  UPDATE_AUTH_SETTINGS_SUCCESS: "triply/app/UPDATE_AUTH_SETTINGS_SUCCESS",
  UPDATE_AUTH_SETTINGS_FAIL: "triply/app/UPDATE_AUTH_SETTINGS_FAIL",
  UPDATE_AUTH_TFA_SETTINGS: "triply/app/UPDATE_AUTH_TFA_SETTINGS",
  UPDATE_AUTH_TFA_SETTINGS_SUCCESS: "triply/app/UPDATE_AUTH_TFA_SETTINGS_SUCCESS",
  UPDATE_AUTH_TFA_SETTINGS_FAIL: "triply/app/UPDATE_AUTH_TFA_SETTINGS_FAIL",
  UPDATE_APP_SETTINGS: "triply/app/UPDATE_APP_SETTINGS",
  UPDATE_APP_SETTINGS_SUCCESS: "triply/app/UPDATE_APP_SETTINGS_SUCCESS",
  UPDATE_APP_SETTINGS_FAIL: "triply/app/UPDATE_APP_SETTINGS_FAIL",
  UPDATE_FEATURE_TOGGLE: "triply/app/UPDATE_FEATURE_TOGGLE",
  UPDATE_FEATURE_TOGGLE_SUCCESS: "triply/app/UPDATE_FEATURE_TOGGLE_SUCCESS",
  UPDATE_FEATURE_TOGGLE_FAIL: "triply/app/UPDATE_FEATURE_TOGGLE_FAIL",
  UPDATE_GLOBAL_PREFIXES: "triply/app/UPDATE_PREFIXES",
  UPDATE_GLOBAL_PREFIXES_SUCCESS: "triply/app/UPDATE_PREFIXES_SUCCESS",
  UPDATE_GLOBAL_PREFIXES_FAIL: "triply/app/UPDATE_PREFIXES_FAIL",
} as const;

type GET_CONFIG = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_CONFIG,
      typeof LocalActions.GET_CONFIG_SUCCESS,
      typeof LocalActions.GET_CONFIG_FAIL
    ];
  },
  Routes.info.Get
>;
type GET_FRONT_PAGE = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_FRONT_PAGE,
      typeof LocalActions.GET_FRONT_PAGE_SUCCESS,
      typeof LocalActions.GET_FRONT_PAGE_FAIL
    ];
  },
  Routes.info.frontpage.Get
>;
type UPDATE_FRONT_PAGE = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_FRONT_PAGE,
      typeof LocalActions.UPDATE_FRONT_PAGE_SUCCESS,
      typeof LocalActions.UPDATE_FRONT_PAGE_FAIL
    ];
  },
  Routes.info.frontpage.Patch
>;
type UPDATE_GLOBAL_PREFIXES = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_GLOBAL_PREFIXES,
      typeof LocalActions.UPDATE_GLOBAL_PREFIXES_SUCCESS,
      typeof LocalActions.UPDATE_GLOBAL_PREFIXES_FAIL
    ];
  },
  Routes.___prefixes.Put
>;

type UPLOAD_LOGO = GlobalAction<
  {
    types: [
      typeof LocalActions.UPLOAD_LOGO,
      typeof LocalActions.UPLOAD_LOGO_SUCCESS,
      typeof LocalActions.UPLOAD_LOGO_FAIL
    ];
  },
  Routes.imgs.logos.Post
>;

type DELETE_BANNER = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_BANNER,
      typeof LocalActions.DELETE_BANNER_SUCCESS,
      typeof LocalActions.DELETE_BANNER_FAIL
    ];
  },
  Routes.imgs.logos.Delete
>;

type UPDATE_APPLICATION_CONFIG = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_APPLICATION_CONFIG,
      typeof LocalActions.UPDATE_APPLICATION_CONFIG_SUCCESS,
      typeof LocalActions.UPDATE_APPLICATION_CONFIG_FAIL
    ];
  },
  Routes.info.Patch
>;

type UPDATE_AUTH_SETTINGS = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_AUTH_SETTINGS,
      typeof LocalActions.UPDATE_AUTH_SETTINGS_SUCCESS,
      typeof LocalActions.UPDATE_AUTH_SETTINGS_FAIL
    ];
  },
  Routes.info.Patch
>;

type UPDATE_AUTH_TFA_SETTINGS = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_AUTH_TFA_SETTINGS,
      typeof LocalActions.UPDATE_AUTH_TFA_SETTINGS_SUCCESS,
      typeof LocalActions.UPDATE_AUTH_TFA_SETTINGS_FAIL
    ];
  },
  Routes.info.Patch
>;

type UPDATE_APP_SETTINGS = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_APP_SETTINGS,
      typeof LocalActions.UPDATE_APP_SETTINGS_SUCCESS,
      typeof LocalActions.UPDATE_APP_SETTINGS_FAIL
    ];
  },
  Routes.info.Patch
>;

type UPDATE_FEATURE_TOGGLE = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_FEATURE_TOGGLE,
      typeof LocalActions.UPDATE_FEATURE_TOGGLE_SUCCESS,
      typeof LocalActions.UPDATE_FEATURE_TOGGLE_FAIL
    ];
  },
  Routes.info.Patch
>;

export type LocalAction =
  | DELETE_BANNER
  | GET_CONFIG
  | GET_FRONT_PAGE
  | UPDATE_FRONT_PAGE
  | UPLOAD_LOGO
  | UPDATE_APPLICATION_CONFIG
  | UPDATE_AUTH_SETTINGS
  | UPDATE_AUTH_TFA_SETTINGS
  | UPDATE_APP_SETTINGS
  | UPDATE_FEATURE_TOGGLE
  | UPDATE_GLOBAL_PREFIXES;

const selectedStaticConfigKeys = [
  "plausible",
  "apiUrl",
  "consoleUrl",
  "imageProxy",
  "brandingLinks",
  "consoleVersion",
  "consoleBuildDate",
  "osmTileUrl",
  "theme",
  "customCss",
  "tileLayers",
  "defaultTileMap",
  "etlLicenseKey",
  "editMode",
  "editModeStatementsLimit",
] as const;

export type StaticConfig = Pick<Config, (typeof selectedStaticConfigKeys)[number]>;
export type ClientConfig = Models.ClientConfig;

export interface State {
  staticConfig?: StaticConfig;
  clientConfig?: ClientConfig;
  frontpage?: Models.FrontPage;
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.DELETE_BANNER_SUCCESS:
      case Actions.TOGGLE_CACHE_SUCCESS:
      case Actions.UPLOAD_LOGO_SUCCESS:
      case Actions.GET_CONFIG_SUCCESS:
      case Actions.UPDATE_APPLICATION_CONFIG_SUCCESS:
      case Actions.UPDATE_AUTH_SETTINGS_SUCCESS:
      case Actions.UPDATE_APP_SETTINGS_SUCCESS:
      case Actions.UPDATE_FEATURE_TOGGLE_SUCCESS:
      case Actions.UPDATE_AUTH_TFA_SETTINGS_SUCCESS:
        draftState.clientConfig = action.result;
        return;
      case Actions.GET_FRONT_PAGE_SUCCESS:
      case Actions.UPDATE_FRONT_PAGE_SUCCESS:
        draftState.frontpage = action.result;
        return;
      case Actions.UPDATE_GLOBAL_PREFIXES_SUCCESS:
        if (!draftState.clientConfig) {
          throw new Error("No client config found in state. Something is wrong");
        }
        draftState.clientConfig.prefixes = action.result;
        return;
    }
  },
  <State>{
    staticConfig: __SERVER__ ? pick(JSON.parse(JSON.stringify(getConfig())), selectedStaticConfigKeys) : undefined,
    clientConfig: undefined,
  }
);

export function shouldLoadConfig(state: GlobalState) {
  return isEmpty(state.config.clientConfig);
}
export function deleteBanner(): BeforeDispatch<DELETE_BANNER> {
  return {
    types: [Actions.DELETE_BANNER, Actions.DELETE_BANNER_SUCCESS, Actions.DELETE_BANNER_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/imgs/logos/banner",
        method: "delete",
      }),
  };
}

export function loadclientConfig(): BeforeDispatch<GET_CONFIG> {
  return {
    types: [Actions.GET_CONFIG, Actions.GET_CONFIG_SUCCESS, Actions.GET_CONFIG_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/info",
        method: "get",
      }),
  };
}
export function getFrontPageInfo(): BeforeDispatch<GET_FRONT_PAGE> {
  return {
    types: [Actions.GET_FRONT_PAGE, Actions.GET_FRONT_PAGE_SUCCESS, Actions.GET_FRONT_PAGE_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/info/frontpage",
        method: "get",
      }),
  };
}
export function updateFrontPageInfo(updateWith: Models.FrontpageUpdate): BeforeDispatch<UPDATE_FRONT_PAGE> {
  return {
    types: [Actions.UPDATE_FRONT_PAGE, Actions.UPDATE_FRONT_PAGE_SUCCESS, Actions.UPDATE_FRONT_PAGE_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/info/frontpage",
        method: "patch",
        body: updateWith,
      }),
  };
}
export function uploadSquareLogo(file: File): BeforeDispatch<UPLOAD_LOGO> {
  return {
    types: [Actions.UPLOAD_LOGO, Actions.UPLOAD_LOGO_SUCCESS, Actions.UPLOAD_LOGO_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/imgs/logos/logo.svg",
        method: "post",
        files: { logo: file },
      }),
  };
}
export function uploadLandscapeLogo(file: File): BeforeDispatch<UPLOAD_LOGO> {
  return {
    types: [Actions.UPLOAD_LOGO, Actions.UPLOAD_LOGO_SUCCESS, Actions.UPLOAD_LOGO_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/imgs/logos/logo-lg.svg",
        method: "post",
        files: { logo: file },
      }),
  };
}
export function uploadBanner(file: File, toPath: string): BeforeDispatch<UPLOAD_LOGO> {
  return {
    types: [Actions.UPLOAD_LOGO, Actions.UPLOAD_LOGO_SUCCESS, Actions.UPLOAD_LOGO_FAIL],
    promise: (client) =>
      client.req({
        pathname: toPath,
        method: "post",
        files: { banner: file },
      }),
  };
}
export function updateClientConfig(updateWith: Models.ClientConfigUpdate): BeforeDispatch<UPDATE_APPLICATION_CONFIG> {
  return {
    types: [
      Actions.UPDATE_APPLICATION_CONFIG,
      Actions.UPDATE_APPLICATION_CONFIG_SUCCESS,
      Actions.UPDATE_APPLICATION_CONFIG_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/info",
        method: "patch",
        body: updateWith,
      }),
  };
}
export function updateAuthSettings(
  enable: boolean,
  permittedSignupDomains: string
): BeforeDispatch<UPDATE_AUTH_SETTINGS> {
  return {
    types: [Actions.UPDATE_AUTH_SETTINGS, Actions.UPDATE_AUTH_SETTINGS_SUCCESS, Actions.UPDATE_AUTH_SETTINGS_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/info",
        method: "patch",
        body: {
          passwordSignup: enable,
          permittedSignupDomains,
        },
      }),
  };
}
export function updateTfaSettings(enforceTfa: EnforceTfaForm.FormData): BeforeDispatch<UPDATE_AUTH_TFA_SETTINGS> {
  return {
    types: [
      Actions.UPDATE_AUTH_TFA_SETTINGS,
      Actions.UPDATE_AUTH_TFA_SETTINGS_SUCCESS,
      Actions.UPDATE_AUTH_TFA_SETTINGS_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/info",
        method: "patch",
        body: enforceTfa.enforceTfa
          ? {
              enforceTfa: {
                userRole: enforceTfa.role,
                authMethod: enforceTfa.authMethod,
              },
            }
          : { enforceTfa: false },
      }),
  };
}

export function updateFeatureToggle(name: FeatureToggle, enable: boolean): BeforeDispatch<UPDATE_FEATURE_TOGGLE> {
  return {
    types: [Actions.UPDATE_FEATURE_TOGGLE, Actions.UPDATE_FEATURE_TOGGLE_SUCCESS, Actions.UPDATE_FEATURE_TOGGLE_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/info",
        method: "patch",
        body: {
          featureToggles: { [name]: enable },
        },
      }),
  };
}

export function updateGlobalPrefixes(prefixes: PrefixUpdate[]): BeforeDispatch<UPDATE_GLOBAL_PREFIXES> {
  return {
    types: [
      Actions.UPDATE_GLOBAL_PREFIXES,
      Actions.UPDATE_GLOBAL_PREFIXES_SUCCESS,
      Actions.UPDATE_GLOBAL_PREFIXES_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/prefixes",
        method: "put",
        //avoid saving empty IRIs (i.e. the last row from our component)
        body: prefixes.filter((p) => !!p.iri),
      }),
  };
}

export function shouldEnforceTfaForUser(user?: Models.User, enforceTfaConfig?: Models.EnforceTfaSetting) {
  if (!user) return false;
  if (!enforceTfaConfig) return false;
  if (enforceTfaConfig.userRole === "admins" && (user.role === "siteAdmin" || user.role === "superAdmin")) {
    return enforceTfaConfig.authMethod === "password" ? user.authMethod === "password" : true;
  } else if (enforceTfaConfig.userRole === "all") {
    return enforceTfaConfig.authMethod === "password" ? user.authMethod === "password" : true;
  }
  return false;
}
