import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { asyncConnect } from "redux-connect";
import { DatasetListItem, FlexContainer, Meta, SearchField, SinkList } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import BasicTree from "#helpers/BasicTree.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import {
  Dataset,
  getDatasetList,
  getDatasetsWithLink,
  isDatasetsListLoadedForQuery,
  ListFor,
  searchDatasets,
} from "#reducers/datasetManagement.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";
import { areTopicsLoaded, getTopics, Topic } from "#reducers/topics.ts";
import styles from "./style.scss";

function getSearchString(queryString: string) {
  const q = parseSearchString(queryString).q;
  return typeof q === "string" && !!q ? q : undefined;
}

export namespace Datasets {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    pushState: typeof connectedReactRouter.push;
    getDatasetsWithLink: DispatchedFn<typeof getDatasetsWithLink>;
  }
  export interface PropsFromState {
    fetchingList?: ListFor | boolean;
    fetchingListError?: string;
    datasets: Dataset[];
    nextPage?: string;
    topics?: Topic[];
  }
  export interface State {
    locationQueryTerm: string;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

@asyncConnect<GlobalState>([
  {
    promise: ({ location, store: { dispatch, getState } }) => {
      const state = getState();
      if (!isDatasetsListLoadedForQuery(state, location.search)) {
        return dispatch<any>(searchDatasets(parseSearchString(location.search)));
      }
    },
  },
  {
    promise: ({ store: { dispatch, getState } }) => {
      if (!areTopicsLoaded(getState())) {
        return dispatch<any>(getTopics());
      }
    },
  },
])
class Datasets extends React.PureComponent<Datasets.Props, Datasets.State> {
  constructor(props: Datasets.Props) {
    super(props);
    this.state = {
      locationQueryTerm: getSearchString(props.location.search) || "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Datasets.Props) {
    const nextSearchString = getSearchString(nextProps.location.search);
    const currentSearchString = getSearchString(this.props.location.search);
    if (nextSearchString !== currentSearchString) {
      this.setState({
        locationQueryTerm: nextSearchString || "",
      });
    }
  }

  loadNextDsPage = () => {
    this.props.nextPage && this.props.getDatasetsWithLink(this.props.nextPage).catch(() => {});
  };

  search = (term: string) => {
    this.props.pushState({
      pathname: `/datasets`,
      search: stringifyQuery({ q: term.trim() }),
    });
  };

  render() {
    const { fetchingList, fetchingListError, nextPage, datasets, topics } = this.props;
    return (
      <>
        <Meta title="Datasets" currentPath={this.props.location.pathname} />
        <FlexContainer className="mt-3">
          <div className={styles.outerSearchContainer}>
            <div className={getClassName("my-7 px-1", styles.innerSearchContainer)}>
              <SearchField
                search={this.search}
                searching={!!fetchingList}
                initialSearchTerm={this.state.locationQueryTerm}
                topics={BasicTree.fromArray(topics || [], "iri")}
                placeHolder="Find a dataset"
                ariaLabel="Sitewide"
                autoFocus
              />
            </div>
          </div>

          <SinkList
            loadNextPage={nextPage ? this.loadNextDsPage : undefined}
            fetchingListError={fetchingListError}
            noContentMsg="No datasets found"
          >
            {datasets.map((ds) => (
              <DatasetListItem key={ds.id} ds={ds} />
            ))}
          </SinkList>
        </FlexContainer>
      </>
    );
  }
}
export default connect<
  Datasets.PropsFromState,
  { [K in keyof Datasets.DispatchProps]: any },
  Datasets.OwnProps,
  GlobalState
>(
  (state, props) => {
    return {
      datasets: getDatasetList(state, {
        account: false,
        searchTerm: getSearchString(props.location.search) || false,
        admin: false,
      }),
      fetchingList: state.datasetManagement.fetchingList,
      fetchingListError: state.datasetManagement.fetchingListError,
      nextPage: state.datasetManagement.nextPage,
      topics: state.topics.list,
    };
  },
  //dispatch
  {
    getDatasetsWithLink,
    pushState: connectedReactRouter.push,
  }
)(Datasets);
