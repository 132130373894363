import { produce } from "immer";
import { isEmpty, isEqual } from "lodash-es";
import { ParsedQs } from "qs";
import { Models, Routes } from "@triply/utils";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";

export const LocalActions = {
  GET_TRIPLES: "triply/triples/GET_TRIPLES",
  GET_TRIPLES_SUCCESS: "triply/triples/GET_TRIPLES_SUCCESS",
  GET_TRIPLES_FAIL: "triply/triples/GET_TRIPLES_FAIL",
  GET_TERMS: "triply/triples/GET_TERMS",
  GET_TERMS_SUCCESS: "triply/triples/GET_TERMS_SUCCESS",
  GET_TERMS_FAIL: "triply/triples/GET_TERMS_FAIL",
} as const;

type GET_TRIPLES = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_TRIPLES,
      typeof LocalActions.GET_TRIPLES_SUCCESS,
      typeof LocalActions.GET_TRIPLES_FAIL
    ];
    dataset: Dataset;
    query: ParsedQs;
    usedNextPageLink?: string;
  },
  Routes.datasets._account._dataset.statements.Get
>;
type GET_TERMS = GlobalAction<
  {
    types: [typeof LocalActions.GET_TERMS, typeof LocalActions.GET_TERMS_SUCCESS, typeof LocalActions.GET_TERMS_FAIL];
    datasetId: string;
  },
  Routes.datasets._account._dataset.terms.Get
>;

export type LocalAction = GET_TRIPLES;

export type Triple = Models.QueryResult;
export type Triples = Models.QueryResult[];
export type Term = Models.NtriplyTerm;
export type Position = "subject" | "predicate" | "object" | "graph";

export interface State {
  list?: Triples;
  listFor?: {
    datasetId: string;
    query: ParsedQs;
    lastGraphsUpdateTime?: string;
  };
  nextPageLink?: string;
}

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.GET_TRIPLES_SUCCESS:
      if (action.usedNextPageLink && action.usedNextPageLink !== draftState.nextPageLink) {
        //the nextPageLink used doesn't match with the data we have, ignore this result
        return;
      }

      draftState.list = [...((action.usedNextPageLink && draftState.list) || []), ...action.result];
      draftState.listFor = {
        datasetId: action.dataset.id,
        query: action.query,
        lastGraphsUpdateTime: action.dataset.lastGraphsUpdateTime,
      };
      draftState.nextPageLink = action.meta.links.next?.url;

      return;
  }
}, <State>{});

export function getTriples(forDataset: Dataset, query: ParsedQs, withLink?: string): BeforeDispatch<GET_TRIPLES> {
  return {
    types: [Actions.GET_TRIPLES, Actions.GET_TRIPLES_SUCCESS, Actions.GET_TRIPLES_FAIL],
    promise: (client) => {
      if (withLink)
        return client.req({
          url: withLink,
          query: {}, //query should already be in the link
          method: "get",
        });
      return client.req({
        pathname: "/datasets/" + forDataset.owner.accountName + "/" + forDataset.name + "/statements.triply",
        query: query,
        method: "get",
      });
    },
    dataset: forDataset,
    query: query,
    usedNextPageLink: withLink,
  };
}

export function getTerms(forDataset: Dataset, query: Models.FindTermsQuery): BeforeDispatch<GET_TERMS> {
  return {
    types: [Actions.GET_TERMS, Actions.GET_TERMS_SUCCESS, Actions.GET_TERMS_FAIL],
    promise: (client) => {
      return client.req({
        pathname: "/datasets/" + forDataset.owner.accountName + "/" + forDataset.name + "/terms",
        query: query,
        method: "get",
      });
    },
    datasetId: forDataset.id,
  };
}

export function triplesAreLoadedFor(state: GlobalState, dataset: Dataset, query: ParsedQs) {
  return (
    state.triples.listFor?.datasetId === dataset.id &&
    ((isEmpty(query) && isEmpty(state.triples.listFor?.query)) || isEqual(query, state.triples.listFor?.query)) &&
    state.triples.listFor.lastGraphsUpdateTime === dataset.lastGraphsUpdateTime
  );
}

export function triplesAreOutdatedFor(state: GlobalState, dataset: Dataset, query: ParsedQs) {
  return (
    state.triples.listFor?.datasetId === dataset.id &&
    ((isEmpty(query) && isEmpty(state.triples.listFor?.query)) || isEqual(query, state.triples.listFor?.query)) &&
    state.triples.listFor.lastGraphsUpdateTime !== dataset.lastGraphsUpdateTime
  );
}

export function getTriplesFor(state: GlobalState, dataset: Dataset, query: ParsedQs) {
  if (triplesAreLoadedFor(state, dataset, query)) {
    return state.triples.list;
  }
}

export function getNextTriplesPageFor(state: GlobalState, dataset: Dataset, query: ParsedQs) {
  if (triplesAreLoadedFor(state, dataset, query)) {
    return state.triples.nextPageLink;
  }
}
