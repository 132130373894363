import { jsonLanguage } from "@codemirror/lang-json";
import { StreamLanguage } from "@codemirror/language";
import { turtle } from "@codemirror/legacy-modes/mode/turtle";
import { EditorView } from "@codemirror/view";
import { Button } from "@mui/material";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import * as React from "react";
import { RawResponse } from "#components/Sparql/SparqlUtils.ts";
import { SparqlVisualizationContext } from "../../SparqlVisualizationContext";
import LargeResultWidget from "../LargeResultWidget";

interface Props {}
const MAX_RESPONSE_SIZE = 250 * 1024;

const RawResponse: React.FC<Props> = () => {
  const { rawData, responseSize, checkLimits, queryName } = React.useContext(SparqlVisualizationContext);
  const [showLargeResult, setShowLargeResult] = React.useState(false);
  if (rawData === undefined) return null;
  if (checkLimits && responseSize && responseSize > MAX_RESPONSE_SIZE && !showLargeResult) {
    return (
      <LargeResultWidget
        exceededSize="resultSize"
        onContinue={() => setShowLargeResult(true)}
        extraAction={
          <Button
            variant="text"
            className="ml-2"
            href={window.URL.createObjectURL(
              new Blob([typeof rawData === "string" ? rawData : JSON.stringify(rawData, null, 2)], {
                type: typeof rawData === "string" ? "application/n-triples" : "application/json",
              })
            )}
            download={`${queryName || "query"}.${typeof rawData === "string" ? "nt" : "json"}`}
          >
            Download response
          </Button>
        }
      />
    );
  }
  return (
    <CodeMirror
      readOnly
      value={typeof rawData === "string" ? rawData : JSON.stringify(rawData, null, 2)}
      extensions={[
        typeof rawData === "string" ? StreamLanguage.define(turtle) : jsonLanguage,
        githubLight,
        EditorView.lineWrapping,
      ]}
    />
  );
};

export default RawResponse;
