import { LoadingButton } from "@mui/lab";
import { Box, Container, TextField } from "@mui/material";
import dedent from "dedent";
import * as React from "react";
import { useLocation } from "react-router";
import { factories, getWriter, parse, Store } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useEditModeStatementsLimit from "#helpers/hooks/useEditModeStatementsLimit.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { Button, Dialog, FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";

const factory = factories.compliant;

const RejectResource: React.FC<{}> = ({}) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const editModeStatementsLimit = useEditModeStatementsLimit();
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();

  const authorAcc = useAuthenticatedUser();
  const resource = parseSearchString(useLocation().search).resource as string;

  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;

  const dispatch = useDispatch();

  return (
    <>
      <Button
        color="error"
        elevation
        onClick={() => setOpen(true)}
        title="Reject instance"
        startIcon={<FontAwesomeIcon icon="xmark" />}
        size="small"
      >
        Reject
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth title="Reject instance" closeButton>
        <Container className="pb-3">
          <p>{`Move instance '${resource}' back to editing.`}</p>
          <p>Show the following editing note to the editors</p>
          <form
            className="px-5 pb-5"
            onSubmit={async (e) => {
              e.preventDefault();
              setSaving(true);

              const textField = e.currentTarget[0] as HTMLTextAreaElement;

              let editorNote = textField.value.trim() || "";
              if (editorNote) editorNote = `"${editorNote}"@nl`;

              const query = dedent`
                prefix vs: <http://www.w3.org/2003/06/sw-vocab-status/ns#>
                prefix skos: <http://www.w3.org/2004/02/skos/core#>
                delete {
                  ?id vs:term_status ?status.
                } insert {
                  ?id vs:term_status "in bewerking"@nl;
                      skos:editorialNote ?note
                } where {
                  bind(<${resource}> as ?id)
                  optional {
                    ?id vs:term_status ?status
                  }
                  optional {
                    values ?note {
                      ${editorNote}
                    }
                  }
                }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name })
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                })
              );

              setSaving(false);
              setOpen(false);
            }}
          >
            <TextField label="editing note" multiline minRows={3} disabled={saving} fullWidth variant="filled" />
            <Box className="mt-3">
              <Button variant="text" onClick={() => setOpen(false)} disabled={saving}>
                Cancel
              </Button>
              <LoadingButton color="error" type="submit" loading={saving} disabled={saving} variant="contained">
                Reject
              </LoadingButton>
            </Box>
          </form>
        </Container>
      </Dialog>
    </>
  );
};

export default RejectResource;
