import { syntaxTree } from "@codemirror/language";
import { Action, Diagnostic } from "@codemirror/lint";
import { EditorView } from "@codemirror/view";
import Debug from "debug";
import { Prefix } from "@triply/utils";
import { OutsidePrefixes } from "../facets/outsidePrefixes.ts";
import { getPrologueNodeFromGlobalTree } from "../fields/definedPrefixes.ts";
import { PNAME_NS, PrefixDecl } from "../grammar/sparqlParser.terms.ts";

const debug = Debug("triply:sparql-editor:linting:prefixes");

function prefixLinter({ state }: EditorView): readonly Diagnostic[] {
  debug("start");
  const diagnostics: Diagnostic[] = [];
  const tree = syntaxTree(state);

  // Don't use the field for these, we want to look inside the prologue
  const prefixDefinitions: string[] = [];
  const prologue = getPrologueNodeFromGlobalTree(tree);
  const definedPrefixNodes = prologue?.getChildren(PrefixDecl);
  // Let's see which prefixes are already defined
  for (const prefixDec of definedPrefixNodes || []) {
    // Check for errors, this makes sure we inject the prefixes at the most "valid" place
    const PrefixNameNode = prefixDec.getChild(PNAME_NS);
    if (PrefixNameNode !== null) {
      const prefixName = state.sliceDoc(PrefixNameNode.from, PrefixNameNode.to - 1);
      if (prefixDefinitions.includes(prefixName)) {
        diagnostics.push(getDuplicatePrefixDiagnostic(prefixDec));
      } else {
        // Push the prefix name except ':'
        prefixDefinitions.push(prefixName);
      }
    }
  }
  const cursor = tree.cursor();
  do {
    if (cursor.node.type.isError || cursor.type.isSkipped) {
      continue;
    } else {
      // Skip prologue since we've already
      if (cursor.node.name === "Prologue") {
        cursor.nextSibling();
      }
      if (cursor.node.name === "PNAME_NS" || cursor.node.name === "PNAME_LN") {
        const prefixLabel = state.doc.sliceString(cursor.node.from, cursor.node.to).split(":")[0];
        if (!prefixDefinitions.some((prefix) => prefixLabel === prefix)) {
          const actions: Action[] = [];
          for (const prefix of state.facet(OutsidePrefixes)) {
            if (prefix.prefixLabel === prefixLabel) {
              actions.push(getImportPrefixAction(prologue?.to || 0, prefix));
            }
          }
          actions.push(getUnknownPrefixAction(prologue?.to || 0, prefixLabel));
          diagnostics.push(getUnknownPrefixDiagnostic(cursor.node, actions));
        }
      }
    }
  } while (cursor.next());
  return diagnostics;
}

export default prefixLinter;

function getDuplicatePrefixDiagnostic(node: { from: number; to: number }): Diagnostic {
  return {
    from: node.from,
    to: node.to,
    severity: "warning",
    message: "Duplicate prefix definition",
    actions: [
      {
        name: "Remove",
        apply(view, from, to) {
          view.dispatch({ changes: { from: from, to: to + 1 } });
        },
      },
    ],
  };
}
function getUnknownPrefixDiagnostic(node: { from: number; to: number }, actions: Action[]): Diagnostic {
  return {
    from: node.from,
    to: node.to,
    severity: "error",
    message: "Unknown prefix definition",
    actions: actions,
  };
}

function getImportPrefixAction(injectAt: number, prefix: Prefix): Action {
  return {
    name: "Import prefix",
    apply(view, _from, _to) {
      view.dispatch({
        changes: {
          from: injectAt,
          insert: `${injectAt !== 1 ? "\n" : ""}prefix ${prefix.prefixLabel}: <${prefix.iri}>\n`,
        },
        userEvent: "input.complete",
      });
    },
  };
}

function getUnknownPrefixAction(injectAt: number, text: string): Action {
  return {
    name: "Define prefix",
    apply(view, _from, _to) {
      view.dispatch({
        changes: {
          from: injectAt,
          insert: `${injectAt !== 1 ? "\n" : ""}prefix ${text.split(":")[0]}: <\n`,
        },
        userEvent: "input.complete",
        selection: {
          anchor: injectAt + `\nPREFIX ${text.split(":")[0]}: <`.length,
        },
      });
    },
  };
}
