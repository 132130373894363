import { Autocomplete, debounce, TextField } from "@mui/material";
import { escapeRegExp, uniqBy } from "lodash-es";
import * as React from "react";
import { Controller, ControllerProps, useWatch } from "react-hook-form";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { useApplyPrefixes } from "../../../components/Prefixed";
import { ResourceData } from "./Types";

const IriField: React.FC<Props> = (props) => {
  const { name, control, range, required } = props;
  const applyPrefixes = useApplyPrefixes();
  const val = useWatch({ name: name, control: control });
  const [value, setValue] = React.useState<Item | null>(val);
  const [focussed, setFocussed] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly Item[]>([]);

  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/internal/sparql`,
    fromBrowser: true,
  });

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: "application/json" },
        body: new URLSearchParams({
          account: currentDs.owner.accountName,
          dataset: currentDs.name,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sparqlUrl, currentDs.owner.accountName, currentDs.name]
  );

  const debouncedQuery = React.useMemo(
    () =>
      debounce(
        (
          { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
          callback: (results?: readonly Item[]) => void
        ) => {
          searchTerm = escapeRegExp(searchTerm.replace(/"/g, "")).replace(/\\/g, "\\\\");
          sparql(
            `
            prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
            prefix skos: <http://www.w3.org/2004/02/skos/core#>
            prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
            prefix owl: <http://www.w3.org/2002/07/owl#>

            select
              ?id
              ((?label_t) as ?label)
              ((?description_t) as ?description)
            where {
              {
                select distinct ?id {
                  ?subType rdfs:subClassOf*  <${range}> .
                  ?id a ?subType .
                }
              }

              optional {
                ?id rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?label_t
              }

              filter (regex(?label_t, "${searchTerm}", "i") || regex(str(?id), "${searchTerm}", "i"))

              optional {
               ?id rdfs:comment|skos:definition ?description_t
              }
            }
            limit 10
            `,
            abortSignal
          )
            .then((results) => {
              callback(results);
            })
            .catch(() => {});
        },
        400
      ),
    [sparql, range]
  );

  React.useEffect(() => {
    if (!focussed) {
      return;
    }

    const abortController = new AbortController();
    let active = true;

    if (inputValue === (value?.label || applyPrefixes(value?.id || ""))) {
      setOptions(value ? [value] : []);
    }

    debouncedQuery({ searchTerm: inputValue, abortSignal: abortController.signal }, (results?: readonly Item[]) => {
      if (active) {
        let newOptions: readonly Item[] = [];

        if (value && !inputValue) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(uniqBy(newOptions, "id"));
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, debouncedQuery, focussed]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: "A value is required.",
      }}
      defaultValue={null}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          options={options}
          filterOptions={(x) => x}
          onChange={(_e, data) => onChange(data)}
          renderInput={(params) => (
            <TextField
              {...(params as any)}
              error={!!error}
              helperText={error?.message || (typeof rest.value !== "string" && rest.value?.description)}
              multiline
              required={required}
            />
          )}
          noOptionsText=""
          isOptionEqualToValue={(option: any, value: any) => {
            return option.id === value.id;
          }}
          getOptionLabel={(option: any) => {
            return option.label || applyPrefixes(option.id);
          }}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onFocus={() => {
            setFocussed(true);
          }}
          {...(rest as any)}
        />
      )}
    />
  );
};

interface Item {
  id: string;
  label?: string;
  description?: string;
}

export default IriField;

type Props = Pick<ControllerProps<ResourceData, `properties.${number}.value`>, "name" | "control"> & {
  range: string | undefined;
  required?: boolean;
};
