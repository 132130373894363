import getClassName from "classnames";
import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import FontAwesomeButton from "#components/FontAwesomeButton/index.tsx";
import { tokenToAuthGroup } from "#components/Forms/Token/tokenGroups.ts";
import { FontAwesomeIcon, FontAwesomeRoundIcon, SinkList } from "#components/index.ts";
import { Token } from "#reducers/tokens.ts";
import styles from "./style.scss";

namespace TokenList {
  export interface Props {
    className?: string;
    tokens: Token[];
    revokeTokenHandler: (tokenId: string) => any;
  }
}
namespace TokenListItem {
  export interface Props {
    token: Token;
    revokeTokenHandler: (tokenId: string) => any;
    className?: string;
  }
}
function formatTimeDifference(date: string) {
  return moment.duration(moment(date).diff(moment(), "minutes"), "minutes").humanize(true);
}
/**
 * To get moment to return values based on days we need to adjust the root locale which will cause problems for when we want the date difference in seconds
 *
 * @returns string A humanized string of the date based on dates
 */
function formatDateDifference(date: string) {
  const dateDifference = moment(date).diff(moment().startOf("day"), "days");
  if (dateDifference >= 0) return "today"; // Positive difference from the start of day means today
  if (dateDifference >= -1) return "yesterday"; //
  return moment.duration(dateDifference, "days").humanize(true);
}
function getLastAccessedText(lastAccessed?: string | null) {
  // Tokens before #3181
  if (lastAccessed === undefined) return "unknown";
  // Tokens which have never been used
  if (lastAccessed === null) return "never";
  return formatDateDifference(lastAccessed);
}
const TokenListItem: React.FC<TokenListItem.Props> = (props) => {
  const { token } = props;
  const tokenGroup = tokenToAuthGroup(token);
  if (!tokenGroup) return null;
  return (
    <div className={getClassName(styles.tokenItem, props.className, "px-4")}>
      <FontAwesomeRoundIcon
        className={getClassName(styles.tokenAuthIcon, "mr-2")}
        icon={tokenGroup.mainField.icon}
        aria-label=""
      />
      <div className={styles.tokenContent}>
        <p className={getClassName(styles.tokenItemName, "mb-2")}>
          {token.description}
          <span className={styles.tokenAuthLabel}> - {tokenGroup.mainField.label}</span>
        </p>
        <div>
          <div className={styles.tokenItemIssuedAt}>
            <span className={styles.tokenItemIssuedTitle}>Created:</span> {formatTimeDifference(token.issuedAt)}
          </div>
          <div className={styles.tokenItemIssuedAt}>
            <span className={styles.tokenItemIssuedTitle}>Last used:</span> {getLastAccessedText(token.lastAccessed)}
          </div>
        </div>
      </div>
      <div className={styles.tokenItemBtns}>
        <Link className={styles.editToken} to={"./tokens/" + token.tokenId} title="Edit">
          <FontAwesomeIcon icon="pencil" />
        </Link>
        <FontAwesomeButton onClick={() => props.revokeTokenHandler(token.tokenId)} title="Delete/revoke" icon="times" />
      </div>
    </div>
  );
};

const TokenList: React.FC<TokenList.Props> = (props) => {
  const { className, tokens } = props;
  return (
    <SinkList className={className}>
      {tokens &&
        tokens.map((token) => (
          <TokenListItem key={token.tokenId} token={token} revokeTokenHandler={props.revokeTokenHandler} />
        ))}
    </SinkList>
  );
};
export default TokenList;
