import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { Button } from "#components/index.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import styles from "./style.scss";

interface Props {
  enabledOauth: boolean;
  passwordSignup: boolean;
}

const LoginButtons: React.FC<Props> = (props) => {
  const location = useLocation();
  const currentLocation = location.pathname + location.search + location.hash;
  const onLoginPage = /^\/login\/?$/i.test(location.pathname);
  const onRegisterPage = /^\/register\/?$/i.test(location.pathname);
  const onAuthPage = /^\/(login|register|auth)(\/?|\/.+)$/i.test(location.pathname);
  const history = useHistory();
  return (
    <>
      <div className={styles.authBtnWrapper}>
        <Button
          role="link"
          className={styles.login}
          disabled={onLoginPage}
          elevation={!onLoginPage}
          onClick={() => {
            history.push({
              pathname: "/login",
              search: onAuthPage
                ? undefined
                : stringifyQuery({
                    returnTo: currentLocation,
                  }),
            });
          }}
        >
          Login
        </Button>
        {(props.passwordSignup || props.enabledOauth) && (
          <Button
            className={styles.register}
            role="link"
            color="secondary"
            disabled={onRegisterPage}
            elevation={!onRegisterPage}
            onClick={() => {
              history.push({
                pathname: "/register",
                search: onAuthPage
                  ? undefined
                  : stringifyQuery({
                      returnTo: currentLocation,
                    }),
              });
            }}
          >
            Register
          </Button>
        )}
      </div>
    </>
  );
};

export default LoginButtons;
