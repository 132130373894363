import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { asyncConnect } from "redux-connect";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import * as Forms from "#components/Forms/index.ts";
import {
  AccountMetadata,
  Button,
  Dialog,
  ErrorPage,
  FlexContainer,
  OrgMemberListItem,
  SinkList,
} from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { Member, Org } from "#reducers/accountCollection.ts";
import { accountIsCurrentAccount, getAccountInfo, getCurrentAccount } from "#reducers/app.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";
import {
  addMember,
  changeRole,
  disallowedToChangeRoleOf,
  disallowedToRemoveMemberFromOrg,
  removeMemberFromOrg,
} from "#reducers/orgs.ts";

namespace OrgMemberList {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    leaveOrganization: DispatchedFn<typeof removeMemberFromOrg>;
    changeRole: DispatchedFn<typeof changeRole>;
    addMember: DispatchedFn<typeof addMember>;
    pushState: typeof connectedReactRouter.push;
    goBack: typeof connectedReactRouter.goBack;
  }
  export interface PropsFromState {
    currentOrg?: Org;
    showForm: boolean; //part of location state
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

const OrgMemberList: React.FC<OrgMemberList.Props> = ({
  currentOrg,
  location,
  goBack,
  pushState,
  changeRole,
  leaveOrganization,
  addMember,
}) => {
  const acl = useAcl();
  const constructUrlToApi = useConstructUrlToApi();
  if (!currentOrg) return <ErrorPage statusCode={404} />;
  if (
    !acl.check({ action: "editAccountMetadata", context: { roleInAccount: acl.getRoleInAccount(currentOrg) } }).granted
  ) {
    return <ErrorPage statusCode={401} />;
  }
  const canManageMembers = acl.check({
    action: "manageOrganizationMembers",
    context: { roleInOrganization: acl.getRoleInAccount(currentOrg) },
  }).granted;
  if (currentOrg.members && currentOrg.members.length > 0) {
    return (
      <FlexContainer className="pt-3 pb-7">
        <AccountMetadata
          currentAccount={currentOrg}
          currentPath={location.pathname}
          title={"Members - Organization settings"}
        />

        {canManageMembers && (
          <Button
            color="secondary"
            className="ml-3 mt-3"
            elevation
            onClick={() => {
              pushState({ state: { memberAddModalShown: true } });
            }}
          >
            Add member
          </Button>
        )}
        <SinkList noContentMsg="This organization has no members">
          {currentOrg.members.map((m) => {
            return (
              <OrgMemberListItem
                key={m.user.accountName}
                member={m}
                changeRoleHandler={
                  canManageMembers
                    ? (member: Member, role: Models.OrgRole) => {
                        return changeRole(currentOrg, member, role);
                      }
                    : undefined
                }
                changeRoleDisabled={disallowedToChangeRoleOf(currentOrg, m)}
                removeMemberHandler={
                  canManageMembers
                    ? (member: Member) => {
                        return leaveOrganization(member.user, currentOrg);
                      }
                    : undefined
                }
                removeMemberDisabled={disallowedToRemoveMemberFromOrg(currentOrg, m)}
              />
            );
          })}
        </SinkList>
        <Dialog
          open={!!location.state && !!location.state.memberAddModalShown}
          onClose={goBack}
          title="Add member"
          maxWidth="lg"
          fullWidth
        >
          <Forms.OrgMemberAdd
            className="m-5"
            onSubmit={(values: Forms.OrgMemberAdd.FormData) => {
              return addMember(currentOrg, values.name, values.role).then(goBack, (e: any) => {
                throw new ReduxForm.SubmissionError({ _error: e.message });
              });
            }}
            accountsUrl={constructUrlToApi({ pathname: "/accounts" })}
            cancel={goBack}
          />
        </Dialog>
      </FlexContainer>
    );
  }
  return <ErrorPage title="No members" message={`Organization ${currentOrg.accountName} has no members`} />;
};

export default connect<
  OrgMemberList.PropsFromState,
  { [K in keyof OrgMemberList.DispatchProps]: any },
  OrgMemberList.OwnProps,
  GlobalState
>(
  (state, ownProps) => {
    const currentAccount = getCurrentAccount(state);
    return {
      currentOrg: currentAccount && currentAccount.type === "org" ? currentAccount : undefined,
      showForm: !!ownProps.location?.state?.showForm,
    };
  },
  //dispatch
  {
    leaveOrganization: removeMemberFromOrg,
    changeRole: changeRole,
    addMember: addMember,
    pushState: connectedReactRouter.push,
    goBack: connectedReactRouter.goBack,
  }
)(
  asyncConnect<GlobalState>([
    {
      promise: ({ match: { params }, store: { dispatch, getState } }) => {
        if (!accountIsCurrentAccount(getState(), params.account)) {
          return dispatch<any>(getAccountInfo(getState(), params.account));
        }
      },
    },
  ])(OrgMemberList) as typeof OrgMemberList
);
