import { compact, groupBy, uniq, uniqBy } from "lodash-es";
import { getPrefixed, getPrefixInfoFromIri, Prefix } from "@triply/utils/prefixUtils.js";

export interface ShortIriDict {
  [key: string]: string;
}

export default function getShortIriDict(uniqueIris: string[], prefixes: Prefix[]): ShortIriDict {
  // Filter out IRI with prefixes
  const uniqueNonPrefixedIris = uniqueIris.filter((iri) => getPrefixed(iri, prefixes) === undefined);
  const map: ShortIriDict = {};
  // Captures anything after / or #
  const regex = /[^\/#]+[\/#]?|\//g;
  // Prepare list
  let list = uniqueNonPrefixedIris.map((iri) => {
    const matches = iri.match(regex);
    // If we can't match,
    if (matches === null) {
      return { original: iri, localName: iri };
    }
    const segment = matches?.pop();
    return { original: iri, iri: matches.join(""), localName: segment };
  });
  do {
    const groups = groupBy(list, (iri) => iri.localName);
    const newList = [];
    for (const grouped of Object.values(groups)) {
      if (grouped.length === 1) {
        const unique = grouped[0];
        map[unique.original] = unique.localName || "";
      } else {
        newList.push(
          ...grouped.map((iri) => {
            const matches = iri.iri!.match(regex);
            if (matches === null || matches.length === 3) {
              return { original: iri.original, localName: iri.original };
            } else {
              const newSegment = matches.pop();
              return {
                original: iri.original,
                iri: matches.join(""),
                localName: [newSegment, iri.localName].join(""),
              };
            }
          })
        );
      }
    }
    list = newList;
  } while (list.length > 0);
  return map;
}
