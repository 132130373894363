import { HierarchyNode, HierarchyRectangularNode, pointer, select, Selection, treemap } from "d3";
import React from "react";
import {
  addShadowFilter,
  BreadCrumbs,
  catchErrors,
  ClassHierarchyComponentProps,
  Datum,
  formatInstances,
  getLabel,
  stratify,
  useRenderRef,
} from "./helpers.tsx";
import styles from "./style.scss";

const TreeMap: React.FC<ClassHierarchyComponentProps> = ({ classHierarchyTree, focussedNode, setFocus }) => {
  const ref = useRenderRef(drawTreeMap, classHierarchyTree, focussedNode, setFocus);
  return (
    <div ref={ref} className={styles.treeMap}>
      <BreadCrumbs type="treemap" focussedNode={focussedNode} />
    </div>
  );
};

export default TreeMap;

const drawTreeMap = catchErrors(function (
  divElement: HTMLDivElement,
  classHierarchyTree: HierarchyNode<Datum>,
  focussedNode: HierarchyNode<Datum>,
  setFocus: (className: string) => void
) {
  const width = Math.max(divElement.offsetWidth, 300);
  const height = width;

  const transitionDuration = 500;

  const subTree =
    focussedNode === classHierarchyTree
      ? classHierarchyTree
      : stratify<Datum>(
          focussedNode.descendants().map((d, i) => (i === 0 ? { ...d.data, parent: undefined } : d.data))
        );

  const subTreeMap = treemap<Datum>().size([width, height]).paddingOuter(10).paddingTop(24).paddingInner(4)(subTree);

  const svg = select(divElement)
    .selectAll<SVGSVGElement, number>("svg")
    .data([0])
    .join((enter) => {
      return addShadowFilter(enter.append("svg"));
    })
    .attr("width", width)
    .attr("height", height);

  function addHover(s: Selection<any, HierarchyRectangularNode<Datum>, Element, any>) {
    const mouseOffsetX = 20;
    const mouseOffsetY = 30;
    const labelHeight = 46;
    return s
      .on("mouseover", function (event, d) {
        const svgNode = svg.node();
        if (!svgNode) return;
        const [mouseX, mouseY] = pointer(event, svgNode);
        const g = svg
          .append("g")
          .classed(styles.hoverLabel, true)
          .attr("transform", () => "translate(" + [mouseX + mouseOffsetX, mouseY + mouseOffsetY] + ")");

        const firstLineLength =
          g
            .append("text")
            .text(() => getLabel(d) + ` (${formatInstances(d.value)})`)
            .attr("dx", 6)
            .attr("dy", 18)
            .node()
            ?.getComputedTextLength() || 0;

        const secondLineLength =
          g
            .append("text")
            .text(() =>
              d.data.prefixInfo?.prefixLabel
                ? `${d.data.prefixInfo.prefixLabel}:${d.data.prefixInfo.localName}`
                : d.id || ""
            )
            .attr("dx", 6)
            .attr("dy", 38)
            .node()
            ?.getComputedTextLength() || 0;

        g.append("rect")
          .attr("width", () => Math.max(firstLineLength, secondLineLength) + 12)
          .attr("height", labelHeight)
          .style("filter", "url(#drop-shadow)")
          .lower();
      })
      .on("mousemove", function (event) {
        const svgNode = svg.node();
        if (!svgNode) return;
        const [mouseX, mouseY] = pointer(event, svgNode);
        const g = svg.select(`.${styles.hoverLabel}`);
        const labelWidth = g?.select<SVGRectElement>("rect").node()?.getBBox().width || 0;
        const offsetX = mouseX + mouseOffsetX + labelWidth > width - 5 ? width - 5 - mouseX - labelWidth : mouseOffsetX;
        const offsetY = mouseY + mouseOffsetY + labelHeight > height - 5 ? -labelHeight - 20 : mouseOffsetY;
        g.attr("transform", () => "translate(" + [mouseX + offsetX, mouseY + offsetY] + ")");
      })
      .on("mouseout", () => {
        svg.selectAll(`.${styles.hoverLabel}`).remove();
      });
  }

  function computeFontSize(this: SVGTextElement, d: HierarchyRectangularNode<Datum>) {
    //make sure we measure the size using the regular font size
    this.style.fontSize = "1em";
    const availableWidth = d.x1 - d.x0;
    const availableHeight = d.y1 - d.y0;
    if (availableWidth <= 0 || availableHeight <= 0) return "0em";
    const usedWidth = this.getComputedTextLength();
    const fontSizeBasedOnWidth = Math.min(1, (0.9 * availableWidth) / usedWidth);
    //measure the height using the new font size
    this.style.fontSize = `${fontSizeBasedOnWidth}em`;
    const fontSizeBasedOnHeight = availableHeight / this.getBBox().height;
    return `${Math.min(fontSizeBasedOnWidth, fontSizeBasedOnHeight)}em`;
  }

  svg
    .selectAll<SVGGElement, HierarchyRectangularNode<Datum>>("g.node")
    .data(subTreeMap.descendants(), (d: HierarchyRectangularNode<Datum>) => d.id || "")
    .join(
      (enter) => {
        const newG = enter
          .append("g")
          .classed("node", true)
          .attr("transform", (d) => "translate(" + [d.x0, d.y0] + ")")
          .style("opacity", 0);

        newG
          .append("rect")
          .attr("fill", (d) => d.data.color || "black")
          .attr("width", (d) => d.x1 - d.x0)
          .attr("height", (d) => d.y1 - d.y0)
          .on("click", function (_event, d) {
            setFocus(d.data.name);
          })
          .call(addHover);

        newG
          .append("text")
          .text(getLabel)
          .attr("y", "0.25em")
          .style("fill", (d) => (d === classHierarchyTree ? "#555555" : "white"))
          .style("font-size", computeFontSize)
          .attr("dx", function (d) {
            const availableWidth = d.x1 - d.x0;
            const usedWidth = this.getComputedTextLength();
            return `${Math.min(6, (availableWidth - usedWidth) / 2)}px`;
          })
          .attr("dy", function (d) {
            return `${Math.min(1.3 * this.getBBox().height, d.y1 - d.y0) / 2}px`;
          })
          .on("click", function (_event, d) {
            setFocus(d.data.name);
          })
          .call(addHover);
        return newG;
      },
      (update) => {
        update
          .select<SVGRectElement>("rect")
          .call(addHover)
          .transition()
          .duration(transitionDuration)
          .attr("width", (d) => d.x1 - d.x0)
          .attr("height", (d) => d.y1 - d.y0);

        update
          .select<SVGTextElement>("text")
          .call(addHover)
          .transition()
          .duration(transitionDuration)
          .style("font-size", computeFontSize)
          .attr("dx", function (d) {
            const availableWidth = d.x1 - d.x0;
            const usedWidth = this.getComputedTextLength();
            return `${Math.min(6, (availableWidth - usedWidth) / 2)}px`;
          })
          .attr("dy", function (d) {
            return `${Math.min(1.3 * this.getBBox().height, d.y1 - d.y0) / 2}px`;
          });
        return update;
      },
      (exit) => {
        exit.transition().duration(transitionDuration).style("opacity", 0).remove();
      }
    )
    .transition()
    .duration(transitionDuration)
    .attr("transform", (d) => "translate(" + [d.x0, d.y0] + ")")
    .style("opacity", 1);
});
