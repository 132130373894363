import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import styles from "./style.scss";

export namespace FontAwesomeRoundIcon {
  export interface Props {
    icon: FontAwesomeIcon.Props["icon"];
    "aria-label": string; // Enforce aria-label
    className?: string;
    title?: string;
    style?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    size?: "sm" | "md" | "lg"; // lg is default
  }
}

const FontAwesomeRoundIcon: React.FC<FontAwesomeRoundIcon.Props> = (props) => {
  if (props.onClick) {
    return (
      <button
        aria-label={props["aria-label"]}
        onClick={props.onClick}
        title={props.title}
        style={props.style}
        className={getClassName(styles.icon, props.className, styles[props.size || "lg"])}
      >
        <FontAwesomeIcon style={props.iconStyle} fixedWidth className={styles.faIcon} icon={props.icon} />
      </button>
    );
  } else {
    return (
      <div
        aria-label={props["aria-label"]}
        role="img"
        title={props.title}
        style={props.style}
        className={getClassName(styles.icon, props.className, styles[props.size || "lg"])}
      >
        <FontAwesomeIcon style={props.iconStyle} fixedWidth className={styles.faIcon} icon={props.icon} />
      </div>
    );
  }
};

export default FontAwesomeRoundIcon;
