import { InputAdornment, Typography } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { InjectedFormProps } from "redux-form";
import FontAwesomeButton from "#components/FontAwesomeButton/index.tsx";
import { FormField, MuiTextFieldRedux } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import styles from "./style.scss";

const toSlug = (name: string) => {
  if (!name) return "";
  let result = name.replace(/ /g, "-");
  result = result.replace(/[^A-Za-z0-9-]/g, "");
  if (result.length < 2) result += "-".repeat(2 - result.length);
  return result.slice(0, 40);
};

const NameField: React.FC<MuiTextFieldRedux.WrappedProps> = (props) => {
  return (
    <MuiTextFieldRedux
      input={props.input}
      meta={props.meta}
      autoFocus={props.autoFocus}
      formIsPristine={props.formIsPristine}
      fullWidth
      inputProps={{ id: "name-field" }}
    />
  );
};

const SlugField: React.FC<
  MuiTextFieldRedux.WrappedProps & {
    open: boolean;
    setOpen: () => void;
    urlPrefix: string;
    disabledWithMessage?: string;
    label: string;
  }
> = (props) => {
  const { submitFailed, error } = props.meta;
  React.useEffect(() => {
    if (submitFailed && error) {
      props.setOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitFailed, error]);

  return (
    <>
      {props.input.value && !props.open && (
        <FontAwesomeButton
          icon={["fas", "pencil"]}
          title={`Edit the ${props.label}`}
          iconStyle={{ fontSize: 12, marginLeft: 5 }}
          className={styles.editSlug}
          onClick={() => props.setOpen()}
        >
          {props.urlPrefix + props.input.value}
        </FontAwesomeButton>
      )}
      {props.open && (
        <MuiTextFieldRedux
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={styles.adornment}>
                <Typography variant="inherit" color="textSecondary" component="div">
                  <span>{props.urlPrefix}</span>
                </Typography>
              </InputAdornment>
            ),
          }}
          leftAlignStartAdornment
          input={props.input}
          meta={props.meta}
          autoFocus
          fullWidth
          disabled={!!props.disabledWithMessage}
          helperText={props.disabledWithMessage}
          inputProps={{ id: "slug-field" }}
        />
      )}
    </>
  );
};

interface Props {
  formIsPristine: boolean;
  changeFormValues: InjectedFormProps["change"];
  initialSlug: string | undefined;
  autoFocus: boolean;
  nameFieldName: string;
  nameFieldLabel: string;
  slugFieldName: string;
  slugFieldLabel: string;
  className?: string;
  urlPrefixPath: string;
  disableSlugField?: string;
  breakPoint?: number;
}

const NameAndUrl: React.FC<Props> = (props) => {
  const [showSlugField, setShowSlugField] = React.useState(false);
  const constructConsoleUrl = useConstructConsoleUrl();

  const autoFillSlug = !props.initialSlug && !showSlugField;

  return (
    <div className={getClassName(props.className)}>
      <FormField label={props.nameFieldLabel} inputId="name-field" breakPoint={props.breakPoint}>
        <ReduxForm.Field
          name={props.nameFieldName}
          component={NameField}
          onChange={(_: any, value: string) => {
            if (autoFillSlug) props.changeFormValues(props.slugFieldName, toSlug(value));
          }}
          formIsPristine={props.formIsPristine}
          autoFocus={props.autoFocus}
        />
      </FormField>

      <FormField
        label={showSlugField ? props.slugFieldLabel : undefined}
        className={getClassName(showSlugField && "mt-5")}
        inputId="slug-field"
        breakPoint={props.breakPoint}
      >
        <ReduxForm.Field
          name={props.slugFieldName}
          component={SlugField}
          label={props.slugFieldLabel}
          open={showSlugField}
          setOpen={() => setShowSlugField(true)}
          urlPrefix={constructConsoleUrl({ pathname: props.urlPrefixPath })}
          disabledWithMessage={props.disableSlugField}
        />
      </FormField>
    </div>
  );
};

export default NameAndUrl;
