import { isEqual } from "lodash-es";
import memoizee from "memoizee";
import * as React from "react";
import { Prefix } from "@triply/utils";
import { extractQueryPrefixes } from "../SparqlUtils.ts";
import { Props as EditorProps } from "./Editor.tsx";
import SparqlEditor from "./index.tsx";

interface EditorContext {
  getQueryString: () => string;
  setQueryString?: (newQueryString: string) => void;
  dirty: boolean;
  getPrefixesFromQuery?: () => Prefix[];
  setPrefixesFromQuery?: (newList: Prefix[]) => void;
  variablesInQuery?: string[];
  setVariablesInQuery?: (newList: string[]) => void;
  valid?: boolean;
  setValid?: (newValue: boolean) => void;
}

const EditorContext = React.createContext<EditorContext>({
  getQueryString: () => {
    throw new Error("Missing provider for SparqlQueryContext");
  },
  dirty: false,
});

export const EditorContextProvider: React.FC<{ initialQueryString: string; children: any }> = ({
  initialQueryString,
  children,
}) => {
  const queryString = React.useRef(initialQueryString);
  const [dirty, setDirty] = React.useState(false);
  const setQueryString = React.useCallback(
    (query: string) => {
      queryString.current = query;
      setDirty(query !== initialQueryString);
    },
    [initialQueryString]
  );
  const getQueryString = React.useCallback(() => queryString.current, []);

  const queryPrefixes = React.useRef(extractQueryPrefixes(initialQueryString));
  const setQueryPrefixes = React.useCallback((prefixes: Prefix[]) => {
    queryPrefixes.current = prefixes;
  }, []);
  const getQueryPrefixes = React.useCallback(() => queryPrefixes.current, []);

  const [queryVariables, setInternalQueryVariables] = React.useState<string[]>([]);
  const setQueryVariables = React.useCallback((variables: string[]) => {
    setInternalQueryVariables((currentValue) => {
      if (isEqual(currentValue, variables)) return currentValue;
      return variables;
    });
  }, []);
  const [valid, setValid] = React.useState<boolean>();

  return (
    <EditorContext.Provider
      value={{
        getQueryString: getQueryString,
        setQueryString: setQueryString,
        getPrefixesFromQuery: getQueryPrefixes,
        setPrefixesFromQuery: setQueryPrefixes,
        setVariablesInQuery: setQueryVariables,
        variablesInQuery: queryVariables,
        valid: valid,
        setValid: setValid,
        dirty: dirty,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
export const ContextualEditor: React.FC<Omit<EditorProps, "onChange">> = (props) => {
  const { setQueryString, setPrefixesFromQuery, setValid, setVariablesInQuery } = React.useContext(EditorContext);
  if (!setQueryString || !setPrefixesFromQuery || !setValid || !setVariablesInQuery)
    throw new Error("Contextual query editor used without SparqlQueryContextProvider");
  const onChange = React.useCallback(
    (values: { query: string; valid: boolean; prefixes: Prefix[] }) => {
      setQueryString(values.query);
      setPrefixesFromQuery(values.prefixes);
      setValid(values.valid);
    },
    [setQueryString, setPrefixesFromQuery, setValid]
  );
  return <SparqlEditor {...props} onChange={onChange} onVariablesChange={setVariablesInQuery} />;
};

export const useContextualEditor = () => {
  const { getQueryString, getPrefixesFromQuery, dirty, variablesInQuery, valid } = React.useContext(EditorContext);
  return { getQueryString, dirty, getPrefixesFromQuery, variablesInQuery, valid };
};
