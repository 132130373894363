import Masonry from "@mui/lab/Masonry";
import { Alert, CardActionArea, CardContent, CardHeader, CardMedia } from "@mui/material";
import Card from "@mui/material/Card";
import getClassname from "classnames";
import { union } from "lodash-es";
import * as React from "react";
import { Binding, isSelectResponse, SparqlResponse } from "#components/Sparql/SparqlUtils.ts";
import ExampleQuery from "../../ExampleQuery";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { EmptyResults, SanitizedContent } from "../displayUtils";
import ErrorResponse from "../Error";
import styles from "./styles.scss";

const EXAMPLE_QUERY = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix def: <https://triplydb.com/Triply/vocab/def/>
select * where {
  values (?widget ?widgetLabel ?widgetImage) { # Replace ?widget with ?markup to ignore the gallery styling
    ("Normal text"@en "Title" "https://triplydb.com/imgs/logos/logo.svg?v=4")
    ("<ol><li>You</li><li>also</li><li>use</li><li>HTML</li></ol>"^^rdf:HTML "HTML" "") # The datatype of a literal is used to determine how the result can be rendered
    ("""graph LR
    Sub --> Pred
    Pred --> Obj
    """^^def:mermaid "A mermaid diagram" "")
    ("""# Or
You *can* just use **Markdown**
    """^^def:markdown "" "")
  }
}`;

// Accepted Widget variables for the Gallery
const GALLERY_WIDGET_VARIABLES = [
  "widget",
  "widgetLabel",
  "widgetLabelLink",
  "widgetImage",
  "widgetImageLink",
  "widgetImageCaption",
  "widgetImageDescription",
  "widgetDescription",
];

// Accepted widget variables for Gallery single view format (non gallery)
const SINGLE_PAGE_VARIABLES = ["widgetSingle", "markup"];

const GALLERY_VARIABLES = union(GALLERY_WIDGET_VARIABLES, SINGLE_PAGE_VARIABLES);

// Checking if SparqlReponse is a Select Result and if that data is compliant with Gallery
function hasGalleryItems(data: SparqlResponse | undefined): boolean {
  if (!isSelectResponse(data)) {
    return false;
  }
  return hasGalleryVariables(data.head.vars);
}

function hasGalleryVariables(vars: string[]): boolean {
  return vars.some((variable) => GALLERY_VARIABLES.includes(variable));
}

// Public function for data validity checking for Gallery
export function canDraw(data: SparqlResponse | undefined): boolean {
  return hasGalleryItems(data) !== undefined;
}

export interface Props {
  minimizeColumns?: boolean;
  reduceSpacing?: boolean;
}

const Gallery: React.FC<Props> = ({ minimizeColumns = false, reduceSpacing = false }) => {
  const { results, isSelect, emptyResults, noResults, variables } = useSparqlResults();
  const { registerDownload, unregisterDownload, setVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (registerDownload && !emptyResults) {
      if (containerRef.current) {
        registerDownload({
          id: "Gallery",
          displayName: "HTML",
          extension: ".html",
          getDataUrl: () =>
            window.URL.createObjectURL(new Blob([containerRef.current!.innerHTML], { type: "text/html" })),
        });
      }
      return () => {
        if (unregisterDownload) {
          unregisterDownload("Gallery");
        }
      };
    }
  }, [registerDownload, unregisterDownload, emptyResults]);

  if (noResults) return null;
  if (!isSelect) {
    return (
      <Alert severity="warning" role="alert">
        This visualization can only render with Select queries
      </Alert>
    );
  }
  if (emptyResults) {
    return <EmptyResults />;
  }
  if (!hasGalleryVariables(variables)) {
    if (!!setVisualizationConfig) {
      return (
        <Alert severity="warning" role="alert">
          Couldn't render the Gallery visualization. Check the documentation using the button at the right of the menu
          above for more information.{" "}
          <ExampleQuery name="Gallery example" query={EXAMPLE_QUERY} visualization="Gallery" />
        </Alert>
      );
    } else {
      return <ErrorResponse error={new Error("No bound gallery variables")} />;
    }
  }

  const markupWidgetSingleBindings = results
    .map((binding) => {
      if (Object.keys(binding).some((k) => SINGLE_PAGE_VARIABLES.includes(k))) return binding;
    })
    .filter((binding) => binding && binding !== undefined);

  if (markupWidgetSingleBindings && markupWidgetSingleBindings.length > 0) {
    return (
      <>
        {markupWidgetSingleBindings.map((binding, index) => {
          if (binding && binding !== undefined) {
            if (binding["widgetSingle"]) {
              return (
                <div key={index} ref={containerRef}>
                  <SanitizedContent term={binding["widgetSingle"]} />
                </div>
              );
            } else if (binding["markup"]) {
              return (
                <div key={index} ref={containerRef}>
                  <SanitizedContent term={binding["markup"]} />
                </div>
              );
            } else {
              return null;
            }
          }
          return null;
        })}
      </>
    );
  } else {
    const widgets = results
      .map((binding, index) => {
        if (!Object.keys(binding).some((key) => GALLERY_WIDGET_VARIABLES.includes(key))) {
          return null;
        }
        return (
          <Card key={`${index}-${Math.random()}`} className="shadow" variant="outlined">
            <GalleryCardHeader binding={binding} />
            <GalleryCardImage binding={binding} />
            {binding["widgetDescription"] && (
              <CardContent className={styles.galleryCardContent}>
                <SanitizedContent term={binding["widgetDescription"]} />
              </CardContent>
            )}
            {binding["widget"] && (
              <CardContent className={styles.galleryCardContent}>
                <CardMedia>
                  <SanitizedContent term={binding["widget"]} />
                </CardMedia>
              </CardContent>
            )}
          </Card>
        );
      })
      .filter((widget) => !!widget);
    if (widgets.length === 0) {
      return (
        <Alert severity="info" role="alert">
          No content
        </Alert>
      );
    }
    return (
      <div
        ref={containerRef}
        className={getClassname(styles.gallery, {
          ["px-3"]: minimizeColumns,
        })}
      >
        <Masonry
          columns={minimizeColumns ? { xs: 1, sm: 1, lg: 2, xl: 2 } : { xs: 1, sm: 2, lg: 3, xl: 4 }}
          spacing={reduceSpacing ? undefined : 2}
          className="mx-3 mt-3"
        >
          {widgets}
        </Masonry>
      </div>
    );
  }
};

const GalleryCardHeader: React.FC<{ binding: Binding }> = ({ binding }) => {
  if (binding["widgetLabel"]) {
    if (binding["widgetLabelLink"]) {
      return (
        <CardActionArea href={binding.widgetLabelLink.value}>
          <CardHeader title={binding.widgetLabel.value} />
        </CardActionArea>
      );
    } else {
      return <CardHeader title={binding.widgetLabel.value} />;
    }
  }
  return null;
};

const GalleryCardImage: React.FC<{ binding: Binding }> = ({ binding }) => {
  if (binding["widgetImage"]) {
    if (binding["widgetImageLink"]) {
      return (
        <figure>
          <CardActionArea href={binding.widgetImageLink.value} className={styles.widgetImageLink}>
            <CardMedia
              component="img"
              src={binding.widgetImage.value}
              title={binding["widgetImageDescription"] ? binding.widgetImageDescription.value : undefined}
            />
          </CardActionArea>
          {binding["widgetImageCaption"] && (
            <CardContent component={"figcaption"} className={styles.galleryFigureCaption}>
              <SanitizedContent term={binding["widgetImageCaption"]} />
            </CardContent>
          )}
        </figure>
      );
    } else {
      return (
        <figure>
          <CardMedia
            component="img"
            src={binding.widgetImage.value}
            title={binding["widgetImageDescription"] ? binding.widgetImageDescription.value : undefined}
          />
          {binding["widgetImageCaption"] && (
            <CardContent className={styles.galleryCardContent}>
              <SanitizedContent term={binding["widgetImageCaption"]} />
            </CardContent>
          )}
        </figure>
      );
    }
  }
  return null;
};

export default React.memo(Gallery);
