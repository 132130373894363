import getClassName from "classnames";
import { push } from "connected-react-router";
import * as React from "react";
import { Models } from "@triply/utils";
import { Alert, Avatar, Button, Dialog, FontAwesomeIcon } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { Account, User } from "#reducers/accountCollection.ts";
import { copyDataset } from "#reducers/datasetManagement.ts";
import styles from "./style.scss";

namespace DatasetCopyModal {
  export interface Props {
    authenticatedUser: User;
    currentDs: Models.DatasetPublic;
    children: React.ReactNode;
  }
}

const DatasetCopyModal: React.FC<DatasetCopyModal.Props> = ({ authenticatedUser, children, currentDs }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [toAccount, setToAccount] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const dispatch = useDispatch();
  const close = () => {
    setShowModal(false);
    setToAccount(undefined);
  };
  const submit = (accountName: string) => {
    if (!toAccount) {
      setToAccount(accountName);
      dispatch<typeof copyDataset>(copyDataset(currentDs, accountName)).then(
        (res) => {
          dispatch(push(`/${res.body.owner.accountName}/${res.body.name}`));
        },
        (err: Error) => setError(err.message)
      );
    }
  };
  const handleClick = () => {
    if (!authenticatedUser?.orgs?.length && authenticatedUser.accountName) submit(authenticatedUser.accountName);
    setShowModal(true);
  };
  if (!authenticatedUser) return null;
  const accounts = [authenticatedUser as Account].concat(authenticatedUser.orgs || []);
  return (
    <div>
      <div
        className={styles.datasetButton}
        onClick={handleClick}
        onKeyPress={(e) => {
          const key = e.key || e.keyCode;
          if (key === "Space" || key === "Enter") {
            handleClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>

      <Dialog open={showModal} onClose={close} title="Copy dataset">
        <div className="px-5 pb-5">
          {!toAccount && accounts.length > 1 && (
            <h5 className="mt-0 mb-3">Select the account to copy the dataset to:</h5>
          )}
          {!toAccount && accounts.length > 1 && (
            <div className={getClassName(styles.accounts, "py-3")}>
              {accounts.map((account) => {
                const accountName = account.accountName;
                if (!accountName) return null;
                return (
                  <div
                    key={account.accountName}
                    className={getClassName(styles.account, "p-2")}
                    onClick={() => submit(accountName)}
                    role="button"
                    tabIndex={0}
                  >
                    <Avatar
                      size="lg"
                      avatarName={accountName}
                      avatarUrl={account.avatarUrl}
                      title={account.accountName}
                      alt={accountName}
                    />
                    <div className={getClassName(styles.accountName, "mt-2")}>{account.accountName}</div>
                  </div>
                );
              })}
            </div>
          )}
          {!error && toAccount && (
            <div>
              <FontAwesomeIcon icon={["fas", "cog"]} spin /> {`Copying dataset to ${toAccount}`}
            </div>
          )}
          {toAccount && <Alert transparent message={error} className="mt-3 mb-5" />}
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default DatasetCopyModal;
