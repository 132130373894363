import { IconButton, Paper, Table, TableContainer, Toolbar, Tooltip, Typography } from "@mui/material";
import {
  CellContext,
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { capitalize } from "lodash-es";
import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import formatNumber from "@triply/utils-private/formatting.js";
import IdeToolTipButton from "#components/IdeTooltipButton/index.tsx";
import { Circle, FontAwesomeIcon, LinkButton } from "#components/index.ts";
import { TableFooter } from "#components/ReactTableUtils/index.ts";
import ReactTableBody from "#components/ReactTableUtils/TableBody.tsx";
import TableHeader from "#components/ReactTableUtils/TableHeader.tsx";
import fetch from "#helpers/fetch.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import { TableHumanizedDateRenderer } from "../../components/ReactTableUtils/DateRenderer";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import { useCurrentAccount } from "../../reducers/app";
import styles from "./style.scss";
import tableStyles from "#components/ReactTableUtils/tableStyle.scss";

const SourceDatasetLinkRenderer: React.FC<CellContext<Models.QueryJob, unknown>> = ({ row }) => {
  const dataset = row.original.sourceDatasetName;
  const datasetOwner = row.original.sourceDatasetOwner;
  if (dataset && datasetOwner) {
    return <Link to={`/${datasetOwner}/${dataset}`}>{`${datasetOwner}/${dataset}`}</Link>;
  }
  return null;
};

const TargetDatasetLinkRenderer: React.FC<CellContext<Models.QueryJob, unknown>> = ({ row }) => {
  const dataset = row.original.targetDatasetName;
  const datasetOwner = row.original.targetDatasetOwner;
  if (dataset && datasetOwner) {
    return <Link to={`/${datasetOwner}/${dataset}/graphs`}>{`${datasetOwner}/${dataset}`}</Link>;
  }
  return null;
};

export function getCircleColor(status: Models.QueryJobStatus | Models.PipelineStatus) {
  switch (status) {
    case "finished":
      return "green";
    case "error":
      return "red";
    case "running":
    case "resultsReady":
    case "servingResults":
    case "importing":
      return "orange";
    case "pending":
    case "cancelled":
    default:
      return "default";
  }
}

const StatusRenderer: React.FC<CellContext<Models.QueryJob, unknown>> = ({ row }) => {
  let statusText: Models.QueryJobStatus = row.original.status;
  return (
    <div className="flex">
      <Circle color={getCircleColor(statusText)} />
      <div className={"pl-2"} style={{ wordBreak: "keep-all" }}>
        {capitalize(statusText)}
      </div>
    </div>
  );
};

const PipelineStatus: React.FC<CellContext<Models.QueryJob, unknown>> = ({
  row: {
    original: { pipelineStatus },
  },
}) => {
  return (
    <div className="flex">
      <Circle color={getCircleColor(pipelineStatus)} />
      <div className={"pl-2"} style={{ wordBreak: "keep-all" }}>
        {capitalize(pipelineStatus)}
      </div>
    </div>
  );
};

const ActionsRenderer: React.FC<CellContext<Models.QueryJob, any>> = ({ row }) => {
  const acl = useAcl();
  const confirm = useConfirmation();
  const currentAccount = useCurrentAccount();
  const cancelUrl = useConstructUrlToApi()({
    pathname: `/queryJobs/${row.original.ownerName}/${row.original.id}/cancel`,
    fromBrowser: true,
  });
  const status = row.original.status;
  const removeCancelIcon = status === "cancelled" || status === "error" || status === "finished";

  function cancelJobQuery() {
    confirm({
      title: "Cancel query job",
      description: `Are you sure you want to cancel this query job?`,
      actionLabel: "Yes, cancel query job",
      onConfirm: () => {
        fetch(cancelUrl, {
          method: "POST",
          credentials: "same-origin",
        }).catch(console.error);
      },
    });
  }

  return (
    <div className={styles.nowrap}>
      <IdeToolTipButton
        queryString={row.original.queryString}
        queryName={row.original.queryName}
        queryOwner={row.original.queryOwner}
      />
      {!removeCancelIcon &&
        acl.check({
          action: "cancelQueryJob",
          context: {
            roleInOwnerAccount: acl.getRoleInAccount(currentAccount),
          },
        }).granted && (
          <>
            <IconButton color="error" onClick={() => cancelJobQuery()} aria-label="Cancel query job">
              <FontAwesomeIcon icon="ban" />
            </IconButton>
          </>
        )}
    </div>
  );
};

const QueryRenderer: React.FC<CellContext<Models.QueryJob, any>> = ({
  row: {
    original: { queryName, queryOwner, queryVersion },
  },
}) => {
  if (queryName && queryOwner) {
    return (
      <span>
        <Link to={`/${queryOwner}/-/queries/${queryName}/${queryVersion}`}>{`${queryOwner}/${queryName}${
          queryVersion ? `/${queryVersion}` : ""
        }`}</Link>
      </span>
    );
  }
  return null;
};

const columnHelper = createColumnHelper<Models.QueryJob>();

const columns = [
  columnHelper.accessor("pipelineId", {
    header: "Pipeline",
    filterFn: "includesString",
    cell: (info) => {
      return (
        <LinkButton
          onClickOrEnter={() => {
            info.column.setFilterValue(info.getValue());
          }}
        >
          {"..." + info.getValue()?.slice(-3)}
        </LinkButton>
      );
    },
  }),
  columnHelper.accessor("pipelineStatus", {
    header: "Pipeline status",
    sortingFn: "alphanumeric",
    cell: PipelineStatus,
    filterFn: "equals",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    filterFn: "equals",
    cell: StatusRenderer,
  }),
  columnHelper.accessor("createdBy", {
    header: "Created by",
    sortingFn: "alphanumeric",
    filterFn: "includesString",
  }),
  columnHelper.accessor("queryName", {
    header: "Query",
    enableColumnFilter: false,
    cell: QueryRenderer,
  }),
  columnHelper.accessor(
    (queryJob) => (queryJob.status === "cancelled" && !queryJob.startTime ? queryJob.createdAt : queryJob.startTime!),
    {
      header: "Started at",
      cell: TableHumanizedDateRenderer,
      enableColumnFilter: false,
    }
  ),
  columnHelper.accessor("sourceDatasetName", {
    header: "Source dataset",
    sortingFn: "alphanumeric",
    filterFn: "includesString",
    cell: SourceDatasetLinkRenderer,
  }),
  columnHelper.accessor("targetDatasetName", {
    header: "Target dataset",
    sortingFn: "alphanumeric",
    filterFn: "includesString",
    cell: TargetDatasetLinkRenderer,
  }),
  columnHelper.accessor("processingTimeMs", {
    header: "Processing time",
    cell: ({ row, getValue }) => {
      let processingTime = getValue();
      if (processingTime === undefined) {
        if (row.original.status === "error") {
          return null;
        } else {
          if (row.original.startTime === undefined) {
            processingTime = 0;
          } else {
            processingTime = moment.duration(Date.now() - Date.parse(row.original.startTime)).asMilliseconds();
          }
        }
      }
      return (
        <Tooltip
          title={moment.utc(moment.duration(processingTime, "milliseconds").asMilliseconds()).format("HH:mm:ss")}
          placement="bottom-end"
        >
          <span>{moment.duration(processingTime, "milliseconds").humanize()}</span>
        </Tooltip>
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("numberOfStatements", {
    header: "Number of statements",
    cell: ({ row, getValue }) => {
      const value = getValue();
      if (value === undefined) return row.original.status === "finished" ? 0 : "Not finished";
      return formatNumber(value);
    },
    filterFn: "inNumberRange",
  }),
  columnHelper.accessor("progress", {
    header: "Progress",
    cell: ({ getValue }) =>
      getValue() === undefined || getValue() === -1 ? "Not finished" : `${(getValue() * 100).toFixed(0)}%`,
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: "actions",
    cell: ActionsRenderer,
  }),
];

const QueryJobsTable: React.FC<{
  queryJobs: Models.QueryJobs;
}> = ({ queryJobs }) => {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const table = useReactTable<Models.QueryJob>({
    columns: columns,
    data: queryJobs,
    state: {
      columnFilters,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId: (query) => query.id,
  });
  return (
    <div className="pt-5">
      <Paper className={tableStyles.tablePaper}>
        <Toolbar className="mb-3">
          <Typography className="mx-2" variant="h5">
            {`Query jobs`}
          </Typography>
          <div className={tableStyles.space} />
        </Toolbar>
        <TableContainer className={tableStyles.tableContainer}>
          <div className="px-5">
            <Table size="small">
              <TableHeader headerGroups={table.getHeaderGroups()} />
              <ReactTableBody
                rows={table.getRowModel().rows}
                loading={false}
                error={undefined}
                columnCount={columns.length}
              />
              <TableFooter
                currentPage={table.getState().pagination.pageIndex}
                pageSize={table.getState().pagination.pageSize}
                rowCount={table.getPrePaginationRowModel().rows.length}
                onChangePage={table.setPageIndex}
                onChangeRowsPerPage={table.setPageSize}
              />
            </Table>
          </div>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default QueryJobsTable;
