import bytes from "bytes";
import getClassName from "classnames";
import { Line } from "rc-progress";
import * as React from "react";
import FontAwesomeButton from "#components/FontAwesomeButton/index.tsx";
import { Label } from "#components/index.ts";
import { ensureTrailingDot } from "#helpers/utils.ts";
import styles from "./style.scss";

export interface FileRowProps {
  isUploading?: boolean;
  uploadProgress?: number;
  error?: string;
  fileName: string;
  fileSize: number;
  removeFile?: () => void;
  removeFileToolTip: string;
}
export namespace FileRow {
  export interface Props extends FileRowProps {}
  export interface State {}
}

class FileRow extends React.PureComponent<FileRow.Props, FileRow.State> {
  render() {
    return (
      <div className={styles.fileRow}>
        <div className={this.props.isUploading ? getClassName(styles.label, styles.uploading) : styles.label}>
          <div className={styles.fileName}>{this.props.fileName}</div>
          <div className={styles.fileSize}>{bytes.format(this.props.fileSize)}</div>
        </div>
        {this.props.isUploading && !this.props.error && (
          <Line
            style={{ width: 110, height: 5, marginRight: 5 }}
            percent={this.props.uploadProgress}
            strokeWidth={4}
            strokeColor="#fa8d3e"
            strokeLinecap="butt"
          />
        )}
        {this.props.isUploading && this.props.error && (
          <div>
            <Label error message={ensureTrailingDot(this.props.error)} />
          </div>
        )}
        {this.props.removeFile && (
          <FontAwesomeButton
            className="px-3"
            onClick={this.props.removeFile}
            title={this.props.removeFileToolTip}
            icon="times"
          />
        )}
      </div>
    );
  }
}
export default FileRow;
