import * as languageParser from "accept-language-parser";
import { produce } from "immer";
import { useSelector } from "react-redux";
import UAParser from "ua-parser-js";
import { Models, Routes } from "@triply/utils";
import { GenericReduxContext } from "#helpers/ApiClient.ts";
import {
  getAccount,
  getAccountFromAccountCollection,
  getPinnedItemsForAccountFromCollections,
  User,
} from "#reducers/accountCollection.ts";
import { getLoggedInUser, isLoaded as isAuthLoaded } from "#reducers/auth.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { SsrRequestInfo } from "#store/create.ts";

export interface State {
  sidePanelCollapsed: boolean | null;
  locale?: string;
  currentAccount?: string;
  fetchingCurrentAccount: string | false;
  assumingMobile?: boolean;
}

export const LocalActions = {
  //Toggling of the panel on small screens is stored in react-router part of the state,
  //as this way we can use the browserhistory api to toggle the panel (e.g. when using the browser back btn)
  TOGGLE_SIDE_PANEL_COLLAPSED: "triply/app/TOGGLE_SIDE_PANEL_COLLAPSED",
  SET_SSR_REQ_INFO: "triply/app/SET_SSR_REQ_INFO",
  GET_CURRENT_ACCOUNT: "triply/app/GET_CURRENT_ACCOUNT",
  GET_CURRENT_ACCOUNT_SUCCESS: "triply/app/GET_CURRENT_ACCOUNT_SUCCESS",
  GET_CURRENT_ACCOUNT_FAIL: "triply/app/GET_CURRENT_ACCOUNT_FAIL",
} as const;

export type TOGGLE_SIDE_PANEL_COLLAPSED = GlobalAction<{
  type: typeof LocalActions.TOGGLE_SIDE_PANEL_COLLAPSED;
  collapse: boolean;
}>;
export type SET_SSR_REQ_INFO = GlobalAction<{
  type: typeof LocalActions.SET_SSR_REQ_INFO;
  locale: string;
  assumingMobile?: boolean;
}>;

export type GET_CURRENT_ACCOUNT = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_CURRENT_ACCOUNT,
      typeof LocalActions.GET_CURRENT_ACCOUNT_SUCCESS,
      typeof LocalActions.GET_CURRENT_ACCOUNT_FAIL
    ];
    verbose: true;
    accountName: string;
  },
  Routes.accounts._account.Get | Models.Account
>;

export type LocalAction = TOGGLE_SIDE_PANEL_COLLAPSED | SET_SSR_REQ_INFO | GET_CURRENT_ACCOUNT;

//The immer producer has useful generics, but these'll cause `Type instantiation is excessively deep and possibly infinite.`
//typescript errors, or possible out-of-memory errors caused by the `Immutable<>` immer typings on our state.
//So, avoid using the immer typings, and add our own for now. We can try to move to the generics in a later stage
//when either typescript or immer solved this issue
export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.TOGGLE_SIDE_PANEL_COLLAPSED:
        draftState.sidePanelCollapsed = action.collapse;
        return;
      case Actions.SET_SSR_REQ_INFO:
        draftState.locale = action.locale;
        draftState.assumingMobile = action.assumingMobile;
        return;

      case Actions.GET_CURRENT_ACCOUNT:
        draftState.fetchingCurrentAccount = action.accountName;
        return;
      case Actions.GET_CURRENT_ACCOUNT_FAIL:
        if (
          draftState.fetchingCurrentAccount &&
          draftState.fetchingCurrentAccount.toLowerCase() === action.accountName.toLowerCase()
        ) {
          draftState.fetchingCurrentAccount = false;
        }
        return;
      case Actions.GET_CURRENT_ACCOUNT_SUCCESS:
        if (draftState.fetchingCurrentAccount !== action.accountName) {
          return;
        }
      case Actions.UPLOAD_ACCOUNT_AVATAR_SUCCESS:
      case Actions.UPDATE_PROFILE_SUCCESS:
        draftState.currentAccount = action.result.uid;
        draftState.fetchingCurrentAccount = false;
        return;
      case Actions.DELETE_ACCOUNT_SUCCESS:
        draftState.currentAccount = undefined;
        return;
    }
  },
  <State>{
    sidePanelCollapsed: null,
    draggingFile: false,
    locale: undefined,
    currentAccount: undefined,
    fetchingCurrentAccount: false,
    assumingMobile: undefined,
  }
) as any;

export function updateWithRequestInfo(requestInfo: SsrRequestInfo): BeforeDispatch<SET_SSR_REQ_INFO> {
  let isoString = "en-US";
  if (requestInfo.requestLanguage) {
    const isoCode = languageParser.parse(requestInfo.requestLanguage as string);
    if (isoCode.length) {
      //Choose the first entry since the entries are ordered by quality (i.e. preference)
      isoString = isoCode[0].code;
      if (isoCode[0].region) isoString += "-" + isoCode[0].region;
      try {
        new Date().toLocaleString(isoString);
      } catch {
        //accept language is invalid, therefore let's default the locale to en-US.
        isoString = "en-US";
      }
    }
  }
  let assumingMobile: boolean | undefined;
  if (requestInfo.userAgent) {
    const uaInfo = new UAParser(requestInfo.userAgent);
    // Note that we cannot detect based on the useragent whether something is a desktop.
    // Instead, we're checking for mobile devices
    // See https://github.com/faisalman/ua-parser-js/issues/182#issuecomment-263115448
    assumingMobile = uaInfo.getDevice().type === "mobile";
  }
  return {
    type: LocalActions.SET_SSR_REQ_INFO,
    locale: isoString,
    assumingMobile,
  };
}

export function toggleSidePanelCollapsed(collapse: boolean): BeforeDispatch<TOGGLE_SIDE_PANEL_COLLAPSED> {
  return {
    type: LocalActions.TOGGLE_SIDE_PANEL_COLLAPSED,
    collapse,
  };
}

function canUseLoggedInUser(state: GlobalState, accountName: string, forceUpdate: boolean) {
  if (forceUpdate || !isAuthLoaded(state, accountName)) return false;
  return state.auth.loggedInUser && !state.accountCollection[state.auth.loggedInUser]?.countersOutdated;
}

export function getAccountInfo(
  state: GlobalState,
  accountName: string,
  forceUpdate = false
): BeforeDispatch<GET_CURRENT_ACCOUNT> {
  if (canUseLoggedInUser(state, accountName, forceUpdate)) {
    const loggedInUser = getLoggedInUser(state) as User;
    return {
      types: [
        LocalActions.GET_CURRENT_ACCOUNT,
        LocalActions.GET_CURRENT_ACCOUNT_SUCCESS,
        LocalActions.GET_CURRENT_ACCOUNT_FAIL,
      ],
      promise: async () => {
        const loggedInUser = getLoggedInUser(state);
        if (loggedInUser?.accountName)
          return {
            ...loggedInUser,
            pinnedItems: getPinnedItemsForAccountFromCollections(
              state.datasetCollection,
              state.accountCollection,
              loggedInUser.uid
            ),
          };
        throw new Error("Account does not exist");
      },
      verbose: true,
      accountName: loggedInUser?.accountName || accountName,
    };
  } else {
    return {
      types: [
        LocalActions.GET_CURRENT_ACCOUNT,
        LocalActions.GET_CURRENT_ACCOUNT_SUCCESS,
        LocalActions.GET_CURRENT_ACCOUNT_FAIL,
      ],
      promise: (client: GenericReduxContext<Routes.accounts._account.Get>) =>
        client.req({
          pathname: "/accounts/" + accountName,
          query: {
            verbose: "",
          },
          method: "get",
        }),
      verbose: true,
      accountName: accountName,
    };
  }
}

export function accountIsCurrentAccount(state: GlobalState, accountName: string) {
  const currentAccountName = state.app.currentAccount && state.accountCollection[state.app.currentAccount]?.accountName;
  return (
    !state.app.fetchingCurrentAccount &&
    accountName &&
    currentAccountName &&
    accountName.toLowerCase() === currentAccountName.toLowerCase()
  );
}

export function countersAreOutdated(state: GlobalState, accountId?: string) {
  return !!accountId && !!state.accountCollection[accountId]?.countersOutdated;
}

export function getCurrentAccount(state: GlobalState) {
  if (!state.app.currentAccount) return undefined;
  return getAccount(state, state.app.currentAccount, true);
}
export const useCurrentAccount = (accountName?: string) => {
  const accountCollection = useSelector((state: GlobalState) => state.accountCollection);
  const currentAccountId = useSelector((state: GlobalState) => state.app.currentAccount);
  const currentAccount = getAccountFromAccountCollection(accountCollection, currentAccountId, true);
  if (accountName && currentAccount?.accountName.toLowerCase() !== accountName.toLowerCase()) return;
  return currentAccount;
};

export function getCurrentPinnedItems(state: GlobalState) {
  return state.app.currentAccount
    ? getPinnedItemsForAccountFromCollections(
        state.datasetCollection,
        state.accountCollection,
        state.app.currentAccount
      )
    : [];
}
export function usePinnedItems(accountId: string | undefined) {
  const datasetCollection = useSelector((state: GlobalState) => state.datasetCollection);
  const accountCollection = useSelector((state: GlobalState) => state.accountCollection);
  if (!accountId) return [];
  return getPinnedItemsForAccountFromCollections(datasetCollection, accountCollection, accountId);
}
