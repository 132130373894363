import { Menu, MenuItem } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Avatar, ConfirmationDialog, FontAwesomeIcon } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { cancelAllTusUploads, state as tusState } from "#helpers/tusUploadManagement.ts";
import { logout } from "#reducers/auth.ts";
import styles from "./style.scss";

export interface Props {
  className?: string;
  accountName?: string;
  avatarUrl?: string;
  siteAdmin: boolean;
  pathname?: string;
  consentExpiring: boolean;
  adminStatus?: "warning" | "exceeded";
}

const UserWidget: React.FC<Props> = ({
  className,
  accountName,
  siteAdmin,
  consentExpiring,
  avatarUrl,
  pathname,
  adminStatus,
}) => {
  // State Init
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  // Ref is used for placing the dialog correctly
  const menuButtonRef = React.useRef<HTMLAnchorElement>(null);
  // Handler init
  const dispatch = useDispatch();

  const reOpenDropdownMenu = () => {
    setMenuOpen(true);
    setConfirmationDialogOpen(false);
  };
  const checkTusOngoingUploadsOrLogout = () => {
    if (tusState.navigationWarningActive) {
      setMenuOpen(false);
      setConfirmationDialogOpen(true);
    } else {
      logout();
    }
  };
  const cancelTusAndLogout = () => {
    cancelAllTusUploads();
    logout();
  };

  return (
    <div className={getClassName(className, styles.userWidget, "ml-4 mr-3")}>
      <a
        aria-label={accountName}
        className={"flex center p-1 noLinkDecoration"}
        onClick={() => setMenuOpen(true)}
        onKeyPress={(e) => {
          const key = e.key || e.keyCode;
          if (key === "Space" || key === "Enter") {
            setMenuOpen(true);
          }
        }}
        ref={menuButtonRef}
        role="button"
        tabIndex={0}
      >
        {accountName && <Avatar avatarName={accountName} avatarUrl={avatarUrl} size="sm" className="mr-2" alt="" />}
        {/* When an user authenticates with OAuth it is possible that the user has no username yet  */}
        <span className="hidden-sm mr-1">{accountName ? accountName + " " : ""}</span>
        <FontAwesomeIcon icon={["fas", "caret-down"]} size={"sm"} className={"ml-1"} />
      </a>
      {menuButtonRef.current && (
        <Menu
          open={menuOpen}
          // disablePortal
          anchorEl={menuButtonRef.current}
          container={menuButtonRef.current.parentElement}
          className={getClassName(styles.dropDownMenu, "ml-2")}
          onClose={() => setMenuOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Prop of List from material-ui
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          transitionDuration={0}
          classes={{ paper: "rounding" }}
          disableAutoFocusItem
        >
          {/* When an user authenticates with OAuth it is possible that the user has no username yet  */}
          {accountName && (
            <MenuItem
              to={"/" + accountName}
              component={Link}
              className={"py-1 px-5 noLinkDecoration"}
              classes={{
                selected: styles["selected"],
              }}
              selected={pathname === "/" + accountName}
              onClick={() => setMenuOpen(false)}
            >
              My account
            </MenuItem>
          )}
          {accountName && (
            <MenuItem
              component={Link}
              to={`/${accountName}/-/settings`}
              className="py-1 px-5 noLinkDecoration"
              classes={{
                selected: styles["selected"],
              }}
              selected={pathname === `/${accountName}/-/settings`}
              onClick={() => setMenuOpen(false)}
            >
              User settings
              {consentExpiring && (
                <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} size="sm" style={{ marginLeft: "8px" }} />
              )}
            </MenuItem>
          )}
          {siteAdmin && (
            <MenuItem
              component={Link}
              to={"/_admin"}
              className="py-1 px-5 noLinkDecoration"
              classes={{
                selected: styles["selected"],
              }}
              selected={pathname === "/_admin"}
              onClick={() => setMenuOpen(false)}
            >
              Admin settings
              {adminStatus && (
                <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} size="sm" style={{ marginLeft: "8px" }} />
              )}
            </MenuItem>
          )}
          <MenuItem
            className={getClassName(styles.customDivider, "py-1 px-5")}
            onClick={checkTusOngoingUploadsOrLogout}
          >
            Logout
          </MenuItem>
        </Menu>
      )}

      <ConfirmationDialog
        open={confirmationDialogOpen && tusState.navigationWarningActive}
        onClose={reOpenDropdownMenu}
        onConfirm={cancelTusAndLogout}
        title="Log out?"
        actionLabel="Log out"
        description="Logging out will stop your ongoing uploads. Continue?"
      />
    </div>
  );
};
export default UserWidget;
