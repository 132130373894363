import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { useLocation } from "react-router";
import { MarkOptional } from "ts-essentials";
import ConfirmationDialog, { Props as ConfirmationDialogProps } from "#components/ConfirmationDialog/index.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { LocationState } from "#reducers/router.ts";

type ConfirmationOptions = MarkOptional<
  Pick<
    ConfirmationDialogProps,
    "description" | "actionLabel" | "title" | "onConfirm" | "onClose" | "confirmationString"
  >,
  "onClose"
>;
const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => void>(() => {});

export const useConfirmation = () => React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [confirmationOptions, setConfirmationOptions] = React.useState<ConfirmationOptions>();
  const dispatch = useDispatch();
  const goBack = React.useCallback(() => dispatch(connectedReactRouter.goBack()), [dispatch]);
  const open = React.useCallback(
    () =>
      dispatch(connectedReactRouter.push({ state: { confirmationDialogOpen: true, preserveScrollPosition: true } })),
    [dispatch]
  );
  const { state } = useLocation<LocationState | undefined>();
  const isOpen = !!state?.confirmationDialogOpen;
  const openConfirmation = (options: ConfirmationOptions) => {
    open();
    setConfirmationOptions(options);
  };

  const handleClose = () => {
    goBack();
    confirmationOptions?.onClose?.();
  };

  const handleSubmit = () => {
    goBack();
    confirmationOptions?.onConfirm();
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation} children={children} />
      {confirmationOptions && (
        <ConfirmationDialog open={isOpen} {...confirmationOptions} onConfirm={handleSubmit} onClose={handleClose} />
      )}
    </>
  );
};
