import { useSelector } from "react-redux";
import { GlobalState } from "../../reducers";

// API for the admin table, since if it's enabled on the API, we should have some way to see what's going on.
export function useQueryJobsApiEnabled() {
  return useSelector((state: GlobalState) => !!state.config.clientConfig?.featureToggles?.queryJobsApi.value);
}

// UI for interacting with the various Query Job UI elements by Super Admins.
export function useQueryJobsUiEnabledForSuperAdmins() {
  return useSelector((state: GlobalState) => !!state.config.clientConfig?.useQueryJobUi);
}
