import getClassName from "classnames";
import { isArray, throttle } from "lodash-es";
import * as React from "react";
import AutoSuggest, { SuggestionsFetchRequestedParams } from "react-autosuggest";
// @ts-ignore
import { Droppable } from "react-drag-and-drop";
import { prefixUtils } from "@triply/utils";
import { FontAwesomeButton } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import { Prefixes } from "#reducers/datasetManagement.ts";
import { Position } from "#reducers/triples.ts";
import styles from "./style.scss";

interface Suggestions {
  q: string;
  graph?: string;
  suggestions: string[];
}

namespace FilterField {
  export interface Props {
    pos: Position;
    prefixes: Prefixes;
    handleFilterChange: (newValue: string, reset?: boolean) => void;
    initialValue: string;
    termPath: string;
    selectedGraph: string | undefined;
  }
}

function processTerms(terms: string[], pos: Position) {
  if (pos === "object") terms = terms.map((term) => term.replace(/^("(.|\n)*"\^\^)<(.*)>$/, "$1$3"));
  return terms.map((term) => (term.length > 5000 ? term.substr(0, 20) + "... Term is too long to query" : term));
}

const FilterField: React.FC<FilterField.Props> = ({
  pos,
  prefixes,
  initialValue,
  handleFilterChange,
  selectedGraph,
  termPath,
}) => {
  const [suggestions, setSuggestions] = React.useState<Suggestions | undefined>(undefined);
  const [value, setValue] = React.useState(initialValue);
  const [escapePressed, setEscapePressed] = React.useState(false);
  const fetchWrapper = (url: string): Promise<string[]> => {
    return fetch(url, { credentials: "same-origin" })
      .then((response) => {
        if (response.status === 200) return response.json();
      })
      .catch(console.error);
  };

  const onSuggestionsFetchRequested = throttle((data: SuggestionsFetchRequestedParams) => {
    // Allow toggling on/off suggestions when Esc is pressed.
    if (data.reason === "escape-pressed" && suggestions) {
      setSuggestions(undefined);
      return;
    }
    const prefixInfo = prefixUtils.getPrefixInfoFromPrefixedValue(data.value, prefixes);
    if (prefixInfo && prefixInfo.prefixLabel) data.value = `${prefixInfo.iri}${prefixInfo.localName}`;

    if (
      suggestions &&
      suggestions.suggestions.length < 20 &&
      selectedGraph === suggestions.graph &&
      data.value.lastIndexOf(suggestions.q, 0) === 0
    ) {
      return;
    }

    let url = `${termPath}?pos=${pos}&q=${encodeURIComponent(data.value)}`;
    if (selectedGraph) url += `&graph=${encodeURIComponent(selectedGraph)}`;
    fetchWrapper(url)
      .then((terms) => {
        if (terms && isArray(terms)) {
          setSuggestions({
            q: data.value,
            graph: selectedGraph,
            suggestions: processTerms(terms, pos),
          });
        }
      })
      .catch(console.error);
  }, 500);

  return (
    <Droppable
      className={getClassName(styles.filterWrapper, { [styles.hasVal]: !!initialValue })}
      types={["text/plain"]}
      onDrop={(data: any) => {
        if (!data || !data["text/plain"]) return;
        handleFilterChange(data["text/plain"], true);
      }}
    >
      <AutoSuggest
        id={pos}
        onSuggestionSelected={(_event, suggestion) => {
          // Getting new results when clicking or hitting enter on a selected suggestion.
          if (suggestion.method === "click" || suggestion.method === "enter") {
            setValue(suggestion.suggestionValue);
            setSuggestions(undefined);
            handleFilterChange(suggestion.suggestionValue);
          }
        }}
        suggestions={suggestions?.suggestions.filter((s) => s.lastIndexOf(value, 0) === 0) || []}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={(s) => s}
        onSuggestionsClearRequested={() => {
          setSuggestions(undefined);
        }}
        shouldRenderSuggestions={() => true}
        renderSuggestion={(s) => <div>{s}</div>}
        focusInputOnSuggestionClick
        inputProps={{
          id: pos,
          type: "search",
          "aria-label": `${pos} filter`,
          value: value,
          onChange: (_e, data) => {
            // this resets the input value when hitting Esc
            if (data.method === "escape" && initialValue !== data.newValue) {
              setValue(initialValue);
              setEscapePressed(true);
            }
            if (data.method === "type" || data.method === "enter") {
              const prefixInfo = prefixUtils.getPrefixInfoFromPrefixedValue(data.newValue, prefixes);
              setValue(
                prefixInfo && prefixInfo.prefixLabel !== undefined
                  ? `${prefixInfo.iri}${prefixInfo.localName}`
                  : // if Esc was pressed then reset to the initial value and
                  // don't set to `data.new value` because it will be empty.
                  escapePressed
                  ? initialValue
                  : data.newValue
              );
              // reset if Esc was pressed
              if (escapePressed) setEscapePressed(false);
            }
          },
          onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              //We need to blur this, otherwise navigating back/forth would auto-focus this field
              //and auto-show the suggestions
              e.currentTarget.blur();
            }
          },
          onBlur: (_e, _data) => {
            //we handle filter change only when the value changed before blurring
            if (initialValue !== value) {
              handleFilterChange(value);
            }
          },
        }}
      />

      <FontAwesomeButton
        icon="times"
        onClick={() => handleFilterChange("")}
        className={styles.removeFilterIcon}
        title="Clear input"
      />
    </Droppable>
  );
};

export default FilterField;
