import { Prefix } from "@triply/utils";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";

function getPrefixAndLabel(prefixes: Prefix[], iri: string) {
  for (const p of prefixes) {
    if (iri.startsWith(p.iri)) {
      return {
        label: iri.slice(p.iri.length),
        prefix: p.prefixLabel,
      };
    }
  }
  return { label: iri };
}

export default function useApplyPrefixes() {
  const prefixes = useDatasetPrefixes();
  return (iri: string) => {
    const { prefix, label } = getPrefixAndLabel(prefixes, iri);
    return prefix ? `${prefix}:${label}` : label;
  };
}
