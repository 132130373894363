import { IconButton } from "@mui/material";
import dedent from "dedent";
import * as React from "react";
import { factories, getWriter } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import { Dialog, FontAwesomeIcon } from "../../components";
import { useRemovePrefixes } from "../../components/Prefixed";
import useConstructConsoleUrl from "../../helpers/hooks/useConstructConsoleUrl";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import ObjectPropertyForm from "./Forms/ObjectProperty";
import { ObjectPropertyData } from "./Forms/Types";

function rdf(sub: string) {
  return factory.namedNode(`http://www.w3.org/1999/02/22-rdf-syntax-ns#${sub}`);
}
function sh(sub: string) {
  return factory.namedNode(`http://www.w3.org/ns/shacl#${sub}`);
}
function owl(sub: string) {
  return factory.namedNode(`http://www.w3.org/2002/07/owl#${sub}`);
}
function xsd(sub: string) {
  return factory.namedNode(`http://www.w3.org/2001/XMLSchema#${sub}`);
}
const factory = factories.compliant;
const AddObjectProperty: React.FC<{
  classShapeIri: string;
}> = ({ classShapeIri }) => {
  const [open, setOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();
  return (
    <>
      <IconButton color="primary" aria-label="add" onClick={() => setOpen(true)} size="small">
        <FontAwesomeIcon icon="plus" />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        title="Add an object property"
        closeButton
      >
        <div className="px-5 pb-5">
          <ObjectPropertyForm
            onSubmit={async (newProperty: ObjectPropertyData) => {
              // console.log(newProperty);
              const id = factory.namedNode(removePrefixes(newProperty.id)).id;
              const propertyShape = factory.namedNode(`${consoleUrl}/${datasetPath}/shp/${Date.now()}`).id;
              const propertyLabel = newProperty.label?.trim();
              const propertyDescription = newProperty.description?.trim();
              const minCount = newProperty.minCount;
              const maxCount = newProperty.maxCount;

              const query = dedent`
                prefix sh: <http://www.w3.org/ns/shacl#>
                prefix owl: <http://www.w3.org/2002/07/owl#>
                insert {
                  ?id a owl:ObjectProperty.
                  ?classShape sh:property ?propertyShape.
                  ?propertyShape a sh:PropertyShape;
                                sh:path ?id;
                                sh:name ?label;
                                sh:description ?description;
                                sh:class ?range;
                                sh:minCount ?minCount;
                                sh:maxCount ?maxCount;
                                sh:minLength 1.
                } where {
                  bind(<${id}> as ?id)
                  bind(<${propertyShape}>as ?propertyShape)
                  bind(<${classShapeIri}> as ?classShape)
                  bind(<${newProperty.range}> as ?range)
                  optional {
                    values ?label {
                      ${propertyLabel ? `"${propertyLabel}"` : ""}
                    }
                  }
                  optional {
                    values ?description {
                      ${propertyDescription ? `"${propertyDescription}"` : ""}
                    }
                  }
                  optional {
                    values ?minCount {
                    ${minCount || ""}
                    }
                  }
                  optional {
                    values ?maxCount {
                    ${maxCount || ""}
                    }
                  }
                }
              `;
              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name })
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                })
              );

              setOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddObjectProperty;
