import * as React from "react";
import { connect } from "react-redux";
import { formValueSelector, SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { FlexContainer, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { resendPassword } from "#reducers/auth.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";

export namespace ResendPassword {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    resendPassword: DispatchedFn<typeof resendPassword>;
  }
  export interface PropsFromState {
    email?: string;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

class ResendPassword extends React.PureComponent<ResendPassword.Props, any> {
  handleSubmit = () => {
    if (!this.props.email) {
      throw new SubmissionError({ _error: "No email address provided" });
    }
    return this.props.resendPassword(this.props.email).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      }
    );
  };
  render() {
    const parsedSearchString = parseSearchString(this.props.location.search);

    return (
      <FlexContainer innerStyle={{ display: "flex", justifyContent: "center" }}>
        <Meta currentPath={this.props.location.pathname} title="Reset password" />
        <div className="whiteSink" style={{ width: "100%", maxWidth: 560 }}>
          <h3 style={{ display: "block", textAlign: "center" }}>Reset password</h3>
          <Forms.ResendPassword
            onSubmit={this.handleSubmit}
            initialValues={{
              email:
                typeof parsedSearchString.email === "string" && parsedSearchString.email.length
                  ? parsedSearchString.email
                  : undefined,
            }}
          />
        </div>
      </FlexContainer>
    );
  }
}

export default connect<
  ResendPassword.PropsFromState,
  { [K in keyof ResendPassword.DispatchProps]: any },
  ResendPassword.OwnProps,
  GlobalState
>(
  (state, _ownProps) => {
    const selector = formValueSelector("resendPassword"); // <-- same as form name
    return {
      email: selector(state, "email") as string,
    };
  },
  //dispatch
  { resendPassword }
)(ResendPassword);
