import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useRouteMatch } from "react-router";
import { matchRoutes, RouteConfig } from "react-router-config";
import { asyncConnect } from "redux-connect";
import { Query } from "@triply/utils/Models.js";
import { ErrorPage } from "#components/index.ts";
import { getCurrentAccount, useCurrentAccount } from "#reducers/app.ts";
import { getCurrentDataset, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { getRunningServices, getServiceByName, getServiceInfo, needToFetchServiceInfo } from "#reducers/services.ts";
import { urlInfoToString } from "#staticConfig.ts";
import SparqlIde from "./IdeComponent.tsx";

// '_' are not part of a valid service name
const SPEEDY_KEY = "_speedy";

export interface LocationState {
  queryConfig: Query;
}
export interface Props {
  route: RouteConfig;
}

const ServiceInterface: React.FC<Props> = ({ route }) => {
  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();
  const location = useLocation<LocationState>();
  const match = useRouteMatch<{ account: string; dataset: string; serviceName?: string }>();
  const services = useSelector((state: GlobalState) => (!!currentDs ? state.services[currentDs.id] : undefined));
  const currentService = useSelector((state: GlobalState) => {
    if (!currentDs || !services) return;
    const runningServices = getRunningServices(services, "sparql");
    if (runningServices.length > 0) {
      /**
       * We've got an exact match with the service name on the path
       */
      if (route.routes) {
        const matches = matchRoutes<{ serviceName: string }>(route.routes, location.pathname);
        if (matches.length && matches[0].route.path === `/:account/:dataset/sparql/:serviceName`) {
          return getServiceByName(state, currentDs, matches[0].match.params.serviceName);
        }
      }
      /**
       * We haven't found the service in the list (anymore). Just take the first service instead
       */
      return runningServices[0];
    }
    return {
      name: SPEEDY_KEY,
      endpoint: urlInfoToString(state.config.staticConfig?.apiUrl, {
        pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/sparql`,
      }),
      status: "running",
      id: `${currentDs.id}-speedy`,
    };
  });
  if (!currentService || !currentAccount || !currentDs) return <ErrorPage statusCode={404} />;
  if (!currentService.endpoint) {
    let message;
    switch (currentService.status) {
      case "stopped":
      case "stopping":
        message = "This service has been stopped, and is therefore not accessible";
        break;
      case "starting":
        message = "This service is starting, please try again later";
        break;
      case "removing":
        message = "This service is being removed, it can not be accessed anymore";
        break;
      case "running":
        message = "This service is running, but its endpoint is not available, please try again later";
        break;
      default:
        message = "This service is not able to connect to the endpoint yet, please try again later";
        break;
    }
    return <ErrorPage title={`${route.type} endpoint not accessible`} message={message} />;
  }
  // Exact means we've matched the `/sparql` route
  if (route.routes && !match.isExact) {
    // The useRouteMatch picks up on the `sparql` path only not the `sparql/service` path
    const matches = matchRoutes<{ serviceName: string }>(route.routes, location.pathname);
    if (matches.length && matches[0].route.path === `/:account/:dataset/sparql/:serviceName`) {
      return (
        <Redirect
          to={{
            ...location,
            pathname: `/${match.params.account}/${match.params.dataset}/sparql`,
            state: { serviceName: matches[0].match.params.serviceName },
          }}
        />
      );
    }
  }
  return <SparqlIde />;
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ match: { params }, store: { dispatch, getState } }) => {
      const state = getState();
      const currentAccount = getCurrentAccount(state);
      const currentDs = getCurrentDataset(state);
      if (!currentAccount || !currentDs) return Promise.resolve();
      if (!!params.serviceName && needToFetchServiceInfo(state, params.serviceName)) {
        return dispatch<any>(
          getServiceInfo(currentAccount.accountName, currentDs.name, currentDs.id, params.serviceName)
        ).catch(() => {});
      }
      return Promise.resolve();
    },
  },
])(ServiceInterface) as typeof ServiceInterface;
