import getClassName from "classnames";
import { isArray, throttle } from "lodash-es";
import * as React from "react";
import AutoSuggest from "react-autosuggest";
// @ts-ignore
import { Droppable } from "react-drag-and-drop";
import { prefixUtils } from "@triply/utils";
import { Prefixes } from "@triply/utils/Models.js";
import fetch from "#helpers/fetch.ts";
import { memoize } from "#helpers/utils.ts";
import styles from "./styles/index.scss";

export namespace ResourceAutocomplete {
  export interface Props {
    selectSearchTerm: (resource: string) => void;
    resource: string;
    prefixes: Prefixes;
    autocompleteUrl: string;
  }

  export interface State {
    searchTerm?: string;
    resourceSuggestions?: {
      q?: string;
      suggestions: string[];
    };
  }
}

const defaultState: ResourceAutocomplete.State = {
  searchTerm: undefined,
  resourceSuggestions: undefined,
};

class ResourceAutocomplete extends React.PureComponent<ResourceAutocomplete.Props, ResourceAutocomplete.State> {
  state = defaultState;

  UNSAFE_componentWillReceiveProps(nextProps: ResourceAutocomplete.Props) {
    if (nextProps.resource !== this.props.resource) {
      this.setState(defaultState);
    }
  }

  @memoize({ primitive: true, promise: true })
  fetch(url: string): Promise<string[]> {
    if (__SERVER__) return Promise.reject("fetch cannot be used on the server");
    return fetch(url, { credentials: "same-origin" })
      .then((response) => {
        if (response.status === 200) return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  needToFetchTerms(q: string) {
    const suggestionState = this.state.resourceSuggestions;
    if (!suggestionState) return true;
    if (suggestionState.suggestions.length < 20 && suggestionState.q && q.lastIndexOf(suggestionState.q, 0) === 0)
      return false;
    return true;
  }

  onSuggestionsFetchRequested = throttle((data: any) => {
    const prefixInfo = prefixUtils.getPrefixInfoFromPrefixedValue(data.value, this.props.prefixes);
    if (prefixInfo && prefixInfo.prefixLabel) data.value = `${prefixInfo.iri}${prefixInfo.localName}`;

    if (!this.needToFetchTerms(data.value)) return;
    var url = `${this.props.autocompleteUrl}?pos=subject&q=${encodeURIComponent(data.value)}`;

    this.fetch(url)
      .then((terms) => {
        if (terms && isArray(terms)) {
          this.setState({
            resourceSuggestions: {
              q: data.value,
              suggestions: terms,
            },
          });
        }
      })
      .catch(console.error);
  }, 500);

  onSearchFieldChange(value: string) {
    const prefixInfo = prefixUtils.getPrefixInfoFromPrefixedValue(value, this.props.prefixes);
    this.setState({
      searchTerm: prefixInfo && prefixInfo.prefixLabel ? `${prefixInfo.iri}${prefixInfo.localName}` : value,
    });
  }
  searchDropHandler = (data: any) => {
    if (!data || !data["text/plain"]) return;
    this.props.selectSearchTerm(data["text/plain"]);
  };
  clearSuggestionHandler = () => this.setState({ resourceSuggestions: { q: undefined, suggestions: [] } });
  returnSelf = (T: any) => T;
  returnTrue = () => true;
  renderSuggestion = (s: any) => <div key={s}>{s}</div>;

  render() {
    return (
      <Droppable types={["text/plain"]} onDrop={this.searchDropHandler} className={styles.searchField}>
        <AutoSuggest
          suggestions={
            (this.state.resourceSuggestions &&
              this.state.resourceSuggestions.suggestions.filter(
                (s) => s.lastIndexOf(this.state.searchTerm || "", 0) === 0
              )) ||
            []
          }
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.clearSuggestionHandler}
          getSuggestionValue={this.returnSelf}
          shouldRenderSuggestions={this.returnTrue}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            type: "search",
            "aria-label": "Subject IRI",
            value: this.state.searchTerm !== undefined ? this.state.searchTerm : this.props.resource || "",
            onChange: (_e, data) => {
              if (data.method === "type" || data.method === "enter" || data.method === "click") {
                this.onSearchFieldChange(data.newValue);
                if (data.method === "click" || data.method === "enter") {
                  this.props.selectSearchTerm(data.newValue);
                }
              }
            },
            onKeyPress: (e) => {
              if (this.state.searchTerm !== undefined && e.key === "Enter") {
                this.props.selectSearchTerm(this.state.searchTerm);
              }
            },
            onFocus: (e: any) => e?.target?.select(),
            className: getClassName(styles.searchFieldInput, "mb-5"),
          }}
        />
      </Droppable>
    );
  }
}
export default ResourceAutocomplete;
