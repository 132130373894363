import { Alert, Skeleton } from "@mui/material";
import getClassName from "classnames";
import { Location } from "history";
import React from "react";
// @ts-ignore
import { Droppable } from "react-drag-and-drop";
import { useHistory, useLocation } from "react-router";
import { DatasetMetadata } from "#components/index.ts";
import { FooterContext } from "#containers/NavConsole/index.tsx";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import Graph from "./Graph.tsx";
import SidePanel from "./SidePanel.tsx";
import Toolbar from "./Toolbar.tsx";
import useFetchSchema from "./useFetchSchema.ts";
import styles from "./style.scss";

const Schema: React.FC<{ location: Location }> = ({ location }) => {
  const [highlightOwl, setHighlightOwl] = React.useState(false);
  const [highlightShacl, setHighlightShacl] = React.useState(false);
  const [highlightHierarchy, setHighlightHierarchy] = React.useState(false);
  const [highlightProperties, setHighlightProperties] = React.useState(false);
  const centerFunctionRef = React.useRef<(iri?: string) => void>(null);
  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();
  const history = useHistory();
  const query = parseSearchString(location.search);
  const { hideFooter } = React.useContext(FooterContext);

  const { loading, error, querySchema, containsShacl } = useFetchSchema();

  React.useEffect(() => {
    hideFooter(true);
    return () => hideFooter(false);
  }, [hideFooter]);

  if (!currentDs || !currentAccount) return null;
  if (error) {
    return (
      <div className="constrainWidth p-5">
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <DatasetMetadata currentAccount={currentAccount} currentDs={currentDs} title="Schema" />
      {querySchema && (
        <Toolbar
          setHighlightHierarchy={setHighlightHierarchy}
          setHighlightProperties={setHighlightProperties}
          setHighlightShacl={setHighlightShacl}
          setHighlightOwl={setHighlightOwl}
          containsShacl={containsShacl}
          location={location}
        />
      )}
      <Droppable
        types={["text/plain"]}
        onDrop={(data: any) => {
          if (data && data["text/plain"]) {
            const subject = data["text/plain"].trim();
            history.push({
              search: stringifyQuery({
                ...query,
                f: subject,
              }),
              hash: "",
            });
          }
        }}
      >
        <div className={getClassName("flex", styles.visualizationContainer)}>
          <div className={styles.fullSize}>
            {querySchema && (
              <Graph
                className={getClassName({
                  [styles.highlightOwl]: highlightOwl,
                  [styles.highlightShacl]: highlightShacl,
                  [styles.highlightHierarchy]: highlightHierarchy,
                  [styles.highlightProperties]: highlightProperties,
                })}
                centerFunctionRef={centerFunctionRef}
                querySchema={querySchema}
                location={location}
              />
            )}
            {loading && <Skeleton variant="rectangular" className={styles.fullSize} />}
          </div>
        </div>
      </Droppable>
      {querySchema && <SidePanel querySchema={querySchema} containsShacl={containsShacl} location={location} />}
    </div>
  );
};

const Memoed = React.memo(Schema);
// We don't need any injected container props, so just leave out any injected props to prevent unnecessary renders.
// We are injecting location ourselves, as using useLocation in subcomponents triggers renders even when location is not different...
export default () => {
  const location = useLocation();
  return <Memoed location={location} />;
};
