import getClassName from "classnames";
import React from "react";
import OtpInput, { OtpInputProps } from "react-otp-input";
import { MarkOptional } from "ts-essentials";
import styles from "./style.scss";

const AuthenticatorCode: React.FC<MarkOptional<OtpInputProps, "numInputs">> = (props) => {
  return (
    <OtpInput
      {...props}
      numInputs={props.numInputs ?? 6}
      isInputNum
      separator="-"
      inputStyle={getClassName(styles.inputStyle, props.inputStyle as Record<string, unknown>)}
      focusStyle={getClassName(props.focusStyle as Record<string, unknown>)}
      errorStyle={getClassName(styles.errorStyle, props.errorStyle as Record<string, unknown>)}
    />
  );
};

export default AuthenticatorCode;
