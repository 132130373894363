import { Alert, Skeleton } from "@mui/material";
import getClassName from "classnames";
import { cloneDeep } from "lodash-es";
import * as React from "react";
import ReactDOM from "react-dom";
import {
  GoogleChartEditor,
  GoogleChartTicks,
  GoogleChartWrapper,
  GoogleChartWrapperChartType,
  GoogleViz,
} from "react-google-charts";
import { Button, FontAwesomeIcon } from "#components/index.ts";
import { isConstructResponse, isSelectResponse, SparqlResponse } from "#components/Sparql/SparqlUtils.ts";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { EmptyResults } from "../displayUtils";
import { parseGoogleData } from "./googleUtil";
import styles from "./styles.scss";

//Lazyloading Chart component
const Chart = React.lazy(() => import("react-google-charts"));

// Chart Plugin Configuration
export interface PluginConfig {
  chartType?: GoogleChartWrapperChartType;
  options?: Partial<{
    width: number;
    height: number;
    is3D: boolean;
    title: string;
    backgroundColor: string;
    hAxis?: {
      minValue?: any;
      maxValue?: any;
      ticks?: GoogleChartTicks;
      title?: string;
      viewWindow?: {
        max?: any;
        min?: any;
      };
      [otherOptionKey: string]: any;
    };
    vAxis?: {
      minValue?: any;
      maxValue?: any;
      ticks?: GoogleChartTicks;
      title?: string;
      viewWindow?: {
        max?: any;
        min?: any;
      };
      [otherOptionKey: string]: any;
    };
    legend: any;
    colors: string[];
    [otherOptionKey: string]: any;
  }>;
  // dataTable?: GoogleDataTable | null; // This exists, but should __NEVER__ be saved, due to size
  view?: any[] | {};
}

// Public function for data validity checking for Chart
export function canDraw(data?: SparqlResponse): boolean {
  return isSelectResponse(data) || isConstructResponse(data);
}

export interface Props {
  reducePadding?: boolean;
}

const Charts: React.FC<Props> = ({ reducePadding }) => {
  const {
    prefixes,
    setVisualizationConfig,
    getVisualizationConfig,
    visualizationActionsCtrId,
    registerDownload,
    unregisterDownload,
  } = React.useContext(SparqlVisualizationContext);
  const { results, emptyResults, variables, isAsk, noResults, isTabular } = useSparqlResults();
  const hasLoaded = React.useRef(false);
  const chartEditorRef = React.useRef<GoogleChartEditor>();
  const chartWrapperRef = React.useRef<GoogleChartWrapper>();
  const googleRef = React.useRef<GoogleViz>();
  const [googleTableData, setGoogleTableData] = React.useState<string | undefined>(undefined);
  const chartContainerRef = React.useRef<HTMLDivElement>(null);
  const visContainerRef = React.useRef<HTMLDivElement>(null);

  function onConfigureClick() {
    if (googleRef.current && chartWrapperRef.current && chartEditorRef.current) {
      chartEditorRef.current.openDialog(chartWrapperRef.current);
    }
  }

  React.useEffect(() => {
    if (googleRef.current && isTabular)
      setGoogleTableData(parseGoogleData(googleRef.current, results, variables, prefixes));
  }, [results, prefixes, variables, isTabular]);

  const chartType = getVisualizationConfig("Charts")?.chartType || "Table";

  React.useEffect(() => {
    if (registerDownload && chartType !== undefined && chartType !== "Table") {
      registerDownload({
        id: "Charts",
        displayName: "SVG",
        extension: ".svg",
        getDataUrl: () => {
          return window.URL.createObjectURL(
            new Blob([chartContainerRef.current!.getElementsByTagName("svg")[0].outerHTML], { type: "image/svg+xml" })
          );
        },
      });
    }

    return () => {
      if (unregisterDownload) {
        unregisterDownload("Charts");
      }
    };
  }, [chartType, registerDownload, unregisterDownload]);

  React.useEffect(() => {
    return () => {
      if (chartWrapperRef.current && googleRef.current && chartEditorRef.current) {
        googleRef.current.visualization.events.removeAllListeners(chartWrapperRef.current);
        chartEditorRef.current.closeDialog();
      }
    };
  }, [emptyResults, noResults]);

  if (noResults) return null;
  if (isAsk) {
    return (
      <Alert severity="warning" role="alert">
        This visualization can't be rendered
      </Alert>
    );
  }
  if (emptyResults) {
    return <EmptyResults />;
  }
  return (
    <div ref={visContainerRef} className={getClassName(styles.chartContainer)}>
      {!!setVisualizationConfig && (
        <ConfigureButton onConfigureClick={onConfigureClick} visualizationActionsCtrId={visualizationActionsCtrId} />
      )}
      <div className={getClassName(styles.chartBoundary, { [styles.table]: chartType === "Table" })}>
        <div
          ref={chartContainerRef}
          className={getClassName(styles.chart, {
            [styles.orgChart]: chartType === "OrgChart",
          })}
        >
          <React.Suspense fallback={<Skeleton height="inherit" variant="rectangular" />}>
            <Chart
              loader={<Skeleton height="500px" variant="rectangular" />}
              chartType={getVisualizationConfig("Charts")?.chartType || "Table"}
              data={googleTableData || undefined}
              chartPackages={["corechart", "charteditor"]}
              getChartEditor={({ chartEditor, chartWrapper, google }) => {
                chartEditorRef.current = chartEditor;
                chartWrapperRef.current = chartWrapper;

                if (chartWrapperRef.current && reducePadding) {
                  function reduceGoogleChartPadding() {
                    if (chartType === "GeoChart") return;
                    const svg = chartContainerRef.current?.querySelector("svg");
                    if (svg) {
                      // Remove rectangle that seems only to be used for setting a white background color
                      // Not removing this means the bounding box never crops the svg
                      svg.querySelector('rect[x="0"][y="0"][stroke="none"]')?.remove();
                      //In some cases (e.g. when using a vertical bar chart) there is an svg clip element
                      //We need to temporarily hide this before fetching the bbox, otherwise we'd end up with an incorrect height
                      const clipElement: HTMLElement | null = svg.querySelector(`[clip-path]`);
                      if (clipElement) {
                        clipElement.style.display = "none";
                      }
                      const bbox = svg.getBBox();
                      const offsetTop = bbox.y;
                      const actualChartHeight = Math.round(bbox.height);

                      if (clipElement) {
                        //Re-activate clip element again
                        clipElement.style.display = "block";
                      }
                      svg.setAttribute("height", `${actualChartHeight + offsetTop}`);
                      if (chartContainerRef.current) {
                        chartContainerRef.current.style.height = `${actualChartHeight + offsetTop}px`;
                        chartContainerRef.current.style.marginTop = `-${offsetTop}px`;
                        visContainerRef.current?.style.setProperty("--tooltip-offset", `-${offsetTop}px`);
                      }
                      // Note the API types don't allow this, as the interface is missing the interaction definitions for the charts themselves
                      google.visualization.events.addListener(
                        chartWrapper.getChart() as any,
                        "onmouseover" as any,
                        () => {
                          const hoverEl = chartContainerRef.current?.querySelector(".google-visualization-tooltip");
                          visContainerRef.current && hoverEl && visContainerRef.current.appendChild(hoverEl);
                        }
                      );
                    }
                  }
                  google.visualization.events.addListener(chartWrapperRef.current, "ready", reduceGoogleChartPadding);
                }

                google.visualization.events.addListener(chartEditorRef.current, "ok", () => {
                  const updatedChartWrapper = chartEditorRef.current?.getChartWrapper();
                  if (updatedChartWrapper) {
                    const chartType = updatedChartWrapper.getChartType();
                    if (chartType === "OrgChart" || chartType === "Table") {
                      updatedChartWrapper.setOption("height", "100%");
                    } else {
                      updatedChartWrapper.setOption("height", "600px");
                    }
                    updatedChartWrapper.setOption("width", "100%");
                    updatedChartWrapper.draw();
                    setVisualizationConfig?.("Charts", {
                      chartType: updatedChartWrapper.getChartType(),
                      options: updatedChartWrapper.getOptions(),
                      view: updatedChartWrapper.getView(),
                    });
                  }
                });
              }}
              //Since the google viz object is available onLoad of Chart, we create and set the data object then
              onLoad={(google) => {
                googleRef.current = google;
                setGoogleTableData(parseGoogleData(googleRef.current, results, variables, prefixes));
                hasLoaded.current = true;
              }}
              height={chartType === "OrgChart" || chartType === "Table" ? "100%" : "600px"}
              //DeepCloning the options config as a mutable object needs to be passed into charts
              options={{
                ...(cloneDeep(getVisualizationConfig("Charts")?.options) || {}),
                tooltip: { isHtml: true, ignoreBounds: false },
                width: undefined,
                height: undefined,
              }}
              chartWrapperParams={{
                view: getVisualizationConfig("Charts")?.view,
              }}
              chartEvents={[
                {
                  eventName: "select",
                  callback: () => {
                    const hoverEl = chartContainerRef.current?.querySelector(".google-visualization-tooltip");
                    visContainerRef.current && hoverEl && visContainerRef.current.appendChild(hoverEl);
                  },
                },
              ]}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Charts);

// Portal for the configure action since it will be placed in the parent component
const ConfigureButton: React.FC<{
  onConfigureClick: () => void;
  visualizationActionsCtrId?: string;
}> = ({ onConfigureClick, visualizationActionsCtrId }) => {
  if (visualizationActionsCtrId) {
    const container = document.getElementById(visualizationActionsCtrId);
    if (container) {
      return ReactDOM.createPortal(
        <Button
          className="my-2 mx-3"
          variant="outlined"
          startIcon={<FontAwesomeIcon icon="gears" />}
          onClick={onConfigureClick}
        >
          Configure
        </Button>,
        container
      );
    }
  }

  return null;
};
