import { Completion, CompletionContext, CompletionResult } from "@codemirror/autocomplete";
import { syntaxTree } from "@codemirror/language";
import { EditorState } from "@codemirror/state";
import { SyntaxNode } from "@lezer/common";
import Debug from "debug";
import { isEmpty } from "lodash-es";
import { node } from "prop-types";
import { OutsideVariableFacet } from "../facets/outsideVariables.ts";
import { getScopeAndParentForPos, QueryScope } from "../fields/queryContext.ts";
import {
  AdditiveExpression,
  Aggregate,
  ArgList,
  AS_KEY,
  Bind,
  BrackettedExpression,
  BuiltInCall,
  ConditionalAndExpression,
  ConditionalOrExpression,
  ConstructTemplate,
  ConstructTriples,
  DescribeQuery,
  ExpressionList,
  GroupClause,
  GroupGraphPattern,
  GroupGraphPatternSub,
  GroupOrUnionGraphPattern,
  InlineData,
  InlineDataFull,
  InlineDataOneVar,
  MultiplicativeExpression,
  Object as ObjectTerm,
  ObjectList,
  ObjectListPath,
  ObjectPath,
  OrderClause,
  PathEltOrInverse,
  PathMod,
  PrimaryExpression,
  PropertyListNotEmpty,
  PropertyListPathNotEmpty,
  QuadsNotTriples,
  RegexExpression,
  RelationalExpression,
  SelectClause,
  SelectQuery,
  ServiceGraphPattern,
  StrReplaceExpression,
  TriplesBlock,
  TriplesSameSubject,
  TriplesSameSubjectPath,
  TriplesTemplate,
  Var,
} from "../grammar/sparqlParser.terms.ts";

const debug = Debug("triply:sparql-editor:autocomplete:vars");

function getAutocompletionObject(autocompletionNode: SyntaxNode, options: [string, any][]): CompletionResult | null {
  if (options.length === 0) return null;
  return {
    from: autocompletionNode.from + 1, // The variable characters are interchangeable
    to: autocompletionNode.to,
    options: options.map(([name, _]) => {
      return {
        label: name,
        apply: name,
      };
    }),
  };
}

export function sparqlVarAutoComplete(context: CompletionContext): CompletionResult | null {
  debug("Start autocompletion");
  const { state, pos } = context;
  const tree = syntaxTree(state);
  const cursorNode = tree.resolve(pos); // returns node inside tree
  const nodeRightOfCursor = tree.resolve(pos, +1);
  // Match as we want to select the token before the cursor in case of `?va| ` the current token will be the whitespace, which will refer to the parent (in this case a TriplesBlock)
  const nodeLeftOfCursor = tree.resolve(pos, -1);
  if (nodeIsVar(nodeLeftOfCursor, nodeRightOfCursor, state)) {
    const [scope, parent] = getScopeAndParentForPos(state.field(QueryScope), pos);
    if (!scope) return null;
    const options = [];
    const projectedVariablesNotInBoundVariables = Object.entries(scope.projectedVariables).filter(
      ([name, node]) => !(name in scope.boundVariables) && node.to !== nodeLeftOfCursor.to
    );
    const externalVariables = state
      .facet(OutsideVariableFacet)
      .map<[string, any]>((val) => [val, undefined])
      .filter(([name, _]) => !(name in scope.projectedVariables || name in scope.boundVariables));

    if (cursorNode.type.id === Bind) {
      const asKeyword = cursorNode.getChild(AS_KEY);
      if (asKeyword && asKeyword.to < pos) {
        return getAutocompletionObject(nodeLeftOfCursor, [
          ...Object.entries(scope.boundVariables).filter(
            ([_, { firstOccurrence }]) => firstOccurrence.from > pos && nodeLeftOfCursor.to !== firstOccurrence.to
          ),
          ...projectedVariablesNotInBoundVariables,
          ...externalVariables,
        ]);
      }
    }
    if (cursorNode.type.id === Aggregate) {
      // Within aggregates we want to autocomplete
      return getAutocompletionObject(nodeLeftOfCursor, [
        ...Object.entries(scope.boundVariables).filter(([name, _]) => !(name in scope.projectedVariables)),
      ]);
    }
    let selectClause: SyntaxNode | null = null;
    if (cursorNode.type.id === SelectQuery) {
      selectClause = cursorNode.getChild(SelectClause);
    } else if (cursorNode.type.id === SelectClause) {
      selectClause = cursorNode;
    } else if (nodeLeftOfCursor.parent?.type.id === SelectClause) {
      selectClause = nodeLeftOfCursor.parent;
    }
    if (selectClause) {
      const isAfterAsKeyword = nodeLeftOfCursor.prevSibling?.type.id === AS_KEY;
      // I guess we want completions from the parent node in this case.
      if (isAfterAsKeyword) {
        if (parent) {
          return getAutocompletionObject(
            nodeLeftOfCursor,
            [
              ...Object.entries(parent.boundVariables),
              ...Object.entries(parent.projectedVariables),
              ...externalVariables,
            ].filter(([name, _]) => !(name in scope.projectedVariables || name in parent.boundVariables))
          );
        } else {
          return null;
        }
      }
      // Expressions should not be filtered
      const inExpression = nodeLeftOfCursor.parent?.parent?.type.id === PrimaryExpression;
      // There is still one case where we are in an expression
      // Here both the incomplete variable and as keyword are invalid
      // (?| as ?something)
      const rebindingVariable = nodeLeftOfCursor.node.nextSibling?.firstChild?.type.id === AS_KEY;
      if (!inExpression && !rebindingVariable) {
        // No need to add the projected query
        return getAutocompletionObject(
          nodeLeftOfCursor,
          Object.entries(scope.boundVariables).filter(
            ([name, { firstOccurrence }]) =>
              !(name in scope.projectedVariables) && nodeLeftOfCursor.to !== firstOccurrence.to
          )
        );
      }
    }
    return getAutocompletionObject(nodeLeftOfCursor, [
      ...Object.entries(scope.boundVariables).filter(
        ([_, { firstOccurrence, bound }]) => nodeLeftOfCursor.to !== firstOccurrence.to || bound
      ),
      ...projectedVariablesNotInBoundVariables,
      ...externalVariables,
    ]);
  }
  debug("Autocompletion options generated");
  return null;
}

function nodeIsVar(nodeBeforeCursor: SyntaxNode, nodeAfterCursor: SyntaxNode, state: EditorState) {
  // Variable ?asd
  if (nodeBeforeCursor.parent?.type.id === Var) return !nodeBeforeCursor.parent?.parent?.type.isError; // We don't want to autocomplete when the var token is inside an invalid position

  // previous var is an error: ...{ ?a ?b ?c ? ?<--catches this
  if (nodeBeforeCursor.prevSibling?.type.isError) return false;

  // Lets make sure not to try larger tokens ?, as qwe p[rt[wqpr]]
  if (nodeBeforeCursor.to - nodeBeforeCursor.from !== 1) return false;

  // catches space between object position var and pathmod:  ?sub rdf:x/rdf:y ?
  if (nodeBeforeCursor.type.id === PathMod) {
    return state.doc.sliceString(nodeBeforeCursor.from - 1, nodeBeforeCursor.from) === " ";
  }

  // var after objectPath should be false: ?s ?p ?o , ?avg ?
  if (nodeBeforeCursor.prevSibling?.type.id === ObjectPath) return false;

  if (
    !nodeBeforeCursor.type.isError ||
    ["?", "$"].indexOf(state.sliceDoc(nodeBeforeCursor.from, nodeBeforeCursor.from + 1)) === -1
  )
    return false;

  // Node is inside Construct Query
  if (
    nodeBeforeCursor.parent?.type.id === ConstructTriples ||
    nodeAfterCursor.type.id === ConstructTriples ||
    nodeAfterCursor.parent?.parent?.type.id === ConstructTriples ||
    nodeAfterCursor.type.id === ConstructTemplate ||
    nodeAfterCursor.parent?.type.id === ConstructTemplate
  ) {
    /**
     * Grammar rule:	PropertyListNotEmpty { Verb ObjectList }
     * This differs from the where clause grammar
     * if the varNode is after the ObjectList we shouldn't allow it.
     * PropertyListNotEmpty { Verb ObjectList ? } // should return false
     */
    const isVarAfterObjectList =
      nodeBeforeCursor.parent?.type.id === ObjectList && nodeBeforeCursor.prevSibling?.type.id === ObjectTerm;
    return !isVarAfterObjectList;
  }
  if (nodeBeforeCursor.parent?.type.id === Bind) {
    if ((nodeAfterCursor.parent?.getChild(AS_KEY)?.to || -1) < nodeBeforeCursor.from) {
      return true;
    } else if (nodeBeforeCursor.parent?.getChild(Var)?.to === nodeBeforeCursor.to) return true;
  }

  if (
    [
      SelectClause,
      ConstructTemplate,
      Aggregate,
      BuiltInCall,
      ExpressionList,
      StrReplaceExpression,
      GroupClause,
      OrderClause,
      GroupGraphPattern,
      RegexExpression,
      GroupGraphPatternSub,
      ArgList,
      GroupOrUnionGraphPattern,
      DescribeQuery,
      AdditiveExpression,
      RelationalExpression,
      ConditionalOrExpression,
      QuadsNotTriples,
      ConditionalAndExpression,
      MultiplicativeExpression,
      ServiceGraphPattern,
      PropertyListPathNotEmpty,
      PropertyListNotEmpty,
      TriplesTemplate,
      ObjectList,
      ObjectListPath,
      TriplesBlock,
      TriplesSameSubjectPath,
      TriplesSameSubject,
      BrackettedExpression,
      InlineDataOneVar,
      InlineDataFull,
      InlineData,
    ].includes(nodeBeforeCursor.parent?.type.id || -1)
  )
    return true;

  if (nodeBeforeCursor.prevSibling?.type.id === PathEltOrInverse) return true;

  // Just logging the current node and the parent
  debug("Possible var autocomplete position", {
    leftNode: {
      from: nodeBeforeCursor.from,
      to: nodeBeforeCursor.to,
      name: nodeBeforeCursor.name,
      parent: nodeBeforeCursor.parent
        ? { from: nodeBeforeCursor.parent.from, to: nodeBeforeCursor.parent.to, name: nodeBeforeCursor.parent.name }
        : undefined,
    },
  });
  return false;
}
