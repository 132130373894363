import { Paper } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { AuthenticatorCode, LoadingButton, Meta } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { showNotification } from "#reducers/notifications.ts";
import styles from "./style.scss";

const Login2FA: React.FC<{}> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();
  const query = parseSearchString(location.search);
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [authenticatorCode, setAuthenticatorCode] = React.useState<string>("");
  const authenticatedUser = useAuthenticatedUser();
  const history = useHistory();

  React.useEffect(() => {
    //already logged in, redirect to account page
    if (authenticatedUser) {
      const destination = (query?.returnTo as string) ?? `/${authenticatedUser.accountName}`;
      history.push(destination);
    }
  }, [authenticatedUser, history, location.search, query]);

  React.useEffect(() => {
    if (authenticatorCode.length === 6) {
      sendCode(true).catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatorCode]);

  async function sendCode(hidden: boolean) {
    if (!hidden) setLoading(true);
    if (done) return;
    try {
      const response = await fetch(constructUrlToApi({ pathname: "/web/auth/local/login/2fa" }), {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: authenticatorCode }),
      });
      const body = response.headers.get("content-type")?.includes("application/json")
        ? await response.json()
        : undefined;

      if (done) return;

      if (response.status === 200) {
        setDone(true);
        window.location.replace((query?.returnTo as string) || "/" + body?.accountName);
        return;
      }

      setLoading(false);
      if (hidden) return;

      if (response.status === 401 && body?.code === 98) {
        // User-error, due to an incorrect authenticator code.
        setError("The code that you entered is incorrect. Please try again.");
      } else if (response.status >= 500) {
        // Server-error, due to api disruptions.
        dispatch(showNotification("Failed sending authenticator code. Please try again.", "error"));
      } else {
        dispatch(showNotification("Something went wrong. Please try again.", "error"));
      }
    } catch (e) {
      setLoading(false);
      if (!hidden) dispatch(showNotification("Failed sending authenticator code. Please try again.", "error"));
    }
  }

  return (
    <div className="flex horizontalCenter mt-6">
      <Meta currentPath={location.pathname} title="Two-factor authentication" />
      <Paper square className={styles.paper}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendCode(false).catch(() => {});
          }}
        >
          <p className="m-0">Please enter the 6-digit code from your authenticator app.</p>
          <AuthenticatorCode
            value={authenticatorCode}
            onChange={(code: string) => {
              if (error) setError(undefined);
              setAuthenticatorCode(code);
            }}
            hasErrored={!!error}
            shouldAutoFocus
            containerStyle="my-4"
          />
          <LoadingButton
            type="submit"
            color="warning"
            disabled={authenticatorCode.length !== 6 || !!error || loading}
            loading={loading}
          >
            Login
          </LoadingButton>
          {error && <p className={styles.errorText}>{error}</p>}
          <Link to="recovery" className={getClassName("mt-3", styles.recoverText)}>
            I have lost my authentication device
          </Link>
        </form>
      </Paper>
    </div>
  );
};

export default Login2FA;
