import { Column } from "@tanstack/react-table";
import * as React from "react";
import BooleanFilter from "./BooleanFilter.tsx";
import SelectFilter from "./SelectFilter.tsx";
import LinearSliderColumnFilter, { LogSliderColumnFilter } from "./SliderFilter.tsx";
import TextFilter from "./TextFilter.tsx";

interface Props {
  column: Column<any, unknown>;
}

const Filter: React.FC<Props> = ({ column }) => {
  const maxRef = React.useRef(
    // Using a ref because we want the returned component to be consistent.
    (column.columnDef.filterFn === "inNumberRange" && column.getCanFilter() && column.getFacetedMinMaxValues()?.[1]) ||
      0
  );
  if (!column.getCanFilter()) {
    return null;
  }
  switch (column.columnDef.filterFn) {
    case "inNumberRange":
      if (maxRef.current <= 1_000) {
        return <LinearSliderColumnFilter column={column as Column<any, number>} />;
      } else {
        return <LogSliderColumnFilter column={column as Column<any, number>} />;
      }
    case "equals":
      const useBooleanFilter = (column.columnDef.sortingFn as any) === "boolean"; // Needs to be the same as a custom injected sort function
      return useBooleanFilter ? <BooleanFilter column={column as any} /> : <SelectFilter column={column as any} />;
    case "includesString":
    default:
      return <TextFilter column={column as Column<any, string>} />;
  }
};

export default Filter;
