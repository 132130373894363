import { NoSsr, TextField, TextFieldProps } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import * as ReduxForm from "redux-form";
import styles from "./style.scss";

/**
 * Regular text field. Use mui text field as-is
 */
namespace MuiTextField {
  export interface OwnProps {
    warning?: boolean;
    name?: string;
    variant?: TextFieldProps["variant"];
    leftAlignStartAdornment?: boolean;
  }
  export type Props = TextFieldProps & OwnProps;
}
const MuiTextField = React.memo<MuiTextField.Props>((props) => {
  const { warning, leftAlignStartAdornment, ...textFieldProps } = props;
  /**
   * Fix for "shrunken label when using a startAdornment (icon)"
   * By default it'll stay "shrunken" (over top over the input even when it's empty)
   */
  const [focussed, setFocus] = React.useState(false);
  if (textFieldProps.InputProps?.startAdornment && !leftAlignStartAdornment) {
    textFieldProps.InputLabelProps = {
      ...textFieldProps.InputLabelProps,
      shrink: focussed || !!textFieldProps.placeholder,
      className: getClassName(textFieldProps.InputLabelProps?.className, styles.firstAdornmentFix),
    };
    textFieldProps.onFocus = (event) => {
      setFocus(true);
      props.onFocus && props.onFocus(event);
    };
    textFieldProps.onBlur = (event) => {
      setFocus(false);
      props.onBlur?.(event);
    };
  }
  // We're wrapping this in a NoSsr component, as an `autofocus` property causes this component to be rendered wrong on the server
  return (
    <NoSsr>
      <TextField
        {...textFieldProps}
        className={getClassName({
          [props.className || ""]: !!props.className,
          [styles.warning]: warning,
        })}
        error={warning || textFieldProps.error}
      />
    </NoSsr>
  );
});
export default MuiTextField;

/**
 * Text field wrapped for easy integration in redux-form field
 */
export namespace MuiTextFieldRedux {
  export type WrappedProps = ReduxForm.WrappedFieldProps & Props;
  export type Props = MuiTextField.Props & {
    formIsPristine?: boolean;
  };
}
export const MuiTextFieldRedux = React.memo<MuiTextFieldRedux.WrappedProps>(
  ({ input, meta, helperText, formIsPristine, ...props }) => {
    let hasError = false;
    let hasWarning = false;
    /**
     * I'm assuming here that immediate feedback for a warning is desirable, where errors should only be shown onblur (i.e. meta.touched)
     * Use case for warnings: changing the name of a query-catalog item should immediately show a warning on change.
     * Use case for error: typing the name of a dataset shouldnt show an error while typing as you'll always see the min-length error popping up when you start to type
     * If the assumption above is wrong, let's think about how/whether we should make this configurable
     */

    if (!formIsPristine && meta.touched && meta.error) {
      helperText = meta.error;
      hasError = true;
    } else if (formIsPristine && props.autoFocus && meta.dirty && meta.error) {
      helperText = meta.error;
      hasError = true;
    } else if (meta.warning) {
      helperText = meta.warning;
      hasWarning = true;
    }
    return <MuiTextField {...input} {...props} helperText={helperText} error={hasError} warning={hasWarning} />;
  }
);
