import { Checkbox, MenuItem } from "@mui/material";
import getClassName from "classnames";
import { find } from "lodash-es";
import memoizee from "memoizee";
import * as React from "react";
import { connect } from "react-redux";
import * as ReduxForm from "redux-form";
import { Dataset, Graph } from "@triply/utils/Models.js";
import {
  Alert,
  Avatar,
  Button,
  FontAwesomeIcon,
  Highlight,
  LoadingButton,
  MuiAutosuggest,
  MuiAutosuggestRedux,
  PlainTextField,
} from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import { ensureTrailingDot } from "#helpers/utils.ts";
import { getGraphsFor } from "#reducers/imports.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";
import styles from "./style.scss";

const PAGE_SIZE = 30;

namespace ImportForm {
  export interface FormData {
    dataset: Dataset;
    graphs: FormGraph[];
  }
  //Each field in this form might return a string as error message, or an object with string values (for the graphs formgroup)
  export type ErrorTypes = { [field: string]: string | { [key: string]: string } };
  export interface FormGraph {
    from: string;
    to: string;
    checked: boolean;
  }
  export interface State {
    graphs: FormGraph[];
    dsError?: string;
    nrToShow: number;
  }
  export interface PropsFromState {
    numSelected: number;
    dataset: Dataset;
  }
  export interface DispatchProps {}

  export interface OwnProps extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    getGraphsFor: DispatchedFn<typeof getGraphsFor>;
    datasetsUrl: string;
    initialGraphCount: number;
    cancel?: React.EventHandler<React.MouseEvent<any>>;
    className?: string;
  }

  export type Props = OwnProps & DispatchProps & PropsFromState;
}

const _ImportForm = ReduxForm.reduxForm<ImportForm.FormData, ImportForm.Props, ImportForm.ErrorTypes>({
  form: "import",
  validate: memoizee(
    (formData: ImportForm.FormData) => {
      function graphValidation() {
        const errors: ImportForm.ErrorTypes = {};
        const formGraphs = formData.graphs || [];
        formGraphs.forEach(function (g1, key) {
          if (!g1.checked) return;
          if (!g1.to) {
            errors[key] = { to: "Required" };
          } else if (find(formGraphs, (g2) => g1 !== g2 && g2.checked && g1.to === g2.to) !== undefined) {
            errors[key] = { to: "Must be unique" };
          }
        });

        if (!formGraphs || !formGraphs.filter((g) => g.checked).length) {
          errors["_error"] = "Select at least one graph";
        }

        return errors;
      }
      return {
        graphs: graphValidation(),
      };
    },
    { max: 10 }
  ),
})(
  class ImportForm extends React.PureComponent<
    ImportForm.Props & Partial<ReduxForm.InjectedFormProps<FormData>>,
    ImportForm.State
  > {
    state: ImportForm.State = {
      graphs: [],
      dsError: undefined,
      nrToShow: PAGE_SIZE,
    };
    componentDidMount() {
      if (this.props.initialValues?.dataset) {
        //fetch the graphs so we can draw the graph selection widget for this dataset
        this.getGraphsFor(this.props.initialValues.dataset);
      }
    }
    getGraphsFor = (dataset: Dataset) => {
      this.resetGraphs();
      if (!dataset) {
        return this.setState({ dsError: "Not a valid dataset" });
      }

      this.props.getGraphsFor(dataset).then(
        (result) => {
          const graphs: ImportForm.FormGraph[] = result.body.map((g: Graph) => ({
            from: g.graphName,
            to: g.graphName,
            checked: true,
          }));
          this.addGraphs(graphs.slice(0, PAGE_SIZE));
          this.setState((s) => ({
            ...s,
            graphs: graphs,
            nrToShow: PAGE_SIZE,
            dsError: graphs.length > 0 ? undefined : `Dataset '${this.getDatasetName(dataset)}' is empty`,
          }));
        },
        (e) => {
          this.setState((s) => ({ ...s, dsError: e.message }));
          this.resetGraphs();
        }
      );
    };

    resetGraphs() {
      this.props.array?.removeAll("graphs");
      this.setState((s) => ({ ...s, graphs: [], nrToShow: PAGE_SIZE }));
    }
    addGraphs(graphs: Array<ImportForm.FormGraph>) {
      for (var i = 0; i < graphs.length; i++) {
        this.props.array?.push("graphs", graphs[i]);
      }
    }
    showMoreGraphs = () => {
      if (this.state.graphs.length > this.state.nrToShow) {
        const graphs: ImportForm.FormGraph[] = this.state.graphs.slice(
          this.state.nrToShow,
          this.state.nrToShow + PAGE_SIZE
        );
        this.addGraphs(graphs);
        this.setState((s) => ({ nrToShow: s.nrToShow + PAGE_SIZE }));
      }
    };

    handleDatasetChange = (dataset: Dataset) => {
      this.props.change?.("dataset", dataset);
      this.getGraphsFor(dataset);
    };

    getDatasetName = (dataset: Dataset) => {
      return dataset.owner.accountName + " / " + dataset.name;
    };

    searchDataset = (queryString: string) => this.search(this.props.datasetsUrl + "/?q=" + queryString);

    search(url: string): Promise<Dataset[]> {
      return this.fetch(url).then((results) => {
        if (results && Array.isArray(results)) {
          return results;
        }
        return [];
      });
    }

    fetch = memoizee(
      async (url: string) => {
        return fetch(url, { credentials: "same-origin" })
          .then((response) => {
            if (response.status === 200) return response.json() as Promise<Dataset[]>;
          })
          .catch((error) => {
            console.error(error);
          });
      },
      { primitive: true, promise: true }
    );

    render() {
      const { handleSubmit, submitting, error, pristine, invalid, cancel, className } = this.props;
      const { dsError } = this.state;

      const totalGraphCount = this.props.dataset ? this.props.dataset.graphCount : this.props.initialGraphCount;
      const selectedGraphCount = this.props.numSelected ? this.props.numSelected : 0;

      return (
        <form method="POST" onSubmit={handleSubmit} className={getClassName(styles.form, className)}>
          <h4>Import from dataset</h4>
          <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiAutosuggest.Props<Dataset, Dataset>>>
            name="dataset"
            props={{
              label: "Dataset",
              loadSuggestions: this.searchDataset,
              clearOnSelect: false,
              TextFieldProps: {
                fullWidth: true,
              },
              transformInitialValueToSearchText: (initialValue) => {
                return this.getDatasetName(initialValue);
              },
              onSuggestionSelected: (_e, data) => this.handleDatasetChange(data.suggestion),
              getSuggestionSearchText: this.getDatasetName,
              renderSuggestion: (ds, { query, isHighlighted }) => {
                return (
                  <MenuItem selected={isHighlighted} component="div">
                    <Avatar
                      size="sm"
                      className="mr-2"
                      avatarUrl={ds.avatarUrl}
                      avatarName={ds.displayName || ds.name}
                      alt=""
                    />
                    <Highlight fullText={this.getDatasetName(ds)} highlightedText={query} />
                  </MenuItem>
                );
              },
            }}
            component={MuiAutosuggestRedux}
          />
          {dsError && <Alert transparent message={dsError} />}
          <div className={styles.graphWrapper}>
            <ReduxForm.FieldArray name={"graphs"} component={renderGraphs as any} />
          </div>
          {this.state.graphs && this.state.graphs.length > this.state.nrToShow && (
            <div className={styles.showMore}>
              <Button onClick={this.showMoreGraphs}>Show more</Button>
            </div>
          )}
          <Alert transparent message={error} />
          <div className={styles.submit}>
            <LoadingButton
              type="submit"
              color="secondary"
              disabled={submitting || pristine || !!dsError || invalid}
              onClick={handleSubmit}
              loading={submitting}
            >
              Import {selectedGraphCount !== totalGraphCount ? `${selectedGraphCount} out of` : ""} {totalGraphCount}{" "}
              graphs
            </LoadingButton>
            {!!cancel && (
              <Button onClick={cancel} variant="text" className="ml-2">
                Cancel
              </Button>
            )}
          </div>
        </form>
      );
    }
  } as any
);
const renderGraphs = (props: ReduxForm.WrappedFieldArrayProps<any>) => {
  return (
    props.fields.length > 0 && (
      <div className={styles.graphs}>
        <h5>Select graphs</h5>
        <div className={styles.graphList}>
          {props.fields.map((graph) => {
            return (
              <div key={`${graph}.from`} className={styles.graph}>
                <ReduxForm.Field name={`${graph}.checked`} component={renderCheckbox} />
                <PlainTextField.Field
                  name={`${graph}.from`}
                  className={styles.from}
                  disabled
                  component={PlainTextField}
                />
                <FontAwesomeIcon className={styles.arrow} icon="long-arrow-right" />
                <PlainTextField.Field
                  name={`${graph}.to`}
                  className={styles.to}
                  immediateError
                  component={PlainTextField}
                />
              </div>
            );
          })}
          {props.meta.error && <div className={styles.error}>{ensureTrailingDot(props.meta.error)}</div>}
        </div>
      </div>
    )
  );
};

const renderCheckbox = ({ input }: ReduxForm.WrappedFieldProps) => (
  <Checkbox className={styles.checkBox} color="primary" checked={!!input.value} onChange={input.onChange} />
);

const selector = ReduxForm.formValueSelector("import"); // <-- same as form name
const ImportForm = connect<ImportForm.PropsFromState, ImportForm.DispatchProps, {}, GlobalState>(
  (state) => {
    const graphs = selector(state, "graphs");
    const ds = selector(state, "dataset");
    return {
      numSelected: graphs === undefined ? graphs : graphs.filter((g: ImportForm.FormGraph) => g.checked).length,
      dataset: ds,
    };
  },
  //dispatch
  {}
)(_ImportForm);
export default ImportForm;
