import {
  HierarchyNode,
  interpolate,
  scaleOrdinal,
  schemeCategory10,
  select,
  Selection,
  stratify as d3Stratify,
} from "d3";
import numeral from "numeral";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import { Models } from "@triply/utils";
import { stringifyQuery } from "#helpers/utils.ts";

export type Datum = Models.ClassHierarchy[0] & { color?: string; branchColor?: string };

export interface ClassHierarchyComponentProps {
  classHierarchyTree: HierarchyNode<Datum>;
  focussedNode: HierarchyNode<Datum>;
  setFocus: (className: string) => void;
}

export const BreadCrumbs: React.FC<{
  type: "bubbles" | "treemap" | "sunburst";
  focussedNode: HierarchyNode<Datum>;
}> = ({ type, focussedNode }) => {
  const breadCrumbs = focussedNode
    .ancestors()
    .map((d) => ({ iri: d.data.name, label: getLabel(d) }))
    .reverse();
  return (
    <div className="mb-3">
      {breadCrumbs.map((b, i) =>
        i === breadCrumbs.length - 1 ? (
          <span key={b.label}>{b.label}</span>
        ) : (
          <span key={b.label}>
            <Link
              key={b.iri}
              to={{ search: stringifyQuery({ type: type, focus: b.iri }), state: { preserveScrollPosition: true } }}
            >
              {b.label}
            </Link>
            {" > "}
          </span>
        )
      )}
    </div>
  );
};

export const useRenderRef = (
  renderFunction: (
    node: HTMLDivElement,
    classHierarchyTree: HierarchyNode<Datum>,
    focussedNode: HierarchyNode<Datum>,
    setFocus: (focus: string) => void
  ) => void,
  classHierarchyTree: HierarchyNode<Datum>,
  focussedNode: HierarchyNode<Datum>,
  setFocus: (focus: string) => void
) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const resizeObserver = useResizeObserver({ ref });

  useEffect(() => {
    if (ref.current && !!resizeObserver.width) {
      renderFunction(ref.current, classHierarchyTree, focussedNode, setFocus);
    }
  }, [renderFunction, classHierarchyTree, resizeObserver.width, focussedNode, setFocus]);

  return ref;
};

export const getLocalName = (iri: string) =>
  iri.indexOf("#") >= 0 ? iri.split("#").slice(-1)[0] : iri.split("/").slice(-1)[0];

export const getLabel = (d: HierarchyNode<Datum>) =>
  (d.id && getLocalName(d.id)) || d.data.prefixInfo?.localName || d.id || "";

export const formatInstances = (n: number | undefined) =>
  n === 1 ? "1 instance" : `${numeral(n || 0).format("0,0")} instances`;

export const pruneEmptyClasses = (data: Models.ClassHierarchy) =>
  d3Stratify<Datum>()
    .id((d) => d.name)
    .parentId((d) => d.parent)(data)
    .sum((d) => +d.numberOfDirectInstances)
    .descendants()
    .filter((d: HierarchyNode<Datum>) => d.value && d.value > 0)
    .map((d) => d.data);

export const stratify = <D extends Datum>(data: Models.ClassHierarchy) =>
  d3Stratify<D>()
    .id((d) => d.name)
    .parentId((d) => d.parent)(data as D[])
    .sum((d) => +d.numberOfDirectInstances)
    .sort((a: HierarchyNode<D>, b: HierarchyNode<D>) => (b.value || 0) - (a.value || 0));

export const setColor = (root: HierarchyNode<Datum>) => {
  root.data.color = "white";
  root.data.branchColor = "white";
  const colorScale = scaleOrdinal(schemeCategory10);
  for (const child of root.children || []) {
    const branchColor = colorScale(child.data.name);
    for (const descendant of child.descendants()) {
      descendant.data.color = interpolate("white", branchColor)((descendant.depth + 1) / (child.height + 2));
      descendant.data.branchColor = branchColor;
    }
  }
};

export function addShadowFilter(svg: Selection<SVGSVGElement, number, any, unknown>) {
  const filter = svg
    .append("defs")
    .append("filter")
    .attr("id", "drop-shadow")
    .attr("width", "200%")
    .attr("height", "200%");
  filter.append("feGaussianBlur").attr("in", "SourceAlpha").attr("stdDeviation", 3);
  filter.append("feOffset").attr("dx", 2).attr("dy", 2).attr("result", "offsetBlur");
  filter.append("feComponentTransfer").append("feFuncA").attr("type", "linear").attr("slope", 0.5);

  const feMerge = filter.append("feMerge");
  feMerge.append("feMergeNode");
  feMerge.append("feMergeNode").attr("in", "SourceGraphic");

  return svg;
}

export function catchErrors(
  renderFunction: (
    divElement: HTMLDivElement,
    classHierarchyTree: HierarchyNode<Datum>,
    focussedNode: HierarchyNode<Datum>,
    setFocus: (className: string) => void
  ) => void
) {
  return (
    divElement: HTMLDivElement,
    classHierarchyTree: HierarchyNode<Datum>,
    focussedNode: HierarchyNode<Datum>,
    setFocus: (className: string) => void
  ) => {
    try {
      renderFunction(divElement, classHierarchyTree, focussedNode, setFocus);
    } catch (e) {
      let message = `Could not render visualization`;
      if (e instanceof Error) {
        message += `: ${e.message}`;
      }
      const div = select(divElement).classed("flex", true).style("justify-content", "center");
      div.selectAll("*").remove();
      div.append("div").text(message).classed("p-5", true);
    }
  };
}
