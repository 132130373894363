import { produce } from "immer";
import { OptionsObject as NotificationOptions, useSnackbar, VariantType } from "notistack";
import { MarkRequired } from "ts-essentials";
import { Constants, Models } from "@triply/utils";
import { Action, Actions, BeforeDispatch, GlobalAction } from "#reducers/index.ts";
import { getRedirectAction } from "../components/Notifications";

export type Level = VariantType;
export const LocalActions = {
  CLOSE_NOTIFICATION: "triply/notifications/CLOSE_SNACKBAR",
  SHOW_NOTIFICATION: "triply/notifications/ENQUEUE_SNACKBAR",
} as const;

type REMOVE_ONE_NOTIFICATION = GlobalAction<{
  type: typeof LocalActions.CLOSE_NOTIFICATION;
  key?: string;
}>;

type SHOW_NOTIFICATION = GlobalAction<{
  type: typeof LocalActions.SHOW_NOTIFICATION;
  message: string;
  level: Level;
  notificationOptions?: NotificationOptions;
}>;

export type LocalAction = REMOVE_ONE_NOTIFICATION | SHOW_NOTIFICATION;

export interface Notification extends MarkRequired<NotificationOptions, "key"> {
  message: string;
}

export type State = Notification[];

export const reducer = produce((draftState: State, action: Action) => {
  const getNotificationWithKey = (notification: { message: string } & Partial<Notification>): Notification => {
    return {
      key: action.type + Date.now(),
      ...notification,
    };
  };
  const successMessage = (msg: string) => {
    draftState.push(
      getNotificationWithKey({
        message: msg,
        variant: "success",
      })
    );
  };
  const errorMessage = (action: Action) => {
    if ("message" in action && action.message) {
      draftState.push(
        getNotificationWithKey({
          variant: "error",
          message: action.message,
          // devMessage: "devMessage" in action && action.devMessage
        })
      );
    }
  };

  switch (action.type) {
    //first deal with errors that aren't handled by other reducers
    case Actions.IMPERSONATE_FAIL:
    case Actions.UNDO_IMPERSONATE_FAIL:
    case Actions.RESEND_VERIFICATION_EMAIL_FAIL:
    case Actions.GET_TOKENS_FAIL:
    case Actions.REVOKE_TOKEN_FAIL:
    case Actions.GET_TOKEN_FAIL:
    case Actions.DELETE_ACCOUNT_FAIL:
    case Actions.UPLOAD_LOGO_FAIL:
    case Actions.UPDATE_APPLICATION_CONFIG_FAIL:
    case Actions.UPDATE_AUTH_SETTINGS_FAIL:
    case Actions.UPDATE_FEATURE_TOGGLE_FAIL:
    case Actions.GET_SERVICE_LIST_AS_ADMIN_FAIL:
    case Actions.DELETE_SERVICE_FAIL:
    case Actions.ISSUE_SERVICE_COMMAND_FAIL:
    case Actions.DELETE_SERVICE_FROM_ADMIN_LIST_FAIL:
    case Actions.DELETE_DATASET_FAIL:
    case Actions.GET_ASSETS_FAIL:
    case Actions.RENAME_GRAPH_FAIL:
    case Actions.REMOVE_GRAPH_FAIL:
    case Actions.REMOVE_ALL_GRAPHS_FAIL:
    case Actions.UPDATE_HOOK_FAIL:
    case Actions.NEW_HOOK_FAIL:
    case Actions.GET_HOOKS_FAIL:
    case Actions.REMOVE_HOOK_FAIL:
    case Actions.GET_HOOK_RECORDS_FAIL:
    case Actions.UPDATE_DATASET_PREFIXES_FAIL:
    case Actions.ADD_QUERY_VERSION_FAIL:
    case Actions.GET_DESCRIPTION_FAIL:
    case Actions.GET_QUERIES_FAIL:
    case Actions.GET_STORIES_FAIL:
    case Actions.UPDATE_SERVICE_FAIL:
    case Actions.ENABLE_AUTHENTICATOR_APP_FAIL:
    case Actions.UPDATE_FEATURE_TOGGLE_FAIL:
    case Actions.ADD_DATASET_PREFIX_FAIL:
    case Actions.DELETE_QUERY_FAIL:
      errorMessage(action);
      return;

    //api calls where we want to ignore 404 status codes
    case Actions.GET_SERVICE_INFO_FAIL:
    case Actions.GET_TRIPLES_FAIL:
      if (action.status !== 404) {
        errorMessage(action);
      }
      return;

    case Actions.GET_CONFIG_FAIL:
      draftState.push(
        getNotificationWithKey({
          variant: "error",
          message: "Failed to connect to backend",
        })
      );
      return;

    //Some success messages
    case Actions.DELETE_SERVICE_SUCCESS:
      successMessage("Service successfully removed");
      return;
    case Actions.COPY_DATASET_SUCCESS:
      successMessage("Dataset successfully copied");
      return;
    case Actions.DELETE_DATASET_SUCCESS:
      successMessage("Dataset successfully deleted");
      return;
    case Actions.DELETE_QUERY_SUCCESS:
      successMessage(`Query '${action.query.name}' successfully deleted`);
      return;
    case Actions.DELETE_STORY_SUCCESS:
      successMessage(`Story '${action.story.name}' successfully deleted`);
      return;
    case Actions.DELETE_ACCOUNT_SUCCESS:
      const message = `${action.account.type === "user" ? "User" : "Organization"} '${
        action.account.accountName
      }' successfully deleted.`;
      successMessage(message);
      return;

    case Actions.SHOW_NOTIFICATION:
      if (action.message) {
        draftState.push(
          getNotificationWithKey({
            ...action.notificationOptions,
            variant: action.level,
            message: action.message,
          })
        );
      }
      return;
    case Actions.SOCKET_EVENT.jobUpdate:
      if (action.data.job.skippedFileCount > 0 && action.data.job.error) {
        draftState.push(
          getNotificationWithKey(
            getSkippedFilesNotification(action.data.job.skippedFileNames, action.data.job.skippedFileCount)
          )
        );
      }
      return;
    case Actions.SKIP_FILES:
      draftState.push(
        getNotificationWithKey(getSkippedFilesNotification(action.skippedFileNames, action.skippedFileNames.length))
      );
      return;
    case Actions.SOCKET_EVENT.jobFinished:
      const job = action.data.job;
      const graphs = action.data.graphs;
      if (job.skippedFileNames.length) {
        draftState.push(
          getNotificationWithKey(getSkippedFilesNotification(job.skippedFileNames, job.skippedFileCount))
        );
      }
      if (checkGraphsForDataQualityIssues(graphs, job.graphNames)) {
        draftState.push(
          getNotificationWithKey({
            variant: "warning",
            message: "Some uploaded graphs have data quality issues.",
            action: getRedirectAction(
              "View graphs",
              `/${action.data.dataset.owner.accountName}/${action.data.dataset.name}/graphs`
            ),
          })
        );
      }
      return;

    // some notification management actions
    case Actions.CLOSE_NOTIFICATION:
      if (action.key) {
        return draftState.filter((notification) => {
          notification.key !== action.key;
        });
      } else {
        draftState.shift();
      }
      return;
  }
}, <State>[]) as any;

function checkGraphsForDataQualityIssues(graphs: Models.Graphs, graphNames?: string[]) {
  if (!!graphNames && graphNames.length > 0) {
    if (graphs.find((graph) => graphNames.includes(graph.graphName) && graph.qualityReport)) return true;
  }
  return false;
}

function getSkippedFilesNotification(skippedFileNames: string[], skippedFileCount: number): Notification {
  const NUM_FILES_TO_SHOW = 5;

  const filesToShow = skippedFileNames.slice(0, NUM_FILES_TO_SHOW); // number of skipped files to show in the list
  const notShownCount = skippedFileCount - filesToShow.length; // number of skipped files that are not shown
  // format the notification message
  const message: string[] = [];
  message.push("Skipped " + (skippedFileCount === 1 ? "a file:" : "some files:"));
  filesToShow.forEach((file) => message.push(` - ${file}`));
  if (notShownCount > 0) message.push(` - And ${notShownCount} more file${notShownCount === 1 ? "" : "s"}`);
  message.push(""); // empty line
  message.push("The following file extensions are supported:");
  message.push(Constants.SUPPORTED_EXTENSIONS.map((ext) => `.${ext}`).join(", "));
  return {
    variant: "info",
    key: message.join("\n") + "info",
    message: message.join("\n"),
    autoHideDuration: (filesToShow.length + Constants.SUPPORTED_EXTENSIONS.length / 2) * 1000 + 4000,
    style: { whiteSpace: "pre-wrap" },
  };
}
/**
 * Removes notification
 * @param [key] If set it remove that notification from the list, otherwise it will only remove the first
 * @returns notification
 */
export function removeNotification(key?: string): BeforeDispatch<REMOVE_ONE_NOTIFICATION> {
  return {
    type: Actions.CLOSE_NOTIFICATION,
    key: key,
  };
}

export function showNotification(
  message: string,
  level: Level,
  notificationOptions?: NotificationOptions
): BeforeDispatch<SHOW_NOTIFICATION> {
  return {
    type: Actions.SHOW_NOTIFICATION,
    message: message,
    level: level,
    notificationOptions: notificationOptions,
  };
}
