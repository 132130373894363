import { NoSsr, Skeleton } from "@mui/material";
import bytes from "bytes";
import getClassName from "classnames";
import { flatten, toPairs, upperFirst } from "lodash-es";
import millify from "millify";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import { MarkRequired } from "ts-essentials";
import { LimitJsonLeaf } from "@triply/utils/Models.js";
import { formatNumber } from "@triply/utils-private/formatting.js";
import { FlexContainer, FontAwesomeIcon, HumanizedDate, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { getLimits } from "#reducers/admin.ts";
import { GlobalState } from "#reducers/index.ts";
import styles from "./style.scss";

// the data used to render the Measure component and the child GaugeChart.
interface MeasureProps {
  title: string;

  // the component is used for both the gauge chart and for literals.
  value: number | string | Date;

  // limit implies the value is a number.
  limit?: number;

  // the gauge library needs an ID field. there's a recent PR to change this:
  // https://github.com/Martin36/react-gauge-chart/pull/37
  gaugeId?: string;

  // whether there's a warning or an exceeded for this measure.
  // exceeded implies not warning.
  warning?: boolean;
  exceeded?: boolean;

  // If none of the widgets in a section have a warning/exceeded alert, then don't
  // reserve any space for the icon. (else, reserve space to keep a neat alignment)
  keepAlertSpacing?: boolean;

  // functions to use for formating the text under gauge charts (X of Y)
  titleFormat?: (num: number) => string;
  spanFormat?: (num: number) => string;
}

// the page is divided into sections>widgets>measures. the leaf Limits are as
// returned from the API. Each Limit is used to create a MeasureProps.
interface PageLayout {
  [sectionTitle: string]: {
    [widgetTitle: string]: {
      [measureTitle: string]: LimitJsonLeaf;
    };
  };
}
// @ts-ignore
const GaugeChart = React.lazy(() => import("react-gauge-chart"));

const formatMillify = (num: number, precision = 1) => {
  return millify(num, {
    // list of words to use for every third power of 10
    // the list isn't complete but we probably won't ever have quintillion+ of anything
    units: ["", "thousand", "million", "billion", "trillion", "quadrillion", "quintillion"],
    space: true,
    precision: precision,
  });
};
const formatBytes = (num: number) => bytes(num, { unitSeparator: " " });
const formatNumBytes = (num: number) => formatNumber(num) + " bytes";

const Gauge: React.FC<MarkRequired<MeasureProps, "limit">> = (props) => {
  const { limit, gaugeId } = props;
  let { titleFormat, spanFormat } = props;
  const count = props.value as number;
  const max = Math.max(limit, count);

  const greenYellowBorder = (limit * 0.9) / max;
  const yellowRedBorder = limit / max;
  const ranges = [greenYellowBorder, yellowRedBorder - greenYellowBorder, 1 - yellowRedBorder];
  const proportion = count / max;

  if (!titleFormat) titleFormat = formatNumber;
  if (!spanFormat) spanFormat = formatMillify;

  // Bloated library with a custom version of d3, saves ~250kb by lazy loading it
  return (
    <>
      <div title={titleFormat(count)} suppressHydrationWarning>
        <NoSsr fallback={<Skeleton height="96px" width="100%" />}>
          {/* Next to hydration, Suspense requires a node-stream instead of toString in the render of the server */}
          <React.Suspense fallback={<Skeleton height="96px" width="100%" />}>
            <GaugeChart
              id={gaugeId} // might soon no longer be needed: https://github.com/Martin36/react-gauge-chart/pull/37
              nrOfLevels={ranges.length}
              arcsLength={ranges}
              arcPadding={0}
              animate={false}
              // colors from the theme palette. would be nice to reference the theme vars instead (#3858)
              colors={["#5cb85c", "#ff9800", "#f44336"]}
              percent={proportion} // they call this a percent, but it is a proportion (0:1)
              cornerRadius={0}
              hideText={true}
            />
          </React.Suspense>
        </NoSsr>
      </div>

      <div className={styles.flexColumn}>
        <span className={styles.bold} title={titleFormat(count)}>
          {spanFormat(count)}
        </span>
        <span title={titleFormat(limit)}>of {spanFormat(limit)}</span>
      </div>
    </>
  );
};

const AlertPlaceholder: React.FC = () => {
  return (
    <div>
      <FontAwesomeIcon icon={["fas", "circle"]} className={styles.invisible} size="lg" />
    </div>
  );
};

const Alert: React.FC<{ level: "warning" | "exceeded"; message: string }> = (props) => {
  const { level, message } = props;
  return (
    <div title={message}>
      <FontAwesomeIcon
        icon={["fas", "exclamation-triangle"]}
        className={level === "warning" ? styles.warningSymbol : styles.exceededSymbol}
        size="lg"
      />
    </div>
  );
};

function getFontSizeForString(str: string) {
  if (str && str.length) {
    if (str.length < 5) return "5em";
    if (str.length < 7) {
      return "4em";
    }
  }
  return "1em";
}

const Literal: React.FC<{ value: string | Date | number }> = (props) => {
  const { value } = props;

  if (value instanceof Date) {
    return (
      <div className={getClassName(styles.literal, styles.dateLiteral)}>
        <HumanizedDate date={value} />
      </div>
    );
  } else if (typeof value === "string") {
    return (
      <div className={styles.literal}>
        <span style={{ fontSize: getFontSizeForString(value) }}>{value}</span>
      </div>
    );
  } else {
    // this code happens to never be reached for anything except unit-agnostic measures.
    // particularly, we don't get any byte numbers here.
    // if this changes in the future, pass some custom formater functions here.

    let [numberPart, unitPart] = formatMillify(value as number)
      .split(" ")
      .filter((v) => !!v);
    if (numberPart.indexOf(".") > -1 && numberPart.length > 3) {
      // xx.x : two-digit number followed by decimal.
      // this is more precision than we need, so reformat without decimal precision.
      [numberPart, unitPart] = formatMillify(value as number, 0)
        .split(" ")
        .filter((v) => !!v);
    }

    return (
      <div className={styles.literal} title={formatNumber(value as number)}>
        <span className={styles.numberLiteralNumberPart}>{numberPart}</span>
        <span className={getClassName(styles.numberLiteralUnitPart, !unitPart && styles.invisible)}>
          {unitPart || "items"}
        </span>
      </div>
    );
  }
};

const Measure: React.FC<MeasureProps> = (props) => {
  const { limit, value, title, exceeded, warning, keepAlertSpacing } = props;
  return (
    <div className={styles.measure}>
      {!!exceeded && <Alert level="exceeded" message={"The limit has been exceeded."} />}
      {!!warning && (
        <Alert level="warning" message={value === limit ? "The limit has been reached." : "Approaching the limit."} />
      )}
      {!exceeded && !warning && keepAlertSpacing && <AlertPlaceholder />}
      {<span>{title}</span>}
      {typeof limit === "number" ? <Gauge {...props} limit={limit} /> : <Literal value={value} />}
    </div>
  );
};

const Widget: React.FC<{ title: string; warning?: boolean; exceeded?: boolean; children: React.ReactNode }> = (
  props
) => {
  const { title, children, warning, exceeded } = props;
  return (
    <div
      className={getClassName(
        "whiteSink",
        styles.widgetsContainer,
        exceeded && styles.exceeded,
        warning && styles.warning,
        (children as React.ReactNodeArray).length === 1 && styles.singleWidget,
        (children as React.ReactNodeArray).length >= 2 && styles.doubleWidget
      )}
    >
      <h4>{title}</h4>
      <div className={styles.widget}>{children}</div>
    </div>
  );
};

const Section: React.FC<{ title: string; children: React.ReactNode }> = (props) => {
  const { title, children } = props;
  return (
    <div>
      <h3 className="headerSpacing">{title}</h3>
      <div className={styles.section}>{children}</div>
    </div>
  );
};

function limitToMeasure(leaf: LimitJsonLeaf): Pick<MeasureProps, "limit" | "value" | "exceeded" | "warning"> {
  return { limit: leaf.limit, value: leaf.count, exceeded: hasExceeded(leaf), warning: hasWarning(leaf) };
}

const hasExceeded = (leaf: LimitJsonLeaf) => {
  return !!leaf.limit && leaf.count > leaf.limit;
};
const hasWarning = (leaf: LimitJsonLeaf) => {
  if (hasExceeded(leaf)) return false;
  return !!leaf.limit && leaf.count >= leaf.limit * 0.9;
};

const AdminOverview: React.FC<IComponentProps> = (props) => {
  const limits = useSelector((state: GlobalState) => state.limits);
  const clientConfig = useSelector((state: GlobalState) => state.config.clientConfig);
  const staticConfig = useSelector((state: GlobalState) => state.config.staticConfig);

  if (!clientConfig || !staticConfig) return null;
  const sections: PageLayout = {
    Accounts: {
      Organizations: { Count: limits.organizations },
      Users: { Count: limits.users },
    },
    Data: {
      Datasets: { Count: limits.datasets },
      Graphs: { Count: limits.graphs.all.count, Statements: limits.graphs.all.statements },
    },
  };
  /**
   * We either render the non-unique graph counts or the unique graph counts, but not both. This only creates confusion.
   * By default, we show the non-unique graph counts. We only show the unique ones if that limit is actually set
   */
  if (limits.graphs.unique.count.limit || limits.graphs.unique.statements.limit) {
    sections.Data.Graphs = { Count: limits.graphs.unique.count, Statements: limits.graphs.unique.statements };
  }

  if (clientConfig.enabledServices.length > 0) {
    if (limits.services.all) {
      sections.Services = {
        "All services": {
          Count: limits.services.all.count,
          Statements: limits.services.all.statements,
        },
      };
    }

    for (const enabledService of clientConfig.enabledServices) {
      const serviceLimits = limits.services[enabledService];
      if (serviceLimits?.count.limit || serviceLimits?.statements.limit) {
        // Only render the specific service types when we actually have a limit.
        if (serviceLimits)
          sections.Services[upperFirst(enabledService)] = {
            Count: serviceLimits.count,
            Statements: serviceLimits.statements,
          };
      }
    }
  }
  const numWarnings: { [key: string]: number } = {};
  const numExceeded: { [key: string]: number } = {};
  Object.keys(sections).forEach((key) => {
    const leaves = flatten(Object.values(sections[key]).map((limitMap) => Object.values(limitMap)));
    numWarnings[key] = leaves.filter(hasWarning).length;
    numExceeded[key] = leaves.filter(hasExceeded).length;
  });

  const sortedSections: string[] = Object.keys(sections).sort((lhs, rhs) => {
    if (numExceeded[lhs] !== numExceeded[rhs]) return numExceeded[rhs] - numExceeded[lhs];
    return numWarnings[rhs] - numWarnings[lhs];
  });

  return (
    <FlexContainer>
      <Meta currentPath={props.location.pathname} title="Admin overview" />
      <Section title="General">
        <Widget title="API">
          <Measure
            title={"Version"}
            value={!clientConfig.version || clientConfig.version === "unset" ? "latest" : clientConfig.version}
          />
          <Measure title={"Build date"} value={clientConfig.buildDate && new Date(clientConfig.buildDate)} />

          <Measure
            title={"Started"}
            value={(clientConfig.instanceStartedAt && new Date(clientConfig.instanceStartedAt)) || ""}
          />
          <Measure
            title={"Updated"}
            value={(clientConfig.instanceUpdatedAt && new Date(clientConfig.instanceUpdatedAt)) || ""}
          />
          {clientConfig.licenceExpireDate && (
            <Measure
              title={"License valid until"}
              value={moment(new Date(clientConfig.licenceExpireDate)).format("YYYY-MM-DD")}
            />
          )}
        </Widget>

        <Widget title="Console">
          <Measure
            title={"Version"}
            value={staticConfig.consoleVersion === "unset" ? "latest" : staticConfig.consoleVersion}
          />
          <Measure
            title={"Build date"}
            value={staticConfig.consoleBuildDate && new Date(staticConfig.consoleBuildDate)}
          />
        </Widget>
        {clientConfig.memoryTotal && clientConfig.memoryFree && (
          <Widget title="Memory usage">
            <Measure
              title={""}
              value={clientConfig.memoryTotal - clientConfig.memoryFree}
              limit={clientConfig.memoryTotal}
              gaugeId={"gauge_memory"}
              titleFormat={formatNumBytes}
              spanFormat={formatBytes}
            />
          </Widget>
        )}
      </Section>
      {sortedSections.map((sectionTitle) => (
        <Section title={sectionTitle} key={sectionTitle}>
          {toPairs(sections[sectionTitle]).map(([widgetTitle, measures]) => {
            const exceeded = !!Object.values(measures).find(hasExceeded);
            const warning = !exceeded && !!Object.values(measures).find(hasWarning);
            return (
              <Widget title={widgetTitle} key={sectionTitle + widgetTitle} exceeded={exceeded} warning={warning}>
                {toPairs(measures).map(([measureTitle, limitData]) => (
                  <Measure
                    keepAlertSpacing={!!(numExceeded[sectionTitle] || numWarnings[sectionTitle])}
                    title={measureTitle}
                    {...limitToMeasure(limitData)}
                    key={sectionTitle + widgetTitle + measureTitle}
                    gaugeId={`gauge_${sectionTitle + widgetTitle + measureTitle}`.replace(/ /g, "")}
                  />
                ))}
              </Widget>
            );
          })}
        </Section>
      ))}
    </FlexContainer>
  );
};

export default asyncConnect<GlobalState>([{ promise: ({ store: { dispatch } }) => dispatch<any>(getLimits()) }])(
  AdminOverview
) as typeof AdminOverview;
