import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import { RedirectForm } from "#components/Forms/index.ts";
import { FontAwesomeButton, FontAwesomeIcon, Label } from "#components/index.ts";
import styles from "./style.scss";

export namespace ReadOnlyRule {
  export interface Props {
    rule: RedirectForm.FormData;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
    className?: string;
  }
}

const DragHandle = SortableHandle(() => (
  <FontAwesomeIcon className={getClassName(styles.enabledDragHandle, styles.noselect)} icon="bars" />
));

const ReadOnlyRule = React.memo<ReadOnlyRule.Props>((props) => {
  const { rule, onEdit, onDelete } = props;
  return (
    <div className={getClassName("py-1", styles.ruleRow)}>
      <DragHandle />

      <div className={styles.ruleData}>
        <div className={getClassName(styles.matchContainer)}>
          <div className={getClassName(styles.labelWrapper)}>
            {rule.matchingMethod === "prefix" && <Label primary>Prefix</Label>}
            {rule.matchingMethod === "regexp" && <Label error>Regex</Label>}
          </div>
          <div className={styles.match}>{rule.match}</div>
        </div>

        <Link to={"/" + rule.datasetName.split(" / ").join("/")} className={getClassName(styles.datasetLink)}>
          {rule.datasetName}
        </Link>
      </div>

      <div className={getClassName(styles.ruleActions)}>
        <FontAwesomeButton
          title="Edit"
          icon="pencil"
          onClick={() => {
            if (rule.id) onEdit(rule.id);
          }}
        />
        <FontAwesomeButton
          title="Remove"
          icon="times"
          onClick={() => {
            if (rule.id) onDelete(rule.id);
          }}
        />
      </div>
    </div>
  );
});

export default ReadOnlyRule;
