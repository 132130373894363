import * as React from "react";
import useCurrentClass from "./useCurrentClass";

const Iri: React.FC<{}> = ({}) => {
  const currentClass = useCurrentClass();

  return (
    <small>
      <a href={currentClass} target="_blank">
        {currentClass}
      </a>
    </small>
  );
};

export default Iri;
