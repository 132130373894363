import { Badge, ClickAwayListener, Fab, Tooltip } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { Models } from "@triply/utils";
import { FontAwesomeIcon } from "#components/index.ts";
import { GlobalState } from "../../reducers";
import { isStricterThan } from "./utils";
import styles from "./WarningsButton.scss";

function getElementsWithWarnings(storyElements: Models.StoryElement[], accessLevel: Models.AccessLevel) {
  return storyElements
    .filter(
      (contentElement) =>
        (contentElement.type === "query" &&
          !contentElement.query?.service &&
          contentElement.query?.serviceConfig.type !== "speedy") ||
        (contentElement.type === "query" && contentElement.query?.requestConfig?.ldFrame) ||
        isStricterThan(contentElement.query?.accessLevel, accessLevel) ||
        isStricterThan(contentElement.query?.dataset?.accessLevel, accessLevel)
    )
    .map((el) => el.id);
}

const WarningsButton: React.FC<{ story: Models.Story }> = ({ story }) => {
  const [currentErrorCounter, setCurrentErrorCounter] = React.useState(0);

  const contentElementWarnings = getElementsWithWarnings(story.content, story.accessLevel);

  return (
    <>
      {contentElementWarnings.length > 0 && (
        <ClickAwayListener onClickAway={() => setCurrentErrorCounter(0)}>
          <HashLink
            to={{
              hash: contentElementWarnings[currentErrorCounter % contentElementWarnings.length],
            }}
            onClick={() => {
              setCurrentErrorCounter((current) => current + 1);
            }}
            scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
            className={styles.warningsButton}
          >
            <Badge
              color="error"
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={
                currentErrorCounter === 0
                  ? contentElementWarnings.length
                  : `${((currentErrorCounter - 1) % contentElementWarnings.length) + 1}/${
                      contentElementWarnings.length
                    }`
              }
            >
              <Tooltip
                title={`A query element requires your attention. Enable edit mode for more information`}
                placement="left"
              >
                <Fab onClick={() => {}} className={styles.contentWarning}>
                  <FontAwesomeIcon icon="exclamation-triangle" className={styles.icon} />
                </Fab>
              </Tooltip>
            </Badge>
          </HashLink>
        </ClickAwayListener>
      )}
    </>
  );
};

export default WarningsButton;
