import { produce } from "immer";
import { assign } from "lodash-es";
import { InstanceLimitsJson } from "@triply/utils/Models.js";
import { Action, Actions } from "#reducers/index.ts";

export type State = InstanceLimitsJson;

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.ADMIN_GET_LIMITS_SUCCESS:
      assign(draftState, action.result);
      return;
  }
}, <State>{});
