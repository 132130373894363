import * as React from "react";
import { Redirect } from "react-router-dom";
import { asyncConnect } from "redux-connect";
import { qs } from "url-parse";
import { ErrorPage } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import {
  accountIsCurrentAccount,
  countersAreOutdated,
  getAccountInfo,
  getCurrentAccount,
  getCurrentPinnedItems,
  useCurrentAccount,
  usePinnedItems,
} from "#reducers/app.ts";
import { getDatasetsForAccount, isDatasetsListLoadedForCurrentUser } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { showNotification } from "#reducers/notifications.ts";
import { getQueries, needToFetchQueries } from "#reducers/queries.ts";
import { descriptionIsLoadedFor, getDescription } from "#reducers/resourceDescriptions.ts";
import { getStories, needToFetchStories } from "#reducers/stories.ts";
import Datasets from "./Datasets.tsx";
import AccountInfo from "./Info.tsx";
import AccountMeta from "./Meta.tsx";
import Overview from "./Overview.tsx";
import Queries from "./Queries.tsx";
import Stories from "./Stories.tsx";
import Tabs from "./Tabs.tsx";
import styles from "./style.scss";

const Account: React.FC<IComponentProps> = (props) => {
  const acl = useAcl();
  const dispatch = useDispatch();
  const currentAccount = useCurrentAccount(props.match.params.account);
  const pinnedItems = usePinnedItems(currentAccount?.uid);

  React.useEffect(
    () => {
      if ("new" in qs.parse(props.location.search)) {
        dispatch(showNotification("Account successfully created.", "success"));
      }
    },
    // only want to show this notification on the very first render (if isNew).
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!currentAccount) {
    return <ErrorPage statusCode={404} message={"Account '" + props.match.params.account + "' does not exist"} />;
  }

  if (props.match.path === "/:account") {
    if (pinnedItems && pinnedItems.length > 0) {
      return <Redirect to={{ pathname: `/${currentAccount.accountName}/-/overview`, search: props.location.search }} />;
    } else {
      return <Redirect to={{ pathname: `/${currentAccount.accountName}/-/datasets`, search: props.location.search }} />;
    }
  }

  const roleInCurrentAccount = acl.getRoleInAccount(currentAccount);
  const mayCreateDataset = acl.check({
    action: "createDataset",
    context: { roleInOwnerAccount: roleInCurrentAccount, accessLevel: "public" },
  }).granted;
  const mayCreateStory = acl.check({
    action: "manageStory",
    context: { roleInOwnerAccount: roleInCurrentAccount, accessLevel: "public" },
  }).granted;
  const mayCreateQuery = acl.check({
    action: "manageQuery",
    context: {
      roleInOwnerAccount: roleInCurrentAccount,
      accessLevel: "public",
      newAccessLevel: undefined,
    },
  }).granted;

  return (
    <div className={styles.accountContainer}>
      <AccountMeta currentAccount={currentAccount} />
      <div className={styles.accountInfo}>
        <AccountInfo account={currentAccount} />
      </div>
      <div className={styles.accountResources}>
        <Tabs matchPath={props.match.path} mayCreateResource={mayCreateDataset || mayCreateStory || mayCreateQuery} />
        {props.match.path === "/:account/-/overview" && <Overview />}
        {props.match.path === "/:account/-/datasets" && <Datasets mayCreateDataset={mayCreateDataset} />}
        {props.match.path === "/:account/-/stories" && <Stories mayCreateStory={mayCreateStory} />}
        {props.match.path === "/:account/-/queries" && <Queries mayCreateQuery={mayCreateQuery} />}
      </div>
    </div>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: async ({ match: { url, path, params }, store: { dispatch, getState } }) => {
      // account
      if (
        !accountIsCurrentAccount(getState(), params.account) ||
        countersAreOutdated(getState(), getState().app.currentAccount)
      ) {
        await dispatch<any>(getAccountInfo(getState(), params.account));
      }
      const currentAccount = getCurrentAccount(getState());

      // overview
      if (path === "/:account/-/overview") {
        const pinnedItems = getCurrentPinnedItems(getState());
        const promises: Promise<any>[] = [];
        for (const pinnedItem of pinnedItems) {
          if (pinnedItem.type !== "Dataset") continue;
          const ds = pinnedItem.item;
          for (const exampleResource of ds.exampleResources) {
            if (
              !descriptionIsLoadedFor({
                scope: "all",
                state: getState().resourceDescriptions,
                dataset: ds,
                resource: exampleResource,
                concise: true,
              })
            ) {
              promises.push(
                dispatch<any>(getDescription({ dataset: ds, resource: exampleResource, concise: true })).catch(() => {})
              );
            }
          }
        }
        return Promise.all(promises);
      }

      // datasets
      else if (
        path === "/:account/-/datasets" &&
        (!getState().router.location?.pathname || url === getState().router.location?.pathname)
      ) {
        //We are using the location from the redux state here (something we should generally not do) because we want
        //to know if the location has changed in the meantime. After fetching the account we've navigated to
        //a different URL if the original url (from the args) is different than the current url in redux.
        //Just stop what we're doing in that case, to avoid race conditions.
        //See https://issues.triply.cc/issues/3510#note-16

        const promises: Promise<any>[] = [];
        if (!isDatasetsListLoadedForCurrentUser(getState()) && currentAccount) {
          promises.push(dispatch<any>(getDatasetsForAccount(currentAccount)));
        }

        const starterDataset = getState().config.clientConfig?.starterDataset;
        if (starterDataset) {
          for (const exampleResource of starterDataset.exampleResources) {
            if (
              !descriptionIsLoadedFor({
                scope: "all",
                state: getState().resourceDescriptions,
                dataset: starterDataset,
                resource: exampleResource,
                concise: true,
              })
            ) {
              promises.push(
                dispatch<any>(
                  getDescription({ dataset: starterDataset, resource: exampleResource, concise: true })
                ).catch(() => {})
              );
            }
          }
        }

        return Promise.all(promises);
      }

      // stories
      else if (path === "/:account/-/stories") {
        if (currentAccount?.accountName && needToFetchStories(getState(), currentAccount)) {
          return dispatch<any>(getStories(currentAccount));
        }
      }

      // queries
      else if (path === "/:account/-/queries") {
        if (currentAccount && needToFetchQueries(getState(), currentAccount)) {
          return dispatch<any>(getQueries(currentAccount));
        }
      }
    },
  },
])(Account) as typeof Account;
