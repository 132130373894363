import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { history, indentLess } from "@codemirror/commands";
import { bracketMatching, foldGutter, indentOnInput } from "@codemirror/language";
import { linter, lintGutter } from "@codemirror/lint";
import { highlightSelectionMatches } from "@codemirror/search";
import { Compartment, EditorState, Facet } from "@codemirror/state";
import {
  crosshairCursor,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLineGutter,
  keymap,
  lineNumbers,
  rectangularSelection,
} from "@codemirror/view";
import { vscodeKeymap } from "@replit/codemirror-vscode-keymap";
import { githubLight } from "@uiw/codemirror-theme-github";
import getClassName from "classnames";
import { debounce } from "lodash-es";
import * as React from "react";
import { Prefix } from "@triply/utils";
import { PositionInformation } from "../SparqlUtils.ts";
import SparqlPrefixAutocomplete from "./autocompleters/prefixAutoComplete.ts";
import { sparqlVarAutoComplete } from "./autocompleters/sparqlVarAutoComplete.ts";
import { getTermAutocompletion, TermAutocompleteFunction } from "./autocompleters/termAutocomplete.ts";
import { autoCloseIri } from "./commands/autoCloseIri.ts";
import autoFormat from "./commands/autoFormat.ts";
import { injectPrefix } from "./commands/injectPrefix.ts";
import { insertSpaces } from "./commands/tabsInsertSpace.ts";
import { NoResultRangesFacet } from "./facets/highlightedRanges.ts";
import { OutsidePrefixes } from "./facets/outsidePrefixes.ts";
import { OutsideVariableFacet } from "./facets/outsideVariables.ts";
import { definedPrefixesField } from "./fields/definedPrefixes.ts";
import { DefinedVariables, DefinedVariablesExtension, QueryScope } from "./fields/queryContext.ts";
import { validField } from "./fields/validity.ts";
import { SparqlLanguage } from "./grammar/sparqlLang.ts";
import PrefixLinter from "./linters/PrefixLinter.ts";
import syntaxLinter from "./linters/SyntaxLinter.ts";
import { getNoResultRangesHighlighter, noResultsHover } from "./views/highlighterView.ts";
import { getHighlightUnusedVariables } from "./views/unusedVariableView.ts";
import styles from "./style.scss";

export interface Props {
  initialValue: string;
  onChange?: (values: { query: string; valid: boolean; prefixes: Prefix[] }) => void;
  onVariablesChange?: (queryVariables: string[]) => void;
  onSubmitQuery?: () => boolean;
  prefixes?: Prefix[];
  searchTerms?: TermAutocompleteFunction;
  className?: string;
  updateEditorHeight?: (val: number | undefined) => void; //callback function to pass the view ref's actual content height
  variables?: string[];
  noResultRanges?: PositionInformation[];
}

const BaseSparqlEditor: React.FC<Props> = ({
  initialValue: _initialValue,
  onChange,
  prefixes,
  searchTerms,
  onSubmitQuery,
  onVariablesChange,
  className,
  updateEditorHeight,
  variables,
  noResultRanges,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const viewRef = React.useRef<EditorView | null>(null);
  const initialValue = React.useRef(_initialValue);
  const initialResultRange = React.useRef(noResultRanges);
  const initialOnChange = React.useRef(onChange);
  const initialOnVariablesChange = React.useRef(onVariablesChange);
  const changeListener = React.useRef(new Compartment());
  const outsidePrefixes = React.useRef(new Compartment());
  const termAutocomplete = React.useRef(new Compartment());
  const variablesFromQuery = React.useRef(new Compartment());
  const onSubmit = React.useRef(new Compartment());
  const noResultRangesToHighlight = React.useRef(new Compartment());

  // Debouncing this to prevent slowdown when editing variables
  const debouncedOnVariableChange = React.useMemo(
    () =>
      debounce(
        (newVariables: string[]) => {
          onVariablesChange?.(newVariables);
        },
        750, // 750ms is the same debounce that the CM linters use
        {}
      ),
    [onVariablesChange]
  );

  // Mount & Cleanup hook
  React.useEffect(() => {
    if (containerRef.current) {
      const initialState = EditorState.create({
        doc: initialValue.current,
        extensions: [
          SparqlLanguage,
          lineNumbers(),
          highlightActiveLineGutter(),
          history(),
          foldGutter(),
          drawSelection(),
          dropCursor(),
          EditorState.allowMultipleSelections.of(true),
          indentOnInput(),
          githubLight,
          bracketMatching(),
          autocompletion({ defaultKeymap: false }),
          closeBrackets(),
          rectangularSelection(),
          crosshairCursor(),
          lintGutter(),
          highlightSelectionMatches(),
          linter(PrefixLinter),
          variablesFromQuery.current.of(OutsideVariableFacet.of([])),
          getHighlightUnusedVariables(styles.unusedVar),
          noResultRangesToHighlight.current.of(NoResultRangesFacet.of(initialResultRange.current || [])),
          getNoResultRangesHighlighter(styles.highlighter),
          noResultsHover,
          linter(syntaxLinter),
          definedPrefixesField, // Make sure this is defined before any of the other field that uses this. I expect all '.from' are run in order
          SparqlLanguage.data.from(definedPrefixesField),
          QueryScope,
          SparqlLanguage.data.from(QueryScope),
          DefinedVariablesExtension,
          validField,
          SparqlLanguage.data.from(validField),
          SparqlLanguage.data.of({
            autocomplete: sparqlVarAutoComplete,
          }),
          SparqlLanguage.data.of({
            autocomplete: SparqlPrefixAutocomplete,
          }),
          changeListener.current.of(EditorView.updateListener.of(() => {})),
          outsidePrefixes.current.of(OutsidePrefixes.of([])),
          termAutocomplete.current.of(SparqlLanguage.data.of(() => {})),
          keymap.of(vscodeKeymap.filter((setting) => !(setting.key === "Tab" && setting?.preventDefault))),
          keymap.of([
            {
              key: "Mod-i",
              shift: autoFormat,
              preventDefault: true,
            },
            {
              key: "Tab",
              run: insertSpaces,
              shift: indentLess,
              preventDefault: true,
            },
            {
              key: "<",
              run: autoCloseIri,
              preventDefault: false,
            },
            {
              key: ":",
              run: injectPrefix,
            },
          ]),
          onSubmit.current.of(keymap.of([])),
        ],
      });
      viewRef.current = new EditorView({
        state: initialState,
        parent: containerRef.current,
      });

      // After initialization call the onChange function to give the parent component information about the variables/validity of a query
      if (initialOnChange.current) {
        initialOnChange.current({
          query: initialState.doc.toString(),
          valid: initialState.field(validField).valid,
          prefixes: initialState.field(definedPrefixesField),
        });
        initialOnVariablesChange.current?.(initialState.facet(DefinedVariables));
      }
      return () => {
        viewRef.current?.destroy();
        viewRef.current = null;
      };
    }
  }, []);
  // Update onChange listener
  React.useEffect(() => {
    viewRef.current?.dispatch({
      effects: changeListener.current.reconfigure(
        // Contrary to the docs, this reassigns a function, not register a new one
        EditorView.updateListener.of(
          onChange
            ? (update) => {
                if (update.docChanged) {
                  onChange?.({
                    query: update.state.doc.toString(),
                    valid: update.state.field(validField).valid,
                    prefixes: update.state.field(definedPrefixesField),
                  });
                  debouncedOnVariableChange(update.state.facet(DefinedVariables));
                }
              }
            : () => {}
        )
      ),
    });
  }, [onChange, debouncedOnVariableChange]);
  // Update onChange listener
  React.useEffect(() => {
    viewRef.current?.dispatch({
      effects: [outsidePrefixes.current.reconfigure(OutsidePrefixes.of(prefixes || []))],
    });
  }, [prefixes]);
  React.useEffect(() => {
    viewRef.current?.dispatch({
      effects: termAutocomplete.current.reconfigure(
        // Contrary to the docs, this reassigns a function, not register a new one
        SparqlLanguage.data.of(
          searchTerms
            ? {
                autocomplete: getTermAutocompletion(searchTerms),
              }
            : () => {}
        )
      ),
    });
  }, [searchTerms]);
  React.useEffect(() => {
    viewRef.current?.dispatch({
      effects: [variablesFromQuery.current.reconfigure(OutsideVariableFacet.of(variables || []))],
    });
  }, [variables]);
  React.useEffect(() => {
    viewRef.current?.dispatch({
      effects: [noResultRangesToHighlight.current.reconfigure(NoResultRangesFacet.of(noResultRanges || []))],
    });
  }, [noResultRanges]);
  React.useEffect(() => {
    viewRef.current?.dispatch({
      effects: onSubmit.current.reconfigure(
        // Contrary to the docs, this reassigns a function, not register a new one
        keymap.of(
          onSubmitQuery
            ? [
                {
                  key: "Mod-Enter",
                  run: onSubmitQuery,
                },
              ]
            : []
        )
      ),
    });
  }, [onSubmitQuery]);

  const contentHeight = viewRef.current?.contentHeight;
  React.useEffect(() => {
    if (updateEditorHeight) updateEditorHeight(contentHeight);
  }, [updateEditorHeight, contentHeight]);

  return <div ref={containerRef} className={getClassName(className, styles.editor)} />;
};

export default BaseSparqlEditor;
