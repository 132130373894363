import getClassName from "classnames";
import * as React from "react";
import { Alert, Avatar, Button, Dialog, FontAwesomeIcon } from "#components/index.ts";
import { Account } from "#reducers/accountCollection.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import styles from "./TransferStoryDialog.scss";

namespace CopyStoryDialog {
  export interface Props {
    ownerAccountName: string;
    onSubmit: (toAccount: string) => Promise<any>;
    open: boolean;
    goBack: () => void;
  }
}

const CopyStoryDialog: React.FC<CopyStoryDialog.Props> = ({
  onSubmit,
  ownerAccountName: currentAccountName,
  goBack,
  open,
}) => {
  const [toAccount, setToAccount] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const authenticatedUser = useAuthenticatedUser();
  const close = () => {
    setToAccount(undefined);
    goBack();
  };
  const submit = (accountName: string) => {
    if (!toAccount) {
      setToAccount(accountName);
      onSubmit(accountName).catch((e: Error) => setError(e.message));
    }
  };

  if (!authenticatedUser) return null;
  const accounts = [authenticatedUser as Account]
    .concat(authenticatedUser.orgs || [])
    .filter((account) => account.accountName !== currentAccountName);
  return (
    <Dialog open={open} onClose={close} title="Transfer story">
      <div className="px-5 pb-5">
        {!toAccount && <h5 className="mt-0 mb-3">Select the account to transfer the story to:</h5>}
        {!toAccount && (
          <div className="flex wrap py-3">
            {accounts.map((account) => {
              const accountName = account.accountName;
              if (!accountName) return null;
              return (
                <div
                  key={account.accountName}
                  className={getClassName(styles.moveAccount, "p-2")}
                  onClick={() => submit(accountName)}
                  tabIndex={0}
                  role="button"
                >
                  <Avatar
                    size="lg"
                    avatarName={accountName}
                    avatarUrl={account.avatarUrl}
                    title={account.accountName}
                    alt=""
                  />
                  <div className={getClassName(styles.moveAccountName, "mt-2")}>{account.accountName}</div>
                </div>
              );
            })}
          </div>
        )}
        {!error && toAccount && (
          <div>
            <FontAwesomeIcon icon={["fas", "cog"]} spin /> {`Transferring story to ${toAccount}`}
          </div>
        )}
        {toAccount && <Alert transparent message={error} className="mt-3 mb-5" />}
        <Button variant="text" onClick={close}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};
export default CopyStoryDialog;
