import * as React from "react";
import IRI from "#components/IRI/index.tsx";
import { Iri } from "#components/Sparql/SparqlUtils.ts";
import { SparqlVisualizationContext } from "../../SparqlVisualizationContext.tsx";
import styles from "./styles.scss";

interface Props {
  term: Iri;
}

const IriTerm: React.FC<Props> = ({ term }) => {
  const { prefixes, onCreatePrefix, datasetPath } = React.useContext(SparqlVisualizationContext);
  return (
    <div className={styles.cellContent}>
      <IRI prefixes={prefixes} onCreatePrefix={onCreatePrefix} datasetPath={datasetPath}>
        {term.value}
      </IRI>
    </div>
  );
};

export default IriTerm;
