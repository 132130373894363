import { IconButton } from "@mui/material";
import dedent from "dedent";
import * as React from "react";
import { factories, getWriter, parse, Store } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import useEditModeStatementsLimit from "#helpers/hooks/useEditModeStatementsLimit.ts";
import { ConfirmationDialog, FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import { PropertyInfo } from "./ClassInfo";

const factory = factories.compliant;

const RemoveClass: React.FC<{ property: PropertyInfo }> = ({ property }) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const editModeStatementsLimit = useEditModeStatementsLimit();
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();

  return (
    <>
      <IconButton title="Remove property" onClick={() => setOpen(true)} size="small">
        <FontAwesomeIcon icon="trash" fixedWidth />
      </IconButton>
      <ConfirmationDialog
        open={open}
        loading={saving}
        onClose={() => setOpen(false)}
        actionLabel="Remove property"
        title="Remove this property?"
        onConfirm={async () => {
          setSaving(true);

          const query = dedent`
          delete {
            ?s ?p ?id.
            ?id ?y ?z.
          } where {
            bind(<${property.propertyShape}> as ?id)
            optional {
              ?s ?p ?id.
            }
            optional {
              ?id ?y ?z
            }
          }
          `;

          const body = new FormData();
          body.set("update", query);

          await fetch(updateUrl, {
            credentials: "same-origin",
            method: "POST",
            body: body,
          });

          await dispatch<typeof refreshDatasetsInfo>(
            refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name })
          );
          await dispatch<typeof getGraphs>(
            getGraphs({
              accountName: currentDs.owner.accountName,
              datasetName: currentDs.name,
              datasetId: currentDs.id,
            })
          );

          setSaving(false);
          setOpen(false);
        }}
        description={`Are you sure you want to remove property '${property.label || property.id}'?`}
      />
    </>
  );
};

export default RemoveClass;
