import bytes from "bytes";
import { isIP } from "is-ip";
import { compact, defaults } from "lodash-es";
import netmask from "netmask";
// @ts-ignore
import psl from "psl";
import urlParse from "url-parse";
import _isEmail from "validator/lib/isEmail.js";
import { Models } from "@triply/utils";
import {
  DATATYPE_DEFINITIONS,
  MAX_ACCOUNT_NAME_LENGTH,
  MAX_DATASET_NAME_LENGTH,
  MAX_QUERY_NAME_LENGTH,
  MAX_SERVICE_NAME_LENGTH,
  MAX_STORY_NAME_LENGTH,
  RESERVED_SERVICE_NAMES,
  RESERVED_SPARQL_VARNAMES,
  SPARQL_VARNAME_CHARS,
} from "@triply/utils/Constants.js";
import { isValidLanguageTag } from "@triplydb/data-factory/DataFactory.js";
import { validate } from "@triplydb/iri";
import { InjectError } from "@triplydb/sparql-ast/error.js";
import { assertValidValue } from "@triplydb/sparql-ast/inject.js";
import { formatNumber } from "./formatting.js";

const isEmail = _isEmail.default;
/**
 * Exported interfaces and functions
 */
export interface RuleConfig {
  formatMessage?: (val: unknown, error: ValidationError) => string;
  skipRuleOn?: (val: unknown, error: ValidationError) => boolean;
}
export interface Rule {
  (val: unknown, config?: RuleConfig): ValidationError | undefined;
}
export enum ErrorCode {
  ACCOUNT_BLACKLIST,
  BLACKLISTED_IP,
  EMPTY_FIELD,
  EXPECTED_ASCII,
  INVALID_EMAIL_ADDRESS,
  INVALID_IP,
  INVALID_IRI,
  INVALID_LANGUAGE_TAG,
  INVALID_NUMBER,
  INVALID_PORT,
  INVALID_PREFIX,
  INVALID_PROFILE_NAME,
  INVALID_QUERY_VARIABLE,
  INVALID_REGEX,
  INVALID_URL,
  REQUIRED,
  MAX_STRING_LENGTH,
  MAX_STRING_SIZE,
  MIN_STRING_LENGTH,
  NAN,
  UNDERSCORE_FIRST_CHAR,
  UNKNOWN_PUBLIC_SUFFIX,
  NOT_A_STRING,
  NOT_AN_ARRAY,
  INVALID_SPARQL_VARNAME,
  RESERVED_SPARQL_VARNAME,
  RESERVED_SERVICE_NAMES,
}
export interface ValidationError {
  message: string;
  code: ErrorCode;
}
export type ToStringValidator = (value: unknown, config?: RuleConfig) => string | undefined;
export function toStringValidator(rules: Rule[], config: RuleConfig = {}): ToStringValidator {
  const rule = combineRules(rules, config);
  return (value: unknown, _config?: RuleConfig) => {
    const err = rule(value, _config);
    if (err) return err.message;
  };
}

export interface MongooseValidate {
  validator: (value: string) => Promise<boolean>;
}
export function toMongooseAsyncValidator(rules: Rule[], config: RuleConfig = {}): MongooseValidate {
  const rule = combineRules(rules, config);
  return {
    validator: async (value) => {
      const err = rule(value);
      if (err) return Promise.reject({ message: err.message, code: err.code });
      return true;
    },
  };
}

/**
 * Helper functions
 */
export function combineRules(rules: Rule[], config: RuleConfig = {}): Rule {
  if (!rules || rules.length === 0) {
    throw new Error("There are no rules to combine.");
  }
  return applyRuleConfig((value: unknown) => {
    for (const rule of rules) {
      const err = rule(value, config);
      if (err) return err;
    }
  });
}
export function applyRuleConfig(rule: Rule, upperConfig: RuleConfig = {}): Rule {
  if (!rule) {
    throw new Error("There is no rule to apply config to.");
  }
  if (typeof rule !== "function") {
    throw new Error(`Expected a function as rule, but got a ${typeof rule}.`);
  }
  return (value, _config) => {
    const config = defaults<{}, RuleConfig, RuleConfig>({}, _config || {}, upperConfig);
    const err = rule(value);
    if (err) {
      if (config.skipRuleOn && config.skipRuleOn(value, err)) return;
      if (config.formatMessage) {
        err.message = config.formatMessage(value, err);
        return err;
      }
      return err;
    }
  };
}

/**
 * Available rules
 */
export const isString: Rule = function (value) {
  // is-string-if-truthy rather than just is-string.
  // we use other checks for seeing if a value is defined or truthy.
  if (value && typeof value !== "string") {
    return {
      message: "Value is not a string.",
      code: ErrorCode.NOT_A_STRING,
    };
  }
};
export const isArray: Rule = function (value) {
  if (value && !Array.isArray(value)) {
    return {
      message: "Value is not an array.",
      code: ErrorCode.NOT_AN_ARRAY,
    };
  }
};
export const defined: Rule = function (value) {
  if (value === undefined) {
    return {
      message: "This field is required.",
      code: ErrorCode.REQUIRED,
    };
  }
};

export const email: Rule = function (value) {
  if (value && typeof value === "string" && !isEmail(value)) {
    return {
      message: "Invalid email address.",
      code: ErrorCode.INVALID_EMAIL_ADDRESS,
    };
  }
};

const SPARQL_VARNAME_REGEX = new RegExp(`^${SPARQL_VARNAME_CHARS}+$`);
export const sparqlVarname: Rule = function (value) {
  // NOTE: tests the part of the varname _after_ the ? or $, so '?s' would fail while 's' would pass.
  if (value && typeof value === "string" && !SPARQL_VARNAME_REGEX.test(value)) {
    return {
      message: "Invalid sparql variable name.",
      code: ErrorCode.INVALID_SPARQL_VARNAME,
    };
  }
};

export const required: Rule = function (value) {
  if (value === undefined || value === null || value === "") {
    return {
      message: "This field is required.",
      code: ErrorCode.REQUIRED,
    };
  }
};

export const asciiRegex = /^[A-Za-z0-9-]*$/;
export const ascii: Rule = function (value) {
  if (typeof value === "string" && !asciiRegex.test(value)) {
    return {
      message: `May only contain letters, numbers and hyphens (-).`,
      code: ErrorCode.EXPECTED_ASCII,
    };
  }
};

export const noUnderscoreAsFirstChar: Rule = function (value) {
  if (value && typeof value === "string" && value[0] === "_") {
    return {
      message: `An underscore as first character is forbidden.`,
      code: ErrorCode.UNDERSCORE_FIRST_CHAR,
    };
  }
};

export function minStringLength(min: number, config?: RuleConfig): Rule {
  // if (!config.formatMessage) config.formatMessage = () => `Must be at least ${min} characters`;
  const check: Rule = (value) => {
    if (typeof value === "string" && value.length < min) {
      return {
        message: `Must be at least ${formatNumber(min)} characters.`,
        code: ErrorCode.MIN_STRING_LENGTH,
      };
    }
  };
  if (config) return applyRuleConfig(check, config);
  return check;
}

export function maxStringLength(max: number, config?: RuleConfig): Rule {
  const check: Rule = (value) => {
    if (typeof value === "string" && value.length > max) {
      return {
        message: `Must be no more than ${formatNumber(max)} characters.`,
        code: ErrorCode.MAX_STRING_LENGTH,
      };
    }
  };
  if (config) return applyRuleConfig(check, config);
  return check;
}
export function maxStringByteSize(max: number, config?: RuleConfig): Rule {
  const check: Rule = (value) => {
    if (typeof value === "string" && Buffer.byteLength(value, "utf-8") > max) {
      return {
        message: "The size of the value is too large",
        code: ErrorCode.MAX_STRING_SIZE,
      };
    }
  };
  if (config) return applyRuleConfig(check, config);
  return check;
}
export function maxSizeArray(max: number, config?: RuleConfig): Rule {
  const check: Rule = (value) => {
    if (value instanceof Array && value.length > max) {
      return {
        message: "The size of the array is too large",
        code: ErrorCode.MAX_STRING_SIZE,
      };
    }
  };
  if (config) return applyRuleConfig(check, config);
  return check;
}

export const integer: Rule = function (value) {
  if (!isNaN(Number(value))) {
    return {
      message: "Must be an integer.",
      code: ErrorCode.INVALID_NUMBER,
    };
  }
};

const ACCOUNT_NAME_BLACKLIST_LOWER = [
  "about",
  "account",
  "accounts",
  "admin",
  "announcement",
  "announcements",
  "auth",
  "blog",
  "blogs",
  "browse",
  "browser",
  "business",
  "businesses",
  "buy",
  "contact",
  "corporate",
  "customer",
  "customers",
  "dataset",
  "datasets",
  "deprecation",
  "details",
  "dist",
  "doc",
  "docs",
  "download",
  "facets",
  "features",
  "find",
  "forum",
  "forums",
  "graph",
  "graphs",
  "help",
  "hook",
  "hooks",
  "jobs",
  "login",
  "loggedin",
  "loggedout",
  "logout",
  "me",
  "members",
  "messages",
  "organization",
  "organizations",
  "plan",
  "plans",
  "policies",
  "policy",
  "preferences",
  "price",
  "prices",
  "pricing",
  "pro",
  "queries",
  "query",
  "register",
  "search",
  "service",
  "services",
  "settings",
  "sitemap",
  "sparql",
  "store",
  "stores",
  "stories",
  "support",
  "terms",
  "triply",
  "triplydb",
  "version",
  "web",
];
export const accountNameBlacklist: Rule = function (value) {
  if (
    value &&
    typeof value === "string" &&
    value.toLowerCase &&
    ACCOUNT_NAME_BLACKLIST_LOWER.indexOf(value.toLowerCase()) >= 0
  ) {
    return {
      message: `Account name '${value}' is not allowed.`,
      code: ErrorCode.ACCOUNT_BLACKLIST,
    };
  }
};

export const reservedSparqlVarname: Rule = function (value) {
  if (value && typeof value === "string" && RESERVED_SPARQL_VARNAMES.indexOf(value) >= 0) {
    return {
      message: `'${value}' is a reserved keyword and can't be used as a SPARQL variable name.`,
      code: ErrorCode.RESERVED_SPARQL_VARNAME,
    };
  }
};

export const reservedServiceName: Rule = function (value) {
  if (value && typeof value === "string" && RESERVED_SERVICE_NAMES.indexOf(value) >= 0) {
    return {
      message: `'${value}' is a reserved keyword and can't be used as a name of service.`,
      code: ErrorCode.RESERVED_SERVICE_NAMES,
    };
  }
};

// https://www.w3.org/TR/rdf-sparql-query/#rPN_CHARS_BASE
// left out \u10000-\uEFFFF from pnCharsBase, since 5 digit characters don't work
const pnCharsBase =
  "[A-Za-z\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u02FF\\u0370-\\u037D\\u037F-\\u1FFF\\u200C-\\u200D\\u2070-\\u218F\\u2C00-\\u2FEF\\u3001-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFFD]";
const pnCharsU = `${pnCharsBase}|_`;
const pnChars = `${pnCharsU}|-|[0-9]|\\u00B7|[\\u0300-\\u036F]|[\\u203F-\\u2040]`;
const pnPrefix = `${pnCharsBase}((${pnChars}|\\.)*(${pnChars}))?`;

const prefixRegex = new RegExp(`^${pnPrefix}$`);
export const prefix: Rule = function (value) {
  if (value && typeof value === "string" && !prefixRegex.test(value)) {
    return {
      message: `Must be a valid prefix.`,
      code: ErrorCode.INVALID_PREFIX,
    };
  }
};

export const IRI: Rule = function (value) {
  const error = {
    message: `'${value}' is not a valid IRI.`,
    code: ErrorCode.INVALID_IRI,
  };

  if (value && typeof value === "string") {
    // detect if the value is not an absolute IRI
    // must have a colon
    const colonIndex = value.indexOf(":");
    if (colonIndex < 1) {
      return error;
    }
    // if there is a slash, it must be after the colon
    const slashIndex = value.indexOf("/");
    if (slashIndex > -1) {
      if (slashIndex < colonIndex) {
        return error;
      }
    }
    try {
      validate(value);
    } catch {
      return error;
    }
  }
};

const urlRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const URL: Rule = function (value) {
  if (value && typeof value === "string" && !urlRegex.test(value)) {
    return {
      message: `'${value}' is not a valid HTTP(S)-URL.`,
      code: ErrorCode.INVALID_URL,
    };
  }
};

const BLACKLISTED_MASKS = ["127.0.0.0/8", "10.0.0.0/8", "172.16.0.0/12", "192.168.0.0/16"].map(
  (m) => new netmask.Netmask(m)
);

const ipBlacklistCheck = (address: string) => {
  for (let mask of BLACKLISTED_MASKS) {
    if (mask.contains(address)) {
      return {
        message: `This IP address is blacklisted.`,
        code: ErrorCode.BLACKLISTED_IP,
      };
    }
  }
};

const listedTLD = (hostname: string) => {
  if (!psl.isValid(hostname)) {
    return {
      message: `Unlisted top-level domain.`,
      code: ErrorCode.UNKNOWN_PUBLIC_SUFFIX,
    };
  }
};

const okProtocol = (protocol: string) => {
  if (["http", "https"].indexOf(protocol) < 0) {
    return {
      message: `Must use HTTP or HTTPS protocols.`,
      code: ErrorCode.INVALID_URL,
    };
  }
};

export const isValidRegex: Rule = (regExp) => {
  if (typeof regExp !== "string") return;
  try {
    new RegExp(regExp);
  } catch (e: any) {
    return {
      message: e.message,
      code: ErrorCode.INVALID_REGEX,
    };
  }
};

export const nonBlacklistedHttpUrl: Rule = function (value: unknown) {
  if (value && typeof value === "string") {
    const parsed = urlParse(value);
    return (
      URL(value) ||
      okProtocol(parsed.protocol.replace(":", "")) ||
      (isIP(parsed.hostname) ? ipBlacklistCheck(parsed.hostname) : listedTLD(parsed.hostname))
    );
  }
};

export const memoryLimit: Rule = function (value: unknown) {
  const nanProblem = function () {
    return {
      message: `'${value}' is not a number.`,
      code: ErrorCode.NAN,
    };
  };

  if (!value) {
    return undefined;
  }

  if (typeof value !== "string") {
    return nanProblem();
  }

  let trimmed = value.trim();

  const endsWithMemoryUnit = function () {
    for (let unit of ["b", "kb", "mb", "gb", "tb"]) {
      if (trimmed.toLowerCase().indexOf(unit) === trimmed.length - unit.length) {
        return true;
      }
    }
    return false;
  };

  if (endsWithMemoryUnit()) {
    try {
      trimmed = bytes.parse(trimmed).toString();
    } catch {
      return nanProblem();
    }
  }

  for (let c of trimmed) {
    if (isNaN(parseInt(c))) {
      return nanProblem();
    }
  }
  if (isNaN(parseInt(trimmed))) {
    return nanProblem();
  }
};

export const noIllegalWhitespace: Rule = function (value) {
  if (!value || typeof value !== "string") return;
  const spaces = value.match(/\s/g);
  if (spaces) {
    for (const space of spaces) {
      if (space !== " ") {
        return {
          message: "Whitespace other than space is not allowed.",
          code: ErrorCode.INVALID_PROFILE_NAME,
        };
      }
    }
  }
};
export const noEmptyField: Rule = function (value) {
  if (!value || typeof value !== "string") return;
  if (value.trim() === "") {
    return {
      message: "Only whitespaces not allowed.",
      code: ErrorCode.EMPTY_FIELD,
    };
  }
};

export const noConsecutiveWhitespace: Rule = function (value) {
  if (!value || typeof value !== "string") return;
  if (value.indexOf("  ") > -1) {
    return {
      message: "Consecutive whitespaces not allowed.",
      code: ErrorCode.INVALID_PROFILE_NAME,
    };
  }
};

export const validLanguageTag: Rule = function (value) {
  if (!value || typeof value !== "string") return;
  if (!isValidLanguageTag(value)) {
    return {
      message: "Invalid language tag",
      code: ErrorCode.INVALID_LANGUAGE_TAG,
    };
  }
};

export type PasswordDictionaryInfo = {
  accountName: string | undefined; // When registering in the UI, we may not  have an accountname yet
  consoleUrl: string;
  email: string | undefined; // When registering in the UI, we may not  have an email yet
  name: string | undefined;
};
export function getUserDictionary({ accountName, email, consoleUrl, name }: PasswordDictionaryInfo) {
  const userDictionary = [accountName, ...consoleUrl.split(/[\W]/g)];
  if (email) userDictionary.push(...email.split(/[\W]/g), email);
  if (name) userDictionary.push(name);
  return compact(userDictionary);
}

/**
 * Rule combinations
 */

export const getProfileNameValidations = (options: { messageSubject: string }) => [
  isString,
  maxStringLength(200, {
    formatMessage: () => `${options.messageSubject} should be at most 200 characters long.`,
  }),
  applyRuleConfig(noIllegalWhitespace, {
    formatMessage: () => `${options.messageSubject} may not contain whitespace characters other than space.`,
  }),
  applyRuleConfig(noConsecutiveWhitespace, {
    formatMessage: () => `${options.messageSubject} may not contain consecutive whitespaces.`,
  }),
];
export const emailValidations = [
  applyRuleConfig(required, { formatMessage: () => `An email address is required.` }),
  isString,
  applyRuleConfig(email, { formatMessage: (val) => `${val} is not a valid email address.` }),
];
export const passwordValidations = [
  applyRuleConfig(required, {
    formatMessage: () => `A password is required.`,
  }),
  isString,
  maxStringLength(1000, {
    formatMessage: () => `The password can be at most 1.000 characters long.`,
  }),
];
export const getDatasetNameValidations = (options?: { messageSubject?: string }) => [
  applyRuleConfig(required, {
    formatMessage: () => (options?.messageSubject ? `${options.messageSubject} is required.` : "Required."),
  }),
  isString,
  minStringLength(2, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} should have at least 2 characters.`
        : "Should have at least 2 characters.",
  }),
  applyRuleConfig(ascii, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} can only contain letters, numbers and hyphens (-).`
        : "Can only contain letters, numbers and hyphens (-).",
  }),
  maxStringLength(MAX_DATASET_NAME_LENGTH, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} should be at most ${MAX_DATASET_NAME_LENGTH} characters long.`
        : `Should be at most ${MAX_DATASET_NAME_LENGTH} characters long.`,
  }),
];
export const getDisplayNameValidations = (options: { messageSubject: string }) => [
  isString,
  maxStringLength(200, {
    formatMessage: () => `${options.messageSubject} should be at most 200 characters long.`,
  }),
  applyRuleConfig(noIllegalWhitespace, {
    formatMessage: () => `${options.messageSubject} may not contain whitespace characters other than space.`,
  }),
  applyRuleConfig(noConsecutiveWhitespace, {
    formatMessage: () => `${options.messageSubject} may not contain consecutive whitespace characters.`,
  }),
];
export const displayNameValidations = getDisplayNameValidations({ messageSubject: "A display name" });
export const datasetDescriptionValidations = [
  isString,
  maxStringLength(5000, {
    formatMessage: () => `Dataset descriptions should be at most 5.000 characters long.`,
  }),
];
export const redirectRegexValidations = [
  applyRuleConfig(required, {
    formatMessage: () => `A value is required.`,
  }),
  isString,
  applyRuleConfig(
    maxStringLength(5000, {
      formatMessage: () => `Evaluations can be at most 5.000 characters long.`,
    })
  ),
  applyRuleConfig(isValidRegex, {
    formatMessage: (message) => `Couldn't parse regular expression: ${message}.`,
  }),
];
export const redirectPrefixValidations = [
  applyRuleConfig(required, {
    formatMessage: () => `A value is required.`,
  }),
  isString,
  applyRuleConfig(
    // https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
    maxStringLength(5000, {
      formatMessage: () => "Prefixes can be at most 5.000 characters long.",
    })
  ),
];

export const serviceNameValidations = [
  applyRuleConfig(required, {
    formatMessage: () => `A service name is required.`,
  }),
  isString,
  minStringLength(2, {
    formatMessage: () => `Service names should have at least 2 characters.`,
  }),
  applyRuleConfig(ascii, {
    formatMessage: () => `Service names can only contain letters, numbers and hyphens (-).`,
  }),
  maxStringLength(MAX_SERVICE_NAME_LENGTH, {
    formatMessage: () => `Service names should be at most ${MAX_SERVICE_NAME_LENGTH} characters long.`,
  }),
  reservedServiceName,
];
export const getQueryNameValidations = (options?: { messageSubject?: string }) => [
  applyRuleConfig(required, {
    formatMessage: () => (options?.messageSubject ? `${options.messageSubject} is required.` : "Required."),
  }),
  isString,
  minStringLength(2, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} should have at least 2 characters.`
        : "Should have at least 2 characters.",
  }),
  applyRuleConfig(ascii, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} can only contain letters, numbers and hyphens (-).`
        : "Can only contain letters, numbers and hyphens (-).",
  }),
  maxStringLength(MAX_QUERY_NAME_LENGTH, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} should be at most ${MAX_QUERY_NAME_LENGTH} characters long.`
        : `Should be at most ${MAX_QUERY_NAME_LENGTH} characters long.`,
  }),
];
export const getStoryNameValidations = (options?: { messageSubject?: string }) => [
  applyRuleConfig(required, {
    formatMessage: () => (options?.messageSubject ? `${options.messageSubject} is required.` : "Required."),
  }),
  isString,
  minStringLength(2, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} should have at least 2 characters.`
        : "Should have at least 2 characters.",
  }),
  applyRuleConfig(ascii, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} can only contain letters, numbers and hyphens (-).`
        : "Can only contain letters, numbers and hyphens (-).",
  }),
  maxStringLength(MAX_STORY_NAME_LENGTH, {
    formatMessage: () =>
      options?.messageSubject
        ? `${options.messageSubject} should be at most ${MAX_STORY_NAME_LENGTH} characters long.`
        : `Should be at most ${MAX_STORY_NAME_LENGTH} characters long.`,
  }),
];
export const queryDescriptionValidations = [
  maxStringLength(1000, {
    formatMessage: () => `Query description should be at most 1.000 characters long.`,
  }),
];
export const storyElementCaptionValidation = [
  isString,
  maxStringLength(1000, {
    formatMessage: () => `Query description should be at most 1.000 characters long.`,
  }),
];
export const storyElementParagraphValidation = [
  applyRuleConfig(required, {
    formatMessage: () => `Content is required.`,
  }),
  isString,
  maxStringLength(10_000, {
    formatMessage: () => `Paragraphs should be at most 10.000 characters long.`,
  }),
  applyRuleConfig(noEmptyField, {
    formatMessage: () => `Paragraphs may not be empty.`,
  }),
];

export const tokenDescriptionValidations = [
  applyRuleConfig(required, {
    formatMessage: () => `A token description is required.`,
  }),
  isString,
  maxStringLength(1000, {
    formatMessage: () => `Token description should be at most 1.000 characters long.`,
  }),
  applyRuleConfig(noEmptyField, {
    formatMessage: () => `Token descriptions may not be empty.`,
  }),
];
export const getShortLinkValidations = (maxSize: number) => [
  applyRuleConfig(required, {
    formatMessage: () => `A URL to shorten is required.`,
  }),
  isString,
  maxStringByteSize(maxSize, {
    formatMessage: () => `Link is too long to be shortened.`,
  }),
  applyRuleConfig(noEmptyField, {
    formatMessage: () => `The link cannot be empty.`,
  }),
];
export const getAccountNameValidations = (options?: {
  messageSubject?: string;
  expandedBlackList?: string[];
  skipBlacklist?: boolean;
}) => {
  const rules = [
    applyRuleConfig(required, {
      formatMessage: () => (options?.messageSubject ? `${options.messageSubject} is required.` : "Required."),
    }),
    isString,
    minStringLength(2, {
      formatMessage: () =>
        options?.messageSubject
          ? `${options.messageSubject} should have at least 2 characters.`
          : "Should have at least 2 characters.",
    }),
    applyRuleConfig(ascii, {
      formatMessage: () =>
        options?.messageSubject
          ? `${options.messageSubject} can only contain letters, numbers and hyphens (-).`
          : "Can only contain letters, numbers and hyphens (-).",
    }),
    maxStringLength(MAX_ACCOUNT_NAME_LENGTH, {
      formatMessage: () =>
        options?.messageSubject
          ? `${options.messageSubject} should be at most ${MAX_ACCOUNT_NAME_LENGTH} characters long.`
          : `Should be at most ${MAX_ACCOUNT_NAME_LENGTH} characters long.`,
    }),
  ];
  if (!options?.skipBlacklist) {
    rules.push(accountNameBlacklist);
    if (options?.expandedBlackList && options.expandedBlackList.length > 0) {
      rules.push(generateAddedBlacklist(options.expandedBlackList));
    }
  }

  return rules;
};
function generateAddedBlacklist(blacklist: string[]) {
  const blacklisted = blacklist.map((value) => value.toLowerCase().trim());
  return (value: unknown) => {
    if (value && typeof value === "string" && value.toLowerCase && blacklisted.indexOf(value.toLowerCase()) >= 0) {
      return {
        message: `Account name '${value}' is not allowed.`,
        code: ErrorCode.ACCOUNT_BLACKLIST,
      };
    }
  };
}

export const accountDescriptionValidations = [
  isString,
  maxStringLength(5000, {
    formatMessage: () => `Description should be at most 5.000 characters long.`,
  }),
];

export const prefixLabelValidations = [
  isString,
  applyRuleConfig(prefix, {
    formatMessage: (val) => `'${val}' is not a valid prefix.`,
  }),
  maxStringLength(100, {
    formatMessage: () => `Prefix label should be at most 100 characters long.`,
  }),
];
export const iriValidations = [
  isString,
  applyRuleConfig(defined, {
    formatMessage: () => `An IRI is required.`,
  }),
  maxStringLength(10000, {
    formatMessage: () => `IRI should be at most 10.000 characters long.`,
  }),
  IRI,
];

export const languageTagValidations = [isString, required, applyRuleConfig(validLanguageTag)];

export const getQueryVarValidations: (variableConfig: Models.VariableConfig) => Rule[] = (variableConfig) => {
  return [
    (value) => {
      if (!value || typeof value !== "string") return;
      try {
        assertValidValue({
          declaration: variableConfig,
          value: value,
        });
      } catch (e) {
        if (e instanceof InjectError) {
          let message = e.message;
          if (variableConfig.termType === "NamedNode") {
            message = "Invalid IRI.";
          } else if (variableConfig.datatype && variableConfig.datatype in DATATYPE_DEFINITIONS) {
            const datatypeInfo = DATATYPE_DEFINITIONS[variableConfig.datatype];
            message = "Invalid value.";
            if (datatypeInfo.examples.length > 0) {
              message += ' Examples: "';
              message += datatypeInfo.examples
                .slice(0, 2)
                .map((example) => example.value)
                .join('", or "'); // We can always join like this, as there are at most 2 elements
              message += '".';
            }
          }
          return {
            message: message,
            code: ErrorCode.INVALID_QUERY_VARIABLE,
          };
        } else if (e instanceof Error) {
          return {
            message: e.message,
            code: ErrorCode.INVALID_QUERY_VARIABLE,
          };
        } else {
          return {
            message: "Unknown validation issue.",
            code: ErrorCode.INVALID_QUERY_VARIABLE,
          };
        }
      }
    },
  ];
};

export const queryAllowedValuesValidations = [
  isArray,
  maxSizeArray(50, {
    formatMessage: () => `A maximum of 50 values is allowed`,
  }),
];

export const sparqlVarnameValidations = [required, isString, sparqlVarname, reservedSparqlVarname];

export const siteNameValidations = [required, maxStringLength(25)];
export const siteTaglineValidations = [required, maxStringLength(50)];
export const siteDescriptionValidations = [required, maxStringLength(5000)];
export const siteWelcomeTextValidations = [maxStringLength(5000)];
