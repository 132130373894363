import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { omit, pick } from "lodash-es";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { FontAwesomeIcon } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { filters } from "./CurrentFilters.tsx";
import { Page } from "./index.tsx";
import styles from "./style.scss";

interface Props {
  page: Page;
  resultCount?: number;
}

const pickQueryForPage = (query: any, page: Page) => {
  // On the stories page, we don't filter on "none" for datasets, as we don't know what the semantics for this should be
  if (page === "stories" && "dataset" in query && query.dataset === "none") query = omit(query, "dataset");
  return pick(query, filters[page], "sort" in query && supportsSortingBy(page, query.sort) ? ["sort", "asc"] : []);
};

const supportsSortingBy = (page: Page, sortBy: string) => {
  switch (page) {
    case "datasets":
      return ["created", "name", "statements", "relevance"].includes(sortBy);
    case "stories":
    case "queries":
    case "organizations":
      return ["created", "name", "relevance"].includes(sortBy);
  }
};

const PageSelect: React.FC<Props> = ({ page, resultCount }) => {
  const history = useHistory();
  const location = useLocation();
  const query = parseSearchString(location.search);
  const constructUrlToApi = useConstructUrlToApi();
  const [fetchedCounts, setFetchedCounts] = React.useState<{
    datasets?: number;
    stories?: number;
    queries?: number;
    organizations?: number;
  }>({});
  React.useEffect(() => {
    setFetchedCounts({});
    if (page !== "datasets")
      fetch(
        constructUrlToApi({
          pathname: "/facets/datasets",
          query: { ...pickQueryForPage(query, "datasets"), fields: "count" },
        })
      )
        .then((response) => response.json())
        .then((json: { count: number }) =>
          setFetchedCounts((fetchedCounts) => ({ ...fetchedCounts, datasets: json.count }))
        )
        .catch(() => {});
    if (page !== "stories")
      fetch(
        constructUrlToApi({
          pathname: "/facets/stories",
          query: { ...pickQueryForPage(query, "stories"), fields: "count" },
        })
      )
        .then((response) => response.json())
        .then((json: { count: number }) =>
          setFetchedCounts((fetchedCounts) => ({ ...fetchedCounts, stories: json.count }))
        )
        .catch(() => {});
    if (page !== "queries")
      fetch(
        constructUrlToApi({
          pathname: "/facets/queries",
          query: { ...pickQueryForPage(query, "queries"), fields: "count" },
        })
      )
        .then((response) => response.json())
        .then((json: { count: number }) =>
          setFetchedCounts((fetchedCounts) => ({ ...fetchedCounts, queries: json.count }))
        )
        .catch(() => {});
    if (page !== "organizations")
      fetch(
        constructUrlToApi({
          pathname: "/facets/organizations",
          query: { ...pickQueryForPage(query, "organizations"), fields: "count" },
        })
      )
        .then((response) => response.json())
        .then((json: { count: number }) =>
          setFetchedCounts((fetchedCounts) => ({ ...fetchedCounts, organizations: json.count }))
        )
        .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, location.search]);
  const counts = {
    ...fetchedCounts,
    [page]: resultCount,
  };
  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="type"
        value={page}
        onChange={(_event, page) => {
          history.push({
            pathname: "/browse/" + page,
            search: stringifyQuery(pickQueryForPage(query, page as Page)),
          });
        }}
      >
        <FormControlLabel
          value="datasets"
          className={styles.radioLabel}
          control={<Radio color="secondary" />}
          label={
            <div className="flex center grow">
              <div className="grow">Datasets</div>
              <div className={styles.smaller}>{counts.datasets ?? <FontAwesomeIcon icon="spinner" pulse />}</div>
            </div>
          }
        />
        <FormControlLabel
          value="stories"
          className={styles.radioLabel}
          control={<Radio color="secondary" />}
          label={
            <div className="flex center grow">
              <div className="grow">Stories</div>
              <div className={styles.smaller}>{counts.stories ?? <FontAwesomeIcon icon="spinner" pulse />}</div>
            </div>
          }
        />
        <FormControlLabel
          value="queries"
          className={styles.radioLabel}
          control={<Radio color="secondary" />}
          label={
            <div className="flex center grow">
              <div className="grow">Queries</div>
              <div className={styles.smaller}>{counts.queries ?? <FontAwesomeIcon icon="spinner" pulse />}</div>
            </div>
          }
        />
        <FormControlLabel
          value="organizations"
          className={styles.radioLabel}
          control={<Radio color="secondary" />}
          label={
            <div className="flex center grow">
              <div className="grow">Organizations</div>
              <div className={styles.smaller}>{counts.organizations ?? <FontAwesomeIcon icon="spinner" pulse />}</div>
            </div>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default PageSelect;
