import { IconButton } from "@mui/material";
import bbox from "@turf/bbox";
import * as React from "react";
import { Geometry, lexicalToValue, MultiPolygon, Polygon } from "@triplydb/recognized-datatypes/wkt.js";
import { FontAwesomeIcon } from "#components/index.ts";
import styles from "#components/TermLiteralWkt/style.scss";

function polygonToSvgPolygonString(geometry: Polygon | MultiPolygon): string[] {
  // Only convert the first array of each polygon, others are holes
  const polygons =
    geometry.type === "Polygon" ? [geometry.coordinates[0]] : geometry.coordinates.map((polygon) => polygon[0]);
  // Inverse the y as svg's draw from the bottom to top, and coordinates work from top to bottom so otherwise the image is vertically flipped
  return polygons.map((polygon) => polygon.map(([x, y]) => [x, -y].join(" ")).join(", "));
}

const GeometryRenderer: React.FC<{ wkt: string }> = ({ wkt: wktString }) => {
  const [showWkt, setShowWkt] = React.useState(false);
  try {
    const geometry = lexicalToValue(wktString);
    if (geometry.type === "Polygon" || geometry.type === "MultiPolygon") {
      const polygons = polygonToSvgPolygonString(geometry);
      // If we have no Polygons, we should throw and just render the wkt string
      if (polygons.length === 0) throw new Error("No polygon to draw");
      // Inverse the y axis (north,south) as svg's draw from the bottom left, and coordinates work from "top left" so otherwise the image is vertically flipped
      const [west, north, east, south] = bbox(geometry);
      const width = east - west;
      const height = -north - -south;
      const svgProps: React.SVGProps<SVGSVGElement> = {};
      // The largest side should be set, so that we'll always get a "squarish" element
      svgProps[width > height ? "width" : "height"] = 48;
      return (
        <div>
          <svg
            {...svgProps}
            viewBox={`${west} ${-south} ${width} ${height}`}
            aria-label={wktString}
            className={styles.svg}
          >
            {polygons
              .filter((polygonString) => polygonString !== undefined)
              .map((polygonString, idx) => (
                <polygon key={idx} points={polygonString} />
              ))}
          </svg>
          {showWkt ? (
            <span>{wktString}</span>
          ) : (
            <IconButton
              aria-label="Show wkt"
              title="show wkt"
              size="small"
              color="info"
              onClick={() => setShowWkt(true)}
            >
              <FontAwesomeIcon icon="eye" />
            </IconButton>
          )}
        </div>
      );
    }
  } catch (e) {
    // Drop down
  }
  return <span>{wktString}</span>;
};

export default React.memo(GeometryRenderer);
