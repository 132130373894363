import { last } from "lodash-es";
import * as React from "react";
import { FileRejection } from "react-dropzone";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { asyncConnect } from "redux-connect";
import { SubmissionError } from "redux-form";
import { JobTypes } from "@triply/utils/Models.js";
import * as Forms from "#components/Forms/index.ts";
import {
  Button,
  ConfirmationDialog,
  DataPipeline,
  DatasetMetadata,
  ErrorPage,
  FlexContainer,
  FontAwesomeIcon,
} from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import usePrevious from "#helpers/hooks/usePrevious.ts";
import { getCurrentAccount, useCurrentAccount } from "#reducers/app.ts";
import { Dataset, getCurrentDataset, refreshDatasetsInfo, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { createJob } from "#reducers/datasets.ts";
import { getGraphs, needToFetchGraphs, removeAllGraphs } from "#reducers/graphs.ts";
import { addImports, getGraphsFor } from "#reducers/imports.ts";
import { GlobalState } from "#reducers/index.ts";
import { sendIgnoredUploadNotification, uploadFile } from "#reducers/uploading.ts";
import AddDataForm from "./addData.tsx";
import QualityReport from "./QualityReport.tsx";
import GraphTable from "./Table.tsx";
import styles from "./style.scss";

export namespace Graphs {
  export interface Props extends IComponentProps {}
}

const Graphs: React.FC<Graphs.Props> = () => {
  const acl = useAcl();
  const dispatch = useDispatch();
  const currentAccount = useCurrentAccount();
  const location = useLocation();
  const userRole = acl.getRoleInAccount(currentAccount);
  const mayImportData =
    acl.check({
      action: "importDataToDataset",
      context: { roleInOwnerAccount: userRole },
    }).granted &&
    userRole !== "editor" &&
    userRole !== "reviewer";
  const mayManageGraphs =
    acl.check({
      action: "manageGraphs",
      context: { roleInOwnerAccount: userRole },
    }).granted &&
    userRole !== "editor" &&
    userRole !== "reviewer";
  const currentDs = useCurrentDataset();

  const graphs = useSelector(
    (state: GlobalState) => currentDs && state.graphs[currentDs.id] && state.graphs[currentDs.id].list
  );
  const constructUrlToApi = useConstructUrlToApi();
  const datasetsUrl = constructUrlToApi({ pathname: "/datasets" });
  const job = useSelector((state: GlobalState) => currentDs && last(state.datasets[currentDs.id].jobs));

  const jobIsfinished = job === undefined || job === null || job.status === "finished" || job.status === "canceled";
  const downloadJobIsOngoing = !!job && job.type === "download" && !jobIsfinished;
  const uploadJobIsOngoing = !!job && job.type === "upload" && !jobIsfinished;
  const isJobOngoing = downloadJobIsOngoing || uploadJobIsOngoing;

  const [show, setShow] = React.useState<"allOptions" | "importFromDataset" | "pipeline" | undefined>(
    mayImportData && !currentDs?.graphCount ? "allOptions" : undefined
  );
  const [initialImportDs, setInitialImportDs] = React.useState<Dataset>();
  const [initialGraphCount, setInitialGraphCount] = React.useState<number>();
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = React.useState(false);
  const [expectedJobType, setExpectedJobType] = React.useState<JobTypes>();
  const previousJob = usePrevious(job);
  React.useEffect(() => {
    if (!job && previousJob) {
      // when a job is finished it's removed from redux. In that case we should only show the graphs
      setShow(undefined);
    }
  }, [job, previousJob]);

  React.useEffect(() => {
    if (mayImportData) {
      if (job && isJobOngoing) {
        setShow("pipeline");
      } else if (!graphs?.length) {
        setShow("allOptions");
      }
    }
  }, [graphs, job, isJobOngoing, mayImportData]);

  const openImportFromFileForm = React.useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        dispatch<typeof sendIgnoredUploadNotification>(
          sendIgnoredUploadNotification(rejectedFiles.map((rej) => rej.file))
        );
      }
      if (acceptedFiles.length === 0) return;
      if (!job && currentDs) {
        setShow("pipeline");
        dispatch<typeof createJob>(createJob(currentDs, { type: "upload" }))
          .then((result) => {
            return dispatch<typeof uploadFile>(uploadFile(result.body, acceptedFiles));
          })
          .catch((e) => {
            // Do nothing
            console.error("Couldnt create a new job", e);
          });
      } else if (job) {
        dispatch<typeof uploadFile>(uploadFile(job, acceptedFiles));
      }
      setExpectedJobType("upload");
    },
    [dispatch, job, currentDs, setExpectedJobType, setShow]
  );
  const openImportFromDatasetForm = React.useCallback(
    (datasetJson: Dataset) => {
      if (!datasetJson) return;
      setInitialImportDs(datasetJson);
      setInitialGraphCount(datasetJson.graphCount || 0);
      setShow("importFromDataset");
    },
    [setShow, setInitialGraphCount, setInitialImportDs]
  );

  const dispatchGetGraphsFor = React.useCallback(
    async (dataset: Dataset) => {
      return dispatch<typeof getGraphsFor>(getGraphsFor(dataset));
    },
    [dispatch]
  );

  const closeImportFromDatasetForm = React.useCallback(() => {
    setShow(!graphs?.length ? "allOptions" : undefined);
  }, [setShow, graphs]);

  // When graphs are added we should change the key to reset the table.
  const [graphsTableKey, setGraphsTableKey] = React.useState(1);
  const currentGraphCount = graphs?.length || 0;
  const previousGraphCount = usePrevious(graphs)?.length || 0;
  React.useEffect(() => {
    if (currentGraphCount > previousGraphCount) setGraphsTableKey((i) => i + 1);
  }, [currentGraphCount, previousGraphCount]);

  if (!currentDs || !currentAccount) return <ErrorPage statusCode={404} />;
  return (
    <FlexContainer className="mt-3">
      <DatasetMetadata
        currentPath={location.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title="Graphs"
      />

      {show === "allOptions" && datasetsUrl && (
        <AddDataForm
          onFilesSelect={openImportFromFileForm}
          onImportSelect={openImportFromDatasetForm}
          onUrlSelect={(values) => {
            setShow("pipeline");
            setExpectedJobType("download");
            const body = { url: values.url, type: "download" as JobTypes };
            if (currentDs) dispatch<typeof createJob>(createJob(currentDs, body)).catch(() => {});
          }}
          datasetsUrl={datasetsUrl}
        />
      )}

      {show === "importFromDataset" && datasetsUrl && (
        <Forms.LocalGraphImport
          initialGraphCount={initialGraphCount || 0}
          getGraphsFor={dispatchGetGraphsFor}
          onSubmit={(values: Forms.LocalGraphImport.FormData) => {
            // const { currentDs, currentAccount, addImports } = this.props;
            if (!values.dataset.owner.accountName) return;
            const i = {
              dataset: { ownerName: values.dataset.owner.accountName, datasetName: values.dataset.name },
              graphs: values.graphs.filter((g) => g.checked).map((g) => ({ from: g.from, to: g.to })),
            };
            if (currentAccount && currentDs)
              return dispatch<typeof addImports>(addImports(currentAccount, currentDs, [i])).then(
                async () => {
                  setShow(undefined);
                  await Promise.all([
                    dispatch<typeof refreshDatasetsInfo>(
                      refreshDatasetsInfo({ accountName: currentAccount.accountName, datasetName: currentDs.name })
                    ),
                    dispatch<typeof getGraphs>(
                      getGraphs({
                        accountName: currentAccount.accountName,
                        datasetName: currentDs.name,
                        datasetId: currentDs.id,
                      })
                    ),
                  ]);
                },
                (e: Error) => {
                  throw new SubmissionError({ _error: e.message });
                }
              );
          }}
          cancel={closeImportFromDatasetForm}
          datasetsUrl={datasetsUrl}
          className="whiteSink mb-7"
          initialValues={{
            dataset: initialImportDs,
          }}
        />
      )}

      {show === "pipeline" && (
        <div className="whiteSink mb-7">
          <DataPipeline job={job} onFilesSelect={openImportFromFileForm} expectedJobType={expectedJobType} />
        </div>
      )}

      {!show && (
        <div className={styles.headerRow}>
          {mayImportData && (
            <Button
              elevation
              className="mt-3 ml-3"
              color="secondary"
              onClick={() => setShow("allOptions")}
              title={"Add new graph(s) to the dataset"}
              startIcon={<FontAwesomeIcon icon="plus" />}
            >
              Import a new graph
            </Button>
          )}
          <div className={styles.grow}></div>
          {currentDs.graphCount > 0 && (
            <>
              <Button
                elevation
                href={`/${currentDs.owner.accountName}/${currentDs.name}/download.trig.gz`}
                LinkComponent={React.forwardRef(({ children, ...props }, ref) => (
                  <a {...props} download target="_blank" ref={ref}>
                    {children}
                  </a>
                ))}
                className="mt-3 ml-3"
                size="small"
                title="Export all graphs"
                startIcon={<FontAwesomeIcon icon="arrow-to-bottom" />}
              >
                Export all graphs
              </Button>

              {mayManageGraphs && (
                <>
                  <Button
                    color="error"
                    elevation
                    className="mt-3 ml-3"
                    size="small"
                    startIcon={<FontAwesomeIcon icon="trash" />}
                    onClick={() => setShowDeleteConfirmationDialog(true)}
                    title="Remove all graphs"
                  >
                    Remove all graphs
                  </Button>
                  <ConfirmationDialog
                    open={showDeleteConfirmationDialog}
                    onClose={() => setShowDeleteConfirmationDialog(false)}
                    actionLabel="Remove all graphs"
                    title="Remove all graphs?"
                    onConfirm={() => {
                      setShowDeleteConfirmationDialog(false);
                      dispatch<typeof removeAllGraphs>(removeAllGraphs(currentDs)).catch(() => {});
                    }}
                    description="Are you sure you want to remove all graphs?"
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
      {graphs && graphs.length > 0 && <GraphTable key={graphsTableKey} graphs={graphs} />}
      {graphs && graphs.length > 0 && (
        <QualityReport graphs={graphs} ldTablePage={`/${currentAccount.accountName}/${currentDs.name}/table`} />
      )}
      {!mayImportData && graphs?.length === 0 && <div className={styles.noContentMsg}>This dataset has no graphs.</div>}
    </FlexContainer>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState } }) => {
      const state = getState();
      const currentDs = getCurrentDataset(state);
      const currentAccount = getCurrentAccount(state);
      if (currentAccount && currentDs && needToFetchGraphs(state, currentDs)) {
        return dispatch<any>(
          getGraphs({ accountName: currentAccount.accountName, datasetName: currentDs.name, datasetId: currentDs.id })
        );
      }
    },
  },
])(Graphs);
