import { Alert, Tooltip } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as ReduxForm from "redux-form";
import { Constants } from "@triply/utils";
import { getNewName } from "@triply/utils-private";
import * as Forms from "#components/Forms/index.ts";
import {
  Button,
  DatasetMetadata,
  Dialog,
  ErrorPage,
  FlexContainer,
  FontAwesomeIcon,
  ServiceListItem,
  SinkList,
} from "#components/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { createService, deleteService, issueServiceCommand, Service } from "#reducers/services.ts";
import SpeedyItem from "./ServiceListItem/SpeedyItem";
import styles from "./style.scss";

const Services: React.FC<{}> = ({}) => {
  const [showModal, setShowModal] = React.useState(false);
  const dispatch = useDispatch();
  const acl = useAcl();
  const confirm = useConfirmation();
  const currentDs = useCurrentDataset();
  const location = useLocation();
  const services = useSelector((state: GlobalState) => (currentDs?.id ? state.services[currentDs.id] : undefined));
  const enabledServices = useSelector((state: GlobalState) => state.config.clientConfig?.enabledServices || []);
  const currentAccount = useCurrentAccount();

  if (!currentDs || !currentAccount || !enabledServices.length) return <ErrorPage statusCode={404} />;
  const type: Forms.ServiceAdd.FormData["type"] = enabledServices[0];
  const initialValues: Forms.ServiceAdd.FormData = {
    type,
    name: getNewName(currentDs.name, services ? services.map((s) => s.name) : [], 40),
    config: { reasonerType: "None" },
  };
  const submitForm = (values: Forms.ServiceAdd.FormData) => {
    const { config, type, ...rest } = values;
    return dispatch<typeof createService>(
      createService(currentAccount, currentDs, {
        type: type,
        ...rest,
        config: (type === "jena" ? config : undefined) as any,
      })
    ).then(
      () => setShowModal(false),
      (e: any) => {
        throw new ReduxForm.SubmissionError({ _error: e.message });
      }
    );
  };
  const mayManageServices = acl.check({
    action: "manageServices",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const mySeeCreateServiceButton = acl.check({
    action: "canSeeCreateServiceButton",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  return (
    <>
      <DatasetMetadata
        currentPath={location.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title="Services"
      />
      <div className="mt-3">
        <FlexContainer>
          {(mySeeCreateServiceButton || mayManageServices) && !!enabledServices.length && (
            <Tooltip title={currentDs?.graphCount === 0 ? Constants.CANT_CREATE_ZERO_GRAPH_SERVICE_MESSAGE : undefined}>
              <span>
                <Button
                  color="secondary"
                  elevation
                  className="ml-3 mt-3"
                  onClick={() => setShowModal(true)}
                  disabled={currentDs?.graphCount === 0}
                  startIcon={<FontAwesomeIcon icon="plus" />}
                >
                  Create new service
                </Button>
              </span>
            </Tooltip>
          )}
          <SinkList>
            <SpeedyItem isHighlighted={location.hash.slice(1) === "Speedy"} />
            {services?.map((c) => {
              const isHighlighted = location.hash.slice(1) === c.name;
              return (
                <ServiceListItem
                  key={"ServiceListItem" + c.id}
                  ds={currentDs}
                  service={c}
                  currentAccount={currentAccount}
                  writeAccess={mayManageServices}
                  isHighlighted={isHighlighted}
                  deleteHandler={(service: Service) => {
                    confirm({
                      title: "Remove service",
                      description: `Are you sure you want to remove service "${service.name}"?`,
                      actionLabel: "Remove",
                      onConfirm: () =>
                        dispatch<typeof deleteService>(deleteService(currentAccount, currentDs, service)).catch(
                          () => {}
                        ),
                    });
                  }}
                  startHandler={(service: Service) => {
                    dispatch<typeof issueServiceCommand>(
                      issueServiceCommand(currentAccount, currentDs, service, "start")
                    ).catch(() => {});
                  }}
                  stopHandler={(service: Service) => {
                    dispatch<typeof issueServiceCommand>(
                      issueServiceCommand(currentAccount, currentDs, service, "stop")
                    ).catch(() => {});
                  }}
                  stopWithAutoresumeHandler={(service: Service) => {
                    dispatch<typeof issueServiceCommand>(
                      issueServiceCommand(currentAccount, currentDs, service, "stopWithAutoresume")
                    ).catch(() => {});
                  }}
                  syncHandler={(service: Service) => {
                    dispatch<typeof issueServiceCommand>(
                      issueServiceCommand(currentAccount, currentDs, service, "sync")
                    ).catch(() => {});
                  }}
                />
              );
            })}
          </SinkList>
        </FlexContainer>
        <Dialog open={showModal} onClose={() => setShowModal(false)} title="Create service" maxWidth="md" fullWidth>
          <Forms.ServiceAdd
            className="m-5"
            initialValues={initialValues}
            forDataset={currentDs}
            onSubmit={submitForm}
            cancelFunction={() => setShowModal(false)}
          />
        </Dialog>
      </div>
      {currentDs.graphCount === 0 &&
        acl.check({
          action: "importDataToDataset",
          context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
        }).granted && (
          <FlexContainer className={styles.noContentMsg}>
            <Link to={`/${currentAccount.accountName}/${currentDs.name}/graphs`} className="noLinkDecoration">
              <Alert variant="filled" className="shadow" key="alert" severity="info">
                This dataset is empty, start by importing data
              </Alert>
            </Link>
          </FlexContainer>
        )}
    </>
  );
};

export default Services;
