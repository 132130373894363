import numeral from "numeral";
import { LiteralUtils } from "@triply/utils";

numeral.register("locale", "custom", {
  // English delimiters as we want to mimic programming languages
  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  // English text stuff, as TriplyDB is in English.
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    var b = number % 10;
    return ~~((number % 100) / 10) === 1 ? "th" : b === 1 ? "st" : b === 2 ? "nd" : b === 3 ? "rd" : "th";
  },
  // Euro currency
  currency: {
    symbol: "€",
  },
});
numeral.defaultFormat("0,0");
numeral.locale("custom");

export const formatNumber = (n: number, format: string = "0,0") => {
  return numeral(n).format(format);
};

export default formatNumber;

export const formatNumericTerm = (value: string, datatype: string) => {
  if (LiteralUtils.isNumericDatatype(datatype)) {
    if (Number.isNaN(+value)) {
      // If a value is invalid return the lexicographical value
      return value;
      // Numeric types with decimals
    } else if (datatype.includes("decimal") || (datatype.includes("float") && !value.toLowerCase().includes("e"))) {
      const specificity = Math.max(value.split(".")[1]?.length || 0);
      return formatNumber(+value, `0,0.${"0".repeat(specificity)}`);
      // Check if for 1.23e10
    } else if (datatype.includes("double") || datatype.includes("float")) {
      const specificity = Math.max(value.toLowerCase().split(".")[1]?.split("e")[0].length || 1);
      return formatNumber(+value, `0.${"0".repeat(specificity)}e+0`);
    } else {
      // Rest
      return formatNumber(+value);
    }
  } else {
    throw new Error("Value does not have a numeric datatype");
  }
};
