import getClassName from "classnames";
import { capitalize } from "lodash-es";
import memoize from "lru-memoize";
import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./style.scss";

const avatarColors: string[] = [
  "#99B1AD",
  "#99A9B0",
  "#A7C1B3",
  "#CDDDDB",
  "#CDD8DD",
  "#D3E4DB",
  "#74908B",
  "#74878F",
  "#81A090",
];

//returns number between 0 and 4294967295 (inclusive)
const maxHash = 4294967295;
const steps = maxHash / avatarColors.length;

const hashString = (str: string) => {
  var hash = 5381,
    i = str.length;

  while (i) hash = (hash * 33) ^ str.charCodeAt(--i);

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
};
const getColorFromString: (str: string) => string = memoize(50)(function (str: string) {
  const hash = hashString(str);
  return avatarColors[Math.floor(hash / steps)];
});

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  // src: string,
  avatarName: string;
  alt: string; // Keep empty if you're displaying for niceness
  size: "xs" | "sm" | "md" | "lg" | "xl";
  linkTo?: string;
  avatarUrl?: string;
  square?: boolean;
  title?: string;
}

const Avatar: React.FC<Props> = ({ className, avatarName, alt, size, linkTo, avatarUrl, square, title, style }) => {
  const classNames: { [className: string]: boolean } = {
    [styles.isLink]: !!linkTo,
    [styles.square]: !!square,
  };
  const content = avatarUrl ? (
    <img title={title} src={avatarUrl} alt={alt} />
  ) : (
    <div
      title={title}
      className={styles.letter}
      style={{ backgroundColor: getColorFromString(avatarName) }}
      aria-label={alt}
    >
      {[...avatarName][0]}
    </div>
  );
  return (
    <div
      className={getClassName(className, styles.avatar, styles[`avatar${capitalize(size)}`], classNames)}
      style={style}
    >
      {linkTo ? (
        <Link to={{ pathname: linkTo }} className="noLinkDecoration">
          {content}
        </Link>
      ) : (
        content
      )}
    </div>
  );
};
export default Avatar;
