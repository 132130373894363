import { Alert } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { Label, LoadingButton } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { editDebugNamespace } from "#reducers/admin.ts";
import { GlobalState } from "#reducers/index.ts";

interface Props {}

const Debug: React.FC<Props> = ({}) => {
  const [editingDebug, setEditingDebug] = React.useState(false);
  const [debugNamespace, setDebugNamespace] = React.useState<string>();
  const [debugError, setDebugError] = React.useState<string>();
  const debugNamespaces = useSelector((state: GlobalState) => state.config.clientConfig?.debugNamespaces);
  const [namespace, setNameSpace] = React.useState<string>(debugNamespaces || "");

  const dispatch = useDispatch();
  const handleDebug = (e: React.FormEvent) => {
    e.preventDefault();
    setEditingDebug(true);
    setDebugError(undefined);
    dispatch<typeof editDebugNamespace>(editDebugNamespace(namespace)).then(
      () => {
        setEditingDebug(false);
        setDebugNamespace(namespace);
      },
      (e: Error) => {
        setEditingDebug(false);
        setDebugError(e.message);
      }
    );
  };

  return (
    <form className="whiteSink" onSubmit={handleDebug}>
      <h3>Debug</h3>
      <Alert severity="info">
        Specify what messages the server should log. Use wildcards like 'triply:*'. Separate namespaces with a comma:
        'triply:pipeline*,triply:services*'. Exclude namespaces with a minus: '-*:query'. NB: errors (triply:*:error)
        will always be displayed, unless explicitly excluded
      </Alert>
      <div style={{ marginBottom: "0px" }}>
        <input onChange={(e) => setNameSpace(e.target.value)} value={namespace} />
      </div>
      <LoadingButton disabled={editingDebug} onClick={handleDebug} loading={editingDebug}>
        Save
      </LoadingButton>
      {debugNamespace && (
        <div style={{ marginTop: 5 }}>
          <Label success message={`Debug namespace successfully set to '${debugNamespace}'`} />
        </div>
      )}
      {debugError && <Alert severity="error">{debugError}</Alert>}
    </form>
  );
};
export default Debug;
