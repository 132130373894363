import { Alert, Button, FormControlLabel, Radio } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Models } from "@triply/utils";
import { SUBSCRIPTIONS_PAGE } from "@triply/utils/Constants.js";
import { FontAwesomeIcon, MaterialRadioButtonGroup } from "#components/index.ts";
import { getAccount } from "#reducers/accountCollection.ts";
import { GlobalState } from "#reducers/index.ts";
import styles from "./style.scss";

namespace AccessLevels {
  export interface Props {
    name: string;
    type: AccessLevels.Type;
    accountType: Models.AccountType;
    className?: string;
    changeAccessLevel: (value: Models.AccessLevel) => void;
  }
  export interface State {}

  export type Type = "dataset" | "query" | "story";
}

const iconMapping: { [key in Models.AccessLevel]: FontAwesomeIcon.Props["icon"] } = {
  private: "lock",
  internal: "shield",
  public: "globe",
};

export function getAccessLevelText(
  accessLevel: Models.AccessLevel,
  type: AccessLevels.Type,
  prefixWithLabel = false,
  accountType?: Models.AccountType
) {
  switch (accessLevel) {
    case "private":
      if (!accountType) {
        throw new Error("Missing account type, this is required for access level private.");
      }
      return `${prefixWithLabel ? "Private: t" : "T"}his ${type} is only visible to ${
        accountType === "user" ? "you" : "members of this organization"
      } (and administrators)`;
    case "internal":
      const internalPrefix = prefixWithLabel ? "Internal: a" : "A";
      switch (type) {
        case "dataset":
          return `${internalPrefix}ny logged-in user can view and query this dataset`;
        case "query":
          return `${internalPrefix}ny logged-in user can view and run this query`;
        case "story":
          return `${internalPrefix}ny logged-in user can view this story`;
        default:
          throw new Error(`Unexpected type ${type}`);
      }
    case "public":
      const publicPrefix = prefixWithLabel ? "Public: a" : "A";
      switch (type) {
        case "dataset":
          return `${publicPrefix}nybody can view and query this dataset`;
        case "query":
          return `${publicPrefix}nybody can view and run this query`;
        case "story":
          return `${publicPrefix}nybody can view this story`;
        default:
          throw new Error(`Unexpected type ${type}`);
      }
    default:
      throw new Error(`Unexpected access level ${accessLevel}`);
  }
}

const RadioBtn: React.FC<{ accessLevel: Models.AccessLevel; label: React.ReactElement; isDisabled?: boolean }> = ({
  accessLevel,
  label,
  isDisabled,
}) => {
  const icon = <FontAwesomeIcon fixedWidth icon={iconMapping[accessLevel]} />;

  return (
    <FormControlLabel
      value={accessLevel}
      label={label}
      disabled={isDisabled}
      control={<Radio color="primary" checkedIcon={icon} icon={icon} value={accessLevel} />}
    />
  );
};

const AccessLevels: React.FC<AccessLevels.Props> = ({ name, type, accountType, className, changeAccessLevel }) => {
  const isTriplyDbComMode = useSelector((state: GlobalState) => !!state.config.clientConfig?.triplydb);

  const triplyDbDemoLink = useSelector((state: GlobalState) => state.config.clientConfig?.triplydb?.triplydbDemoLink);

  const role = useSelector((state: GlobalState) => {
    const account = getAccount(state, state.auth.loggedInUser);
    const role = account?.type === "user" ? account.role : undefined;
    return role;
  });

  useEffect(() => {
    if (isTriplyDbComMode && (role === "light" || role === "none")) {
      changeAccessLevel("public");
    }
    // changeAccessLevel causes for infinite rerenders, but we want to use this as a componentDidMount effect.
    // So we are ignoring the missing dependencies error.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, isTriplyDbComMode]);

  // if you need more fine-grained control on stories, queries and datasets, you could consider using the ACL for this
  const isDisabled = isTriplyDbComMode && (role === "light" || role === "none");

  const isDisabledStyle = isDisabled ? styles.disabledText : undefined;

  return (
    <MaterialRadioButtonGroup.Field name={name} component={MaterialRadioButtonGroup} className={className}>
      {isDisabled && (
        <Alert
          severity="info"
          action={
            triplyDbDemoLink && (
              <Button color="info" href={triplyDbDemoLink} target="_blank" variant="text">
                Book a Demo
              </Button>
            )
          }
        >
          Only{" "}
          <a href={SUBSCRIPTIONS_PAGE} target="_blank">
            enterprise users
          </a>{" "}
          may create private or internal resources.
          <br />
          To find out more about this feature, check out{" "}
          <a href="https://docs.triply.cc/triply-db-getting-started/reference/#access-levels" target="_blank">
            our documentation
          </a>{" "}
          or book a demo!
        </Alert>
      )}
      <RadioBtn
        accessLevel="private"
        label={
          <div className="my-2">
            <h6 className={isDisabledStyle}>Private</h6> {getAccessLevelText("private", type, false, accountType)}
          </div>
        }
        isDisabled={isDisabled}
      />
      <RadioBtn
        accessLevel="internal"
        label={
          <div className="my-2">
            <h6 className={isDisabledStyle}>Internal</h6> {getAccessLevelText("internal", type)}
          </div>
        }
        isDisabled={isDisabled}
      />
      <RadioBtn
        accessLevel="public"
        label={
          <div className="my-2">
            <h6>Public</h6> {getAccessLevelText("public", type)}
          </div>
        }
      />
    </MaterialRadioButtonGroup.Field>
  );
};

export default AccessLevels;
