import moment, { Moment } from "moment";

export default function (
  date: Moment | Date,
  direction: "past" | "future",
  firstChar: "capitalize" | "lowercase" = "lowercase",
  suffix: "with suffix" | "without suffix" = "with suffix"
): string {
  const dateAsMoment: Moment = "startOf" in date ? date : moment(date);
  const withSuffix = suffix === "with suffix";
  function truncate(days: number) {
    if (direction === "future") {
      return Math.max(0, days);
    } else {
      return Math.min(0, days);
    }
  }
  const duration = truncate(dateAsMoment.startOf("day").diff(moment().startOf("day"), "days"));
  let dateString: string;
  if (withSuffix && duration == 0) {
    dateString = "today";
  } else if (withSuffix && Math.abs(duration) <= 1) {
    dateString = direction === "future" ? "tomorrow" : "yesterday";
  } else {
    // https://momentjs.com/docs/#/durations/humanize/
    dateString = moment.duration(duration, "days").humanize(withSuffix);
  }
  if (firstChar === "capitalize") dateString = dateString[0].toUpperCase() + dateString.slice(1);
  return dateString;
}
