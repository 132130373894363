import { Alert, Autocomplete, Skeleton, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import { cloneDeep, merge } from "lodash-es";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { useApplyPrefixes, useRemovePrefixes } from "../../../components/Prefixed";
import useCurrentClass from "../useCurrentClass";
import useSparql from "../useSparql";
import { validateIri } from "./helpers";
import localTheme from "./Theme";
import { DatatypePropertyData } from "./Types";

const PropertyForm: React.FC<{ onSubmit: (values: DatatypePropertyData) => Promise<void> }> = ({ onSubmit }) => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    setError,
  } = useForm<DatatypePropertyData>({
    defaultValues: async () => ({ label: "", id: applyPrefixes(`${datasetUrl}/def/${Date.now()}`), datatype: "" }),
    mode: "onChange",
  });

  const currentDs = useCurrentDataset()!;
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });
  const currentClass = useCurrentClass();

  const applyPrefixes = useApplyPrefixes();
  const removePrefixes = useRemovePrefixes();

  const { data: existingProperties } = useSparql<{ id: string; label: string; description?: string }[]>(`
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>
    prefix owl: <http://www.w3.org/2002/07/owl#>

    select ?id (sample(?label_t) as ?label) (sample(?description_t) as ?description) where {
      bind(<${currentClass}> as ?class)
      # ?id rdfs:subPropertyOf*/rdfs:domain/^rdfs:subClassOf* ?class.
      ?id rdfs:domain/^rdfs:subClassOf* ?class.
      # ?id rdfs:subPropertyOf*/rdfs:range/rdfs:subClassOf* rdfs:Literal.
      ?id a owl:DatatypeProperty.
      ?id rdfs:label|skos:prefLabel ?label_t
      optional {
        ?id rdfs:comment|skos:definition ?description_t
      }
    }
    group by ?id
    order by ?label
    `);

  const { data: datatypes } = useSparql<{ datatype: string; label: string; description?: string }[]>(`
    prefix xsd: <http://www.w3.org/2001/XMLSchema#>
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>

    select ?datatype (sample(?label_t) as ?label) (sample(?description_t) as ?description) where {
      {
        values (?datatype ?label_t) {
                (xsd:string 'String')
                }
      } union {
        ?datatype a rdfs:Datatype.
        optional {
          ?datatype rdfs:label|skos:prefLabel ?l.
        }
        bind(coalesce(?l, str(?datatype)) as ?label_t)
        optional {
          ?datatype rdfs:comment ?description_t
        }
      }
    }
    group by ?datatype
    order by ?label
    `);

  const submit = async (values: DatatypePropertyData) => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setError("root.serverError", {
        type: "500",
      });
    }
  };

  if (!datatypes || !existingProperties) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }

  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
        <FormField label="Label" required>
          <Controller
            name={"label"}
            control={control}
            rules={{
              validate: (value) => {
                if (!value.trim()) return "A label is required.";
              },
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                required
                autoFocus
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Property IRI" required>
          <Controller
            name={"id"}
            control={control}
            rules={{
              validate: validateIri,
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              const currentProperty = existingProperties.find((p) => value === p.id);
              return (
                <Autocomplete
                  fullWidth
                  options={existingProperties}
                  {...rest}
                  onChange={(_e, value) => {
                    if (typeof value === "string") {
                      onChange(removePrefixes(value.trim()));
                    } else if (typeof value === "object" && value && "id" in value) {
                      onChange(value.id);
                    } else {
                      onChange(null);
                    }
                  }}
                  freeSolo
                  value={value || ""}
                  renderInput={(params) => (
                    <TextField
                      {...(params as any)}
                      fullWidth
                      required
                      error={!!error}
                      helperText={
                        error?.message ||
                        (currentProperty && (currentProperty.description || currentProperty.label)) ||
                        (value ? "New property" : "")
                      }
                      onBlur={(e) => {
                        const currentProperty = existingProperties.find(
                          (p) => removePrefixes(e.target.value.trim()) === p.id
                        );
                        if (currentProperty) {
                          onChange(currentProperty.id);
                        } else {
                          onChange(removePrefixes(e.target.value.trim()));
                        }
                      }}
                    />
                  )}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") return applyPrefixes(option);
                    return applyPrefixes(option.id);
                  }}
                  renderOption={(props, option) => {
                    if (typeof option === "string") return <li>{option}</li>;
                    return (
                      <li {...props}>
                        <div>
                          <span>{option.label}</span>
                          <div>
                            <Typography variant="caption">{applyPrefixes(option.id)}</Typography>
                          </div>
                          {/*<div>
                            <Typography variant="body2">{option.description}</Typography>
                          </div>*/}
                        </div>
                      </li>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (!option) return false;
                    if (typeof value === "string") {
                      return value === option.id;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                />
              );
            }}
          />
        </FormField>
        <FormField label="Description">
          <Controller
            name={"description"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                minRows={3}
                multiline
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>
        <FormField label="Datatype" required>
          <Controller
            name={`datatype`}
            control={control}
            rules={{
              validate: validateIri,
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              return (
                <Autocomplete
                  {...rest}
                  fullWidth
                  options={datatypes}
                  getOptionLabel={(option) => applyPrefixes(option.datatype)}
                  onChange={(_e, value) => {
                    onChange(value ? value.datatype : undefined);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <div>
                          <span>{option.label}</span>
                          <div>
                            <Typography variant="caption">{applyPrefixes(option.datatype)}</Typography>
                          </div>
                        </div>
                      </li>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option && option.datatype === value.datatype;
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...(params as any)}
                        required
                        error={!!error}
                        helperText={
                          error?.message ||
                          datatypes?.find((d) => d.datatype === removePrefixes(params.inputProps?.value as any))
                            ?.description
                        }
                        fullWidth
                      />
                    );
                  }}
                />
              );
            }}
          />
        </FormField>

        <FormField label="Min count">
          <Controller
            name={"minCount"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type="number"
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Max count">
          <Controller
            name={"maxCount"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type="number"
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <LoadingButton color="secondary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </LoadingButton>

        {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
      </form>
    </ThemeProvider>
  );
};

export default PropertyForm;
