import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Alert, Avatar, Button } from "#components/index.ts";
import { Account } from "#reducers/accountCollection.ts";
import styles from "./style.scss";

namespace OrgListItem {
  export interface Props {
    account: Account;
    className?: string;
    link?: string;
    leaveOrgHandler?: Function;
  }
  export interface State {
    error?: string;
  }
}

const OrgListItem: React.FC<OrgListItem.Props> = ({ link, leaveOrgHandler, account, className }) => {
  const [leaveError, setLeaveError] = React.useState<string>();
  const useLink = link || `/${account.accountName}`;
  return (
    <div className={className}>
      <div className={getClassName(styles.box)}>
        <div className={styles.colAvatar}>
          {account.accountName && (
            <Avatar
              linkTo={useLink}
              size="md"
              avatarName={account.accountName}
              avatarUrl={account.avatarUrl}
              className={styles.avatar}
              alt=""
            />
          )}
        </div>
        <div className={styles.colDescription}>
          <Link to={useLink}>
            <h3>{account.name || account.accountName}</h3>
          </Link>
          <p className={styles.description}>{account.email}</p>
        </div>
        <div className={styles.colIcons}>
          {leaveOrgHandler && (
            <Button
              disabled={!!leaveError}
              size="small"
              variant="outlined"
              onClick={() => {
                setLeaveError(undefined);
                leaveOrgHandler(account).catch((e: Error) => setLeaveError(e.message));
              }}
            >
              Leave
            </Button>
          )}
        </div>
      </div>
      {leaveError && (
        <div style={{ padding: "0px 15px 15px 15px" }}>
          <Alert transparent message={leaveError} size="sm" />
        </div>
      )}
    </div>
  );
};
export default OrgListItem;
