import * as React from "react";
import { CachePolicies } from "use-http";
import { Models } from "@triply/utils";
import { ErrorPage, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "../../helpers/hooks/useFetch.ts";
import OrganizationsTable from "./OrganizationsTable.tsx";

export interface Props extends IComponentProps {}

const AdminOrganizations: React.FC<Props> = ({ location }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const {
    data: orgs,
    error: fetchOrgsError,
    loading,
  } = useFetch<Array<Models.Org>>(
    constructUrlToApi({
      pathname: "/accounts",
      query: { type: "org", sortBy: "createdAt", sortDirection: "desc", limit: "-1" },
    }),
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    []
  );
  const acl = useAcl();

  if (fetchOrgsError) return <ErrorPage error={fetchOrgsError} />;

  if (!acl.check({ action: "manageUsers" }).granted) return <ErrorPage statusCode={401} />;

  return (
    <>
      <Meta currentPath={location.pathname} title="Organizations - Admin settings" />

      <OrganizationsTable orgs={orgs || []} error={fetchOrgsError} loading={loading} />
    </>
  );
};

export default AdminOrganizations;
