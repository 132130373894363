import getClassName from "classnames";
import * as React from "react";
import { Models } from "@triply/utils";
import { Markdown } from "#components/index.ts";
import LNCSMetadata from "./LNCSMetadata.tsx";
import useOpenLinkInCurrentWindow from "./useOpenLinkInCurrentWindow.ts";
import styles from "./Paragraph.scss";

export interface Props {
  storyElement: Models.StoryElement;
}

const Paragraph: React.FC<Props> = (props) => {
  const openLinkInCurrentWindow = useOpenLinkInCurrentWindow();

  const metadataMatch = new RegExp("(?<=<lncsmetadata>)(.|\n)*?(?=</lncsmetadata>)").exec(
    props.storyElement.paragraph?.trim() || ""
  );
  if (metadataMatch && metadataMatch[0]) {
    return <LNCSMetadata metadataString={metadataMatch[0]} />;
  }

  return (
    <div className={styles.paragraph}>
      <Markdown openLinkInCurrentWindow={openLinkInCurrentWindow} className={getClassName("pb-2", styles.markdown)}>
        {props.storyElement.paragraph || ""}
      </Markdown>
    </div>
  );
};

export default Paragraph;
