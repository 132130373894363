import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import * as Forms from "#components/Forms/index.ts";
import { Button, ErrorPage, FontAwesomeIcon, StoryListItem } from "#components/index.ts";
import { useCreateStory, useLoadNextPage } from "#helpers/hooks/stories.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";
import { useStories } from "#reducers/stories.ts";
import styles from "./style.scss";

const Stories: React.FC<{ mayCreateStory: boolean }> = ({ mayCreateStory }) => {
  const currentAccount = useCurrentAccount();
  const stories = useStories({ account: currentAccount?.accountName, searchTerm: false });
  const nextPage = useSelector((state: GlobalState) => state.stories.nextPage);
  const accountName = currentAccount?.accountName;
  const createStory = useCreateStory(accountName, false);
  const loadNextStoryPage = useLoadNextPage(currentAccount?.accountName);

  if (!currentAccount) {
    return <ErrorPage statusCode={404} />;
  }

  const hasStories = stories && stories.length > 0;
  const showForm = !hasStories && mayCreateStory;

  return (
    <div className="my-5">
      {!showForm && !hasStories && (
        <div className={getClassName(styles.center, styles.empty)}>
          <FontAwesomeIcon size="3x" icon={["fal", "empty-set"]} />
        </div>
      )}

      {showForm && (
        <div>
          <h3 className={getClassName("headerSpacing", styles.title)}>Create your first story</h3>
          <Forms.Story
            initialValues={{
              accessLevel: "private",
            }}
            onSubmit={createStory}
            currentAccount={currentAccount}
            className={styles.form}
          />
        </div>
      )}

      {!showForm && (
        <div>
          {stories && stories.map((s) => <StoryListItem story={s} key={s.id} className={styles.listItem} />)}

          {nextPage && (
            <div className={styles.showMore}>
              <Button elevation onClick={loadNextStoryPage} className="rounding mt-5">
                Show more...
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Stories;
