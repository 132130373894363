import { Pagination } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { FACETED_SEARCH_PAGE_SIZE } from "@triply/utils/Constants.js";
import { DatasetFacets, OrganizationFacets, QueryFacets, StoryFacets } from "@triply/utils/Models.js";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { Page } from "./index.tsx";
import ListItem from "./ListItem.tsx";
import styles from "./style.scss";

const Results: React.FC<{ data: DatasetFacets | StoryFacets | QueryFacets | OrganizationFacets; page: Page }> = ({
  data,
  page,
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = parseSearchString(location.search);

  return (
    <div className={styles.list}>
      {data.results.map((item) => (
        <ListItem type={page} item={item} key={item.id} />
      ))}
      {!!data.results.length && data.results.length < data.count && (
        <Pagination
          count={Math.ceil(data.count / FACETED_SEARCH_PAGE_SIZE)}
          page={data.page}
          onChange={(_event, p) => {
            history.push({ search: stringifyQuery({ ...query, page: p.toString() }) });
          }}
          className="mt-4"
        />
      )}
      {data.results.length === 0 && (
        <div className="p-4">
          {`No ${page} meet the filter criteria. `}
          <Link to={{ search: "" }}>Clear all filters</Link>
        </div>
      )}
    </div>
  );
};

export default Results;
