import { Autocomplete, AutocompleteProps, InputAdornment, TextField } from "@mui/material";
import match from "autosuggest-highlight/match/index.js";
import parse from "autosuggest-highlight/parse/index.js";
import getClassName from "classnames";
import * as React from "react";
import styles from "./style.scss";

interface Props
  extends Omit<
    AutocompleteProps<string, false, true, true>,
    "options" | "renderInput" | "onChange" | "freeSolo" | "value"
  > {
  options: string[];
  error?: string;
  onChange: (value: string) => void;
  value: string | undefined;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  disabledOptions?: string[];
}

const SimpleAutoSuggest = React.forwardRef<unknown, Props>(
  ({ error, onChange, value, startAdornment, endAdornment, options, disabledOptions, ...props }, ref) => {
    return (
      <Autocomplete<string, false, true, true>
        {...props}
        ref={ref}
        disableClearable
        value={value ?? ""}
        freeSolo={true}
        options={options}
        inputValue={value ?? ""}
        onChange={(_, value) => onChange(value || "")}
        renderInput={({ inputProps, InputProps, ...rest }) => (
          <TextField
            {...rest}
            fullWidth
            error={!!error}
            helperText={error}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value);
            }}
            inputProps={{
              ...inputProps,
              width: typeof inputProps.width === "number" ? `${inputProps.width}px` : inputProps.width,
              height: typeof inputProps.height === "number" ? `${inputProps.height}px` : inputProps.height,
            }}
            InputProps={{
              ...InputProps,
              onChange: (e) => onChange(e.target.value),
              endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null,
              startAdornment: startAdornment ? (
                <InputAdornment position="start">{startAdornment}</InputAdornment>
              ) : null,
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          // The render option is used to highlight text
          const matches = match(option, inputValue, { insideWords: true, findAllOccurrences: true });
          const parts = parse(option, matches);
          return (
            <li key={option} {...props} className={getClassName(styles.autocompleteItem, props.className)}>
              {parts.map((part, index) => (
                <span key={part.text + index} className={part.highlight ? styles.autocompleteHighlight : undefined}>
                  {part.text}
                </span>
              ))}
            </li>
          );
        }}
        getOptionDisabled={disabledOptions ? (option) => disabledOptions.includes(option) : undefined}
      />
    );
  }
);

export default SimpleAutoSuggest;
