// @ts-ignore
import autocast from "autocast";
import reduceDeep from "deepdash/reduceDeep";
import { camelCase, compact, flatten, set, size, snakeCase, startsWith } from "lodash-es";

export interface ProcessEnvOptions {
  envPrefix: string;
  env: NodeJS.ProcessEnv;
  preserveVariableNameCasing?: boolean;
}
export type EnvVars = { [varname: string]: string };
export function getObjectAsEnvVars({ obj, envPrefix }: { obj: {}; envPrefix: string }): EnvVars {
  return reduceDeep(
    obj,
    (accumulator: EnvVars, value, key, _parentValue, context) => {
      const path = [...compact(context.parents.map((p: any) => p.key)), key] as string[];
      // Need a regex here, to cater to the snakeCase function translating bla1 into BLA_1 (we dont want this extra underscore)
      const pathAsString = envPrefix + path.map((p) => snakeCase(p).toUpperCase().replace(/_(\d)/g, "$1")).join("__");
      accumulator[pathAsString] = "" + value;
      return accumulator;
    },
    {},
    { leavesOnly: true }
  );
}
export function getEnvironmentVarsAsObject({ envPrefix, env, preserveVariableNameCasing }: ProcessEnvOptions) {
  if (!env || size(env) === 0) {
    /* eslint-disable */
    console.trace(
      "WARNING: No environment variables set, running config generation on client? Config cannot be overwritten by env"
    );
    return {};
  }

  const values = {};
  for (let envKey in env) {
    //should we store this env var in the config:
    if (startsWith(envKey, envPrefix)) {
      let val: any = env[envKey];
      if (val === "") continue; // empty env variable
      //simply casting. So far, not using vals such as dates that might get misinterpreted
      val = autocast(val);
      envKey = envKey.substring(envPrefix.length);
      //read env variables, and store in config. E.g. TRIPLY__MAIL__REPLY_TO = 'bla'
      //i.e., splits by __, changes to camelcase when needed, and set in config
      const path = flatten(
        envKey.split("__").map((segment) => {
          if (preserveVariableNameCasing) return segment;
          return camelCase(segment);
        })
      );
      set(values, path, val);
    }
  }
  return values;
}
