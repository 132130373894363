import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as ReduxForm from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { Alert, Button, ErrorPage, FlexContainer, LinkButton, Meta, Svg } from "#components/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { resendVerificationEmail, useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import ConsentDialog from "./ConsentDialog.tsx";
import githubSvg from "./github.svg";
import googleSvg from "./google.svg";
import styles from "./style.scss";

function getReasonMessage(reason: string) {
  switch (reason) {
    case "disabled":
      return "Your account has been disabled.";
    case "deleted":
      return "Your account has been deleted.";
    case "password-changed":
      return "You have been logged out as your password has changed.";
    case "mfa-changed":
      return "You have been logged out as your two-factor authentication settings have changed.";
    case "expired":
      return "You have been logged out due to inactivity.";
    case "session-terminated":
      return "You have been logged out as your sessions have been terminated.";
    case "invalid":
    default:
      return "";
  }
}

const Login: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const authenticatedUser = useAuthenticatedUser();
  const history = useHistory();
  const location = useLocation();
  const query = parseSearchString(location.search);
  const enabledOauth = useSelector((state: GlobalState) => state.config.clientConfig?.enabledOauth);
  const samlIdps = useSelector((state: GlobalState) => state.config.clientConfig?.saml);
  const [verifyAccount, setVerifyAccount] = React.useState<{ email: string; password?: string }>();
  const constructUrlToApi = useConstructUrlToApi();

  React.useEffect(() => {
    //already logged in, redirect to account page
    if (authenticatedUser) {
      const destination = (query?.returnTo as string) ?? `/${authenticatedUser.accountName}`;
      history.push(destination);
    }
  }, [authenticatedUser, history, location.search, query]);

  const handleLogin = async (values: Forms.Login.FormData) => {
    try {
      const response = await fetch(constructUrlToApi({ pathname: "/web/auth/local/login" }), {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: values.email, password: values.password }),
      });
      const body = response.headers.get("content-type")?.includes("application/json")
        ? await response.json()
        : undefined;

      if (response.status === 200) {
        if (body?.mfaRequired) {
          history.replace(`/login/2fa${location.search}`);
        } else {
          window.location.replace((query?.returnTo as string) || `/${body?.accountName}`);
        }
      } else {
        throw body?.message ? body : new Error("Something went wrong.");
      }
    } catch (e: any) {
      // arbitrary code to signal console to show pretty 'please verify your account' component
      if (e.code === 99) {
        setVerifyAccount({ email: values.email, password: values.password });
      }
      throw new ReduxForm.SubmissionError({ _error: e.message });
    }
  };

  if (authenticatedUser) return null;

  if (query?.error) return <ErrorPage message={query.error as string} />;
  //should have an array at least
  if (!enabledOauth) return <ErrorPage />;

  const showSsoButtons = !!enabledOauth.length || !!samlIdps?.length;
  const containerStyles = {
    [styles.container]: !!styles.container,
    [styles.ssoDisabled]: !showSsoButtons,
  };

  if (verifyAccount) {
    return (
      <FlexContainer innerClassName={getClassName(containerStyles)}>
        <Meta currentPath={location.pathname} title={"Verify your account"} />
        <div>
          <div className={getClassName(styles.box, "whiteSink")}>
            <div className={styles.title}>
              <h3 className="headerSpacing">Verify your account</h3>
            </div>

            <div className={styles.content}>
              {
                <div>
                  <p>Please check your inbox and follow the link we've sent you to verify your account.</p>
                  <small>
                    If you did not receive a verification e-mail,{" "}
                    <LinkButton
                      onClickOrEnter={() => {
                        if (!verifyAccount.email || !verifyAccount.password)
                          throw new Error("Cannot send verification email. Missing info");
                        dispatch(resendVerificationEmail(verifyAccount.email, verifyAccount.password));
                      }}
                    >
                      click here
                    </LinkButton>{" "}
                    to resend.
                  </small>
                </div>
              }
            </div>
          </div>
        </div>
      </FlexContainer>
    );
  }

  return (
    <FlexContainer innerClassName={getClassName(containerStyles)}>
      <Meta currentPath={location.pathname} title="Login" />
      <div>
        <div className={getClassName(styles.box, "whiteSink")}>
          <div className={styles.title}>
            <h3 className="headerSpacing">Login</h3>
          </div>
          {query?.reason && <Alert info message={getReasonMessage(query.reason.toString())} />}
          <div className={styles.content}>
            {showSsoButtons && (
              <div className={getClassName(styles.openIdProviders, "px-5")} style={{ flexBasis: "240px" }}>
                <ul>
                  {enabledOauth.indexOf("google") >= 0 && (
                    <li>
                      <Button
                        variant="outlined"
                        href="/web/auth/oauth/google?from=login"
                        startIcon={<Svg src={googleSvg} alt="" />}
                      >
                        Login using Google
                      </Button>
                    </li>
                  )}

                  {enabledOauth.indexOf("github") >= 0 && (
                    <li>
                      <Button
                        variant="outlined"
                        href="/web/auth/oauth/github?from=login"
                        startIcon={<Svg src={githubSvg} alt="" />}
                      >
                        Login using GitHub
                      </Button>
                    </li>
                  )}
                  {!!samlIdps?.length &&
                    samlIdps.map((saml) => {
                      return (
                        <li key={saml.idp}>
                          <Button variant="outlined" href={"/web/auth/saml?" + stringifyQuery({ idp: saml.idp })}>
                            {saml.label}
                          </Button>
                        </li>
                      );
                    })}
                </ul>
                <ConsentDialog />
              </div>
            )}

            {showSsoButtons && (
              <div className={getClassName(styles.separator)}>
                <div className={styles.line} />
                <div className={styles.wordwrapper}>or</div>
              </div>
            )}

            <div
              className={getClassName(styles.authForm, "px-5")}
              style={{ flexBasis: showSsoButtons ? "50%" : "100%" }}
            >
              <Forms.Login onSubmit={handleLogin} />
            </div>
          </div>
        </div>
      </div>
    </FlexContainer>
  );
};

export default Login;
