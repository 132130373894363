import * as React from "react";
import { useLocation } from "react-router";
import { ErrorPage, FlexContainer, Meta } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import ContactEmail from "./ContactEmail.tsx";
import Debug from "./Debug.tsx";
import EnforceTfa from "./EnforceTfa.tsx";
import FeatureToggles from "./FeatureToggles.tsx";
import MainPageItems from "./MainPageItems.tsx";
import Prefixes from "./Prefixes.tsx";
import SignUp from "./SignUp.tsx";
import SiteLogos from "./SiteLogos.tsx";
import SiteMetadata from "./SiteMetadata.tsx";
import StarterDataset from "./StarterDataset.tsx";
import Topics from "./Topics.tsx";

const AdminSettings: React.FC<{}> = ({}) => {
  const acl = useAcl();

  if (!acl.check({ action: "editInstanceMetadata" }).granted) return <ErrorPage statusCode={401} />;
  return (
    <FlexContainer>
      <SettingsMeta />
      <SiteLogos />
      <SiteMetadata />
      <ContactEmail />
      <MainPageItems />
      <StarterDataset />
      <Debug />
      {acl.check({ action: "toggleInstanceFeatures" }).granted && <FeatureToggles />}
      <div className="whiteSink">
        <h3>Authentication</h3>
        <SignUp />
        <EnforceTfa />
      </div>
      <Prefixes />

      {acl.check({ action: "manageTopics" }).granted && <Topics />}
    </FlexContainer>
  );
};

export default AdminSettings;

const SettingsMeta: React.FC = () => {
  const location = useLocation(); // Prevent re-rendering of the component when the location changes
  return <Meta currentPath={location.pathname} title="Admin settings" />;
};
