import { useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { useCurrentAccount } from "../../reducers/app";
import { useCurrentDataset } from "../../reducers/datasetManagement";
import useAcl from "./useAcl";
import useEditModeStatementsLimit from "./useEditModeStatementsLimit";

export default function useIsEditorModeEnabled() {
  const editorModeEnabled = useSelector((state: GlobalState) => state.config.staticConfig?.editMode) || false;
  const editModeStatementsLimit = useEditModeStatementsLimit();
  const acl = useAcl();
  const dataset = useCurrentDataset();
  const account = useCurrentAccount();
  const userAllowedToEditData = acl.check({
    action: "importDataToDataset",
    context: { roleInOwnerAccount: acl.getRoleInAccount(account) },
  }).granted;
  if (!userAllowedToEditData || !editorModeEnabled || !dataset) return false;
  return dataset.statements <= editModeStatementsLimit;
}
