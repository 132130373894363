import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import * as Forms from "#components/Forms/index.ts";
import { Dialog } from "#components/index.ts";
import { createQuery, formDataToUpdateObj as queryFormDataToUpdateObj } from "#reducers/queries.ts";
import { storyElementToUpdateObj, updateStory } from "#reducers/stories.ts";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "../../helpers/hooks/useDispatch.ts";
import { useCurrentAccount } from "../../reducers/app.ts";
import { GlobalState } from "../../reducers/index.ts";
import { LocationState } from "./index.tsx";

const StoryElementDialog: React.FC<{ story: Models.Story }> = ({ story }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const dispatch = useDispatch();
  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();
  const currentAccount = useCurrentAccount();
  const position = location.state?.position;

  const submitCreateQuery = React.useCallback(
    (values: Forms.QueryMeta.FormData) => {
      if (!currentAccount?.accountName) return Promise.reject("No account name given");
      return dispatch<typeof createQuery>(createQuery(currentAccount.accountName, queryFormDataToUpdateObj(values)));
    },
    [dispatch, currentAccount]
  );

  const submitAddStoryElement = React.useCallback(
    (values: Forms.StoryElement.FormData) => {
      if (story) {
        return dispatch<typeof updateStory>(
          updateStory(story, {
            content: [
              ...story.content.slice(0, position),
              values,
              ...story.content.slice(position === undefined ? story.content.length : position),
            ].map(storyElementToUpdateObj),
          })
        )
          .catch((e: Error) => {
            throw new ReduxForm.SubmissionError({ _error: e.message });
          })
          .then(() => history.goBack());
      }
    },
    [dispatch, story, position, history]
  );

  const submitEditStoryElement = React.useCallback(
    (values: Forms.StoryElement.FormData) => {
      if (story) {
        return dispatch<typeof updateStory>(
          updateStory(story, {
            content: story.content.map((e) => (e.id === values.id ? values : e)).map(storyElementToUpdateObj),
          })
        )
          .catch((e: Error) => {
            throw new ReduxForm.SubmissionError({ _error: e.message });
          })
          .then(() => history.goBack());
      }
    },
    [dispatch, story, history]
  );

  const edit = !!location.state?.storyElementEditModalShown;

  if (!currentAccount) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="lg"
      fullWidth
      open={!!location.state?.storyElementEditModalShown || !!location.state?.storyElementAddModalShown}
      onClose={() => history.goBack()}
      title={edit ? "Edit story element" : "Add new story element"}
    >
      <Forms.StoryElement
        initialValues={
          edit
            ? story.content.find((e) => e.id === location.state?.storyElementEditModalShown)
            : {
                type: "paragraph",
              }
        }
        updating={edit}
        onSubmit={edit ? submitEditStoryElement : submitAddStoryElement}
        cancelFunction={() => history.goBack()}
        querySearchUrl={constructUrlToApi({ pathname: "/queries" })}
        datasetSearchUrl={constructUrlToApi({ pathname: "/datasets" })}
        createQuery={submitCreateQuery}
        currentAccount={currentAccount}
        storyAccessLevel={story.accessLevel}
      />
    </Dialog>
  );
};

export default StoryElementDialog;
