import { Alert, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import useAcl from "#helpers/hooks/useAcl.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import RemoveClass from "./RemoveClass";
import useApplyPrefixes from "./useApplyPrefixes";
import useCurrentClass from "./useCurrentClass";
import useSparql from "./useSparql";

const Label: React.FC<{}> = ({}) => {
  const currentClass = useCurrentClass();

  const { data, error, loading } = useSparql<[{ label: string }?]>(
    currentClass &&
      `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix foaf: <http://xmlns.com/foaf/0.1/>
    prefix schema: <https://schema.org/>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>
    prefix dc: <http://purl.org/dc/elements/1.1/>
    prefix dct: <http://purl.org/dc/terms/>
    prefix sh: <http://www.w3.org/ns/shacl#>

    select ?label where {
      bind(<${currentClass}> as ?currentClass)
      ?currentClass rdfs:label|foaf:name|schema:name|skos:prefLabel|dct:title|dc:title|sh:name ?label
    } limit 1
  `
  );

  const applyPrefixes = useApplyPrefixes();

  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const mayManageOntology = acl.check({
    action: "manageOntology",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  if (!currentClass) return null;

  if (error) return <Alert severity="warning">Label could not be loaded.</Alert>;

  if (loading) return <Skeleton />;

  return (
    <div className="flex center g-5">
      <Typography className="grow" variant="h5" component="h1">
        {data?.[0]?.label || applyPrefixes(currentClass)}
      </Typography>
      {mayManageOntology && <RemoveClass />}
    </div>
  );
};

export default Label;
