import { Chip } from "@mui/material";
import getClassName from "classnames";
import { capitalize, fromPairs, isEmpty, pick } from "lodash-es";
import numeral from "numeral";
import * as React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { FACETED_SEARCH_NULL_ALIAS } from "@triply/utils/Constants.js";
import { DatasetFacets, OrganizationFacets, QueryFacets, StoryFacets } from "@triply/utils/Models.js";
import { stringifyQuery } from "#helpers/utils.ts";
import { Page } from "./index.tsx";
import styles from "./style.scss";

interface Props {
  query: any;
  page: Page;
  data: DatasetFacets | StoryFacets | QueryFacets | OrganizationFacets | undefined;
}

export const filters = {
  datasets: [
    "q",
    "created",
    "owner",
    "access",
    "sparql",
    "search",
    "statementsMin",
    "statementsMax",
    "topic",
    "license",
  ],
  queries: ["q", "created", "owner", "access", "dataset", "visualization"],
  stories: ["q", "created", "owner", "access", "dataset", "query"],
  organizations: [
    "q",
    "created",
    "membersMin",
    "membersMax",
    "datasetsMin",
    "datasetsMax",
    "storiesMin",
    "storiesMax",
    "queriesMin",
    "queriesMax",
  ],
};

const CurrentFilters: React.FC<Props> = ({ query, page, data }) => {
  const history = useHistory();
  if (!data) return null;

  return (
    <>
      {!isEmpty(pick(query, filters[page])) && (
        <div>
          <div className={getClassName("mb-2 flex center", styles.title)}>
            <div className="grow">Current filters</div>
            <div className={getClassName(styles.smaller, styles.clearAll)}>
              <Link
                to={{ search: stringifyQuery({ ...query, ...fromPairs(filters[page].map((f) => [f, undefined])) }) }}
              >
                Clear all
              </Link>
            </div>
          </div>
          <div className={styles.currentFilters}>
            {filters[page].includes("q") && "q" in query && (
              <Chip
                color="primary"
                label={`Keywords: ${query.q}`}
                title={query.q}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, q: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("created") && "created" in query && (
              <Chip
                color="primary"
                label={`Created: Past ${query.created}`}
                title={`Past ${query.created}`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, created: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("owner") && "owner" in query && "owner" in data.facets && (
              <Chip
                color="primary"
                label={`Owner: ${data.facets.owner.find((o) => o.id === query.owner)?.label || query.owner}`}
                title={`${data.facets.owner.find((o) => o.id === query.owner)?.label || query.owner}`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, owner: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("access") && "access" in query && (
              <Chip
                color="primary"
                label={capitalize(query.access)}
                title={capitalize(query.access)}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, access: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("sparql") && "sparql" in query && (
              <Chip
                color="primary"
                label="SPARQL"
                title="SPARQL"
                onDelete={() => history.push({ search: stringifyQuery({ ...query, sparql: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("search") && "search" in query && (
              <Chip
                color="primary"
                label="Elasticsearch"
                title="Elasticsearch"
                onDelete={() => history.push({ search: stringifyQuery({ ...query, search: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("statementsMin") && "statementsMin" in query && (
              <Chip
                color="primary"
                title={`${numeral(query.statementsMin).format("0,0")}`}
                label={`Min: ${numeral(query.statementsMin).format("0,0")} statements`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, statementsMin: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("statementsMax") && "statementsMax" in query && (
              <Chip
                color="primary"
                title={`${numeral(query.statementsMax).format("0,0")}`}
                label={`Max: ${numeral(query.statementsMax).format("0,0")} statements`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, statementsMax: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("membersMin") && "membersMin" in query && (
              <Chip
                color="primary"
                title={query.membersMin}
                label={`Min: ${query.membersMin} members`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, membersMin: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("membersMax") && "membersMax" in query && (
              <Chip
                color="primary"
                title={query.membersMax}
                label={`Max: ${query.membersMax} members`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, membersMax: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("datasetsMin") && "datasetsMin" in query && (
              <Chip
                color="primary"
                title={query.datasetsMin}
                label={`Min: ${query.datasetsMin} datasets`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, datasetsMin: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("datasetsMax") && "datasetsMax" in query && (
              <Chip
                color="primary"
                title={query.datasetsMax}
                label={`Max: ${query.datasetsMax} datasets`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, datasetsMax: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("storiesMin") && "storiesMin" in query && (
              <Chip
                color="primary"
                title={query.storiesMin}
                label={`Min: ${query.storiesMin} stories`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, storiesMin: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("storiesMax") && "storiesMax" in query && (
              <Chip
                color="primary"
                title={query.storiesMax}
                label={`Max: ${query.storiesMax} stories`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, storiesMax: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("queriesMin") && "queriesMin" in query && (
              <Chip
                color="primary"
                title={query.queriesMin}
                label={`Min: ${query.queriesMin} queries`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, queriesMin: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("queriesMax") && "queriesMax" in query && (
              <Chip
                color="primary"
                title={query.queriesMax}
                label={`Max: ${query.queriesMax} queries`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, queriesMax: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("topic") && "topic" in query && (
              <Chip
                color="primary"
                label={`Topic: ${
                  ("topic" in data.facets && data.facets.topic?.find((t) => t.id === query.topic)?.label) || query.topic
                }`}
                title={
                  ("topic" in data.facets && data.facets.topic?.find((t) => t.id === query.topic)?.label) || query.topic
                }
                onDelete={() => history.push({ search: stringifyQuery({ ...query, topic: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("dataset") && "dataset" in query && (
              <Chip
                color="primary"
                label={
                  query.dataset === "none"
                    ? `No dataset`
                    : `Dataset: ${
                        ("dataset" in data.facets && data.facets.dataset?.find((d) => d.id === query.dataset)?.label) ||
                        query.dataset
                      }`
                }
                title={
                  query.dataset === "none"
                    ? `No dataset`
                    : `${
                        ("dataset" in data.facets && data.facets.dataset?.find((d) => d.id === query.dataset)?.label) ||
                        query.dataset
                      } by ${
                        "dataset" in data.facets && data.facets.dataset?.find((o) => o.id === query.dataset)?.owner
                      }`
                }
                onDelete={() => history.push({ search: stringifyQuery({ ...query, dataset: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("query") && "query" in query && (
              <Chip
                color="primary"
                label={`Query: ${
                  ("query" in data.facets && data.facets.query?.find((q) => q.id === query.query)?.label) || query.query
                }`}
                title={`${
                  ("query" in data.facets && data.facets.query?.find((q) => q.id === query.query)?.label) || query.query
                } by ${"dataset" in data.facets && data.facets.dataset?.find((o) => o.id === query.dataset)?.owner}`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, query: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("visualization") && "visualization" in query && (
              <Chip
                color="primary"
                label={`Visualization: ${capitalize(query.visualization)}`}
                title={`${capitalize(query.visualization)}`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, visualization: undefined }) })}
                className={styles.chip}
              />
            )}
            {filters[page].includes("license") && "license" in query && (
              <Chip
                color="primary"
                label={query.license === FACETED_SEARCH_NULL_ALIAS ? "License: Not set" : `License: ${query.license}`}
                title={query.license === FACETED_SEARCH_NULL_ALIAS ? "License not set" : `${query.license}`}
                onDelete={() => history.push({ search: stringifyQuery({ ...query, license: undefined }) })}
                className={styles.chip}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CurrentFilters;
