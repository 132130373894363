import getClassName from "classnames";
import * as React from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import AddDownloadUrlForm from "#components/Forms/AddDownloadUrl/index.tsx";
import PreImportLocalGraphImport from "#components/Forms/PreLocalGraphImport/index.tsx";
import { FontAwesomeIcon } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { getSupportedExtensions } from "#reducers/uploading.ts";
import styles from "./styles/addData.scss";

export namespace AddData {
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    onFilesSelect: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
    onUrlSelect: (result: AddDownloadUrlForm.FormData) => void;
    onImportSelect: (valueJson: Models.Dataset) => void;
    datasetsUrl: string;
  }
}
const AddDataForm: React.FC<AddData.Props> = ({ onFilesSelect, onUrlSelect, onImportSelect, datasetsUrl }) => {
  const acl = useAcl();
  return (
    <div className="whiteSink">
      <h2>Add data</h2>
      <PreImportLocalGraphImport datasetsUrl={datasetsUrl} onImportSelect={onImportSelect} />
      <div className={styles.wordWrapper}>or</div>
      <AddDownloadUrlForm onSubmit={onUrlSelect} />
      <div className={getClassName(styles.wordWrapper)}>or</div>
      <div>
        <Dropzone
          // See https://issues.triply.cc/issues/7219
          useFsAccessApi={false}
          onDrop={onFilesSelect}
          accept={{
            // Dropzone requires a mapping between mime-type and extension.
            // We use a fake mimetype: For our usecase, the extension seems to suffice: we can only select files with these extensions
            "application/x-triply": getSupportedExtensions(acl.check({ action: "uploadHdt" }).granted).filter(
              (e) => !e.includes("-")
            ),
          }}
          multiple
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps({
                className: getClassName(styles.fileDropZone, "p-3", "mb-3", {
                  [styles.dragActive]: isDragActive,
                }),
              })}
            >
              <input {...getInputProps()} />
              <div>
                <FontAwesomeIcon icon={["fas", "cloud-upload"]} className={styles.icon} />
              </div>
              <span className="mt-3">Add data from files (Turtle, TriG, N-Triples, N-Quads, JSON-LD, CSV or TSV)</span>
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
};
export default AddDataForm;
