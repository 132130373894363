import { MenuItem, Select } from "@mui/material";
import { isArray } from "lodash-es";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import { DatasetLicenseId, LICENSES } from "@triply/utils/Constants.js";
import { AccessLevel } from "@triply/utils/Models.js";
import {
  applyRuleConfig,
  datasetDescriptionValidations,
  getDatasetNameValidations,
  getDisplayNameValidations,
  iriValidations,
  required,
  toStringValidator,
} from "@triply/utils-private/validation.js";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import {
  AccessLevels,
  Alert,
  FontAwesomeIcon,
  FormField,
  Highlight,
  Label,
  MarkdownEditField,
  MarkdownEditFieldRedux,
  MuiAutoSuggestMulti,
  MuiAutoSuggestMultiSortable,
  NameAndUrl,
} from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import { memoize } from "#helpers/utils.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import styles from "./style.scss";

const descriptionValidator = toStringValidator(datasetDescriptionValidations);
const datasetNameValidator = toStringValidator(getDatasetNameValidations());
const iriValidator = toStringValidator(iriValidations);
const datasetDisplayNameValidator = toStringValidator([
  ...getDisplayNameValidations({ messageSubject: "A dataset name" }),
  applyRuleConfig(required, {
    formatMessage: () => `A dataset name is required.`,
  }),
]);

namespace DatasetProfile {
  export interface FormData {
    name: string;
    displayName: string;
    description: string;
    accessLevel: AccessLevel;
    license: DatasetLicenseId;
    topics: Models.Topic[];
    exampleResources: string[];
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    // onSubmit:Function,//passed from parent property. Use the `handleSubmit` as actual submit handler for the form
    currentDs: Dataset;
    termPath: string;
    topicPath: string;
  }

  export interface LicenseFieldProps {
    label: string;
    children: any[];
  }
  export interface State {}
}

class _DatasetProfile extends React.PureComponent<
  DatasetProfile.Props & ReduxForm.InjectedFormProps<DatasetProfile.FormData>,
  DatasetProfile.State
> {
  constructor(props: DatasetProfile.Props & ReduxForm.InjectedFormProps<DatasetProfile.FormData>) {
    super(props);
  }
  f = <T1 extends {}>(arg1: T1) => {
    return { arg1 };
  };
  search = <I extends {}>(url: string) => {
    return this.fetch<I>(url).then((results) => {
      if (results && isArray(results)) {
        return results;
      }
      return [];
    });
  };

  @memoize({ primitive: true, promise: true })
  fetch<I>(url: string): Promise<I[]> {
    return fetch(url, { credentials: "same-origin" })
      .then((response) => {
        if (response.status === 200) return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  licenseChange = (value: DatasetLicenseId) => {
    this.setState({
      licenseLink: LICENSES[value]?.url,
    });
  };

  searchTopic = (q: string) => this.search<Models.Topic>(`${this.props.topicPath}?q=${encodeURIComponent(q)}`);

  searchTerm = (q: string) => this.search<string>(`${this.props.termPath}?pos=subject&q=${encodeURIComponent(q)}`);

  renderLicenseField = ({ input, children }: ReduxForm.WrappedFieldProps & DatasetProfile.LicenseFieldProps) => {
    const licenseLink = LICENSES[input.value as DatasetLicenseId]?.url;
    return (
      <div className="flex center">
        <div className={styles.licenseFormEl}>
          <Select
            {...input}
            displayEmpty
            inputProps={{
              id: "license",
            }}
          >
            {children}
          </Select>
          {licenseLink && (
            <div className={styles.licenseLink}>
              <a href={licenseLink} rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon="link" />
              </a>
            </div>
          )}
        </div>
      </div>
    );
  };
  renderTopicField = ({ input }: ReduxForm.WrappedFieldProps) => {
    return (
      <MuiAutoSuggestMulti<Models.Topic>
        className="pb-3"
        placeholder="Type to search"
        label="Add a topic"
        {...input}
        getDisplayValueFromSuggestion={(suggestion) => suggestion.label}
        getKeyFromFromSuggestion={(suggestion) => suggestion.id}
        loadSuggestions={this.searchTopic}
        TextFieldProps={{ fullWidth: true }}
        renderSuggestion={(topic, { query, isHighlighted }) => {
          return (
            <MenuItem selected={isHighlighted} component="div">
              <Highlight fullText={topic.label} highlightedText={query} />
            </MenuItem>
          );
        }}
      />
    );
  };
  renderExampleResourceField = ({ input, meta }: ReduxForm.WrappedFieldProps) => {
    return (
      <MuiAutoSuggestMultiSortable
        className="pb-3"
        placeholder="Type to search"
        label="Add an example resource"
        getDisplayValueFromSuggestion={(newValue) => newValue}
        getValueFromString={(newValue) => newValue}
        getStringFromValue={(newValue) => newValue}
        {...input}
        loadSuggestions={this.searchTerm}
        TextFieldProps={{ fullWidth: true, error: !!meta.error, helperText: meta.error }}
        renderValue={(suggestion) => suggestion}
        allowTypedValues={true}
        renderSuggestion={(suggestion, { query, isHighlighted }) => {
          return (
            <MenuItem selected={isHighlighted} component="div">
              <Highlight fullText={suggestion} highlightedText={query} />
            </MenuItem>
          );
        }}
      />
    );
  };
  render() {
    const { handleSubmit, submitting, submitSucceeded, error, pristine, valid, change, initialValues, currentDs } =
      this.props;
    return (
      <div>
        <form method="POST" onSubmit={handleSubmit}>
          <NameAndUrl
            changeFormValues={change}
            initialSlug={initialValues.name}
            formIsPristine={pristine}
            autoFocus={false}
            nameFieldName="displayName"
            nameFieldLabel="Dataset name"
            slugFieldName="name"
            slugFieldLabel="URL"
            className="mt-5 mb-7"
            urlPrefixPath={`/${currentDs.owner.accountName}/`}
          />

          <FormField label="Dataset description" className="mb-7">
            <ReduxForm.Field<ReduxForm.BaseFieldProps<MarkdownEditField.Props>>
              name="description"
              props={{
                type: "text",
                fullWidth: true,
                multiline: true,
                rows: 5,
              }}
              component={MarkdownEditFieldRedux}
            />
          </FormField>

          <FormField label="Access level" className="mb-7">
            <AccessLevels
              name="accessLevel"
              type="dataset"
              accountType={this.props.currentDs.owner.type}
              changeAccessLevel={(value) => this.props.change?.("accessLevel", value)}
            />
          </FormField>

          <FormField label="Topics" className="mb-7">
            <ReduxForm.Field name="topics" component={this.renderTopicField} />
          </FormField>

          <FormField label="Example resources" className="mb-7">
            <ReduxForm.Field name="exampleResources" component={this.renderExampleResourceField} />
          </FormField>

          <FormField label="License" className="mb-7">
            <ReduxForm.Field name="license" component={this.renderLicenseField as any}>
              <MenuItem value="">None</MenuItem>

              {Object.entries(LICENSES).map(([licenseId, license]) => {
                return (
                  <MenuItem key={licenseId} value={licenseId} disabled={license.deprecated}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {license.gui_name}
                      {!!LICENSES[licenseId as DatasetLicenseId]?.deprecated && (
                        <div title="This license is deprecated or no longer applicable." className={styles.licenseLink}>
                          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} fixedWidth className="ml-1" />
                        </div>
                      )}
                    </span>
                  </MenuItem>
                );
              })}
            </ReduxForm.Field>
          </FormField>

          <Alert className="mt-5" transparent message={error} />

          <div className="form-group mt-3">
            <LoadingButton
              type="submit"
              color="secondary"
              disabled={pristine || !valid}
              onClick={handleSubmit}
              loading={submitting}
            >
              Save
            </LoadingButton>
          </div>
        </form>
        {submitSucceeded && pristine && (
          <div className="mt-3">
            <Label success message="You have successfully updated your dataset." />
          </div>
        )}
      </div>
    );
  }
}

const DatasetProfile = ReduxForm.reduxForm<DatasetProfile.FormData, DatasetProfile.Props>({
  form: "dsProfileForm",
  validate: memoizee(
    (formData: DatasetProfile.FormData) => {
      let exampleResourceCheck = undefined;
      for (const resource of formData.exampleResources) {
        const check = iriValidator(resource);
        if (check) {
          exampleResourceCheck = check;
          break;
        }
      }
      return {
        name: datasetNameValidator(formData.name),
        displayName: datasetDisplayNameValidator(formData.displayName),
        description: descriptionValidator(formData.description),
        exampleResources: exampleResourceCheck,
      };
    },
    { max: 10 }
  ),
  warn: memoizee(
    (formData: DatasetProfile.FormData, props: DatasetProfile.Props) => {
      return {
        name:
          (formData.name &&
            props.initialValues?.name &&
            formData.name.toLowerCase() !== props.initialValues.name.toLowerCase() &&
            "Changing the dataset URL will break existing links to the web pages and API addresses of the dataset.") ||
          undefined,
      };
    },
    { max: 10 }
  ),
})(_DatasetProfile as any);

export default DatasetProfile;
