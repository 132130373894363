import { produce } from "immer";
import { isEmpty, isEqual } from "lodash-es";
import { useSelector } from "react-redux";
import { Models, Routes } from "@triply/utils";
import { parseSearchString } from "#helpers/utils.ts";
import { Account, User } from "#reducers/accountCollection.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { getDataset, getDatasetFromCollections } from "./datasetCollection.ts";

export interface ListFor {
  account: string | false;
  searchTerm: string | false;
  admin: "fullList" | "jobs" | false;
}

export type PrefixUpdate = Models.PrefixUpdate;
export type Prefixes = Models.Prefixes;

export const LocalActions = {
  ADD_DATASET: "triply/dataset/ADD_DATASET",
  ADD_DATASET_SUCCESS: "triply/dataset/ADD_DATASET_SUCCESS",
  ADD_DATASET_FAIL: "triply/dataset/ADD_DATASET_FAIL",
  COPY_DATASET: "triply/dataset/COPY_DATASET",
  COPY_DATASET_SUCCESS: "triply/dataset/COPY_DATASET_SUCCESS",
  COPY_DATASET_FAIL: "triply/dataset/COPY_DATASET_FAIL",
  CHOWN_DATASET: "triply/dataset/CHOWN_DATASET",
  CHOWN_DATASET_SUCCESS: "triply/dataset/CHOWN_DATASET_SUCCESS",
  CHOWN_DATASET_FAIL: "triply/dataset/CHOWN_DATASET_FAIL",
  GET_DATASETS: "triply/dataset/GET_DATASETS",
  GET_DATASETS_SUCCESS: "triply/dataset/GET_DATASETS_SUCCESS",
  GET_DATASETS_FAIL: "triply/dataset/GET_DATASETS_FAIL",
  GET_CURRENT_DATASET: "triply/dataset/GET_CURRENT_DATASET",
  GET_CURRENT_DATASET_SUCCESS: "triply/dataset/GET_CURRENT_DATASET_SUCCESS",
  GET_CURRENT_DATASET_FAIL: "triply/dataset/GET_CURRENT_DATASET_FAIL",
  REFRESH_CURRENT_DATASET: "triply/dataset/REFRESH_CURRENT_DATASET",
  REFRESH_CURRENT_DATASET_SUCCESS: "triply/dataset/REFRESH_CURRENT_DATASET_SUCCESS",
  REFRESH_CURRENT_DATASET_FAIL: "triply/dataset/REFRESH_CURRENT_DATASET_FAIL",
  UPDATE_DATASET: "triply/dataset/UPDATE_DATASET",
  UPDATE_DATASET_SUCCESS: "triply/dataset/UPDATE_DATASET_SUCCESS",
  UPDATE_DATASET_FAIL: "triply/dataset/UPDATE_DATASET_FAIL",
  DELETE_DATASET: "triply/dataset/DELETE_DATASET",
  DELETE_DATASET_SUCCESS: "triply/dataset/DELETE_DATASET_SUCCESS",
  DELETE_DATASET_FAIL: "triply/dataset/DELETE_DATASET_FAIL",
  UPLOAD_DATASET_AVATAR: "triply/dataset/UPLOAD_DATASET_AVATAR",
  UPLOAD_DATASET_AVATAR_SUCCESS: "triply/dataset/UPLOAD_DATASET_AVATAR_SUCCESS",
  UPLOAD_DATASET_AVATAR_FAIL: "triply/dataset/UPLOAD_DATASET_AVATAR_FAIL",
  UPDATE_DATASET_PREFIXES: "triply/dataset/prefixes/UPDATE_DATASET_PREFIXES",
  UPDATE_DATASET_PREFIXES_SUCCESS: "triply/dataset/prefixes/UPDATE_DATASET_PREFIXES_SUCCESS",
  UPDATE_DATASET_PREFIXES_FAIL: "triply/dataset/prefixes/UPDATE_DATASET_PREFIXES_FAIL",
  ADD_DATASET_PREFIX: "triply/dataset/prefixes/ADD_DATASET_PREFIX",
  ADD_DATASET_PREFIX_SUCCESS: "triply/dataset/prefixes/ADD_DATASET_PREFIX_SUCCESS",
  ADD_DATASET_PREFIX_FAIL: "triply/dataset/prefixes/ADD_DATASET_PREFIX_FAIL",
} as const;

type ADD_DATASET = GlobalAction<
  {
    types: [
      typeof LocalActions.ADD_DATASET,
      typeof LocalActions.ADD_DATASET_SUCCESS,
      typeof LocalActions.ADD_DATASET_FAIL
    ];
    verbose: false;
  },
  Routes.datasets._account._dataset.Post
>;

type GET_DATASETS = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_DATASETS,
      typeof LocalActions.GET_DATASETS_SUCCESS,
      typeof LocalActions.GET_DATASETS_FAIL
    ];
    listFor?: State["listFor"];
    append: boolean;
  },
  Routes.datasets._account.Get
>;

type GET_CURRENT_DATASET = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_CURRENT_DATASET,
      typeof LocalActions.GET_CURRENT_DATASET_SUCCESS,
      typeof LocalActions.GET_CURRENT_DATASET_FAIL
    ];
    verbose: true;
  },
  Routes.datasets._account._dataset.Get<true>
>;

type REFRESH_CURRENT_DATASET = GlobalAction<
  {
    types: [
      typeof LocalActions.REFRESH_CURRENT_DATASET,
      typeof LocalActions.REFRESH_CURRENT_DATASET_SUCCESS,
      typeof LocalActions.REFRESH_CURRENT_DATASET_FAIL
    ];
    verbose: true;
  },
  Routes.datasets._account._dataset.Get<true>
>;

type UPDATE_DATASET = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_DATASET,
      typeof LocalActions.UPDATE_DATASET_SUCCESS,
      typeof LocalActions.UPDATE_DATASET_FAIL
    ];
    renaming: boolean;
    verbose: false;
  },
  Routes.datasets._account._dataset.Patch
>;

type DELETE_DATASET = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_DATASET,
      typeof LocalActions.DELETE_DATASET_SUCCESS,
      typeof LocalActions.DELETE_DATASET_FAIL
    ];
    dataset: Dataset;
  },
  Routes.datasets._account._dataset.Delete
>;

type UPLOAD_DATASET_AVATAR = GlobalAction<
  {
    types: [
      typeof LocalActions.UPLOAD_DATASET_AVATAR,
      typeof LocalActions.UPLOAD_DATASET_AVATAR_SUCCESS,
      typeof LocalActions.UPLOAD_DATASET_AVATAR_FAIL
    ];
    verbose: false;
  },
  Routes.imgs.avatars.d._datasetId.Post
>;

type COPY_DATASET = GlobalAction<
  {
    types: [
      typeof LocalActions.COPY_DATASET,
      typeof LocalActions.COPY_DATASET_SUCCESS,
      typeof LocalActions.COPY_DATASET_FAIL
    ];
  },
  Routes.datasets._account._dataset.copy.Post
>;

type CHOWN_DATASET = GlobalAction<
  {
    types: [
      typeof LocalActions.CHOWN_DATASET,
      typeof LocalActions.CHOWN_DATASET_SUCCESS,
      typeof LocalActions.CHOWN_DATASET_FAIL
    ];
    fromUid: string;
    datasetName: string;
  },
  Routes.datasets._account._dataset.chown.Post
>;

type UPDATE_PREFIXES = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_DATASET_PREFIXES,
      typeof LocalActions.UPDATE_DATASET_PREFIXES_SUCCESS,
      typeof LocalActions.UPDATE_DATASET_PREFIXES_FAIL
    ];
    datasetId: string;
  },
  Routes.datasets._account._dataset.prefixes.Put
>;

type ADD_PREFIX = GlobalAction<
  {
    types: [
      typeof LocalActions.ADD_DATASET_PREFIX,
      typeof LocalActions.ADD_DATASET_PREFIX_SUCCESS,
      typeof LocalActions.ADD_DATASET_PREFIX_FAIL
    ];
    datasetId: string;
  },
  Routes.datasets._account._dataset.prefixes.Post
>;

export type LocalAction =
  | ADD_DATASET
  | GET_DATASETS
  | GET_CURRENT_DATASET
  | REFRESH_CURRENT_DATASET
  | UPDATE_DATASET
  | DELETE_DATASET
  | UPLOAD_DATASET_AVATAR
  | COPY_DATASET
  | CHOWN_DATASET
  | UPDATE_PREFIXES
  | ADD_PREFIX;

export type Dataset = Models.Dataset;

export interface State {
  list: string[];
  listFor?: ListFor;
  nextPage?: string;
  fetchingList?: ListFor | false;
  fetchingListError?: string;
  current?: string;
  fetchingCurrent: boolean;
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.ADD_DATASET_SUCCESS:
        draftState.list = [action.result.id, ...draftState.list];
        return;

      case Actions.COPY_DATASET_SUCCESS:
        draftState.list = [];
        draftState.listFor = undefined;
        return;

      case Actions.GET_DATASETS:
      case Actions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS:
        draftState.fetchingList = action.listFor;
        return;

      case Actions.GET_DATASETS_SUCCESS:
      case Actions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_SUCCESS:
        if (!isEqual(draftState.fetchingList, action.listFor)) {
          // ignore stale response
          return;
        }

        draftState.fetchingList = false;
        draftState.fetchingListError = undefined;
        draftState.nextPage =
          action.meta && action.meta.links && action.meta.links["next"] ? action.meta.links["next"].url : undefined;
        if (action.append) {
          draftState.list = [...draftState.list, ...action.result.map((ds) => ds.id)];
          return;
        }
        draftState.list = action.result.map((ds) => ds.id);
        draftState.listFor = action.listFor;
        return;

      case Actions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_FAIL:
      case Actions.GET_DATASETS_FAIL:
        if (!isEqual(draftState.fetchingList, action.listFor)) {
          // ignore stale response
          return;
        }
        draftState.fetchingList = false;
        draftState.nextPage = undefined;
        draftState.list = [];
        draftState.listFor = action.listFor;
        draftState.fetchingListError = action.message;
        return;

      case Actions.GET_CURRENT_DATASET:
        draftState.current = undefined;
        draftState.fetchingCurrent = true;
        return;

      case Actions.DELETE_DATASET_SUCCESS:
        draftState.list = draftState.list.filter((datasetId) => datasetId !== action.dataset.id);
        if (draftState.current && action.dataset.id === draftState.current) {
          draftState.current = undefined;
        }
        return;

      case Actions.GET_CURRENT_DATASET_SUCCESS:
      case Actions.REFRESH_CURRENT_DATASET_SUCCESS:
        draftState.fetchingCurrent = false;
      case Actions.UPLOAD_DATASET_AVATAR_SUCCESS:
      case Actions.UPDATE_DATASET_SUCCESS:
        draftState.current = action.result.id;
        return;

      case Actions.GET_CURRENT_DATASET_FAIL:
        draftState.fetchingCurrent = false;
        return;
    }
  },
  <State>{
    list: [],
    listFor: undefined,
    nextPage: undefined,
    fetchingList: false,
    fetchingListError: undefined,
    current: undefined,
    fetchingCurrent: false,
    deletingDs: false,
  }
);

export function createDataset(
  forUser: string,
  name: string,
  accessLevel: "public" | "private" | "internal",
  description?: string,
  displayName?: string
): BeforeDispatch<ADD_DATASET> {
  return {
    types: [Actions.ADD_DATASET, Actions.ADD_DATASET_SUCCESS, Actions.ADD_DATASET_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forUser}`,
        method: "post",
        body: {
          name,
          accessLevel: accessLevel,
          description: description,
          displayName: displayName,
        },
      }),
    verbose: false,
  };
}

export function searchDatasets(
  query?: Routes.search.datasets.Get["Req"]["Query"],
  admin?: ListFor["admin"]
): BeforeDispatch<GET_DATASETS> {
  return {
    types: [Actions.GET_DATASETS, Actions.GET_DATASETS_SUCCESS, Actions.GET_DATASETS_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/search/datasets",
        method: "get",
        query,
      }),
    listFor: {
      admin: admin || false,
      account: false,
      searchTerm: !!query && !!query.q && query.q,
    },
    append: false,
  };
}

export function getDatasetsForAccount(forUser: Account): BeforeDispatch<GET_DATASETS> {
  return {
    types: [Actions.GET_DATASETS, Actions.GET_DATASETS_SUCCESS, Actions.GET_DATASETS_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forUser.accountName}`,
        method: "get",
      }),
    listFor: {
      admin: false,
      account: forUser.accountName,
      searchTerm: false,
    },
    append: false,
  };
}

export function getDatasetsWithLink(link: string): BeforeDispatch<GET_DATASETS> {
  return {
    types: [Actions.GET_DATASETS, Actions.GET_DATASETS_SUCCESS, Actions.GET_DATASETS_FAIL],
    promise: (client) =>
      client.req({
        url: link,
        method: "get",
      }),
    listFor: undefined,
    append: true,
  };
}

export function isDatasetsListLoadedForCurrentUser(state: GlobalState) {
  return (
    !state.datasetManagement.fetchingList &&
    !state.datasetManagement.fetchingListError &&
    !isEmpty(getCurrentAccount(state)) && //can only check when user is loaded as well
    isEqual(state.datasetManagement.listFor, {
      account: getCurrentAccount(state)?.accountName,
      admin: false,
      searchTerm: false,
    })
  );
}

export function isDatasetsListLoadedForUser(state: GlobalState, user: User) {
  return (
    !state.datasetManagement.fetchingList &&
    !state.datasetManagement.fetchingListError &&
    !!user && //can only check when user is loaded as well
    isEqual(state.datasetManagement.listFor, { account: user.accountName, admin: false, searchTerm: false })
  );
}

export function isDatasetsListLoadedForQuery(state: GlobalState, searchString: string) {
  const query = parseSearchString(searchString);
  return (
    !state.datasetManagement.fetchingListError &&
    isEqual(state.datasetManagement.listFor, {
      account: false,
      admin: false,
      searchTerm: !!query && !!query.q && query.q,
    })
  );
}
export function isDatasetsListLoadedForAdmin(state: GlobalState) {
  return !state.datasetManagement.fetchingListError && state.datasetManagement?.listFor?.admin === "fullList";
}

export function shouldLoadDataset(globalState: GlobalState, accountName: string, dsName: string) {
  const currentDs = getCurrentDataset(globalState);
  return (
    //is a valid ds name
    dsName &&
    dsName[0] !== "_" && //is not already loaded
    !(
      currentDs &&
      dsName.toLowerCase() === currentDs.name.toLowerCase() &&
      currentDs.owner.accountName &&
      accountName.toLowerCase() === currentDs.owner.accountName.toLowerCase()
    )
  );
}

export function getDatasetInfo(accountName: string, dsName: string): BeforeDispatch<GET_CURRENT_DATASET> {
  return {
    types: [Actions.GET_CURRENT_DATASET, Actions.GET_CURRENT_DATASET_SUCCESS, Actions.GET_CURRENT_DATASET_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + accountName + "/" + dsName,
        method: "get",
        query: { verbose: null },
      }),
    verbose: true,
  };
}

export function refreshDatasetsInfo({
  accountName,
  datasetName,
}: {
  accountName: string;
  datasetName: string;
}): BeforeDispatch<REFRESH_CURRENT_DATASET> {
  return {
    types: [
      Actions.REFRESH_CURRENT_DATASET,
      Actions.REFRESH_CURRENT_DATASET_SUCCESS,
      Actions.REFRESH_CURRENT_DATASET_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + accountName + "/" + datasetName,
        method: "get",
        query: { verbose: null },
      }),
    verbose: true,
  };
}

export function updateDataset(
  account: Account,
  currentDataset: Dataset,
  updateProps: Models.UpdateDataset
): BeforeDispatch<UPDATE_DATASET> {
  //our form sets the value for "None" to an empty string. Have to convert this to null when sending the path request
  if (typeof updateProps.license === "string" && updateProps.license.length === 0) updateProps.license = null;
  return {
    types: [Actions.UPDATE_DATASET, Actions.UPDATE_DATASET_SUCCESS, Actions.UPDATE_DATASET_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${account.accountName}/${currentDataset.name}`,
        method: "patch",
        body: updateProps,
      }),
    renaming: !!updateProps.name && updateProps.name !== currentDataset.name,
    verbose: false,
  };
}

export function deleteDataset(dataset: Dataset): BeforeDispatch<DELETE_DATASET> {
  return {
    types: [Actions.DELETE_DATASET, Actions.DELETE_DATASET_SUCCESS, Actions.DELETE_DATASET_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/datasets/" + dataset.owner.accountName + "/" + dataset.name,
        method: "delete",
      }),
    dataset,
  };
}

export function uploadAvatar(forDs: Dataset, file: File): BeforeDispatch<UPLOAD_DATASET_AVATAR> {
  return {
    types: [Actions.UPLOAD_DATASET_AVATAR, Actions.UPLOAD_DATASET_AVATAR_SUCCESS, Actions.UPLOAD_DATASET_AVATAR_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/imgs/avatars/d/" + forDs.id,
        method: "post",
        files: { avatar: file },
      }),
    verbose: false,
  };
}

export function copyDataset(dataset: Dataset, toAccount: string): BeforeDispatch<COPY_DATASET> {
  return {
    types: [Actions.COPY_DATASET, Actions.COPY_DATASET_SUCCESS, Actions.COPY_DATASET_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${dataset.owner.accountName}/${dataset.name}/copy`,
        method: "post",
        body: {
          toAccount,
        },
      }),
  };
}

export function transferDataset(dataset: Dataset, toAccount: string): BeforeDispatch<CHOWN_DATASET> {
  return {
    types: [Actions.CHOWN_DATASET, Actions.CHOWN_DATASET_SUCCESS, Actions.CHOWN_DATASET_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${dataset.owner.accountName}/${dataset.name}/chown`,
        method: "post",
        body: {
          toAccount,
        },
      }),
    datasetName: dataset.name,
    fromUid: dataset.owner.uid,
  };
}

export function getCurrentDataset(state: GlobalState): Dataset | undefined {
  if (!state.datasetManagement.current) return undefined;
  return getDataset(state, state.datasetManagement.current);
}

export const useCurrentDataset = () => {
  const datasetCollection = useSelector((state: GlobalState) => state.datasetCollection);
  const accountCollection = useSelector((state: GlobalState) => state.accountCollection);
  const currentDatasetId = useSelector((state: GlobalState) => state.datasetManagement.current);
  if (!currentDatasetId) return undefined;
  return getDatasetFromCollections(datasetCollection, accountCollection, currentDatasetId);
};

export function getDatasetList(state: GlobalState, listFor: typeof state.datasetManagement.listFor) {
  if (!isEqual(listFor, state.datasetManagement.listFor)) return [];
  return state.datasetManagement.list
    .map((datasetId) => getDataset(state, datasetId))
    .filter((ds) => !!ds) as Dataset[];
}
export function useDatasetList(listFor: ListFor) {
  const listForFromState = useSelector((state: GlobalState) => state.datasetManagement.listFor);
  const listFromState = useSelector((state: GlobalState) => state.datasetManagement.list);
  const datasetCollection = useSelector((state: GlobalState) => state.datasetCollection);
  const accountCollection = useSelector((state: GlobalState) => state.accountCollection);
  if (!isEqual(listFor, listForFromState)) return [];
  return listFromState
    .map((datasetId) => getDatasetFromCollections(datasetCollection, accountCollection, datasetId))
    .filter((ds) => !!ds) as Dataset[];
}

export function updateDatasetPrefixes(
  prefixes: PrefixUpdate[],
  forUser: Account,
  forDataset: Dataset
): BeforeDispatch<UPDATE_PREFIXES> {
  prefixes = prefixes.filter((item) => !isEmpty(item));
  return {
    types: [
      Actions.UPDATE_DATASET_PREFIXES,
      Actions.UPDATE_DATASET_PREFIXES_SUCCESS,
      Actions.UPDATE_DATASET_PREFIXES_FAIL,
    ],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forUser.accountName}/${forDataset.name}/prefixes`,
        method: "put",
        //the filter also makes sure we don't save any empty prefix defs
        body: prefixes.filter((p) => p.iri !== "global"),
      }),
    datasetId: forDataset.id,
  };
}

export function createDatasetPrefix(
  prefix: PrefixUpdate,
  forUser: Account,
  forDataset: Dataset
): BeforeDispatch<ADD_PREFIX> {
  return {
    types: [Actions.ADD_DATASET_PREFIX, Actions.ADD_DATASET_PREFIX_SUCCESS, Actions.ADD_DATASET_PREFIX_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forUser.accountName}/${forDataset.name}/prefixes`,
        method: "post",
        body: prefix,
      }),
    datasetId: forDataset.id,
  };
}
