import { Alert, Backdrop, NoSsr, Skeleton } from "@mui/material";
import getClassnames from "classnames";
import * as React from "react";
import { isConstructResponse, SparqlResponse } from "#components/Sparql/SparqlUtils.ts";
import useClickOutside from "#helpers/hooks/useClickOutside.ts";
import useHover from "#helpers/hooks/useHover.ts";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { EmptyResults } from "../displayUtils";
import LargeResultWidget from "../LargeResultWidget";
import styles from "./styles.scss";

export interface PluginConfig {
  seed?: string | number;
}

// Public function for data validity checking for Network
export function canDraw(data?: SparqlResponse): boolean {
  return data !== undefined && isConstructResponse(data);
}

//Lazyloading Network libs
const NetworkCore = React.lazy(() => import("./networkCore"));

const Network: React.FC = () => {
  const { setVisualizationConfig, checkLimits } = React.useContext(SparqlVisualizationContext);
  const { isConstruct, emptyResults, noResults, results } = useSparqlResults();
  const [showLargeResult, setShowLargeResult] = React.useState(false);

  const [backdropEnabled, setBackdropEnabled] = React.useState<boolean>(true);
  const containerRef = React.useRef<HTMLDivElement>(null);
  useClickOutside(() => {
    setBackdropEnabled(true);
  }, containerRef);

  const isHovered = useHover(containerRef);

  if (noResults) return null;
  if (!isConstruct) {
    return (
      <Alert severity="warning" role="alert">
        This visualization can only render with Construct queries
      </Alert>
    );
  }
  if (emptyResults) {
    return <EmptyResults />;
  }
  if (checkLimits && results.length > 200 && !showLargeResult) {
    return <LargeResultWidget exceededSize="amountOfResults" onContinue={() => setShowLargeResult(true)} />;
  }

  return (
    <NoSsr>
      <div className={styles.networkContainer} ref={containerRef}>
        {!!!setVisualizationConfig && (
          <Backdrop
            className={getClassnames(styles.backdrop, isHovered ? styles.backdropShow : styles.backdropHide)}
            open={backdropEnabled}
            onClick={() => setBackdropEnabled(false)}
          >
            Click to interact
          </Backdrop>
        )}
        <React.Suspense fallback={<Skeleton height="inherit" variant="rectangular" />}>
          <NetworkCore disableHotkeys={!!!setVisualizationConfig && backdropEnabled} />
        </React.Suspense>
      </div>
    </NoSsr>
  );
};

export default Network;
