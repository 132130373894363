import { Alert, Autocomplete, Skeleton, TextField, ThemeProvider, useTheme } from "@mui/material";
import { cloneDeep, merge } from "lodash-es";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField } from "#components/index.ts";
import useApplyPrefixes from "../useApplyPrefixes.ts";
import useCurrentClass from "../useCurrentClass";
import useRemovePrefixes from "../useRemovePrefixes.ts";
import useSparql from "../useSparql";
import { validateIri } from "./helpers.ts";
import localTheme from "./Theme";
import { ClassData } from "./Types";

const ClassForm: React.FC<{ onSubmit: (values: ClassData) => Promise<void>; datasetUrl: string }> = ({
  onSubmit,
  datasetUrl,
}) => {
  const theme = useTheme();

  const currentClass = useCurrentClass();

  const { data: classes } = useSparql<{ id: string; label: string; description?: string }[]>(`
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix owl: <http://www.w3.org/2002/07/owl#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>

    select ?id (sample(?label_t) as ?label) (sample(?description_t) as ?description) where {
      {
        {
          select distinct ?id where {
            {
              ?id a rdfs:Class
            } union {
              ?id a owl:Class
            } union {
              ?id rdfs:subClassOf|^rdfs:subClassOf []
            }
            filter (!regex(str(?id), "\.well-known/genid"))
          }
        }
        optional {
          ?id rdfs:label|skos:prefLabel ?l
        }
        optional {
          ?id rdfs:comment|skos:definition ?description_t
        }
        bind(coalesce(?l, str(?id)) as ?label_t)
      }
    }
    group by ?id
    order by ?label
    `);

  const applyPrefixes = useApplyPrefixes();
  const removePrefixes = useRemovePrefixes();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    setError,
  } = useForm<ClassData>({ mode: "onChange" });

  const submit = async (values: ClassData) => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setError("root.serverError", {
        type: "500",
      });
    }
  };

  if (!classes) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }
  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
        <FormField label="Label">
          <Controller
            name="label"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => {
                if (!value.trim()) return "A label is required.";
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                required
                autoFocus
              />
            )}
          />
        </FormField>

        <FormField label="Class IRI">
          <Controller
            name="iri"
            control={control}
            rules={{
              validate: (value) => validateIri(removePrefixes(value?.trim())),
            }}
            defaultValue={applyPrefixes(`${datasetUrl}/def/${Date.now()}`)}
            render={({ field, fieldState }) => {
              return (
                <TextField {...field} error={!!fieldState.error} required helperText={fieldState.error?.message} />
              );
            }}
          />
        </FormField>

        <FormField label="Description (optional)">
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                minRows={3}
                multiline
              />
            )}
          />
        </FormField>

        <FormField label="Superclass (optional)">
          <Controller
            name="parent"
            control={control}
            defaultValue={(classes.find((c) => c.id === currentClass) as any) || null}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <Autocomplete
                options={classes}
                onChange={(_e, data) => {
                  return onChange(data);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...(params as any)}
                    error={!!error}
                    helperText={error?.message || typeof rest.value === "string" ? rest.value : rest.value?.description}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                getOptionKey={(option) => {
                  if (typeof option === "string") return option;
                  return option.id;
                }}
                {...rest}
              />
            )}
          />
        </FormField>

        <LoadingButton color="secondary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </LoadingButton>

        {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
      </form>
    </ThemeProvider>
  );
};

export default ClassForm;
