import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import { Avatar, Markdown, Topics } from "#components/index.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import styles from "./style.scss";

interface Props {
  ds: Dataset;
  className?: string;
  hideOwnerName?: boolean;
}

const DatasetListItem: React.FC<Props> = ({ ds, className, hideOwnerName }) => {
  const description = React.useRef<HTMLDivElement>(null);
  const descriptionHeight = description.current?.clientHeight || 0;
  const showReadFurther = descriptionHeight >= 180;

  //sanity check
  if (!ds || !ds.owner) return null;
  const link = "/" + ds.owner.accountName + "/" + ds.name;
  return (
    <div className={getClassName(className, styles.box)}>
      <div className="flex center">
        <div className={styles.colAvatar} aria-hidden="true">
          <Avatar
            size="md"
            avatarName={ds.displayName || ds.name}
            avatarUrl={ds.avatarUrl}
            className={styles.avatar}
            linkTo={link}
            alt=""
          />
        </div>
        <div className={styles.colDescription}>
          <div>
            <Link to={link} className={styles.dsTitle}>
              <h3 className="headerSpacing">{ds.displayName || ds.name}</h3>
            </Link>
            {!hideOwnerName && (
              <div className={styles.ownerName}>
                by <Link to={`/${ds.owner.accountName}`}>{ds.owner.name || ds.owner.accountName}</Link>
              </div>
            )}
          </div>
          {ds.description && (
            <div ref={description}>
              <Markdown className={styles.description} compact>
                {ds.description}
              </Markdown>
            </div>
          )}
          {showReadFurther && <Link to={link}>Read further</Link>}
          {ds.topics.length > 0 && (
            <div className="mt-3">
              <Topics className={styles.topics} topics={ds.topics} />
            </div>
          )}
        </div>
        <div className={styles.colIcons}>
          <AccessLevelIcon level={ds.accessLevel} type="dataset" addTitleForAccountType={ds.owner.type} />
        </div>
      </div>
    </div>
  );
};
export default DatasetListItem;
