import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import * as React from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router";
import useRunningServices from "#helpers/hooks/useRunningServices.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import styles from "./style.scss";

interface Props {
  currentServiceName: string;
}

const ServiceSelector: React.FC<Props> = ({ currentServiceName }) => {
  const currentDs = useCurrentDataset();
  const location = useLocation();
  const history = useHistory();
  const runningServices = useRunningServices("elasticsearch");
  const [_cookies, setCookie] = useCookies([`${currentDs?.id}-cse-elasticsearch`]);

  React.useEffect(() => {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 6);
    // Can't add path variable due to loading behavior of cookies, see #1803
    setCookie(`${currentDs?.id}-cse-elasticsearch`, currentServiceName, {
      maxAge: (60 * 60 * 24 * 365) / 2, // Keep around for half a year
      expires: expires,
      httpOnly: false,
      sameSite: "strict", // we don't need CORS since this cookie is only used in the frontend
      path: "/",
    });
  }, [currentServiceName, currentDs?.id, setCookie]);

  if (!currentDs) return null;

  return (
    <FormControl variant="filled" className={styles.dropdown} size="small">
      <InputLabel htmlFor="serviceSelect">Service</InputLabel>
      <Select
        label="Service"
        size="small"
        variant="filled"
        value={currentServiceName}
        onChange={(e) => {
          if (e.target.value === currentServiceName) return;
          if (typeof e.target.value === "string") {
            history.push({
              pathname: `/${currentDs.owner.accountName}/${currentDs.name}/elasticsearch/${e.target.value}`,
              search: location.search,
              hash: location.hash,
            });
          }
        }}
        displayEmpty
        inputProps={{
          id: "serviceSelect",
        }}
      >
        {runningServices.map((service) => {
          return (
            <MenuItem key={service.id} value={service.name}>
              {service.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ServiceSelector;
