import * as React from "react";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import { AccountMetadata, ErrorPage, FlexContainer } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { accountIsCurrentAccount, getAccountInfo, useCurrentAccount } from "#reducers/app.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import ChangeUsername from "./ChangeUsername.tsx";
import Consent from "./Consent.tsx";
import DeleteAccount from "./DeleteAccount.tsx";
import DisableAccount from "./DisableAccount.tsx";
import UsageOverview from "./LightUserUsageOverview.tsx";
import Permissions from "./Permissions.tsx";
import ProfileSettings from "./ProfileSettings.tsx";
import Security from "./Security.tsx";

const AccountSettings: React.FC<{}> = () => {
  const currentAccount = useCurrentAccount();
  const loggedInUser = useAuthenticatedUser();
  const consentRequired = useSelector(
    (state: GlobalState) =>
      !!state.config.clientConfig?.legal?.privacyPolicyUrl || !!state.config.clientConfig?.legal?.generalTermsUrl
  );
  const acl = useAcl();

  if (!currentAccount) {
    return <ErrorPage statusCode={404} message={"Account does not exist"} />;
  }
  const roleInAccount = acl.getRoleInAccount(currentAccount);
  const editingSelf = acl.isAuthenticatedUser(currentAccount);

  if (!acl.check({ action: "editAccountMetadata", context: { roleInAccount } }).granted) {
    return <ErrorPage statusCode={401} />;
  }

  return (
    <FlexContainer>
      <AccountMetadata
        currentAccount={currentAccount}
        title={`${currentAccount.type === "user" ? "User" : "Organization"} settings`}
      />
      {currentAccount.type === "user" && consentRequired && currentAccount.legalConsent !== "ok" && (
        <Consent currentAccount={currentAccount} editingSelf={editingSelf} />
      )}
      <ProfileSettings />
      {currentAccount.type === "user" && <Security currentAccount={currentAccount} loggedInUser={loggedInUser} />}
      {currentAccount.type === "user" && currentAccount.role === "light" && (
        <UsageOverview currentAccount={currentAccount} />
      )}
      {acl.check({ action: "manageUsers" }).granted && currentAccount.type === "user" && (
        <Permissions currentAccount={currentAccount} editingSelf={editingSelf} />
      )}
      {currentAccount.type === "user" &&
        acl.check({ action: "deleteAccount", context: { roleInAccount: roleInAccount } }).granted && (
          <ChangeUsername currentAccount={currentAccount} />
        )}
      {acl.check({ action: "manageUsers" }).granted && currentAccount.type === "user" && (
        <DisableAccount currentAccount={currentAccount} editingSelf={editingSelf} />
      )}
      {acl.check({ action: "deleteAccount", context: { roleInAccount: roleInAccount } }).granted && (
        <DeleteAccount currentAccount={currentAccount} loggedInUser={loggedInUser} editingSelf={editingSelf} />
      )}
    </FlexContainer>
  );
};

const AccountSettingsMemo = React.memo(AccountSettings);

export default asyncConnect<GlobalState>([
  {
    promise: ({ match: { params }, store: { dispatch, getState } }) => {
      if (!accountIsCurrentAccount(getState(), params.account)) {
        return dispatch<any>(getAccountInfo(getState(), params.account, true));
      }
    },
  },
])(() => <AccountSettingsMemo />);
