import { Divider, Fade, Tooltip, useMediaQuery } from "@mui/material";
import getClassName from "classnames";
import { push } from "connected-react-router";
import { find } from "lodash-es";
import * as React from "react";
import { useCookies } from "react-cookie";
// @ts-ignore
import { Droppable } from "react-drag-and-drop";
import { useSelector } from "react-redux";
import { matchRoutes } from "react-router-config";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeButton, FontAwesomeIcon } from "#components/index.ts";
import SquareLogo from "#components/SquareLogo/index.tsx";
import { IComponentProps } from "#containers/index.ts";
import { SIDE_PANEL_COLLAPSED_COOKIE } from "#containers/NavConsole/index.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useGraphqlEnabled } from "#helpers/hooks/useGraphQLEnabled.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { toggleSidePanelCollapsed, useCurrentAccount } from "#reducers/app.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { getRunningServices } from "#reducers/services.ts";
import { addDatasetHistoryItem } from "#reducers/sessionHistory.ts";
import useIsEditorModeEnabled from "../../helpers/hooks/useEditMode.tsx";
import DatasetCopyModal from "./DatasetCopyModal/index.tsx";
import PanelGroup from "./PanelGroup/index.tsx";
import PanelItem from "./PanelItem/index.tsx";
import itemStyles from "./PanelItem/style.scss";
import styles from "./style.scss";

export interface Props {
  route: IComponentProps["route"];
}
const addTrailingSlash = (str: string) => {
  if (str[str.length - 1] === "/") return str;
  return str + "/";
};
const getQueryForResource = (page: "browser" | "table" | "schema" | "ontology", resource: string) => {
  switch (page) {
    case "browser":
      return { resource: resource };
    case "ontology":
      return { class: resource };
    case "table":
      return { subject: resource };
    case "schema":
      return { f: resource };
  }
};
const DatasetPanel: React.FC<Props> = ({ route }) => {
  const acl = useAcl();
  const dispatch = useDispatch();
  const currentDs = useCurrentDataset();
  const currentAccount = useCurrentAccount();
  const authenticatedUser = useAuthenticatedUser();
  const {
    lastTableQuery,
    lastBrowserResource,
    lastOntologyResource,
    lastSearchQuery,
    loadingAssets,
    loadingGraphs,
    loadingServices,
    runningSearchServices,
  } = useSelector((state: GlobalState) => {
    const dataState = currentDs ? state.datasets[currentDs.id] : undefined;
    const services = currentDs && state.services[currentDs.id];
    const assetUploads = currentDs && state.assetUploads[currentDs.id];
    const jobUploads = currentDs && state.uploading[currentDs.id];

    return {
      lastTableQuery: dataState?.lastTableQuery,
      lastBrowserResource: dataState?.lastBrowserResource,
      lastOntologyResource: dataState?.lastOntologyResource,
      lastSearchQuery: dataState?.lastSearchQuery,
      runningSearchServices: !!getRunningServices(services || [], "elasticSearch").length,
      loadingServices:
        !!services && !!services.find((s) => ["starting", "stopping", "removing", "updating"].includes(s.status)),
      loadingAssets: !!assetUploads && find(assetUploads, (a) => !a.message) !== undefined,
      loadingGraphs:
        (!!jobUploads && find(jobUploads, (upload) => !upload.error && !upload.cancelled) !== undefined) ||
        (!!dataState && !!dataState.jobs.find((job) => ["downloading", "indexing"].indexOf(job.status) > -1)),
    };
  });
  const { appConfig } = useSelector((state: GlobalState) => {
    return {
      appConfig: state.config.clientConfig,
    };
  });

  const history = useHistory();

  const [cookies, setCookies] = useCookies([SIDE_PANEL_COLLAPSED_COOKIE]);
  const collapsed = useSelector(
    (state: GlobalState) => state.app.sidePanelCollapsed ?? cookies[SIDE_PANEL_COLLAPSED_COOKIE] === "1"
  );

  const location = useLocation<{ dsPanelOpen: boolean } | undefined>();
  const panelOpen = !!location.state?.dsPanelOpen;
  const mobilePanel = useMediaQuery("(max-width: 767px)");

  const panelCollapsed = collapsed && !mobilePanel;

  const logoBgType = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgType);
  const logoBgColor = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgColor);

  const editMode = useIsEditorModeEnabled();

  const graphqlEnabled = useGraphqlEnabled();

  // Collapse panel when it was temporarily expanded
  React.useEffect(() => {
    if (cookies[SIDE_PANEL_COLLAPSED_COOKIE] === "1" && !collapsed) {
      dispatch(toggleSidePanelCollapsed(true));
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  //Moved this here from NavDataset, since this is a functional component and using componentDidMount did not work when navigating from dataset to dataset
  const currentDsId = currentDs?.id; //only trigger the useEffect when the dataset id changes
  React.useEffect(() => {
    if (currentDs) dispatch(addDatasetHistoryItem(currentDs));
  }, [currentDsId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentAccount || !currentDs) return <></>;

  const matches = route?.routes ? matchRoutes(route.routes, location.pathname) : undefined;

  const currentPath = "/" + currentAccount.accountName + "/" + currentDs.name + "/";

  const linkIsMatched = (subPath: string, exact = true) => {
    if (exact) return !!matches && addTrailingSlash(matches[0].match.url) === addTrailingSlash(currentPath + subPath);
    return !!matches && matches[0].match.url.indexOf(currentPath + subPath) === 0;
  };

  const dropHandler = (data: any, path: "browser" | "table" | "schema" | "ontology") => {
    if (data && data["text/plain"]) {
      dispatch(
        push({
          pathname: currentPath + path,
          search: stringifyQuery(getQueryForResource(path, data["text/plain"].trim())),
        })
      );
    }
  };

  const mayEditDsMetadata = acl.check({
    action: "editDatasetMetadata",
    context: {
      roleInOwnerAccount: acl.getRoleInAccount(currentAccount),
      accessLevel: currentDs.accessLevel,
      newAccessLevel: undefined,
    },
  }).granted;

  const mayManageGraphs = acl.check({
    action: "manageGraphs",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  return (
    <>
      {mobilePanel && (
        <div
          className={getClassName(styles.dsPanelOverlay, { [styles.open]: panelOpen })}
          onClick={() => history.goBack()}
          role="button"
          tabIndex={0}
          aria-label="Close side bar"
        />
      )}
      <nav
        className={getClassName("hideOnPrint", styles.panel, {
          [styles.collapsed]: panelCollapsed,
          [styles.open]: panelOpen,
        })}
        aria-label="Dataset"
      >
        {/*header*/}
        {appConfig && (
          <div className={styles.homeButton}>
            <PanelItem
              panelCollapsed={panelCollapsed}
              replace={panelOpen}
              iconComponent={
                <SquareLogo
                  heightWidthPx={34}
                  logo={appConfig.branding.logo}
                  logoBgColor={logoBgColor}
                  logoBgType={logoBgType}
                />
              }
              name={appConfig.branding.name}
              to="/"
            />
          </div>
        )}
        <div className={getClassName("mt-3", styles.header)}>
          <div
            className={getClassName(
              styles.headerTitle,
              mayEditDsMetadata ? styles.withSettings : styles.withoutSettings
            )}
          >
            <PanelItem
              active={linkIsMatched("")}
              panelCollapsed={panelCollapsed}
              replace={panelOpen}
              icon="home"
              name={currentDs.displayName || currentDs.name}
              to={currentPath}
            />
          </div>

          {mayEditDsMetadata && (
            <div>
              <Link
                aria-label="Settings"
                replace={panelOpen}
                to={currentPath + "settings"}
                className={getClassName(styles.config, "resetButton", styles.hideCollapsed, {
                  [styles.buttonConfig]: linkIsMatched("settings"),
                })}
                tabIndex={panelCollapsed ? -1 : 0}
              >
                <FontAwesomeIcon icon="cog" />
              </Link>
            </div>
          )}
        </div>
        {mayEditDsMetadata && (
          <PanelItem
            active={linkIsMatched("settings")}
            panelCollapsed={panelCollapsed}
            replace={panelOpen}
            icon="cog"
            name="Dataset settings"
            className={styles.settingsCollapsed}
            to={currentPath + "settings"}
            tabIndex={panelCollapsed ? 0 : -1}
          />
        )}
        {/*main*/}
        <Divider className="my-3" />
        <div className={styles.main}>
          <div className={styles.section}>
            <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "browser")}>
              <PanelItem
                active={linkIsMatched("browser")}
                panelCollapsed={panelCollapsed}
                replace={panelOpen}
                icon="id-card"
                name="Instances"
                to={currentPath + `browser?${stringifyQuery({ resource: lastBrowserResource })}`}
              />
            </Droppable>
            <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "table")}>
              <PanelItem
                active={linkIsMatched("table")}
                panelCollapsed={panelCollapsed}
                replace={panelOpen}
                icon="th"
                name="Table"
                to={currentPath + `table?${stringifyQuery(lastTableQuery)}`}
              />
            </Droppable>
            <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "ontology")}>
              <PanelItem
                active={linkIsMatched("ontology")}
                panelCollapsed={panelCollapsed}
                replace={panelOpen}
                icon="ruler-triangle"
                name="Ontology"
                to={currentPath + `ontology?${stringifyQuery({ class: lastOntologyResource })}`}
              />
            </Droppable>
            <Droppable types={["text/plain"]} onDrop={(data: any) => dropHandler(data, "schema")}>
              <PanelItem
                active={linkIsMatched("schema")}
                panelCollapsed={panelCollapsed}
                replace={panelOpen}
                icon="pen-ruler"
                name="Schema"
                to={currentPath + "schema?c=shacl"}
              />
            </Droppable>
            <Divider className="my-3" />
            <PanelItem
              active={linkIsMatched("sparql")}
              panelCollapsed={panelCollapsed}
              replace={panelOpen}
              icon="code"
              name="SPARQL"
              to={currentPath + "sparql"}
            />
            {graphqlEnabled && (
              <PanelItem
                active={linkIsMatched("graphql")}
                panelCollapsed={panelCollapsed}
                replace={panelOpen}
                icon="gramophone"
                name="GraphQL"
                to={currentPath + "graphql"}
              />
            )}
            {runningSearchServices && (
              <PanelItem
                active={linkIsMatched("search", false) || linkIsMatched("elasticsearch", false)}
                panelCollapsed={panelCollapsed}
                replace={panelOpen}
                icon="search"
                name="Elasticsearch"
                to={
                  currentPath +
                  "elasticsearch" +
                  (!!lastSearchQuery ? `?${stringifyQuery({ q: lastSearchQuery })}` : "")
                }
              />
            )}
          </div>
          <div className={styles.section}>
            <PanelItem
              active={linkIsMatched("graphs")}
              panelCollapsed={panelCollapsed}
              replace={panelOpen}
              icon="share-alt"
              name="Graphs"
              number={currentDs.graphCount}
              loading={loadingGraphs}
              to={currentPath + "graphs"}
              warning={mayManageGraphs && currentDs.hasDataQualityIssues}
            />
            <PanelItem
              active={linkIsMatched("services")}
              panelCollapsed={panelCollapsed}
              replace={panelOpen}
              icon="cloud"
              name="Services"
              number={currentDs.serviceCount + 1} // +1 for speedy
              loading={loadingServices}
              to={currentPath + "services"}
            />
            <PanelItem
              active={linkIsMatched("assets")}
              panelCollapsed={panelCollapsed}
              replace={panelOpen}
              icon="paperclip"
              name="Assets"
              number={currentDs.assetCount}
              loading={loadingAssets}
              to={currentPath + "assets"}
            />
            <PanelGroup
              active={linkIsMatched("insights")}
              panelCollapsed={panelCollapsed}
              icon="glasses"
              name="Insights"
            >
              <PanelItem
                active={matches?.[1]?.match.url === currentPath + "insights/classFrequency"}
                panelCollapsed={panelCollapsed}
                replace={panelOpen}
                name="Class frequency"
                to={currentPath + "insights/classFrequency"}
              />
              <PanelItem
                active={matches?.[1]?.match.url === currentPath + "insights/classHierarchy"}
                panelCollapsed={panelCollapsed}
                replace={panelOpen}
                name="Class hierarchy"
                to={currentPath + "insights/classHierarchy"}
              />
            </PanelGroup>
          </div>
        </div>

        {/*footers*/}
        {authenticatedUser && (
          <DatasetCopyModal authenticatedUser={authenticatedUser} currentDs={currentDs}>
            <Tooltip
              title={panelCollapsed ? "Copy dataset" : ""}
              placement="right"
              enterDelay={0}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 0 }}
            >
              <div className={getClassName(itemStyles.item, { [itemStyles.collapsed]: panelCollapsed })}>
                <FontAwesomeIcon icon="code-branch" size="lg" fixedWidth className={itemStyles.icon} />
                <span className={getClassName(itemStyles.name, "grow")}>Copy dataset</span>
              </div>
            </Tooltip>
          </DatasetCopyModal>
        )}

        <FontAwesomeButton
          disableHoverStyling
          className={styles.toggler}
          onClick={() => {
            const newValue = !panelCollapsed;
            dispatch(toggleSidePanelCollapsed(newValue));
            setCookies(SIDE_PANEL_COLLAPSED_COOKIE, newValue ? "1" : "0", { path: "/" });
          }}
          aria-label={`Shrink sidebar`}
          aria-pressed={panelCollapsed}
          icon={panelCollapsed ? "chevron-right" : "chevron-left"}
        />
      </nav>
    </>
  );
};

export default DatasetPanel;
