import { Divider } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import styles from "./styles.scss";

const ForwardedDragHandle = React.forwardRef<
  { handleAxis: string; dragging: boolean; onDoubleClick?: React.MouseEventHandler },
  any
>(({ handleAxis, dragging, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={getClassName(styles.dragHandle, `handle-${handleAxis}`, { [styles.dragging]: dragging })}
      aria-hidden
    >
      <br />
      <Divider></Divider>
    </div>
  );
});

export default ForwardedDragHandle;
