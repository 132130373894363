import { produce } from "immer";
import { useSelector } from "react-redux";
import { Models } from "@triply/utils";
import { SocketEvent } from "@triply/utils/SocketEvents.js";
import { GenericReduxApiResponse } from "#helpers/ApiClient.ts";
import { Account, getAccount, getAccountFromAccountCollection, User } from "#reducers/accountCollection.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";

export const LocalActions = {
  IMPERSONATE: "triply/auth/IMPERSONATE",
  IMPERSONATE_SUCCESS: "triply/auth/IMPERSONATE_SUCCESS",
  IMPERSONATE_FAIL: "triply/auth/IMPERSONATE_FAIL",
  UNDO_IMPERSONATE: "triply/auth/UNDO_IMPERSONATE",
  UNDO_IMPERSONATE_SUCCESS: "triply/auth/UNDO_IMPERSONATE_SUCCESS",
  UNDO_IMPERSONATE_FAIL: "triply/auth/UNDO_IMPERSONATE_FAIL",
  RESEND_VERIFICATION_EMAIL: "RESEND_VERIFICATION_EMAIL",
  RESEND_VERIFICATION_EMAIL_SUCCESS: "RESEND_VERIFICATION_EMAIL_SUCCESS",
  RESEND_VERIFICATION_EMAIL_FAIL: "RESEND_VERIFICATION_EMAIL_FAIL",
  LOAD_LOGGED_IN_USER: "triply/auth/LOAD_LOGGED_IN_USER",
  LOAD_LOGGED_IN_USER_SUCCESS: "triply/auth/LOAD_LOGGED_IN_USER_SUCCESS",
  LOAD_LOGGED_IN_USER_FAIL: "triply/auth/LOAD_LOGGED_IN_USER_FAIL",
  RESEND_PASSWORD: "triply/auth/RESEND_PASSWORD",
  RESEND_PASSWORD_SUCCESS: "triply/auth/RESEND_PASSWORD_SUCCESS",
  RESEND_PASSWORD_FAIL: "triply/auth/RESEND_PASSWORD_FAIL",
  RESET_PASSWORD: "triply/auth/RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "triply/auth/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "triply/auth/RESET_PASSWORD_FAIL",
  CHECK_TOKEN_VALIDITY: "triply/auth/CHECK_TOKEN_VALIDITY",
  CHECK_TOKEN_VALIDITY_SUCCESS: "triply/auth/CHECK_TOKEN_VALIDITY_SUCCESS",
  CHECK_TOKEN_VALIDITY_FAIL: "triply/auth/CHECK_TOKEN_VALIDITY_FAIL",
  REGISTER: "triply/auth/REGISTER",
  REGISTER_SUCCESS: "triply/auth/REGISTER_SUCCESS",
  REGISTER_FAIL: "triply/auth/REGISTER_FAIL",
  VERIFY: "triply/auth/VERIFY",
  VERIFY_SUCCESS: "triply/auth/VERIFY_SUCCESS",
  VERIFY_FAIL: "triply/auth/VERIFY_FAIL",
  GET_SOCKET_TOKEN: "triply/socket/GET_SOCKET_TOKEN",
  GET_SOCKET_TOKEN_SUCCESS: "triply/socket/GET_SOCKET_TOKEN_SUCCESS",
  GET_SOCKET_TOKEN_FAIL: "triply/socket/GET_SOCKET_TOKEN_FAIL",
} as const;
type GET_SOCKET_TOKEN = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_SOCKET_TOKEN,
      typeof LocalActions.GET_SOCKET_TOKEN_SUCCESS,
      typeof LocalActions.GET_SOCKET_TOKEN_FAIL
    ];
  },
  GenericReduxApiResponse<{ Body: { token: string } }> // We have not typed this response in our Routes/Models. Instead, it suffices to only pass the type here
>;
type LOAD_LOGGED_IN_USER = GlobalAction<
  {
    types: [
      typeof LocalActions.LOAD_LOGGED_IN_USER,
      typeof LocalActions.LOAD_LOGGED_IN_USER_SUCCESS,
      typeof LocalActions.LOAD_LOGGED_IN_USER_FAIL
    ];
    verbose: true;
  },
  GenericReduxApiResponse<{ Body: Models.User }>
>;

type VERIFY = GlobalAction<
  {
    types: [typeof LocalActions.VERIFY, typeof LocalActions.VERIFY_SUCCESS, typeof LocalActions.VERIFY_FAIL];
  },
  void
>;

type RESEND_VERIFICATION_EMAIL = GlobalAction<
  {
    types: [
      typeof LocalActions.RESEND_VERIFICATION_EMAIL,
      typeof LocalActions.RESEND_VERIFICATION_EMAIL_SUCCESS,
      typeof LocalActions.RESEND_VERIFICATION_EMAIL_FAIL
    ];
  },
  void
>;

type IMPERSONATE = GlobalAction<
  {
    types: [
      typeof LocalActions.IMPERSONATE,
      typeof LocalActions.IMPERSONATE_SUCCESS,
      typeof LocalActions.IMPERSONATE_FAIL
    ];
  },
  void
>;

type UNDO_IMPERSONATE = GlobalAction<
  {
    types: [
      typeof LocalActions.UNDO_IMPERSONATE,
      typeof LocalActions.UNDO_IMPERSONATE_SUCCESS,
      typeof LocalActions.UNDO_IMPERSONATE_FAIL
    ];
  },
  void
>;

type REGISTER = GlobalAction<
  {
    types: [typeof LocalActions.REGISTER, typeof LocalActions.REGISTER_SUCCESS, typeof LocalActions.REGISTER_FAIL];
  },
  void
>;

type RESEND_PASSWORD = GlobalAction<
  {
    types: [
      typeof LocalActions.RESEND_PASSWORD,
      typeof LocalActions.RESEND_PASSWORD_SUCCESS,
      typeof LocalActions.RESEND_PASSWORD_FAIL
    ];
  },
  void
>;

type RESET_PASSWORD = GlobalAction<
  {
    types: [
      typeof LocalActions.RESET_PASSWORD,
      typeof LocalActions.RESET_PASSWORD_SUCCESS,
      typeof LocalActions.RESET_PASSWORD_FAIL
    ];
  },
  void
>;

type CHECK_TOKEN_VALIDITY = GlobalAction<
  {
    types: [
      typeof LocalActions.CHECK_TOKEN_VALIDITY,
      typeof LocalActions.CHECK_TOKEN_VALIDITY_SUCCESS,
      typeof LocalActions.CHECK_TOKEN_VALIDITY_FAIL
    ];
  },
  void
>;

export type LocalAction =
  | LOAD_LOGGED_IN_USER
  | VERIFY
  | RESEND_VERIFICATION_EMAIL
  | IMPERSONATE
  | UNDO_IMPERSONATE
  | REGISTER
  | RESEND_PASSWORD
  | RESET_PASSWORD
  | CHECK_TOKEN_VALIDITY;

export interface State {
  triedLoadingLoggedInUser: boolean;
  loggedInUser?: string;
  verified: boolean;
  verifying: boolean;
  verifyError?: string;
}

const initialState: State = {
  triedLoadingLoggedInUser: false,
  loggedInUser: undefined,
  verified: false,
  verifying: false,
  verifyError: undefined,
};

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.LOAD_LOGGED_IN_USER:
      draftState.triedLoadingLoggedInUser = true;
      return;
    case Actions.LOAD_LOGGED_IN_USER_SUCCESS:
      if (action.result) draftState.loggedInUser = action.result.uid;
      return;
    case Actions.VERIFY:
      draftState.verifying = true;
      return;
    case Actions.VERIFY_SUCCESS:
      draftState.verifying = false;
      draftState.verified = true;
      return;
    case Actions.VERIFY_FAIL:
      draftState.verifying = false;
      draftState.verifyError = action.message;
      return;
  }
}, initialState) as any;

export function shouldLoadLoggedInUser(state: GlobalState) {
  return !state.auth.triedLoadingLoggedInUser && !isLoaded(state);
}

export function isLoaded(state: GlobalState, accountName?: string) {
  if (!accountName) return !!state.auth.loggedInUser;
  const loggedInUser = getLoggedInUser(state);
  return !!loggedInUser?.accountName && accountName.toLowerCase() === loggedInUser.accountName.toLowerCase();
}

export function getSocketToken<E extends SocketEvent>(namespace: E["namespace"]): BeforeDispatch<GET_SOCKET_TOKEN> {
  return {
    types: [Actions.GET_SOCKET_TOKEN, Actions.GET_SOCKET_TOKEN_SUCCESS, Actions.GET_SOCKET_TOKEN_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/web/auth/socket" + namespace,
        method: "get",
      }),
  };
}

export function load(): BeforeDispatch<LOAD_LOGGED_IN_USER> {
  return {
    types: [Actions.LOAD_LOGGED_IN_USER, Actions.LOAD_LOGGED_IN_USER_SUCCESS, Actions.LOAD_LOGGED_IN_USER_FAIL],
    promise: (client) =>
      client
        .req({
          pathname: "/me",
          method: "get",
          query: {
            verbose: null,
          },
          statusCodeMap: { 401: 202, 404: 202 },
        })
        .then((result) => (result.body.createdAt ? result.body : null)),
    verbose: true,
  };
}

export function verify(userId: string, token: string): BeforeDispatch<VERIFY> {
  return {
    types: [Actions.VERIFY, Actions.VERIFY_SUCCESS, Actions.VERIFY_FAIL],
    promise: (client) =>
      client
        .req({
          pathname: "/web/auth/local/verify",
          method: "post",
          body: {
            userId,
            token,
          },
        })
        .then(() => {
          if (__CLIENT__) {
            window.location.replace("/me");
          }
        }),
  };
}

export function resendVerificationEmail(email: string, password: string): BeforeDispatch<RESEND_VERIFICATION_EMAIL> {
  return {
    types: [
      Actions.RESEND_VERIFICATION_EMAIL,
      Actions.RESEND_VERIFICATION_EMAIL_SUCCESS,
      Actions.RESEND_VERIFICATION_EMAIL_FAIL,
    ],
    promise: (client) =>
      client
        .req({
          pathname: "/web/auth/local/resendVerificationEmail",
          method: "post",
          body: {
            email: email,
            password: password,
          },
        })
        .then(() => {}),
  };
}

export function impersonateTo(accountName: string): BeforeDispatch<IMPERSONATE> {
  return {
    types: [Actions.IMPERSONATE, Actions.IMPERSONATE_SUCCESS, Actions.IMPERSONATE_FAIL],
    promise: (client) =>
      client
        .req({
          pathname: "/web/auth/impersonate",
          method: "get",
          query: {
            accountName,
          },
        })
        //easiest to reload the page. this refreshes the redux state to
        //avoid using a stale redux state
        .then(() => {
          location.replace("/" + accountName);
        }),
  };
}
export function undoImpersonate(): BeforeDispatch<UNDO_IMPERSONATE> {
  return {
    types: [Actions.UNDO_IMPERSONATE, Actions.UNDO_IMPERSONATE_SUCCESS, Actions.UNDO_IMPERSONATE_FAIL],
    promise: (client) =>
      client
        .req({
          pathname: "/web/auth/impersonate",
          method: "get",
        })
        //easiest to reload the page. this refreshes the redux state to
        //avoid using a stale redux state
        .then(() => {
          location.reload();
        }),
  };
}

export function register(
  email: string,
  password: string,
  accountName: string,
  legalConsent?: boolean,
  returnTo?: string
): BeforeDispatch<REGISTER> {
  return {
    types: [Actions.REGISTER, Actions.REGISTER_SUCCESS, Actions.REGISTER_FAIL],
    promise: (client) =>
      client
        .req({
          pathname: "/web/auth/local/register",
          method: "post",
          body: {
            email,
            password,
            accountName,
            legalConsent,
          },
        })
        .then((result) => {
          const destination = returnTo || "/" + result.body.accountName;
          window.location.replace(destination);
        }),
  };
}
export function resendPassword(email: string): BeforeDispatch<RESEND_PASSWORD> {
  return {
    types: [Actions.RESEND_PASSWORD, Actions.RESEND_PASSWORD_SUCCESS, Actions.RESEND_PASSWORD_FAIL],
    promise: (client) =>
      client
        .req({
          pathname: "/web/auth/local/resend",
          method: "post",
          body: {
            email,
          },
        })
        .then(() => {}),
  };
}
export function resetPassword(
  verification: { token?: string; currentPassword?: string },
  password: string,
  forAccount?: Account
): BeforeDispatch<RESET_PASSWORD> {
  const data: {
    password: string;
    token?: string;
    currentPassword?: string;
    accountName?: string;
  } = {
    password,
  };
  if (verification.token) {
    data.token = verification.token;
  } else {
    data.currentPassword = verification.currentPassword;
    data.accountName = forAccount?.accountName;
  }
  return {
    types: [Actions.RESET_PASSWORD, Actions.RESET_PASSWORD_SUCCESS, Actions.RESET_PASSWORD_FAIL],
    promise: (client) =>
      client
        .req({
          pathname: "/web/auth/local/reset",
          method: "post",
          body: data,
        })
        .then(() => {
          if (__CLIENT__ && data.token) {
            window.location.replace("/me");
          }
          return;
        }),
  };
}
export function checkResetTokenValidity(token: string): BeforeDispatch<CHECK_TOKEN_VALIDITY> {
  return {
    types: [Actions.CHECK_TOKEN_VALIDITY, Actions.CHECK_TOKEN_VALIDITY_SUCCESS, Actions.CHECK_TOKEN_VALIDITY_FAIL],
    promise: (client) =>
      client
        .req({
          pathname: "/web/auth/local/reset/" + token,
          method: "get",
        })
        .then(() => {}),
  };
}

export function logout() {
  window.location.assign("/web/auth/logout");
}

export function getLoggedInUser(state: GlobalState): User | undefined {
  if (!state.auth.loggedInUser) return undefined;
  return getAccount(state, state.auth.loggedInUser, true) as User;
}

export const useAuthenticatedUser = () => {
  const accountCollection = useSelector((state: GlobalState) => state.accountCollection);
  const authenticatedUserId = useSelector((state: GlobalState) => state.auth.loggedInUser);
  if (!authenticatedUserId) return undefined;
  return getAccountFromAccountCollection(accountCollection, authenticatedUserId, true) as User;
};
