import { TextField } from "@mui/material";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { factories } from "@triplydb/data-factory";
import { getPrefixAndLabel } from "../../../components/Prefixed";
import { useDatasetPrefixes } from "../../../helpers/hooks/useDatasetPrefixes";
import { ResourceData } from "./Types";

const factory = factories.compliant;

const LiteralField: React.FC<
  Pick<ControllerProps<ResourceData, `properties.${number}.value`>, "name" | "control"> & {
    datatype: string;
    required?: boolean;
  }
> = ({ name, control, datatype, required }) => {
  const prefixes = useDatasetPrefixes();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) => {
          if (!value?.id?.trim()) return "A value is required.";
          try {
            if (value?.datatype) {
              const str = value.id.trim();
              factory.literal(str, factory.namedNode(value.datatype!));
            }
          } catch (e: any) {
            const { prefix, label } = getPrefixAndLabel(prefixes, value!.datatype!);
            console.error(e);
            return `Not a valid ${prefix ? `${prefix}:${label}` : label}.`;
          }
        },
      }}
      defaultValue={null}
      render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
        <TextField
          {...rest}
          onChange={(e) => {
            onChange({ id: e.target.value, datatype: datatype });
          }}
          required={required}
          value={value?.id || ""}
          error={!!error}
          helperText={error?.message}
          multiline
        />
      )}
    />
  );
};

export default LiteralField;
