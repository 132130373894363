import * as React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "#reducers/index.ts";
import { ConstructUrlToApi, getConstructUrlToApi } from "#staticConfig.ts";

export default function useConstructUrlToApi(): ConstructUrlToApi {
  const apiUrlInfo = useSelector((state: GlobalState) => state.config.staticConfig?.apiUrl);
  const consoleUrlInfo = useSelector((state: GlobalState) => state.config.staticConfig?.consoleUrl);
  const constructUrlToApi = React.useCallback<ConstructUrlToApi>(
    (opts) => {
      if (!apiUrlInfo || !consoleUrlInfo) return ""; //Should never happen
      return getConstructUrlToApi({
        apiUrlInfo: apiUrlInfo,
        consoleUrlInfo: consoleUrlInfo,
      })(opts);
    },
    [apiUrlInfo, consoleUrlInfo]
  );
  return constructUrlToApi;
}
