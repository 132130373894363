import { syntaxTree } from "@codemirror/language";
import { EditorState, Facet, StateField } from "@codemirror/state";
import Debug from "debug";
import { isEmpty, isEqual } from "lodash-es";
import {
  AS_KEY,
  ConstructQuery,
  ConstructTemplate,
  DISTINCT_KEY,
  SelectClause,
  SelectQuery,
  SubSelect,
  Var,
} from "../grammar/sparqlParser.terms.ts";
import { updateOnDocAndParserChanges } from "./utils.ts";

const debug = Debug("triply:sparql-editor:fields:vars");

interface BaseQueryScope {
  boundVariables: { [variable: string]: BoundVar };
  from: number;
  to: number;
  projectedVariables: { [variable: string]: Node };
  subScopes?: QueryScope[];
}

interface Node {
  from: number;
  to: number;
}

interface BoundVar {
  firstOccurrence: Node;
  bound: boolean;
}

interface SelectQueryScope extends BaseQueryScope {
  type: "select";
}
interface ConstructQueryScope extends BaseQueryScope {
  type: "construct";
  templateEndsAt: number;
}

export type QueryScope = SelectQueryScope | ConstructQueryScope;

export const QueryScope = StateField.define<QueryScope | {}>({
  create: parseQuery,
  update: (value, transaction) => {
    if (updateOnDocAndParserChanges(transaction)) {
      return parseQuery(transaction.state);
    }
    return value;
  },
  compare: isEqual,
});

function parseQuery(state: EditorState): QueryScope | {} {
  /** Stack contains all the clauses that are not the current one */
  const queryScopeStack: QueryScope[] = [];
  let currentQuery: QueryScope | undefined = undefined;

  function closeScope() {
    if (!currentQuery) return;
    // If not `select *` or `construct where`
    if (!isEmpty(currentQuery.projectedVariables)) {
      const childQuery = currentQuery;
      currentQuery = queryScopeStack.pop();
      if (currentQuery) {
        if (currentQuery.subScopes === undefined) currentQuery.subScopes = [];
        currentQuery.subScopes.push(childQuery);
      }
    } else {
      const innerVarsInWhere = currentQuery.boundVariables;
      currentQuery = queryScopeStack.pop();
      if (currentQuery) {
        for (const [variableName, node] of Object.entries(innerVarsInWhere)) {
          addVarToCurrentClause(variableName, node.firstOccurrence, node.bound);
        }
      }
    }
  }

  function addVarToCurrentClause(variableName: string, node: Node, bound?: boolean) {
    if (!currentQuery || variableName === "") return;
    if (variableName in currentQuery.boundVariables) {
      currentQuery.boundVariables[variableName].bound = true;
    } else if (variableName in currentQuery.projectedVariables) {
      currentQuery.boundVariables[variableName] = {
        firstOccurrence: {
          from: node.from,
          to: node.to,
        },
        bound: true,
      };
    } else {
      currentQuery.boundVariables[variableName] = {
        firstOccurrence: {
          from: node.from,
          to: node.to,
        },
        bound: bound ?? false,
      };
    }
  }

  const tree = syntaxTree(state);
  const cursor = tree.cursor();
  do {
    if (cursor.type.isError || cursor.type.isSkipped) {
      continue;
    }
    if (cursor.type.id === SelectQuery || cursor.type.id === SubSelect) {
      // New (sub)-select query
      if (!!currentQuery) {
        // Add items in the select clause as variables in the where clause.
        const selectQueryNode = cursor.node.getChild(SelectClause);
        const varsNodesInSelect = selectQueryNode?.getChildren(Var) || [];
        const isDistinct = !!selectQueryNode?.getChild(DISTINCT_KEY);

        for (const varNodeInSelect of varsNodesInSelect) {
          const varName = state.sliceDoc(varNodeInSelect.from + 1, varNodeInSelect.to);
          // When a select is distinct all variables are used to make the entries distinct. So they are added "again" to make sure they are not marked
          addVarToCurrentClause(varName, varNodeInSelect, isDistinct);
        }
        // Put the current clause on the stack
        queryScopeStack.push(currentQuery);
      }
      currentQuery = {
        type: "select",
        boundVariables: {},
        from: cursor.from,
        to: cursor.to,
        projectedVariables: {},
      };
    }

    if (cursor.type.id === ConstructQuery) {
      // Construct queries always are at the top of the Query
      const templateNode = cursor.node.getChild(ConstructTemplate); // If this is null, then we're in a `construct where` query
      currentQuery = {
        type: "construct",
        boundVariables: {},
        to: cursor.to,
        from: cursor.from,
        projectedVariables: {},
        templateEndsAt: templateNode?.to || cursor.from,
      };
    }
    // We introduced INCOMPLETE VAR as a token, we shouldn't highlight that one
    if (cursor.type.id === Var && currentQuery && cursor.to - cursor.from > 1) {
      const varName = state.sliceDoc(cursor.from + 1, cursor.to);
      if (cursor.matchContext(["SelectClause"])) {
        // Select clause
        currentQuery.projectedVariables[varName] = { from: cursor.node.from, to: cursor.node.to };
        // When a variable is bound in the select (e.g. `("blabla" AS ?var)`), we should also mark it as used
        if (cursor.node.prevSibling?.type.id === AS_KEY) {
          addVarToCurrentClause(varName, cursor.node);
          // Vars in expressions won't have the SelectClause as a direct parent.
        }
      } else if (currentQuery.type === "construct" && cursor.from < currentQuery.templateEndsAt) {
        // Can't use context, as we're dealing with recursive Triple patterns
        // Construct template
        currentQuery.projectedVariables[varName] = cursor.node;
      } else {
        // Where clause
        addVarToCurrentClause(varName, cursor.node);
      }
    }
    // Report the scope when we're past the last scope
    if (currentQuery && cursor.from > currentQuery.to) {
      closeScope();
    }
  } while (cursor.next());
  // Report the outermost scope
  if (queryScopeStack.length > 1) debug("Unexpected length left at end of debugging");
  // closeScope();
  return currentQuery || {};
}

export const DefinedVariables = Facet.define<any, string[]>({
  combine: (values) => values.at(-1) || [],
});

export const DefinedVariablesExtension = DefinedVariables.from<QueryScope>(QueryScope, (scope) => {
  if ("type" in scope) {
    const variables = new Set<string>();
    function getVariablesFromScope(scope: QueryScope) {
      for (const variable of [...Object.keys(scope.boundVariables), ...Object.keys(scope.projectedVariables)]) {
        variables.add(variable);
      }
      for (const subScope of scope.subScopes || []) {
        getVariablesFromScope(subScope);
      }
    }
    getVariablesFromScope(scope);
    return Array.from(variables);
  }
  return [];
});

function isValidScope(scope: QueryScope | {}): asserts scope is QueryScope {
  if (isEmpty(scope)) {
    throw new Error("Empty scope");
  }
}
export function getScopeAndParentForPos(
  scope: QueryScope | {},
  pos: number
): [QueryScope | undefined, QueryScope | undefined] {
  try {
    isValidScope(scope);
    let newScope = scope;
    let parent: QueryScope | undefined = undefined;
    while (newScope?.subScopes) {
      let changedScope = false;
      for (const innerScope of newScope.subScopes) {
        if (innerScope.from < pos && pos < innerScope.to) {
          parent = newScope;
          newScope = innerScope;
          changedScope = true;
        }
      }
      if (!changedScope) break;
    }
    return [newScope, parent];
  } catch {
    return [undefined, undefined];
  }
}
