import { produce } from "immer";
import { omit } from "lodash-es";
import { Models } from "@triply/utils";
import { PinnedQuery, PinnedStory } from "@triply/utils/Models.js";
import { Action, Actions, GlobalState } from "#reducers/index.ts";
import { getDatasetFromCollections } from "./datasetCollection.ts";

export type Account = User | Org;
export type User = Omit<Models.User, "pinnedItems" | "orgs"> & { orgs?: Org[] };
export type Org = Omit<Models.Org, "pinnedItems" | "members"> & { members?: Member[] };
export type Member = Omit<Models.OrgMember, "user"> & { user: User };

type ReduxPinnedItems = Array<PinnedStory | PinnedQuery | { type: "Dataset"; item: string }>;
type ReduxUser = Pick<
  Models.User,
  | "accountName"
  | "authMethod"
  | "avatarUrl"
  | "createdAt"
  | "description"
  | "disabled"
  | "email"
  | "expiresAt"
  | "impersonated"
  | "name"
  | "role"
  | "type"
  | "uid"
  | "updatedAt"
  | "verified"
  | "usageInfo"
  | "legalConsent"
  | "mfaEnabled"
> & { orgs?: string[]; pinnedItems?: ReduxPinnedItems; verbose: boolean; countersOutdated?: boolean };

type ReduxOrg = Pick<
  Models.Org,
  "accountName" | "avatarUrl" | "createdAt" | "description" | "email" | "name" | "type" | "uid" | "updatedAt"
> & { members?: ReduxOrgMember[]; pinnedItems?: ReduxPinnedItems; verbose: boolean; countersOutdated?: boolean };

type ReduxOrgMember = Pick<Models.OrgMember, "role" | "createdAt" | "updatedAt"> & { user: string };

export type State = { [id: string]: ReduxUser | ReduxOrg | undefined };

export const reducer = produce((draftState: State, action: Action) => {
  switch (action.type) {
    case Actions.LOAD_LOGGED_IN_USER_SUCCESS:
      if (action.result) {
        addUser(draftState, action.result, action.verbose);
      }
      return;

    case Actions.UPLOAD_ACCOUNT_AVATAR_SUCCESS:
    case Actions.GET_CURRENT_ACCOUNT_SUCCESS:
    case Actions.UPDATE_PROFILE_SUCCESS:
    case Actions.ADD_EXTRA_DETAILS_SUCCESS:
    case Actions.ADMIN_REGISTER_SUCCESS:
      if (action.result.type === "user") {
        addUser(draftState, action.result, action.verbose);
      } else {
        addOrg(draftState, action.result, action.verbose);
      }
      return;

    case Actions.ENABLE_AUTHENTICATOR_APP_SUCCESS:
      const user = draftState[action.accountId];
      if (user?.type === "user") {
        user.mfaEnabled = action.result.mfaEnabled;
      }
      return;

    case Actions.GET_ACCOUNTS_SUCCESS:
      for (const account of action.result) {
        if (account.type === "user") {
          addUser(draftState, account, false);
        } else {
          addOrg(draftState, account, false);
        }
      }
      return;

    case Actions.DELETE_ACCOUNT_SUCCESS:
      delete draftState[action.account.uid];
      if (action.account.type === "org") {
        for (const id in draftState) {
          const account = draftState[id];
          if (account && account.type === "user" && account.verbose && account.orgs) {
            account.orgs = account.orgs.filter((o) => o !== action.account.uid);
          }
        }
      }
      return;

    case Actions.CREATE_ORG_SUCCESS:
      const forUser = draftState[action.user.uid] as ReduxUser;
      if (forUser && forUser.orgs) forUser.orgs.push(action.result.uid);
      addOrg(draftState, action.result, action.verbose);
      return;

    case Actions.DELETE_MEMBER_SUCCESS:
      const userDeletedFromOrg = draftState[action.user.uid] as ReduxUser;
      if (userDeletedFromOrg && userDeletedFromOrg.orgs)
        userDeletedFromOrg.orgs = userDeletedFromOrg.orgs.filter((o) => o !== action.org.uid);
      const deletedFromOrg = draftState[action.org.uid] as ReduxOrg;
      if (deletedFromOrg && deletedFromOrg.members)
        deletedFromOrg.members = deletedFromOrg.members.filter((m) => m.user !== action.user.uid);
      return;

    case Actions.ADD_MEMBER_SUCCESS:
      const addedToOrg = draftState[action.org.uid] as ReduxOrg;
      if (addedToOrg && addedToOrg.members) addedToOrg.members.push({ ...action.result, user: action.result.user.uid });
      const addedMember = draftState[action.result.user.uid] as ReduxUser;
      if (addedMember && addedMember.orgs) addedMember.orgs.push(action.org.uid);
      addUser(draftState, action.result.user, false);
      return;

    case Actions.UPDATE_MEMBER_SUCCESS:
      const updatedInOrg = draftState[action.org.uid] as ReduxOrg;
      if (updatedInOrg && updatedInOrg.members) {
        const updatedMemberIndex = updatedInOrg.members.findIndex((m) => m.user === action.result.user.uid);
        if (updatedMemberIndex >= 0)
          updatedInOrg.members[updatedMemberIndex] = { ...action.result, user: action.result.user.uid };
      }
      addUser(draftState, action.result.user, false);
      return;
    case Actions.SOCKET_EVENT.jobFinished:
      if (action.data.dataset.owner.type === "user") {
        addUser(draftState, action.data.dataset.owner, false);
      } else {
        addOrg(draftState, action.data.dataset.owner, false);
      }
      return;
    case Actions.UPLOAD_DATASET_AVATAR_SUCCESS:
    case Actions.GET_CURRENT_DATASET_SUCCESS:
    case Actions.REFRESH_CURRENT_DATASET_SUCCESS:
    case Actions.UPDATE_DATASET_SUCCESS:
      if (action.result.owner.type === "user") {
        addUser(draftState, action.result.owner, false);
      } else {
        addOrg(draftState, action.result.owner, false);
      }
      return;

    case Actions.GET_DATASETS_SUCCESS:
    case Actions.ADMIN_GET_DATASETS_WITH_RUNNING_JOBS_SUCCESS:
      for (const dataset of action.result) {
        if (dataset.owner.type === "user") {
          addUser(draftState, dataset.owner, false);
        } else {
          addOrg(draftState, dataset.owner, false);
        }
      }
      return;

    case Actions.CREATE_SERVICE_SUCCESS:
      // Update accountUsage info
      const createServiceUser = draftState[action.accountUid];
      if (createServiceUser && createServiceUser.type === "user" && createServiceUser.usageInfo) {
        createServiceUser.usageInfo.services.push(`/${createServiceUser.accountName}/${action.datasetName}/services`);
        draftState[action.accountUid] = createServiceUser;
      }
      return;

    case Actions.DELETE_SERVICE_SUCCESS:
      // Update accountUsage info
      const deleteServiceUser = draftState[action.accountUid];
      if (deleteServiceUser && deleteServiceUser.type === "user" && deleteServiceUser.usageInfo) {
        const serviceToRemoveIdx = deleteServiceUser.usageInfo.services.indexOf(
          `/${deleteServiceUser.accountName}/${action.datasetName}/services`
        );
        deleteServiceUser.usageInfo.services.splice(serviceToRemoveIdx, 1);
        draftState[action.accountUid] = deleteServiceUser;
      }
      return;
    case Actions.DELETE_DATASET_SUCCESS:
      // Update accountUsage info
      const deleteDatasetUser = draftState[action.dataset.owner.uid];
      if (deleteDatasetUser && deleteDatasetUser.type === "user" && deleteDatasetUser.usageInfo) {
        deleteDatasetUser.usageInfo.services = deleteDatasetUser.usageInfo.services.filter(
          (serviceLink) => serviceLink !== `/${deleteDatasetUser.accountName}/${action.dataset.name}/services`
        );
        draftState[action.dataset.owner.uid] = deleteDatasetUser;
      }

      if (deleteDatasetUser) deleteDatasetUser.countersOutdated = true;
      return;
    case Actions.CHOWN_DATASET_SUCCESS:
      // Update accountUsage info
      const chownAccountFrom = draftState[action.fromUid];
      if (chownAccountFrom && chownAccountFrom.type === "user" && chownAccountFrom.usageInfo) {
        chownAccountFrom.usageInfo.services = chownAccountFrom.usageInfo.services.filter(
          (serviceLink) => serviceLink !== `/${chownAccountFrom.accountName}/${action.datasetName}/services`
        );
        draftState[action.fromUid] = chownAccountFrom;
      }
      const chownAccountTo = draftState[action.result.owner.uid];
      if (chownAccountTo && chownAccountTo.type === "user" && chownAccountTo.usageInfo) {
        for (let i = 0; i < action.result.serviceCount; i++) {
          chownAccountTo.usageInfo.services.push(`/${chownAccountTo.accountName}/${action.datasetName}/services`);
        }
        draftState[chownAccountTo.uid] = chownAccountTo;
      }

      if (chownAccountFrom) chownAccountFrom.countersOutdated = true;
      if (chownAccountTo) chownAccountTo.countersOutdated = true;
      return;

    case Actions.ADD_DATASET_SUCCESS:
    case Actions.CREATE_STORY_SUCCESS:
    case Actions.CREATE_QUERY_SUCCESS:
    case Actions.COPY_DATASET_SUCCESS:
    case Actions.COPY_STORY_SUCCESS:
      const owner = draftState[action.result.owner.uid];
      if (owner) owner.countersOutdated = true;
      return;

    case Actions.CHOWN_STORY_SUCCESS:
      const fromAccount = draftState[action.fromUid];
      if (fromAccount) fromAccount.countersOutdated = true;
      const toAccount = draftState[action.result.owner.uid];
      if (toAccount) toAccount.countersOutdated = true;
      return;

    case Actions.DELETE_QUERY_SUCCESS:
      const deleteQueryAccount = draftState[action.query.owner.uid];
      if (deleteQueryAccount) deleteQueryAccount.countersOutdated = true;
      return;

    case Actions.DELETE_STORY_SUCCESS:
      const deleteStoryAccount = draftState[action.story.owner.uid];
      if (deleteStoryAccount) deleteStoryAccount.countersOutdated = true;
      return;
  }
}, <State>{}) as any;

function pinnedItemToRedux(pinnedItem: Models.PinnedItem) {
  if (pinnedItem.type === "Dataset") {
    return { type: "Dataset" as const, item: pinnedItem.item.id };
  } else {
    return pinnedItem;
  }
}

function addUser(draftState: State, user: Models.User, verbose: boolean) {
  if (!verbose) {
    if (!draftState[user.uid] || !draftState[user.uid]?.verbose) {
      draftState[user.uid] = {
        ...draftState[user.uid],
        ...user,
        orgs: undefined,
        pinnedItems: undefined,
        verbose: false,
      };
    }
    return;
  }
  if ((draftState[user.uid] as ReduxUser | undefined)?.impersonated) {
    // Don't change the impersonated field
    user.impersonated = true;
  }
  draftState[user.uid] = {
    ...user,
    orgs: user.orgs ? user.orgs.map((o) => o.uid) : [],
    pinnedItems: user.pinnedItems ? user.pinnedItems.map(pinnedItemToRedux) : [],
    verbose: verbose,
  };
  if (user.orgs) {
    for (const org of user.orgs) {
      if (!draftState[org.uid] || !draftState[org.uid]?.verbose)
        draftState[org.uid] = { ...org, members: undefined, pinnedItems: undefined, verbose: false };
    }
  }
}

function addOrg(draftState: State, org: Models.Org, verbose: boolean) {
  if (!verbose) {
    if (!draftState[org.uid] || !draftState[org.uid]?.verbose)
      draftState[org.uid] = { ...org, members: undefined, pinnedItems: undefined, verbose: false };
    return;
  }
  draftState[org.uid] = {
    ...org,
    members: org.members
      ? org.members.map((m) => ({
          ...m,
          user: m.user.uid,
        }))
      : [],
    pinnedItems: org.pinnedItems ? org.pinnedItems.map(pinnedItemToRedux) : [],
    verbose: verbose,
  };
  if (org.members) {
    for (const memberObject of org.members) {
      if (!draftState[memberObject.user.uid] || !draftState[memberObject.user.uid]?.verbose)
        draftState[memberObject.user.uid] = {
          ...memberObject.user,
          orgs: undefined,
          pinnedItems: undefined,
          verbose: false,
        };
    }
  }
}

export function getAccount(state: GlobalState, id?: string, verbose = false): Account | undefined {
  return getAccountFromAccountCollection(state.accountCollection, id, verbose);
}
export function getAccountFromAccountCollection(
  accountCollection: State,
  id?: string,
  verbose = false
): Account | undefined {
  if (!id) return undefined;
  const account = accountCollection[id];
  if (!account) return undefined;

  if (account.type === "user") {
    // user
    if (verbose && account.orgs) {
      return {
        ...omit(account, "pinnedDatasets"),
        orgs: account.orgs.map((orgId) => getAccountFromAccountCollection(accountCollection, orgId) as Org),
      };
    }
    const { orgs, pinnedItems, ...rest } = account;
    return { ...rest };
  } else {
    // org
    if (verbose && account.members) {
      return {
        ...omit(account, "pinnedDatasets"),
        members: account.members.map((memberObj) => ({
          ...memberObj,
          user: getAccountFromAccountCollection(accountCollection, memberObj.user) as User,
        })),
      };
    }
    const { members, ...rest } = account;
    return { ...rest };
  }
}

export function getPinnedItemsForAccountFromCollections(
  datasetCollection: GlobalState["datasetCollection"],
  accountCollection: State,
  accountId: string
): Models.PinnedItem[] {
  const pinnedItems = accountCollection[accountId]?.pinnedItems;

  if (!pinnedItems) return [];
  return pinnedItems.map((pinnedItem) => {
    if (pinnedItem.type === "Dataset") {
      const dataset = getDatasetFromCollections(datasetCollection, accountCollection, pinnedItem.item);
      return { type: "Dataset", item: dataset } as Models.PinnedDataset;
    } else {
      return pinnedItem;
    }
  });
}
