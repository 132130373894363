import React from "react";
import { useSelector } from "react-redux";
import { ErrorPage } from "#components/index.ts";
import { TabLink } from "#containers/ConsoleTabs/index.tsx";
import { ConsoleTabs, IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { GlobalState } from "#reducers/index.ts";

const AdminTabs: React.FC<IComponentProps> = (props) => {
  const acl = useAcl();
  const clientConfig = useSelector((state: GlobalState) => state.config.clientConfig);

  const tabLinks: TabLink[] = [];
  if (acl.check({ action: "readInstanceLimits" }).granted) {
    tabLinks.push({ pathname: "", name: "Overview" });
  }
  if (acl.check({ action: "editInstanceMetadata" }).granted) {
    tabLinks.push({ pathname: "/settings", name: "Settings" });
  }
  if (acl.check({ action: "manageUsers" }).granted) {
    tabLinks.push({ pathname: "/users", name: "Users" });
  }
  if (acl.check({ action: "manageUsers" }).granted) {
    tabLinks.push({ pathname: "/organizations", name: "Organizations" });
  }
  if (acl.check({ action: "manageDatasets" }).granted) {
    tabLinks.push({ pathname: "/datasets", name: "Datasets" });
  }
  if (acl.check({ action: "manageQueries" }).granted) {
    tabLinks.push({ pathname: "/queries", name: "Queries" });
  }
  if (acl.check({ action: "listServicesOfInstance" }).granted) {
    tabLinks.push({ pathname: "/services", name: "Services" });
  }
  if (acl.check({ action: "manageRedirects" }).granted) {
    tabLinks.push({ pathname: "/redirects", name: "Redirects" });
  }
  if (acl.check({ action: "readJobsOnInstance" }).granted || acl.check({ action: "manageQueryJobs" }).granted) {
    tabLinks.push({ pathname: "/jobs", name: "Jobs" });
  }
  if (acl.check({ action: "manageInstanceCache" }).granted) {
    tabLinks.push({ pathname: "/cache", name: "Caches" });
  }
  if (acl.check({ action: "manageBackgroundTasks" }).granted) {
    tabLinks.push({ pathname: "/tasks", name: "Background tasks" });
  }
  if (acl.check({ action: "manageSpeedy" }).granted) {
    tabLinks.push({ pathname: "/speedy", name: "Speedy" });
  }

  if (!tabLinks.length) return <ErrorPage statusCode={401} />;

  const limitStatus = clientConfig?.limitStatus;

  return (
    <ConsoleTabs
      {...props}
      title="Admin settings"
      alerts={limitStatus && { Overview: limitStatus }}
      tabLinks={tabLinks}
    />
  );
};

export default AdminTabs;
