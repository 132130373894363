//
import getClassName from "classnames";
import * as React from "react";
import styles from "./style.scss";

/**
IMPORTANT:
Use this SVG for images that are either large, or when they are infrequently used in the website.
SVGs that are small and used frequently, used be used via a sprite, in orde to improve caching.
**/
export interface ISvgProps {
  className?: string;
  innerClassName?: string;
  // style?: React.CSSProperties,
  src: string;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  alt: string;
}

const Svg: React.FC<ISvgProps> = ({ className, src, style, imgStyle, innerClassName, alt }) => {
  const classNames: { [className: string]: boolean } = {
    [styles.svgStatic]: true,
    "svg-static": true,
  };
  return (
    <div className={getClassName(className, classNames)} style={style ? style : {}}>
      <img src={src} style={imgStyle ? imgStyle : {}} className={innerClassName} alt={alt} />
    </div>
  );
};

export default Svg;
