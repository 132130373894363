import * as React from "react";

export default function useHover<T extends HTMLElement>(ref: React.RefObject<T>): boolean {
  const [value, setValue] = React.useState(false);

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseOver = React.useCallback(() => setValue(true), []);
  const handleMouseOut = React.useCallback(() => setValue(false), []);

  React.useEffect(
    () => {
      const node = ref.current;

      if (node) {
        node.addEventListener("mouseover", handleMouseOver);

        node.addEventListener("mouseout", handleMouseOut);

        node.addEventListener("touchmove", handleMouseOver);

        node.addEventListener("touchend", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);

          node.removeEventListener("mouseout", handleMouseOut);

          node.removeEventListener("touchmove", handleMouseOver);

          node.removeEventListener("touchend", handleMouseOut);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  );

  return value;
}
