import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { asyncConnect } from "redux-connect";
import * as Forms from "#components/Forms/index.ts";
import {
  Button,
  DatasetMetadata,
  Dialog,
  ErrorPage,
  FlexContainer,
  SinkList,
  WebHookListItem,
} from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { AclContext } from "#context.ts";
import { Acl } from "#helpers/Acl.ts";
import { Account } from "#reducers/accountCollection.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import { Dataset, getCurrentDataset } from "#reducers/datasetManagement.ts";
import { getHookRecords } from "#reducers/hookRecords.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";
import { getHooks, Hook, newHook, removeHook, updateHook } from "#reducers/webhooks.ts";

export namespace DatasetHooks {
  export interface OwnProps extends IComponentProps {}

  export interface DispatchProps {
    updateHook: DispatchedFn<typeof updateHook>;
    removeHook: DispatchedFn<typeof removeHook>;
    newHook: DispatchedFn<typeof newHook>;
    getRecords: DispatchedFn<typeof getHookRecords>;
    pushState: typeof connectedReactRouter.push;
    goBack: typeof connectedReactRouter.goBack;
  }

  export interface PropsFromState {
    currentDs?: Dataset;
    currentAccount?: Account;
    hooks?: Hook[];
  }

  export type Props = OwnProps & DispatchProps & PropsFromState;

  export interface State {}
}

@asyncConnect<GlobalState>([
  {
    promise: async ({ store: { dispatch, getState } }) => {
      const currentAccount = getCurrentAccount(getState());
      const currentDs = getCurrentDataset(getState());
      if (currentAccount && currentDs) return dispatch<any>(getHooks(currentAccount, currentDs));
    },
  },
])
class DatasetHooks extends React.PureComponent<DatasetHooks.Props, DatasetHooks.State> {
  static contextType = AclContext;
  context!: Acl;
  openForm = () => {
    this.props.pushState({ state: { hookAddModalShown: true } });
  };

  render() {
    const { currentDs, currentAccount, hooks, removeHook, updateHook, getRecords, location, goBack } = this.props;

    if (
      !currentAccount ||
      !currentDs ||
      !this.context.check({
        action: "editDatasetMetadata",
        context: {
          roleInOwnerAccount: this.context.getRoleInAccount(currentAccount),
          accessLevel: currentDs.accessLevel,
          newAccessLevel: undefined,
        },
      }).granted
    )
      return <ErrorPage statusCode={401} />;

    return (
      <FlexContainer>
        <DatasetMetadata
          currentPath={this.props.location.pathname}
          currentAccount={currentAccount}
          currentDs={currentDs}
          title="Dataset Webhooks"
        />
        {hooks && hooks.length > 0 && (
          <Button color="secondary" elevation className="ml-3 mt-5" onClick={this.openForm}>
            Create webhook
          </Button>
        )}
        {hooks && hooks.length === 0 && (
          <div className="whiteSink">
            <h3>Create webhook</h3>
            <Forms.WebHook
              form="new_hook_form"
              initialValues={{ active: true, payloadFormat: "JSON" }}
              onSubmit={(values: Forms.WebHook.FormData) => {
                this.props.newHook(currentAccount, currentDs, values).then(
                  () => {},
                  () => {
                    // ok, user gets err message in alert
                  }
                );
              }}
            />
          </div>
        )}
        <SinkList noContentMsg=" ">
          {!!hooks &&
            hooks
              .slice(0)
              .reverse()
              .map((hook) => (
                <WebHookListItem
                  key={hook.id}
                  hook={hook}
                  currentAccount={currentAccount}
                  currentDs={currentDs}
                  removeHook={removeHook}
                  updateHook={updateHook}
                  getRecords={getRecords}
                  writeAccess={
                    this.context.check({
                      action: "editDatasetMetadata",
                      context: {
                        roleInOwnerAccount: this.context.getRoleInAccount(currentAccount),
                        accessLevel: currentDs.accessLevel,
                        newAccessLevel: undefined,
                      },
                    }).granted
                  }
                />
              ))}
        </SinkList>
        <Dialog
          open={!!location.state && !!location.state.hookAddModalShown}
          onClose={goBack}
          title="Create webhook"
          maxWidth="md"
          fullWidth
        >
          <Forms.WebHook
            className="m-5"
            form="new_hook_form"
            initialValues={{ active: true, payloadFormat: "JSON" }}
            onSubmit={(values: Forms.WebHook.FormData) => {
              this.props.newHook(currentAccount, currentDs, values).then(
                () => {
                  this.props.goBack();
                },
                () => {
                  // ok, user gets err message in alert
                }
              );
            }}
            cancelFunction={goBack}
          />
        </Dialog>
      </FlexContainer>
    );
  }
}

export default connect<
  DatasetHooks.PropsFromState,
  { [K in keyof DatasetHooks.DispatchProps]: any },
  DatasetHooks.OwnProps,
  GlobalState
>(
  (state) => {
    return {
      currentDs: getCurrentDataset(state),
      currentAccount: getCurrentAccount(state),
      hooks: state.datasetManagement.current ? state.hooks[state.datasetManagement.current] : undefined,
    };
  },
  {
    updateHook: updateHook,
    removeHook: removeHook,
    newHook: newHook,
    getRecords: getHookRecords,
    goBack: connectedReactRouter.goBack,
    pushState: connectedReactRouter.push,
  }
)(DatasetHooks);
