import { InputAdornment } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { validation } from "@triply/utils-private";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Alert, FontAwesomeIcon, FormField, MuiTextFieldRedux } from "#components/index.ts";

const emailValidator = validation.toStringValidator(validation.emailValidations);

namespace ResendPasswordForm {
  export interface FormData {
    email: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    // onSubmit: Function,
  }
}

const ResendPasswordForm = ReduxForm.reduxForm<ResendPasswordForm.FormData, ResendPasswordForm.Props>({
  form: "resendPassword",
  validate: memoizee(
    (formData: ResendPasswordForm.FormData) => {
      return {
        email: emailValidator(formData.email),
      };
    },
    { max: 10 }
  ),
})(
  class ResendPasswordForm extends React.PureComponent<
    ResendPasswordForm.Props & Partial<ReduxForm.InjectedFormProps<FormData>>,
    any
  > {
    render() {
      const { submitting, handleSubmit, submitSucceeded, error } = this.props;

      return (
        <div>
          {!submitSucceeded && (
            <form method="POST" onSubmit={handleSubmit}>
              <FormField label="Email address">
                <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                  name="email"
                  props={{
                    type: "email",
                    fullWidth: true,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon="envelope" />
                        </InputAdornment>
                      ),
                    },
                  }}
                  component={MuiTextFieldRedux}
                />
              </FormField>

              <Alert transparent message={error} />
              <div className="form-group mt-5">
                <LoadingButton type="submit" color="secondary" onClick={handleSubmit} loading={submitting}>
                  Reset password
                </LoadingButton>
              </div>
            </form>
          )}
          {submitSucceeded && (
            <Alert
              success
              message="We sent you an email. Use the link from this email to reset your password."
              transparent
            />
          )}
        </div>
      );
    }
  } as any
);
export default ResendPasswordForm;
