import { Alert } from "@mui/material";
import * as React from "react";
import { Prefix } from "@triply/utils";
import { getPrefixed } from "@triply/utils/prefixUtils.js";
import { Html, Markdown } from "#components/index.ts";
import renderMarkup from "#helpers/markup/index.ts";
import { isLinkToDomain } from "#helpers/markup/unifiedPlugins.ts";
import { Term } from "../../SparqlUtils";
import { SparqlVisualizationContext } from "../SparqlVisualizationContext";

const DATATYPE_HTML = "http://www.w3.org/1999/02/22-rdf-syntax-ns#HTML";
const DATATYPE_MARKDOWN = "https://triplydb.com/Triply/vocab/def/markdown";
const DATATYPE_MERMAID = "https://triplydb.com/Triply/vocab/def/mermaid";

function isMarkUp(datatype: string): boolean {
  return datatype === DATATYPE_MARKDOWN;
}

function isHtml(datatype: string): boolean {
  return datatype === DATATYPE_HTML;
}
function isMermaid(datatype: string): boolean {
  return datatype === DATATYPE_MERMAID;
}

export const SanitizedContent: React.FC<{ term: Term | undefined; className?: string }> = ({ term, className }) => {
  const { prefixes } = React.useContext(SparqlVisualizationContext);
  if (!term) return null;
  const value = term.value;
  const datatype = "datatype" in term ? term.datatype : undefined;
  if (term.type === "uri") {
    return <Markdown className={className}>{`[${getPrefixed(value, prefixes) || value}](${value})`}</Markdown>;
  } else if (datatype && isMarkUp(datatype)) {
    return <Markdown className={className}>{value}</Markdown>;
  } else if (datatype && isHtml(datatype)) {
    return <Html className={className}>{value}</Html>;
  } else if (datatype && isMermaid(datatype)) {
    return <Markdown className={className}>{`\`\`\`mermaid\n${value}\n\`\`\``}</Markdown>;
  } else {
    if ("xml:lang" in term) {
      return (
        <span className={className} lang={term["xml:lang"]}>
          {value}
        </span>
      );
    }
    return <span className={className}>{value}</span>;
  }
};

export function termToSanitizedHtml(term: Term | undefined, prefixes?: Prefix[]) {
  if (!term) return "";
  let value = term.value;
  const datatype = "datatype" in term ? term.datatype : undefined;
  let renderAs: "markdown" | "html" | "text" = "text";
  if (term.type === "uri") {
    renderAs = "markdown";
    value = `[${prefixes ? getPrefixed(value, prefixes) || value : value}](${value})`;
  } else if (datatype && isMarkUp(datatype)) {
    renderAs = "markdown";
  } else if (datatype && isHtml(datatype)) {
    renderAs = "html";
  } else if (datatype && isMermaid(datatype)) {
    renderAs = "markdown";
    value = "```mermaid\n" + value + "\n```";
  }
  if (renderAs !== "text") {
    return renderMarkup(value, {
      from: renderAs,
      to: "html",
      openLinkInCurrentWindow: (href: string) => isLinkToDomain(href, location.hostname),
      compact: false,
    });
  } else {
    // Use the browser to clean the text
    const element = document.createElement("div");
    element.innerText = value;
    const result = element.innerHTML;
    element.remove();
    return result;
  }
}

export const EmptyResults: React.FC<{}> = () => {
  const { customNoResultsMessage } = React.useContext(SparqlVisualizationContext);
  return (
    <Alert severity="info" role="status" className="noResultsMessage">
      <span>{customNoResultsMessage || "No results"}</span>
    </Alert>
  );
};
