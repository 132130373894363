import * as React from "react";
import { KeyboardShortcut, useDisplayHotkeys } from "#containers/Hotkeys/index.tsx";
import BaseSparqlEditor, { Props as EditorProps } from "./Editor.tsx";

const keyBindings: KeyboardShortcut[] = [
  { component: "SPARQL Editor", keyBinds: "Mod+shift+i", description: "Auto-format query" },
  { component: "SPARQL Editor", keyBinds: "Mod+Enter", description: "Run query" },
  { component: "SPARQL Editor", keyBinds: "Mod+Alt+up", description: "Add cursor above" },
  { component: "SPARQL Editor", keyBinds: "Mod+Alt+down", description: "Add cursor below" },
  { component: "SPARQL Editor", keyBinds: "Mod+d", description: "Select and add cursor to next occurrence" },
  { component: "SPARQL Editor", keyBinds: "Mod+g", description: "Go to line" },
  { component: "SPARQL Editor", keyBinds: "Mod+L", description: "Select line" },
  { component: "SPARQL Editor", keyBinds: "Mod+shift+L", description: "Select and add cursor to all occurrences" },
  { component: "SPARQL Editor", keyBinds: "Alt+Shift+i", description: "Add cursor to end of selected lines" },
  { component: "SPARQL Editor", keyBinds: "Mod+Shift+\\", description: "Move cursor to matching bracket" },
  { component: "SPARQL Editor", keyBinds: "Mod+[", description: "Add indent" },
  { component: "SPARQL Editor", keyBinds: "Mod+]", description: "Remove indent" },
  { component: "SPARQL Editor", keyBinds: "Mod+Shift+k", description: "Delete current line" },
  { component: "SPARQL Editor", keyBinds: "Mod+/", description: "Comment/Uncomment line" },
  { component: "SPARQL Editor", keyBinds: "Mod+u", description: "Undo selection" },
  { component: "SPARQL Editor", keyBinds: "Mod+z", description: "Undo" },
  { component: "SPARQL Editor", keyBinds: "Mod+shift+z, Mod+y", description: "Redo" },
  { component: "SPARQL Editor", keyBinds: "F8", description: "Move to next diagnostic" },
];

/**
 * Split this apart so the tests are run without needing more information
 */
const SparqlEditor: React.FC<EditorProps> = (props) => {
  useDisplayHotkeys(keyBindings);
  return <BaseSparqlEditor {...props} />;
};

export default SparqlEditor;
