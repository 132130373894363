import { push } from "connected-react-router";
import { closeSnackbar, enqueueSnackbar, SnackbarAction } from "notistack";
import * as React from "react";
import { useSelector } from "react-redux";
import LinkButton from "#components/LinkButton/index.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { GlobalState } from "#reducers/index.ts";
import { removeNotification } from "#reducers/notifications.ts";
import styles from "./style.scss";

const Notifications: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const [shownNotifications, setShownNotifications] = React.useState<string[]>([]);
  const notifications = useSelector((state: GlobalState) => {
    return state.notifications;
  });
  React.useEffect(() => {
    const activeNotifications = shownNotifications.filter((key) =>
      notifications.find((notification) => notification.key === key)
    );
    let changed = activeNotifications.length !== shownNotifications.length;
    for (const notification of notifications) {
      // Don't show notifications which are already shown
      if (notification.key && activeNotifications.indexOf(notification.key.toString()) >= 0) return;
      const { message, ...options } = notification;
      enqueueSnackbar(<div className={styles.notification}>{message}</div>, {
        ...options,
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
          if (notification.key) dispatch(removeNotification(notification.key.toString()));
        },
        //When using an action and the snackbar ui closes, the snackbar item doesn't unmount itself on its own and lingeres around.
        //CloseSnackbar needs to be called with the key to force unmount the snackbar item.
        onExited(node, key) {
          closeSnackbar(key);
        },
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      changed = true;
      activeNotifications.push(notification.key.toString());
    }
    if (changed) setShownNotifications(activeNotifications);
  }, [notifications, dispatch, shownNotifications]);
  return null;
};

export default Notifications;

export const getRedirectAction: (text: string, to: string) => SnackbarAction = (text, to) => () => {
  return <SnackBarLink text={text} to={to} />;
};

const SnackBarLink: React.FC<{ text: string; to: string }> = ({ text, to }) => {
  const dispatch = useDispatch();
  // Can't use link here, as the notifications exist outside of the router
  return (
    <LinkButton
      className={styles.linkAction}
      onClickOrEnter={(e) => {
        e.preventDefault();
        dispatch(push(to));
      }}
    >
      {text}
    </LinkButton>
  );
};
