import getClassName from "classnames";
import * as React from "react";
import styles from "./style.scss";

interface Props {
  onClickOrEnter: (ev: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => void;
  className?: string;
  children: string | (string | number)[];
}

const LinkButton: React.FC<Props> = ({ onClickOrEnter, className, children }) => {
  const onKeyDown: React.KeyboardEventHandler<HTMLAnchorElement> = React.useCallback(
    (event) => {
      if (event.key === " " || event.key === "Enter") {
        // ' ' is the horrible string used to identify '<space bar>' :https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
        onClickOrEnter(event);
      }
    },
    [onClickOrEnter]
  );
  return (
    <a
      onClick={onClickOrEnter}
      role="button"
      className={getClassName(className, styles.pointer)}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      {children}
    </a>
  );
};

export default LinkButton;
