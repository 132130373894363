import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import { ensureTrailingDot } from "#helpers/utils.ts";
import styles from "./style.scss";

export interface AlertProps {
  className?: string;
  style?: React.CSSProperties;
  message?: string | React.ReactElement<any>;
  transparent?: boolean;
  hideIcon?: boolean;
  size?: "md" | "sm";
  error?: boolean;
  warning?: boolean;
  info?: boolean;
  success?: boolean;
  children?: never;
}
/**
 * @deprecated Please use @mui/material instead
 */
const Alert: React.FunctionComponent<AlertProps> = (props: AlertProps) => {
  const { className, message, style, error, warning, info, success, size } = props;

  if (!message) return null;

  const classNames: { [className: string]: boolean } = {
    [styles.alert]: !!styles.alert,
    [styles.transparent]: !!props.transparent,
    [styles.error]: (!error && !warning && !info && !success) || !!error, //Default style is error
    [styles.warning]: !!warning,
    [styles.info]: !!info,
    [styles.success]: !!success,
    [className || ""]: !!className,
    [styles.sm]: size === "sm",
  };

  return (
    <div style={style || {}} className={getClassName(classNames)}>
      {!props.hideIcon && (
        <div className={styles.icon}>
          <FontAwesomeIcon
            icon={
              props.transparent
                ? info || success
                  ? "info-circle"
                  : "exclamation-triangle"
                : ["fas", info || success ? "info-circle" : "exclamation-triangle"]
            }
          />
        </div>
      )}
      <div className={styles.content}>{typeof message === "string" ? ensureTrailingDot(message) : message}</div>
    </div>
  );
};

export default Alert;
