import React from "react";
import { parse, Store } from "@triplydb/data-factory";
import { Bindings, Term } from "@triplydb/engine/constants.js";
import { newEngine } from "@triplydb/speedy-memory"; // Only use this for typings, we want to lazy load speedy-memory and data-factory
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";

export type QuerySchema = (sparqlQuery: string) => Promise<Bindings<Term>[]>;

const useFetchSchema = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string>();
  const [engine, setEngine] = React.useState<ReturnType<typeof newEngine>>();
  const [containsShacl, setContainsShacl] = React.useState(false);
  const currentDs = useCurrentDataset();
  const schemaUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs?.owner.accountName}/${currentDs?.name}/schema.ttl`,
    fromBrowser: true,
  });

  React.useEffect(() => {
    const fetchSchema = async () => {
      let errorMessage: string | undefined;
      try {
        const response = await fetch(schemaUrl);
        if (!response.ok) {
          if (response.status === 400) {
            errorMessage = "The schema is too large to display.";
          }
          throw new Error("Schema request failed.");
        }
        const ld = await response.text();
        const store = new Store();
        try {
          const quads = parse(ld, { format: "turtle" });
          store.addQuads(quads);
        } catch (e) {
          errorMessage = e instanceof Error ? `Failed parsing the schema: ${e.message}` : "Failed parsing the schema.";
          throw e;
        }
        const speedy = await import("@triplydb/speedy-memory");
        const newEngine = speedy.newEngine(store as any); // Temporary fix as some packages have not yet updated at this moment

        const result = await newEngine.query(`
          prefix sh: <http://www.w3.org/ns/shacl#>
          select * where {
              ?shape sh:targetClass ?class .
          }
          limit 1
          `);
        const containsShacl = result.type === "bindings" && (await result.toArray()).length > 0;
        setEngine(newEngine);
        setContainsShacl(containsShacl);
      } catch (e) {
        console.error(e);
        setError(errorMessage || "Failed loading the schema.");
      } finally {
        setLoading(false);
      }
    };

    fetchSchema().catch(() => {});
  }, [schemaUrl]);

  const querySchema = React.useCallback(
    async (sparqlQuery: string) => {
      if (!engine) throw new Error("No data");
      const result = await engine.query(sparqlQuery);
      if (result.type !== "bindings") throw new Error("Expected bindings");
      return result.toArray();
    },
    [engine]
  );

  return {
    querySchema: engine && querySchema,
    containsShacl: containsShacl,
    loading: loading,
    error: error,
  };
};

export default useFetchSchema;
