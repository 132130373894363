import { Alert, MenuItem } from "@mui/material";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { Dataset } from "@triply/utils/Models.js";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField, Highlight, Label } from "#components/index.ts";
import { MuiAutosuggestRedux } from "#components/MuiAutosuggest/index.ts";
import fetch from "#helpers/fetch.ts";
import { memoize } from "#helpers/utils.ts";
import styles from "./style.scss";

namespace StarterDatasetForm {
  export interface FormData {
    starterDataset: Dataset;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    datasetPath: string;
  }
  // export interface State {}
}

function getFullName(d: Dataset) {
  return `${d.owner.accountName} / ${d.name}`;
}

class _StarterDatasetForm extends React.PureComponent<
  StarterDatasetForm.Props & Partial<ReduxForm.InjectedFormProps<FormData>>
> {
  search(url: string): Promise<Dataset[]> {
    return this.fetch(url).then((results) => {
      if (results && Array.isArray(results)) {
        return results;
      }
      return [];
    });
  }
  renderStarterDatasetField = ({ input, meta }: ReduxForm.WrappedFieldProps) => {
    return (
      <MuiAutosuggestRedux<Dataset, Dataset>
        placeholder="Type to search"
        input={input}
        meta={meta}
        loadSuggestions={this.searchDataset}
        TextFieldProps={{ fullWidth: true }}
        getSuggestionSearchText={getFullName}
        transformInitialValueToSearchText={getFullName}
        renderSuggestion={(ds, { query, isHighlighted }) => {
          return (
            <MenuItem selected={isHighlighted} component="div">
              <Highlight fullText={getFullName(ds)} highlightedText={query} />
            </MenuItem>
          );
        }}
      />
    );
  };

  onChange = (event: any) => {
    const filter: any[] = [];
    for (const object in event) {
      if (object === "0" || Number(object)) {
        filter.push(event[object].fullName);
      }
    }
    this.setState({ filter });
  };
  @memoize({ primitive: true, promise: true })
  fetch(url: string): Promise<Dataset[]> {
    return fetch(url, { credentials: "same-origin" })
      .then((response) => {
        if (response.status === 200) return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  searchDataset = (q: string) => this.search(`${this.props.datasetPath}?q=${encodeURIComponent(q)}`);

  render() {
    const { handleSubmit, submitting, submitSucceeded, error, pristine } = this.props;
    return (
      <div>
        <Alert className={styles.alert} severity="info">
          This dataset will be presented to users on their account page, with an option to import(copy) them
          immediately. This needs to be a public dataset! If it's not public, new users will have to create a dataset.
          The starter dataset is only shown if the user currently has no datasets.
        </Alert>
        <form method="POST" onSubmit={handleSubmit}>
          <FormField label="Starter dataset" className="mt-6 mb-6">
            <ReduxForm.Field name="starterDataset" component={this.renderStarterDatasetField} />
          </FormField>
          {error && (
            <Alert severity="error" variant="outlined">
              {error}
            </Alert>
          )}
          <div className={styles.buttons}>
            <div className={styles.alert}>
              <LoadingButton
                type="button"
                color="secondary"
                disabled={pristine}
                onClick={handleSubmit}
                loading={submitting}
              >
                Save
              </LoadingButton>
            </div>
          </div>
        </form>
        {submitSucceeded && pristine && (
          <div className="mt-4">
            <Label success message="You have successfully updated the starter dataset." />
          </div>
        )}
      </div>
    );
  }
}

const StarterDatasetForm = ReduxForm.reduxForm<StarterDatasetForm.FormData, StarterDatasetForm.Props>({
  form: "starterDataset",
  enableReinitialize: true,
})(_StarterDatasetForm as any);

export default StarterDatasetForm;
