import * as React from "react";
import { Prefix } from "@triply/utils/prefixUtils.js";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";

export function getPrefixAndLabel(prefixes: Prefix[], iri: string) {
  for (const p of prefixes) {
    if (iri.startsWith(p.iri)) {
      return {
        label: iri.slice(p.iri.length),
        prefix: p.prefixLabel,
      };
    }
  }
  return { label: iri };
}

export function useApplyPrefixes() {
  const prefixes = useDatasetPrefixes();
  return (iri: string) => {
    const { prefix, label } = getPrefixAndLabel(prefixes, iri);
    return prefix ? `${prefix}:${label}` : label;
  };
}

export function useRemovePrefixes() {
  const prefixes = useDatasetPrefixes();
  return <A extends string | undefined | null>(prefixed: A) => {
    if (prefixed === undefined || prefixed === null) return prefixed as A;
    for (const p of prefixes) {
      if (prefixed.startsWith(p.prefixLabel + ":")) {
        return `${p.iri}${prefixed.slice(p.prefixLabel.length + 1)}` as A;
      }
    }
    return prefixed as A;
  };
}

const Prefixed: React.FC<{ children: string }> = ({ children }) => {
  const prefixes = useDatasetPrefixes();
  const { prefix, label } = getPrefixAndLabel(prefixes, children);
  return <>{prefix ? `${prefix}:${label}` : label}</>;
};

export default Prefixed;
