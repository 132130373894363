import * as React from "react";
import { SelectResult } from "../../../components/Sparql/SparqlUtils";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import { useCurrentDataset } from "../../../reducers/datasetManagement";

const useSparql = () => {
  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/sparql`,
    fromBrowser: true,
  });

  const sparql = React.useCallback(
    async (query: string) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        method: "POST",
        headers: { Accept: "application/sparql-results+json" },
        body: new URLSearchParams({ query: query }),
      });
      const result = await response.json();
      return result as SelectResult;
    },
    [sparqlUrl]
  );

  return sparql;
};

export default useSparql;
