import {
  Autocomplete,
  AutocompleteProps,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField,
} from "@mui/material";
import match from "autosuggest-highlight/match/index.js";
import parse from "autosuggest-highlight/parse/index.js";
import getClassName from "classnames";
import { debounce } from "lodash-es";
import { stringToTerm } from "rdf-string";
import * as React from "react";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { validation } from "@triply/utils-private";
import { FontAwesomeIcon } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useFetch from "../../../helpers/hooks/useFetch";
import { formValuesToQueryVar, VariableType } from "./QueryVariables";
import styles from "./style.scss";

const MAX_ALLOWED_VALUES_LENGTH = 50;

interface Props
  extends Omit<
    AutocompleteProps<string, false, true, true>,
    "options" | "renderInput" | "onChange" | "freeSolo" | "value" | "disableClearable"
  > {
  datasetPath: string;
  variableType: VariableType;
  datatype?: string;
  language?: string;
  error?: string;
  onChange: (value: string[]) => void;
  value: string[];
}

const AllowedValues = React.forwardRef<unknown, Props>(
  ({ datasetPath, variableType, datatype, language, error, onChange, value, ...props }, ref) => {
    const [searchString, setSearchString] = React.useState<string>("");
    const [textValue, setTextValue] = React.useState<string>("");
    const constructUrlToApi = useConstructUrlToApi();
    const debouncedSetSearchString = React.useMemo(() => debounce(setSearchString, 250), [setSearchString]);
    const termsPath = constructUrlToApi({
      pathname: `/datasets/${datasetPath}/terms`,
      query: {
        q: `${variableType !== "NamedNode" ? '"' : ""}${searchString}`,
        dataType:
          variableType === "TypedLiteral" && datatype
            ? datatype
            : variableType === "StringLiteral"
            ? "http://www.w3.org/2001/XMLSchema#string"
            : undefined,
        languageTag: variableType === "LanguageStringLiteral" && language ? language : undefined,
        termType: variableType === "NamedNode" ? "NamedNode" : "Literal",
      },
    });
    const { data, error: fetchError } = useFetch<string[]>(termsPath, {}, [termsPath]);
    const options =
      Array.isArray(data) && !fetchError
        ? data?.map((termString) => stringToTerm(termString).value).filter((option) => !value.includes(option))
        : [];
    const validator = React.useMemo(
      () =>
        validation.toStringValidator(
          validation.getQueryVarValidations(
            formValuesToQueryVar({
              name: "newValue",
              variableType: variableType,
              datatype: datatype,
              language: language,
            })
          )
        ),
      [datatype, language, variableType]
    );
    const exceededMaxLength =
      value.length >= MAX_ALLOWED_VALUES_LENGTH
        ? `Only a maximum of ${MAX_ALLOWED_VALUES_LENGTH} values is allowed.`
        : undefined;
    const validationError = textValue !== "" ? validator(textValue) : undefined;
    const textError = error || validationError || exceededMaxLength;
    const addNewLine = React.useCallback(
      (newValue: string) => {
        if (newValue === "") return;
        if (value.includes(newValue)) return;
        if (validator(newValue)) return;

        onChange([...value, newValue]);
      },
      [onChange, value, validator]
    );
    return (
      <div className={styles.allowedValuesWrapper}>
        <Autocomplete<string, false, true, true>
          {...props}
          ref={ref}
          disabled={!!exceededMaxLength}
          freeSolo={true}
          disableClearable
          options={options}
          inputValue={textValue}
          onChange={(e, value) => {
            e.preventDefault();
            e.stopPropagation();
            if (value) {
              addNewLine(value || "");
              setTextValue("");
            }
          }}
          renderInput={({ inputProps, InputProps, ...rest }) => (
            <TextField
              {...rest}
              fullWidth
              error={!!textError}
              helperText={textError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTextValue(e.target.value);
                debouncedSetSearchString(e.target.value);
              }}
              inputProps={{
                ...inputProps,
                width: typeof inputProps.width === "number" ? `${inputProps.width}px` : inputProps.width,
                height: typeof inputProps.height === "number" ? `${inputProps.height}px` : inputProps.height,
              }}
              InputProps={{
                ...InputProps,
                onChange: (e) => {
                  setTextValue(e.target.value);
                  debouncedSetSearchString(e.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="Add value"
                      title="Add value"
                      onClick={(_) => {
                        addNewLine(inputProps.value as string);
                        setTextValue("");
                      }}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            // The render option is used to highlight text
            const matches = match(option, inputValue, { insideWords: true, findAllOccurrences: true });
            const parts = parse(option, matches);
            return (
              <li key={option} {...props} className={getClassName(styles.autocompleteItem, props.className)}>
                {parts.map((part, index) => (
                  <span key={part.text + index} className={part.highlight ? styles.autocompleteHighlight : undefined}>
                    {part.text}
                  </span>
                ))}
              </li>
            );
          }}
        />
        <AllowedValueContainer
          values={value}
          useDragHandle
          lockAxis="y"
          helperClass={styles.cursorOverride}
          lockToContainerEdges
          removeItem={(valueToRemove) => onChange(value.filter((val) => val !== valueToRemove))}
          validateValue={validator}
          onSortEnd={({ oldIndex, newIndex }) => {
            onChange(arrayMove(value, oldIndex, newIndex));
          }}
        />
      </div>
    );
  }
);

export default AllowedValues;

const DragHandle = SortableHandle(() => (
  <FontAwesomeIcon className={getClassName(styles.enabledDragHandle)} icon="bars" />
));

interface AllowedValueProps {
  value: string;
  onRemove: (value: string) => void;
  invalid: string | undefined;
}

const AllowedValue = SortableElement<AllowedValueProps>(({ value, onRemove, invalid }: AllowedValueProps) => (
  <ListItem
    className={getClassName(styles.listItem, styles.fixDraggedZOrder)}
    secondaryAction={
      <ListItemSecondaryAction>
        <IconButton onClick={() => onRemove(value)} size="small">
          <FontAwesomeIcon icon="times" />
        </IconButton>
      </ListItemSecondaryAction>
    }
  >
    <ListItemIcon>
      <DragHandle />
    </ListItemIcon>
    {value}
    {!!invalid && (
      <span aria-label={invalid} title={invalid} className="mx-3">
        <FontAwesomeIcon icon="exclamation-triangle" />
      </span>
    )}
  </ListItem>
));

interface AllowedValueContainerProps {
  values: string[];
  validateValue: (value: string) => string | undefined;
  removeItem: (value: string) => void;
}

const AllowedValueContainer = SortableContainer<AllowedValueContainerProps>(
  ({ values, validateValue, removeItem }: AllowedValueContainerProps) => {
    return (
      <List dense disablePadding className={styles.listContainer}>
        {values.map((value, index) => (
          <AllowedValue value={value} index={index} invalid={validateValue(value)} onRemove={removeItem} key={value} />
        ))}
      </List>
    );
  }
);
