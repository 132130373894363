import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import styles from "./style.scss";

export interface BaseProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "size" | "role" | "tabIndex" | "type"> {
  type?: "button" | "submit" | "reset";
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon: FontAwesomeIcon.Props["icon"];
  iconStyle?: React.CSSProperties;
  size?: FontAwesomeIcon.Props["size"];
  iconClassName?: string;
  iconOnStart?: unknown;
  disableHoverStyling?: boolean;
}
export interface TitledButton extends BaseProps {
  title: string;
  children?: never;
}
export interface LabeledButton extends BaseProps {
  "aria-label": string;
  children?: never;
}
export interface IconButtonWithText extends BaseProps {
  children: string | (string | number)[];
  iconOnStart?: boolean;
}

const FontAwesomeButton: React.FC<LabeledButton | TitledButton | IconButtonWithText> = ({
  icon,
  title,
  iconStyle,
  className,
  iconClassName,
  size,
  children,
  iconOnStart,
  disableHoverStyling,
  type,
  ...props
}) => {
  return (
    <button
      title={title}
      type={type || "button"}
      aria-label={props["aria-label"] || title}
      className={getClassName(styles.buttonStyle, "px-2", className, { [styles.disableHover]: !!disableHoverStyling })}
      {...props}
    >
      {!iconOnStart && children}
      {!iconOnStart && !!children && " "}
      <FontAwesomeIcon style={iconStyle} icon={icon} className={iconClassName} size={size} />
      {!!iconOnStart && !!children && " "}
      {!!iconOnStart && children}
    </button>
  );
};

export default FontAwesomeButton;
