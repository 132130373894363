import { Paper } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { AuthSettings } from "#components/Forms/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateAuthSettings } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";

interface Props {}

const SignUp: React.FC<Props> = ({}) => {
  const passwordSignup = useSelector((state: GlobalState) => state.config.clientConfig?.passwordSignup);
  const permittedSignupDomains = useSelector((state: GlobalState) => state.config.clientConfig?.permittedSignupDomains);

  const dispatch = useDispatch();
  const handleAuthSettingsSubmit = (values: AuthSettings.FormData) => {
    return dispatch<typeof updateAuthSettings>(
      updateAuthSettings(values.passwordSignup, values.permittedSignupDomains)
    ).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      }
    );
  };
  return (
    <Paper variant="outlined" className="p-5">
      <h4>Sign-up</h4>
      <AuthSettings
        initialValues={{
          passwordSignup: passwordSignup,
          permittedSignupDomains: permittedSignupDomains,
        }}
        onSubmit={handleAuthSettingsSubmit}
      />
    </Paper>
  );
};
export default SignUp;
