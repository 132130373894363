import { Alert, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { partition } from "lodash-es";
import * as React from "react";
import useAcl from "#helpers/hooks/useAcl.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { Prefixed } from "../../components";
import AddDatatypeProperty from "./AddDatatypeProperty";
import AddObjectProperty from "./AddObjectProperty";
import RemoveProperty from "./RemoveProperty";
import useApplyPrefixes from "./useApplyPrefixes";
import useCurrentClass from "./useCurrentClass";
import useSparql from "./useSparql";

export interface PropertyInfo {
  id: string;
  label?: string;
  description?: string;
  propertyShape: string;
  required: "true" | "false";
  inherited: "true" | "false";
  class: string;
  classLabel?: string;
  datatype?: string;
  range?: string;
  rangeLabel?: string;
}

const ClassInfo: React.FC<{}> = ({}) => {
  const currentClass = useCurrentClass();
  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const mayManageOntology = acl.check({
    action: "manageOntology",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const {
    data: nodeShapes,
    error: nodeShapesError,
    loading: loadingNodeShapes,
  } = useSparql<
    {
      id: string;
    }[]
  >(
    currentClass &&
      `
      prefix sh: <http://www.w3.org/ns/shacl#>
      select ?id where {
        bind(<${currentClass}> as ?currentClass)
        ?id sh:targetClass ?currentClass.
      }
    `
  );
  const nodeShape = nodeShapes?.[0]?.id;

  const {
    data: properties,
    error: propertiesError,
    loading: loadingProperties,
  } = useSparql<PropertyInfo[]>(
    currentClass &&
      `
      prefix owl: <http://www.w3.org/2002/07/owl#>
      prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      prefix sh: <http://www.w3.org/ns/shacl#>
      prefix skos: <http://www.w3.org/2004/02/skos/core#>

      select
        ?id
        (sample(?label_t) as ?label)
        (sample(?description_t) as ?description)
        ?propertyShape
        ?inherited
        ?required
        ?class
        (sample(?classLabel_t) as ?classLabel)
        ?range
        (sample(?rangeLabel_t) as ?rangeLabel)
        ?datatype
      where {
        bind(<${currentClass}> as ?currentClass)
        ?currentNodeShape sh:targetClass ?currentClass.
        ?currentClass rdfs:subClassOf*/^sh:targetClass ?nodeShape.
        ?nodeShape sh:targetClass ?class.

        bind((?nodeShape != ?currentNodeShape) as ?inherited)

        ?class rdfs:label|skos:prefLabel ?classLabel_t.

        ?nodeShape sh:property ?propertyShape .
        ?propertyShape sh:path ?id .

        optional {
          ?propertyShape sh:class ?range .
          optional {
            ?range rdfs:label|skos:prefLabel ?rangeLabel_t
          }
        }
        optional {
          ?propertyShape sh:datatype ?datatype .
        }
        optional {
          ?id rdfs:label|skos:prefLabel ?p_label_t
        }
        optional {
          ?propertyShape sh:name ?sh_label_t
        }
        bind(coalesce(?sh_label_t, ?p_label_t) as ?label_t)

        optional {
          ?propertyShape sh:description ?description_t
        }

        optional {
          ?propertyShape sh:minCount ?minCount
          filter(?minCount > 0)
        }

        bind(bound(?minCount) as ?required)
      }

      group by
      ?currentClass
      ?nodeShape
      ?propertyShape
      ?inherited
      ?required
      ?class
      ?id
      ?range
      ?datatype

      order by
      desc(?required)
      ?label
    `
  );

  const applyPrefixes = useApplyPrefixes();

  if (nodeShapesError || propertiesError) return <Alert severity="warning">Class info could not be loaded.</Alert>;

  if (loadingNodeShapes || loadingProperties) return <Skeleton />;
  if (!currentClass || properties === undefined || nodeShapes === undefined) return null;

  const [datatypeProperties, objectProperties] = partition(properties, (p) => !!p.datatype);

  return (
    <>
      <TableContainer sx={{ width: "max-content", flexShrink: 0 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                <div className="flex center g-5">
                  <div>Datatype properties</div>
                  {mayManageOntology && nodeShape && <AddDatatypeProperty classShapeIri={nodeShape} />}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datatypeProperties.map((datatypeProperty) => (
              <TableRow
                key={datatypeProperty.id}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "#f9f9f9",
                  },
                }}
              >
                <TableCell title={applyPrefixes(datatypeProperty.id)}>
                  {datatypeProperty.label || applyPrefixes(datatypeProperty.id)}
                </TableCell>
                <TableCell>{datatypeProperty.required === "true" ? "Required" : "Optional"}</TableCell>
                <TableCell>
                  <Prefixed>{datatypeProperty.datatype || ""}</Prefixed>
                </TableCell>
                <TableCell sx={{ maxWidth: 300 }}>{datatypeProperty.description}</TableCell>
                {/*<TableCell>{datatypeProperty.inherited === "true" ? "Inherited" : ""}</TableCell>*/}
                {datatypeProperty.inherited === "false" && (
                  <TableCell>{mayManageOntology && <RemoveProperty property={datatypeProperty} />}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={{ width: "max-content", flexShrink: 0 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                <div className="flex center g-5">
                  <div>Object properties</div>
                  {mayManageOntology && nodeShape && <AddObjectProperty classShapeIri={nodeShape} />}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {objectProperties.map((objectProperty) => (
              <TableRow
                key={objectProperty.id}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "#f9f9f9",
                  },
                }}
              >
                <TableCell title={applyPrefixes(objectProperty.id)}>
                  {objectProperty.label || applyPrefixes(objectProperty.id)}
                </TableCell>
                <TableCell>{objectProperty.required === "true" ? "Required" : "Optional"}</TableCell>
                <TableCell title={applyPrefixes(objectProperty.range || "")}>
                  <Prefixed>{objectProperty.rangeLabel || objectProperty.range || ""}</Prefixed>
                </TableCell>
                <TableCell sx={{ maxWidth: 300 }}>{objectProperty.description}</TableCell>
                {/*<TableCell>{objectProperty.inherited === "true" ? "Inherited" : ""}</TableCell>*/}
                {objectProperty.inherited === "false" && (
                  <TableCell>{mayManageOntology && <RemoveProperty property={objectProperty} />}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ClassInfo;
